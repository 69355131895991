// // @spellchecker: ignore prefs
import { Auth } from "./auth-state";
import { Demo } from "./demo-state";
import { Layout } from "./layout-state";
import Vue from "vue";
import { UIPreferences } from "$/interfaces/ui-api/response/ui-preferences";
import { Users } from "@/api";
import { User } from "@/models/user";
import { SECRETS_DEFAULT_FILTERS } from "@/pages/risks/secrets/secrets-constants";
import { vuetify } from "@/plugins";

export const Preferences = new (class PreferencesState {
  private readonly _defaults: UIPreferences = {
    pinned: true,
    dark: window.matchMedia?.("(prefers-color-scheme: dark)").matches,
    expandSSoRulesTable: true,
    scmType: null,
    onboardingDismissed: false,
    secrets: {
      filters: SECRETS_DEFAULT_FILTERS
    }
  };

  private readonly _queued: Partial<UIPreferences>[] = [];
  private _saving = false;

  public constructor() {
    Vue.observable(this);
  }

  private getQueued() {
    const prefs = this._queued.reduce((acc, curr) => ({ ...acc, ...curr }), {});
    this._queued.length = 0;
    return prefs;
  }

  public get defaults(): UIPreferences {
    return { ...this._defaults };
  }

  public get user(): UIPreferences | null {
    return Auth.user?.userInfo?.preferences ?? null;
  }

  public async load(userInfo?: User["userInfo"]) {
    let prefs = this.defaults;

    userInfo ??= await Users.me();

    if (userInfo?.preferences) {
      prefs = {
        ...prefs,
        ...userInfo.preferences
      };
      userInfo.preferences = prefs;
    }

    vuetify.framework.theme.dark = prefs.dark;
    Layout.drawer.pinned = prefs.pinned;
    //Members.isSkipped = prefs.skipInviteUsers;
    Layout.scmType = prefs.scmType;

    return prefs;
  }

  public async save(preferences: Partial<UIPreferences>, silent = false) {
    if (Demo.enabled) {
      // prevents saving preferences in demo mode
      return;
    }
    this._queued.push(preferences);
    if (this._saving) {
      return;
    }
    this._saving = true;

    try {
      preferences = this.getQueued();
      if (!Object.keys(preferences).length) {
        return;
      }

      const userInfo = await Users.me(undefined, undefined, silent);
      const currentPrefs = {
        dark: vuetify.framework.theme.dark,
        pinned: Layout.drawer.anchored,
        //skipInviteUsers: Members.isSkipped,
        scmType: Layout.scmType,
        onboardingDismissed: Layout.onboarding.dismissed
      };

      const update: UIPreferences = {
        ...this.defaults,
        ...currentPrefs,
        ...userInfo.preferences,
        ...preferences
      };

      await Users.updatePreferences(update, silent);

      const current = Auth.user?.userInfo;
      if (current) {
        current.preferences = update;
      }
    } finally {
      this._saving = false;
      const queued = this.getQueued();
      if (Object.keys(queued).length) {
        await this.save(queued);
      }
    }
  }
})();
