
<v-container class="component page home-page">
  <v-row v-if="$state.Layout.onboarding.isDoneOnboarding">
    <v-col>
      <v-card>
        <v-card-title>Congratulations!</v-card-title>
        <v-card-subtitle>You have completed the onboarding process</v-card-subtitle>
        <v-card-text>
          What next?
          <v-list>
            <v-list-item v-if="rootJobs && rootJobs.length === 0">
              <v-list-item-icon>
                <v-icon>mdi-factory</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <router-link :to="{ name: 'jobs' }"><a>Create a crawling job</a></router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else-if="rootJobs && rootJobs.filter((j) => j.status === 'complete').length > 0">
              <v-list-item-icon>
                <v-icon>mdi-server-security</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <router-link :to="{ path: '/risks' }"><a>View Risks</a></router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-icon>
                <v-icon>mdi-factory</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <router-link :to="{ name: 'jobs' }"><a>Monitor crawling job status</a></router-link>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-view-grid-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <router-link :to="{ name: 'admin-integrations' }"><a>Add another integration</a></router-link>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
