import { DismissedRiskReportItem } from "$/dynamo";
import { CreateDismissItemRequest } from "$/interfaces/ui-api/request";
import { SCMType } from "$/scm-type";
import { Dismissals as Api } from "@/api";
import { Insights } from "@/state";
import { RiskReports } from "@/state";

export const Dismissals = new (class DismissalsState {
  public githubDismissedItems: DismissedRiskReportItem[] | null = null;
  public adoDismissedItems: DismissedRiskReportItem[] | null = null;

  /**
   * Used to optimistically remove dismissed items from the reports while the insights and risk reports are generating
   */
  public recentlyDismissedItems = new Map<string, DismissedRiskReportItem>();

  public async init() {
    const [ado, github] = await Promise.all([Api.get(SCMType.AZURE_DEVOPS), Api.get(SCMType.GITHUB)]);
    this.adoDismissedItems = ado;
    this.githubDismissedItems = github;
  }

  public async dismissItem(dismissItemRequest: CreateDismissItemRequest) {
    const createdDismissals = await Api.create(dismissItemRequest);
    createdDismissals.forEach((dismissal) => {
      this.recentlyDismissedItems.set(dismissal.riskReportItemSCMTypeAndItemTypeAndId, dismissal);
    });
    this.silentlyGenerateInsightsAndReports().catch((e) => {
      console.error("An error occurred while generating insights", e);
    });
  }

  public async undismissItem(dismissedItemKey: string) {
    await Api.delete(dismissedItemKey);
    this.recentlyDismissedItems.delete(dismissedItemKey);
    this.silentlyGenerateInsightsAndReports().catch((e) => {
      console.error("An error occurred while generating insights", e);
    });
  }

  private async silentlyGenerateInsightsAndReports() {
    await Insights.generateInsights({ withSBOM: false, withBusinessImportance: false })
      .then((_) => {
        Insights.init(true);
        RiskReports.init({ silent: true });
      })
      .catch((e) => {
        console.error("An error occurred while generating insights", e);
      });
  }
})();
