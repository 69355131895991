import Vue from "vue";
import { DashboardResponse } from "$/dashboard/dashboard-response";
import { GenerateInsightReportOptions } from "$/interfaces/ui-api/request/generate-insight-report-options";
import { GetInsightResponseItem, GetInsightsReportResponse, InsightType } from "$/interfaces/ui-api/response/get-insight-response-item";
import { Insights as Api } from "@/api";

export const Insights = new (class InsightState {
  public insightsReport: GetInsightsReportResponse | null = null;
  public dashboardResponses: DashboardResponse[] | null = null;
  protected insightsBySeverity: GetInsightResponseItem[][] | null = null;
  protected insightsByType: Map<InsightType, GetInsightResponseItem> | null = null;
  public initialized = false;
  public constructor() {
    Vue.observable(this);
  }

  public async initDashboard(silent = false) {
    this.dashboardResponses = await Api.dashboard(silent);
  }

  public async regenerateDashboard(silent = false) {
    await Api.regenerateDashboard(silent);
  }
  public async init(silent = false) {
    this.insightsReport = await Api.get(silent);

    if (this.insightsReport && this.insightsReport.insights) {
      //  this.insightsReport.insights = this.insightsReport.insights.filter(insight => insight.severity > 1 /*&& insight.scmType === "AZURE_DEVOPS"*/);
      // this.insightsReport.insights.forEach(i=>{
      //   i.countItem *= 1045;
      //   i.countTotal *= 1045;
      // })

      this.insightsByType = this.insightsReport.insights.mapBy((i) => i.type);
      this.insightsReport.insights.sort((a, b) => a.severity - b.severity);
      this.insightsBySeverity = [[], [], [], []];
      for (const insight of this.insightsReport.insights) {
        if (insight.severity >= 1 && insight.severity <= 4) {
          this.insightsBySeverity[insight.severity - 1]?.push(insight);
        }
      }
    }

    this.initialized = true;
  }

  public async generateInsights(generateInsightReportOptions: GenerateInsightReportOptions) {
    await Api.generate(generateInsightReportOptions);
  }

  public getInsightBySeverity(severity: number): GetInsightResponseItem[] | null {
    return this.insightsBySeverity?.[severity - 1] ?? null;
  }

  public getInsightByType(insightType: InsightType): GetInsightResponseItem | null {
    return this.insightsByType?.get(insightType) ?? null;
  }
})();
