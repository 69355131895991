export enum TaskStatus {
  /**
   * The task has not been enqueued yet.
   */
  pending = "pending",

  /**
   * The task has been enqueued for execution.
   */
  enqueued = "enqueued",

  /**
   * The task is currently running.
   */
  running = "running",

  /**
   * The task is awaiting a dependency/sub-task to complete.
   */
  awaiting = "awaiting",

  /**
   * The task has completed successfully.
   */
  completed = "completed",

  /**
   * The task has settled with error(s).
   */
  failed = "failed",

  /**
   * The task has been cancelled.
   */
  cancelled = "cancelled",

  /**
   * The task has been skipped.
   * @note e.g. The task conditions did not match a policy.
   */
  skipped = "skipped"
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TaskStatus {
  /**
   * A set of all task states.
   */
  export const ALL: Set<TaskStatus> = new Set<TaskStatus>(Object.values(TaskStatus) as TaskStatus[]);

  /**
   * A set of all settled task states.
   * - completed
   * - failed
   * - cancelled
   * - skipped
   */
  export const SETTLED: Set<TaskStatus> = new Set([TaskStatus.completed, TaskStatus.failed, TaskStatus.cancelled, TaskStatus.skipped]);

  /**
   * A set of all unsettled task states.
   * - pending
   * - enqueued
   * - running
   * - awaiting
   */
  export const UNSETTLED: Set<TaskStatus> = ALL.not(SETTLED);

  /**
   * Tasks that have not finished their own execution (ignoring child task status).
   * - pending
   * - enqueued
   * - running
   */
  export const SELF_UNSETTLED: Set<TaskStatus> = UNSETTLED.not(new Set([TaskStatus.awaiting]));

  /**
   * A set of all errored task states.
   * - failed
   * - cancelled
   */
  export const ERRORED: Set<TaskStatus> = new Set([TaskStatus.failed, TaskStatus.cancelled]);

  /**
   * A set of all successful task states.
   * - completed
   * - skipped
   */
  export const SUCCESSFUL: Set<TaskStatus> = new Set([TaskStatus.completed, TaskStatus.skipped]);

  export const ui: Readonly<Record<TaskStatus, { displayName: string; tooltip: string; icon: string; color: string }>> = {
    [TaskStatus.pending]: { displayName: "Pending", tooltip: "The task has not been enqueued yet", icon: "mdi-progress-clock", color: "orange" },
    [TaskStatus.enqueued]: { displayName: "Enqueued", tooltip: "The task has been enqueued for execution", icon: "mdi-progress-download", color: "orange" },
    [TaskStatus.running]: { displayName: "Running", tooltip: "The task is currently running", icon: "mdi-run", color: "blue" },
    [TaskStatus.awaiting]: { displayName: "Awaiting", tooltip: "The task is awaiting a dependency/sub-task to complete", icon: "mdi-sleep", color: "cyan" },
    [TaskStatus.completed]: { displayName: "Completed", tooltip: "The task has completed successfully", icon: "mdi-check-circle-outline", color: "success" },
    [TaskStatus.failed]: { displayName: "Failed", tooltip: "The task has settled with error(s)", icon: "mdi-close-circle-outline", color: "error" },
    [TaskStatus.cancelled]: { displayName: "Cancelled", tooltip: "The task has been cancelled", icon: "mdi-minus-circle-outline", color: "grey" },
    [TaskStatus.skipped]: { displayName: "Skipped", tooltip: "The task has been skipped (e.g. no matching policy)", icon: "mdi-debug-step-over", color: "success" }
  };

  export function isSettled(state: TaskStatus): boolean {
    return SETTLED.has(state);
  }

  export function isErrored(state: TaskStatus): boolean {
    return ERRORED.has(state);
  }

  export function isSuccessful(state: TaskStatus): boolean {
    return SUCCESSFUL.has(state);
  }

  export function is(value: unknown): value is TaskStatus {
    return typeof value === "string" && TaskStatus[value as keyof typeof TaskStatus] === value;
  }

  Object.hideProps(TaskStatus, (k, v) => typeof v !== "string");
}
