import { LineRange, ScmUrlGenerator } from "../../data-access/src/scm-url-generator";

export class GitLabUrlGenerator implements ScmUrlGenerator {
  private static readonly gitLabBaseUrl = "https://gitlab.com";
  private readonly baseUrl: string = this.customBaseUrl || GitLabUrlGenerator.gitLabBaseUrl;
  public constructor(private readonly customBaseUrl: string | undefined) {}

  public getOrganizationUrl(organizationId: string): string {
    return `${this.baseUrl}/${organizationId.encodeURI()}`;
  }

  public getProjectUrl(_organizationId: string, projectId: string): string {
    return `${this.baseUrl}/${projectId}`;
  }

  public getRepositoryUrl(_organizationId: string, repositoryId: string): string {
    return `${this.baseUrl}/${repositoryId}`;
  }

  public getRepositoryBranchUrl(_organizationId: string, repositoryId: string, branch: string): string {
    return `${this.baseUrl}/${repositoryId}/-/tree/${branch.encodeURI()}?ref_type=heads`;
  }

  public getRepositoryCommitUrl(_organizationId: string, repositoryId: string, commitSha: string): string {
    return `${this.baseUrl}/${repositoryId}/-/commit/${commitSha.encodeURI()}`;
  }

  public getRepositoryBranchFileUrl(organizationId: string, repositoryId: string, branch: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, branch, path, lines);
  }
  public getRepositoryCommitFileUrl(organizationId: string, repositoryId: string, commitSha: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, commitSha, path, lines);
  }
  private getRepositoryFileUrl(_organizationId: string, repositoryId: string, commitReference: string, path: string, lines?: LineRange): string {
    const url = new URL(`${this.baseUrl}/${repositoryId}/-/blob/${commitReference.encodeURI()}/${path.encodePathURI()}`);
    if (lines) {
      url.hash = `L${lines.start}`;
    }
    return url.toString();
  }

  public getRepositoryPullRequestUrl(_organizationId: string, repositoryId: string, pullRequestId: number): string {
    return `${this.baseUrl}/${repositoryId}/-/merge_requests/${pullRequestId}`;
  }

  public getRepositoryPullRequestCommentUrl(_organizationId: string, repositoryId: string, pullRequestId: number, commentId: string): string {
    return `${this.baseUrl}/${repositoryId}/-/merge_requests/${pullRequestId}#note_${commentId.encodeURI()}`;
  }

  public getUserUrl(_organizationId: string, userId: string): string {
    return `${this.baseUrl}/${userId.encodeURI()}`;
  }

  public getRepositoryId(_projectId: string | undefined, repoId: string): string {
    return repoId;
  }
}
