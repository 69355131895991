import Vue from "vue";
import { DirectiveBinding } from "vue/types/options";
import { Scope } from "@/interfaces/scope";
import { Auth } from "@/state";


export class ScopeDirective {
  public static hasSome(currentScopes: PartialRecord<Scope, true | undefined>, requiredScopes: Scope[] = [Scope.arnica_admin]): boolean {
    return requiredScopes.some((scope) => !!currentScopes[scope]);
  }

  public static hasAll(currentScopes: PartialRecord<Scope, true | undefined>, requiredScopes: Scope[] = [Scope.arnica_admin]): boolean {
    return !!requiredScopes.length && requiredScopes.every((scope) => !!currentScopes[scope]);
  }

  public static inserted(el: HTMLElement, binding: DirectiveBinding): void {
    const requiredScopes = Object.keys(binding.modifiers) as Scope[];
    if (!requiredScopes.length) {
      console.warn("ScopeDirective: no scopes");
      return;
    }
    const currentScopes = Auth.scopes;
    const show = ScopeDirective.hasSome(currentScopes, requiredScopes);

    if (!show) {
      // based on https://stackoverflow.com/a/43543814/2355482
      const comment = document.createComment(`${el.tagName} ${requiredScopes.join(",")}`);
      el.parentNode?.replaceChild(comment, el);
    }
  }
}

Vue.directive("scope", {
  inserted: ScopeDirective.inserted
});
