<template>
  <v-card-text class="component prompt-dialog-content">
    <v-text-field color="success" :hint="hint" v-model="args.value" autofocus @focus="$event.target.select()" :rules="rules"></v-text-field>
  </v-card-text>
</template>

<script lang="ts">
import { PropType } from "vue";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { InputValidationRule } from "vuetify";

export interface PromptDialogArgs {
  value: string | null;
}

@Component
export class PromptDialogContent extends Vue {
  @Prop({
    type: Object,
    required: true
  })
  protected readonly args!: PromptDialogArgs;

  @Prop({
    type: String,
    default: null
  })
  protected readonly hint!: string | null;

  @Prop({
    type: Array as PropType<InputValidationRule[]>,
    default: () => []
  })
  protected readonly rules!: InputValidationRule[];

  @Prop({
    type: Boolean,
    default: false
  })
  protected valid!: boolean;

  @Watch("args.value", { immediate: true })
  private onChanged(value: string): void {
    this.emitValid(!this.rules || this.rules.every((r) => r(value)));
  }

  @Emit("update:valid")
  private emitValid(value: boolean): boolean {
    return value;
  }
}

export default PromptDialogContent;
</script>
