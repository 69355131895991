import { uuid } from "../uuid";
import { UIOrganization } from "./ui-organization";

export interface UILocationDTO {
  readonly id: uuid;
  readonly name: string;
  readonly fullName: string;
  readonly childProjectCount: number;
  readonly childRepositoryCount: number;
  readonly organization: UIOrganization;
}

export class UILocation {
  public readonly id: uuid;
  public readonly name: string;
  public readonly fullName: string;
  public readonly childProjectCount: number;
  public readonly childRepositoryCount: number;
  public readonly hasChildren: boolean;
  public readonly organization: UIOrganization;

  public constructor(dto: UILocationDTO) {
    Object.assign(this, dto);
    this.hasChildren = dto.childProjectCount + dto.childRepositoryCount > 0;
  }
}
