import { PolicyItemType } from "../../policy-item-base";
import type { PolicyRule } from "../../policy-rule";
import { PolicyAction, PolicyActionSubType } from "../policy-action";

declare module "../policy-action" {
  enum PolicyActionSubType {
    block_pr = "block_pr"
  }
}

(PolicyActionSubType as { block_pr: "block_pr" }).block_pr = "block_pr";

export interface PolicyActionBlockPR extends PolicyAction {
  sub: PolicyActionSubType.block_pr;
}

export const PolicyActionBlockPR = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.block_pr
  } as const,
  isOrIncludedIn(this: void, item: PolicyRule | PolicyAction): boolean {
    switch (item.type) {
      case PolicyItemType.action: {
        return PolicyAction.isOrParentOf(item, PolicyActionSubType.block_pr);
      }
      case PolicyItemType.rule: {
        return PolicyAction.inRule(item, PolicyActionSubType.block_pr);
      }
      default: {
        return false;
      }
    }
  },
  generateDefault(): PolicyActionBlockPR {
    return {
      sub: PolicyActionSubType.block_pr,
      id: `${PolicyItemType.action}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.action,
      name: "",
      children: []
    };
  }
} as const;
