import { JobSchedule } from "./job-schedule";
import { JobStatus } from "./job-status";
import { TaskProgress } from "./task-progress";
import moment from "moment";

export type JobDtoWithSchedule = JobDto & { schedule: JobSchedule };
export type JobId = `job://${JobDto["name"]}`;

export interface JobDto extends TaskProgress<JobStatus> {
  /**
   * Arnica Org ID
   */
  readonly tenantId: string;

  /**
   * The runtime ID of the job.
   * @example "job://CodeScanJob?foo=bar"
   */
  readonly id: JobId;

  /**
   * The globally unique name of the job.
   * @example "CodeScanJob"
   */
  readonly name: string;

  /**
   * The schedule ID of the job.
   * @example "schedule://96068235-79a6-46ba-bed9-8927d0518e7c"
   * @note This refers to current or last running schedule.
   */
  readonly schedule: JobSchedule | null;

  /**
   * The current status of the job.
   */
  readonly status: JobStatus;

  /**
   * The priority of the job. Higher numbers are higher priority. Tasks inherit this priority.
   * @note Currently anything > 0 is considered high priority.
   */
  readonly priority: number;

  /**
   * How many steps (of `total`) have been completed.
   */
  readonly step: number;

  /**
   * How many steps are required to complete the job.
   */
  readonly total: number;

  /**
   * The ISO timestamp of when job task was created.
   */
  readonly createdAt: string;

  /**
   * The identity of the user/process who created the job.
   */
  readonly createdBy: string;

  /**
   * The ISO timestamp of when the job should be executed next.
   * @note `null` means the job should not be executed.
   */
  readonly runAfter: string | null;
}

const MAX_DURATION = moment.duration(3, "days");

export const JobDto = {
  is(value: unknown): value is JobDto {
    return typeof value === "object" && !!value && "id" in value && typeof value.id === "string" && value.id.startsWith("job://");
  },
  getUri({ name }: Pick<JobDto, "name">, modifiers?: Record<string, string>): JobDto["id"] {
    const url = new URL(`job://${name.encodeURI()}`);
    if (modifiers) {
      for (const [key, value] of Object.entries(modifiers)) {
        url.searchParams.append(key, value);
      }
    }
    return url.toString() as JobDto["id"];
  },
  generate(tenantId: string, name: string, createdBy: string, priority: number): JobDto {
    const now = new Date().toISOString();
    return {
      tenantId,
      id: JobDto.getUri({ name }),
      name,
      schedule: null,
      status: JobStatus.pending,
      ...TaskProgress.getEmpty(),
      createdBy,
      createdAt: now,
      runAfter: now,
      priority,
      total: 1,
      step: 0
    };
  }
} as const;

export const JobDtoWithSchedule = {
  is(value: unknown): value is JobDtoWithSchedule {
    return JobDto.is(value) && !!value.schedule;
  }
} as const;

export const JobId = {
  is(value: unknown): value is JobId {
    return typeof value === "string" && value.startsWith("job://");
  },
  parse(uri: JobId): { type: "job"; schedule: null; name: string; args: Record<string, string> } {
    const url = new URL(uri);
    const name = url.host;
    if (!name) {
      throw new Error(`Invalid task URI: ${uri}`);
    }
    return {
      type: "job",
      schedule: null,
      name,
      args: Object.fromEntries(url.searchParams)
    };
  }
} as const;
