import { ApiBase } from "@/api/api-base";

class TopicsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "inventory/topics", name: "TopicsApi" });
  }

  public async list(): Promise<string[]> {
    const res = await this.client.get<string[]>("");
    const topics = new Set(res.data).toArray().sort();
    return topics;
  }
}

export const Topics = new TopicsApi();
