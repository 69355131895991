export const ProgrammingLanguage = {
  javascript: "JavaScript",
  typescript: "TypeScript",
  react: "React",
  json: "JSON",
  yaml: "YAML",
  vue: "Vue",
  html: "HTML",
  css: "CSS",
  scss: "SCSS",
  sass: "SASS",
  less: "LESS",
  markdown: "Markdown",
  text: "Text",
  c: "C",
  "c++": "C++",
  python: "Python",
  java: "Java",
  go: "Go",
  bash: "Bash",
  ruby: "Ruby",
  php: "PHP",
  "c#": "C#",
  xml: "XML",
  sql: "SQL",
  rust: "Rust",
  kotlin: "Kotlin",
  swift: "Swift",
  haskell: "Haskell",
  lua: "Lua",
  docker: "Docker",
  certificate: "Certificate",
  key: "Key",
  terraform: "Terraform",
  scala: "Scala",
  perl: "Perl",
  powershell: "PowerShell",
  elixir: "Elixir",
  clojure: "Clojure",
  dart: "Dart",
  groovy: "Groovy",
  julia: "Julia",
  ocaml: "OCaml",
  pascal: "Pascal",
  racket: "Racket",
  scheme: "Scheme",
  verilog: "Verilog",
  vhdl: "VHDL",
  elm: "Elm",
  erlang: "Erlang",
  "f#": "F#",
  fortran: "Fortran",
  lisp: "Lisp",
  nim: "Nim",
  reason: "Reason",
  r: "R",
  unknown: "Unknown",
  serverless: "Serverless"
} as const;

export type ProgrammingLanguage = keyof typeof ProgrammingLanguage;
export const ExtensionsToLanguage: Record<string, ProgrammingLanguage> = {
  js: "javascript",
  jsx: "react",
  ts: "typescript",
  tsx: "react",
  vue: "vue",
  json: "json",
  yaml: "yaml",
  yml: "yaml",
  html: "html",
  htm: "html",
  css: "css",
  scss: "scss",
  sass: "sass",
  less: "less",
  md: "markdown",
  txt: "text",
  c: "c",
  cpp: "c++",
  py: "python",
  java: "java",
  go: "go",
  sh: "bash",
  rb: "ruby",
  php: "php",
  cs: "c#",
  xml: "xml",
  sql: "sql",
  rs: "rust",
  kt: "kotlin",
  swift: "swift",
  hs: "haskell",
  hsc: "haskell",
  lua: "lua",
  docker: "docker",
  cer: "certificate",
  pem: "certificate",
  key: "key",
  tf: "terraform",
  scala: "scala",
  serverless: "serverless"
};

export const LanguageToExtensions: Record<ProgrammingLanguage, string[]> = Object.entries(ExtensionsToLanguage)  
.groupBy(([_, value]) => value)  
.mapValues((value) => value.map(([key]) => key)).toRecord();  

function processExtension(file: string) {
  const extension = file?.split(".").pop();
  const isYaml = ["yaml", "yml"].includes(extension ?? "");
  const isDockerFile = file.includes("dockerfile");
  const isDockerCompose = file.includes("docker-compose") && isYaml;
  const isDocker = isDockerFile || isDockerCompose;

  if (isDocker) {
    return "docker";
  }

  const isServerless = file.includes("serverless") && isYaml;
  if (isServerless) {
    return "serverless";
  }
  return extension;
}

export function getLanguageByPath(file: string): { language: ProgrammingLanguage; extension?: string } {
  const filename = file.split("/").pop()?.toLowerCase();

  if (!filename) {
    return { language: "unknown" };
  }

  if (filename === "go.mod") {
    return { language: "go", extension: "mod" };
  }

  const extensionToCheck = processExtension(filename);

  return getLanguageByExtension(extensionToCheck);
}

export function getLanguageByExtension(extension?: string): { language: ProgrammingLanguage; extension?: string } {
  return { language: ExtensionsToLanguage[extension || ""] ?? "unknown", extension };
}
