import "./app-bar-routes";
import * as FirstNavigation from "./first-navigation";
import "./help-routes";
import * as InitState from "./init-state-routes";
import "./nav-drawer-routes";
import * as SCMRoutes from "./scm-routes";
import * as ScopedRoutes from "./scoped-routes";
import "./sidebar-routes";
import * as TieredRoutes from "./tiered-routes";
import * as TitledRoutes from "./titled-routes";
import * as UpdateAnalytics from "./update-analytics";
import Router from "vue-router";

export function installGuards(router: Router): Router {
  router = FirstNavigation.install(router);
  router = TieredRoutes.install(router);
  router = ScopedRoutes.install(router);
  router = TitledRoutes.install(router);
  router = SCMRoutes.install(router);
  router = UpdateAnalytics.install(router);
  router = InitState.install(router);
  return router;
}
