import { SCMType } from "$/scm-type";

const REDIRECT_TEXT = {
  [SCMType.AZURE_DEVOPS]: "View in Azure DevOps",
  [SCMType.GITHUB]: "View in GitHub",
  [SCMType.BITBUCKET_SERVER]: "View in Bitbucket",
  [SCMType.BITBUCKET_CLOUD]: "View in Bitbucket",
  [SCMType.GITLAB]: "View in GitLab",
} as const;

export function scmRedirectTextFilter(scmType: SCMType): string {
  const text = REDIRECT_TEXT[scmType];
  if (!text) {
    throw new Error(`Unsupported SCM Type: ${scmType}`);
  }
  return text;
}
