<template>
  <div class="component tooltip" :style="`top: ${top}px; left: ${left}px;`">
    <v-scale-transition>
      <div v-show="!!message" class="tooltip-content">
        <template v-if="message">
          <div v-if="html" v-html-sanitize="message"></div>
          <span v-else v-for="(line, idx) of message" :key="idx" :style="`text-align: ${align}`">{{ line }}</span>
        </template>
      </div>
    </v-scale-transition>
  </div>
</template>

<script lang="ts">
import { VNode } from "vue";
import { Component, Vue } from "vue-property-decorator";
import type { DirectiveBinding } from "vue/types/options";
import { TooltipDirectiveConfigurations } from "@/directives/tooltip-directive";

@Component
export class Tooltip extends Vue {
  private unsubscribe!: () => void;
  protected message: string[] | null = null;
  protected html = false;
  protected el: HTMLElement | null = null;
  protected left = -100;
  protected top = -100;
  protected align = "center";
  private onHandle: number | null = null;
  private offHandle: number | null = null;

  public beforeMount(): void {
    const enter = this.onTooltipEnter.bind(this);
    const leave = this.onTooltipLeave.bind(this);
    this.$root.$on("tooltip.enter", enter);
    this.$root.$on("tooltip.leave", leave);
    this.unsubscribe = () => {
      this.$root.$off("tooltip.enter", enter);
      this.$root.$off("tooltip.leave", leave);
    };
  }

  public beforeDestroy(): void {
    this.unsubscribe();
  }

  private onTooltipEnter(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode, config: Required<TooltipDirectiveConfigurations> /*, ev: MouseEvent */): void {
    this.el = el;
    this.offHandle && window.clearTimeout(this.offHandle);
    this.offHandle = null;
    this.html = config.html;
    const message: string[] | null = (this.html ? binding.value : binding.value?.split("\n")) ?? null;
    this.onHandle = window.setTimeout(async () => {
      this.message = message;
      this.align = config.align;
      await this.$nextTick();
      const { top, left } = this.calcPosition(el, config);
      this.top = top;
      this.left = left;
    }, config.on);
  }

  private onTooltipLeave(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode, config: Required<TooltipDirectiveConfigurations> /*, ev: MouseEvent */): void {
    this.onHandle && window.clearTimeout(this.onHandle);
    this.onHandle = null;
    this.offHandle = window.setTimeout(() => {
      this.clear();
    }, config.off);
  }

  private calcPosition(el: HTMLElement, { top, left }: Required<TooltipDirectiveConfigurations>) {
    const target = el.getBoundingClientRect();
    const me = this.$el.getBoundingClientRect();
    return {
      // the target's bottom offset by the window's vertical scroll lowered by the configured vertical offset
      top: Math.max(0, Math.min(target.bottom + window.scrollY - top, window.innerHeight - me.height)),
      // the target's horizontal center offset by the target's horizontal position and the window's horizontal scroll
      // offset by the tooltip's horizontal center and the configured horizontal offset
      left: Math.max(0, target.width / 2 - me.width / 2 + target.left + window.scrollX + left)
    };
  }

  private clear() {
    this.message = null;
    this.top = this.left = -100;
  }
}

export default Tooltip;
</script>

<style lang="scss">
div.component.tooltip {
  z-index: 999999999999;
  position: absolute;


  .tooltip-content {
    border-radius: $border-radius-root;
    padding: 2px 10px;
    font-size: smaller;
    color: white !important;
    background-color: $tooltip-background-color;
    /*.theme--light &{
      background-color: darken($tooltip-background-color, 10%);
    }
    .theme--dark &{
      background-color: lighten($tooltip-background-color, 10%);
    }*/


    span {
      display: block;
    }
  }
}

//.theme--light {
//  div.component.tooltip {
//    //background: #4a4a4a;
//    color: white !important;
//  }
//}
//
//.theme--dark {
//  div.component.tooltip {
//    color: black;
//    //background: white;
//  }
//}
</style>
