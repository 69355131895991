import { ApiBase } from "./api-base";
import { CreateAPIKeyRequest } from "$/interfaces/ui-api/request/create-api-key-request";
import { CreateAPIKeysResponse } from "$/interfaces/ui-api/response/create-api-key-response";
import { GetAPIKeysResponse } from "$/interfaces/ui-api/response/get-api-keys-response";

class APIKeysAPI extends ApiBase {
  public constructor() {
    super({ pathPrefix: "api-keys", name: "APIKeysAPI" });
  }

  public async createAPIKey(createAPIKeyRequest: CreateAPIKeyRequest): Promise<CreateAPIKeysResponse> {
    const response = await this.client.post<CreateAPIKeysResponse>("", createAPIKeyRequest);
    return response.data;
  }

  public async getAPIKeys(): Promise<GetAPIKeysResponse[]> {
    const response = await this.client.get<GetAPIKeysResponse[]>("");
    return response.data;
  }

  public async delete(id: string) {
    await this.client.delete(`/${encodeURIComponent(id)}`);
  }
}

export const APIKeys = new APIKeysAPI();
