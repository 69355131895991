import { PolicyItemType } from "../../policy-item-base";
import type { PolicyRule } from "../../policy-rule";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    sla = "sla"
  }
}

(PolicyConditionSubType as { sla: "sla" }).sla = "sla";

export interface PolicyConditionFindingSLA extends PolicyCondition {
  sub: PolicyConditionSubType.sla;
  /**
   * SLA duration threshold (days)
   */
  threshold: number;
}

export const PolicyConditionFindingSLA = {
  /**
   * Minimum SLA duration (days)
   */
  SLA_MIN: 1,
  /**
   * Maximum SLA duration (days)
   */
  SLA_MAX: 366,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.sla
  },
  isOrIncludedIn(item: PolicyRule | PolicyCondition): boolean {
    switch (item.type) {
      case PolicyItemType.condition: {
        return PolicyCondition.isOrParentOf(item, PolicyConditionSubType.sla);
      }
      case PolicyItemType.rule: {
        return PolicyCondition.inRule(item, PolicyConditionSubType.sla);
      }
      default: {
        return false;
      }
    }
  }
} as const;
