import { ApiBase } from "./api-base";
import { SQLMigrationStatus } from "$/interfaces/ui-api/response/sql-migration-status";

class MigrationApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "admin/prisma", name: "MigrationApi" });
  }

  public async migrateToSQL(calculateTotal = false): Promise<void> {
    await this.client.post("/migrate/sql/data", undefined, { params: { calculateTotal } });
  }

  public async getSQLMigrationStatus(refreshTotalFromSQL = false): Promise<SQLMigrationStatus> {
    return (await this.client.get<SQLMigrationStatus>("/migrate/sql/data", { params: { refreshTotalFromSQL } }))?.data;
  }

  public async stopSQLMigration(reset = false): Promise<void> {
    await this.client.delete("/migrate/sql/data", { params: { reset } });
  }

  //TODO: this is not working currently...
  public async migrateSchema(): Promise<{ stdout: string; stderr: string; code: number | null }> {
    return (await this.client.post<{ stdout: string; stderr: string; code: number | null }>("/migrate/sql/schema"))?.data;
  }

  public async killIdleConnections(): Promise<void> {
    await this.client.delete("/manage/sql/connections");
  }
  public async getIdleConnections(): Promise<{ query: string; state: string; query_start: string }[]> {
    return (await this.client.get<{ query: string; state: string; query_start: string }[]>("/manage/sql/connections"))?.data;
  }
}

export const Migration = new MigrationApi();
