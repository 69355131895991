import Vue from "vue";
import { BusinessImportanceReportItem } from "$/business-importance";
import {
  ADOExcessiveLicensesInventoryReport,
  ADOInventoryReportQueryType,
  ADOTotalIdentitiesInventoryReport,
  ADOTotalRepositoriesInventoryReport,
  ADOWebhooksInventoryReport,
  GitHubAggregatedRepositoriesInventoryReport,
  GitHubAppInventoryReport,
  GitHubCodeownerErrorsInventoryReport,
  GitHubInactiveIdentitiesInventoryReport,
  GitHubInventoryReportQueryType,
  GitHubRepositoriesBranchProtectionInventoryReport,
  GitHubTotalIdentitiesInventoryReport,
  GitHubTotalOrgsInventoryReport,
  GitHubTotalRepositoriesInventoryReport,
  GitHubWebhooksInventoryReport,
  InventoryReport,
  InventoryReportItem,
  InventoryReportQueryType,
  UniversalScmInventoryReportQueryType,
  UniversalScmRepositoriesInventoryReport,
  UniversalScmUsersInventoryReport
} from "$/report/inventory-report/inventory-report";
import { SBOMReportResponse } from "$/sbom/interfaces/sbom-report-item";
import { SCMType } from "$/scm-type";
import { Reports } from "@/api";
import { BaseRequestParams } from "@/api/api-base";

const UNINITIALIZED = Symbol("UNINITIALIZED");

export const InventoryReports = new (class InventoryReportsState {
  #sbomReport: SBOMReportResponse | null = null;

  private _businessImportanceReport: Promise<BusinessImportanceReportItem[] | null> | null = null;
  public get businessImportanceReport(): Promise<BusinessImportanceReportItem[] | null> {
    return (this._businessImportanceReport ??= Reports.getBusinessImportanceReport());
  }

  public scmType: SCMType | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public refreshBusinessImportanceReport() {
    this._businessImportanceReport = Reports.getBusinessImportanceReport();
  }

  //report per integrationOrgId
  private inventoryReports: PartialRecord<InventoryReportQueryType, InventoryReport<InventoryReportItem> | null | typeof UNINITIALIZED> = {
    [GitHubInventoryReportQueryType.GH_TOTAL_REPOSITORIES]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_TOTAL_IDENTITIES]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_TOTAL_ORGS]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_TOTAL_WORKFLOWS]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_INACTIVE_IDENTITIES]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_REPOSITORIES_NO_BRANCH_PROTECTION]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_AGGREGATED_REPOSITORIES]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_CODEOWNER_ERRORS]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_APP]: UNINITIALIZED,
    [GitHubInventoryReportQueryType.GH_WEBHOOKS]: UNINITIALIZED,
    [ADOInventoryReportQueryType.ADO_TOTAL_IDENTITIES]: UNINITIALIZED,
    [ADOInventoryReportQueryType.ADO_TOTAL_REPOSITORIES]: UNINITIALIZED,
    [ADOInventoryReportQueryType.ADO_EXCESSIVE_LICENSES]: UNINITIALIZED,
    [ADOInventoryReportQueryType.ADO_WEBHOOKS]: UNINITIALIZED,
    [UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_REPOSITORIES]: UNINITIALIZED,
    [UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_USERS]: UNINITIALIZED
  };

  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_TOTAL_IDENTITIES): GitHubTotalIdentitiesInventoryReport | null;
  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_AGGREGATED_REPOSITORIES): GitHubAggregatedRepositoriesInventoryReport | null;
  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_TOTAL_REPOSITORIES): GitHubTotalRepositoriesInventoryReport | null;
  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_REPOSITORIES_NO_BRANCH_PROTECTION): GitHubRepositoriesBranchProtectionInventoryReport | null;
  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_TOTAL_ORGS): GitHubTotalOrgsInventoryReport | null;
  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_INACTIVE_IDENTITIES): GitHubInactiveIdentitiesInventoryReport | null;
  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_CODEOWNER_ERRORS): GitHubCodeownerErrorsInventoryReport | null;
  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_APP): GitHubAppInventoryReport | null;
  public getInventoryReport(queryType: GitHubInventoryReportQueryType.GH_WEBHOOKS): GitHubWebhooksInventoryReport | null;
  public getInventoryReport(queryType: ADOInventoryReportQueryType.ADO_TOTAL_IDENTITIES): ADOTotalIdentitiesInventoryReport | null;
  public getInventoryReport(queryType: ADOInventoryReportQueryType.ADO_TOTAL_REPOSITORIES): ADOTotalRepositoriesInventoryReport | null;
  public getInventoryReport(queryType: ADOInventoryReportQueryType.ADO_EXCESSIVE_LICENSES): ADOExcessiveLicensesInventoryReport | null;
  public getInventoryReport(queryType: ADOInventoryReportQueryType.ADO_WEBHOOKS): ADOWebhooksInventoryReport | null;
  public getInventoryReport(queryType: UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_REPOSITORIES): UniversalScmRepositoriesInventoryReport | null;
  public getInventoryReport(queryType: UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_USERS): UniversalScmUsersInventoryReport | null;
  public getInventoryReport(queryType: InventoryReportQueryType): InventoryReport<InventoryReportItem> | null;
  public getInventoryReport(queryType: InventoryReportQueryType): InventoryReport<InventoryReportItem> | null {
    const report = this.inventoryReports[queryType] || null;
    return report === UNINITIALIZED ? null : report;
  }

  public async loadReport(queryType: InventoryReportQueryType, params: BaseRequestParams = {}): Promise<void> {
    this.inventoryReports[queryType] = await Reports.getInventory(queryType, params);
  }

  public async init(params: BaseRequestParams = {}) {
    const inventoryReportResponses = await Reports.getInventoryReports(params);
    for (const reportResponse of inventoryReportResponses) {
      this.inventoryReports[reportResponse.summary.queryType] = reportResponse;
    }
  }

  public async initReport(reportType: InventoryReportQueryType, params: BaseRequestParams = {}): Promise<void> {
    const report = this.inventoryReports[reportType];
    if (report === UNINITIALIZED) {
      this.inventoryReports[reportType] = await Reports.getInventory(reportType, params);
    }
  }

  public async initSBOMReport() {
    this.#sbomReport = await Reports.getSBOMReport();
    if (this.#sbomReport) {
      console.log(`SBOM report loaded. Size: ${this.#sbomReport.findings?.length ?? -1}`);
    }
  }

  public get sbomReport(): SBOMReportResponse | null {
    return this.#sbomReport;
  }

  public set sbomReport(report: SBOMReportResponse | null) {
    this.#sbomReport = report;
  }
})();
