import { PolicyItemType } from "../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "./policy-condition";

declare module "./policy-condition" {
  enum PolicyConditionSubType {
    always = "always",
    and = "and",
    or = "or",
    not = "not"
  }
}

(PolicyConditionSubType as { always: "always" }).always = "always";
(PolicyConditionSubType as { and: "and" }).and = "and";
(PolicyConditionSubType as { or: "or" }).or = "or";
(PolicyConditionSubType as { not: "not" }).not = "not";

export interface PolicyConditionBoolean extends PolicyCondition {
  sub: PolicyConditionSubType.always | PolicyConditionSubType.and | PolicyConditionSubType.or | PolicyConditionSubType.not;
  children: PolicyCondition[];
}

export const PolicyConditionBoolean = {
  components: [
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.always
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.or
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.and
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.not
    }
  ] as const,
  generateDefault(): PolicyConditionBoolean {
    return {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.always,
      id: `${PolicyItemType.condition}-${Math.floor(Date.now() * Math.random())}`,
      children: []
    };
  }
} as const;
