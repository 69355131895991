import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    path = "path"
  }
}

(PolicyConditionSubType as { path: "path" }).path = "path";

export interface PolicyConditionFindingPath extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.path;
  /**
   * A path RegExp to match against the finding's path.
   */
  path: string;
}

export const PolicyConditionFindingPath = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.path
  } as const
} as const;
