import { CodeRiskFinding } from "../../../sast/code-risk-finding";
import { PolicyEventContext } from "../policy-event-context";
import { PolicyItemType } from "../policy-item-base";
import { PolicyTrigger, PolicyTriggerSubType } from "./policy-trigger";
import { DismissalData } from "./policy-trigger-user-dismissed-finding";

declare module "./policy-trigger" {
  enum PolicyTriggerSubType {
    dismissal_request_answered = "dismissal_request_answered"
  }
}

(PolicyTriggerSubType as { dismissal_request_answered: "dismissal_request_answered" }).dismissal_request_answered = "dismissal_request_answered";

export interface PolicyTriggerDismissalRequestAnswered extends PolicyTrigger {
  sub: PolicyTriggerSubType.dismissal_request_answered;
}

export const PolicyTriggerDismissalRequestAnswered = {
  component: {
    type: PolicyItemType.trigger,
    sub: PolicyTriggerSubType.dismissal_request_answered
  }
} as const;

export type PolicyEventDismissalRequestAnsweredTriggerContext = PolicyEventContext<CodeRiskFinding> & { dismissalData: DismissalData };
