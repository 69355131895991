import { CodeRiskFindingType, SecretFindingType } from "../finding-types";
import type { TaskProgress } from "../jobs-v2";
import { Integration, IntegrationOrgIdAndType, ScmIntegrationType, integrationSortKey } from "./integration/integration";
import { JobStatus } from "./job-status";

export type JobType = "ingest" | "mitigate" | "revert-mitigation" | "activity-scan" | "detect" | "detect-repo" | "detect-repo-scanner" | "permission-grant";

export type JobTypePropertiesMap = Record<JobType, JobTypeProperties>;

export interface JobTypeProperties {
  /**
   * If a job fails, wait this many minutes before rerunning it
   */
  timeToRerunJob: number;
  numberOfTimesToRerunOnError: number;
  triggersInsight: boolean;
  triggersSBOM?: boolean;
  triggersImportance?: boolean;
}

export const JOB_TYPE_PROPERTIES_MAP: JobTypePropertiesMap = {
  ingest: { numberOfTimesToRerunOnError: 0, triggersInsight: true, triggersSBOM: true, triggersImportance: true, timeToRerunJob: 30 },
  detect: { numberOfTimesToRerunOnError: 0, triggersInsight: true, triggersSBOM: true, triggersImportance: true, timeToRerunJob: 30 },
  "detect-repo": { numberOfTimesToRerunOnError: 0, triggersInsight: true, triggersSBOM: true, triggersImportance: true, timeToRerunJob: 0 },
  "detect-repo-scanner": { numberOfTimesToRerunOnError: 1, triggersInsight: false, triggersSBOM: false, triggersImportance: false, timeToRerunJob: 30 },
  "activity-scan": { numberOfTimesToRerunOnError: 0, triggersInsight: true, triggersSBOM: true, triggersImportance: true, timeToRerunJob: 30 },
  mitigate: { numberOfTimesToRerunOnError: 0, triggersInsight: true, timeToRerunJob: 0 },
  "revert-mitigation": { numberOfTimesToRerunOnError: 0, triggersInsight: true, timeToRerunJob: 0 },
  "permission-grant": { numberOfTimesToRerunOnError: 0, triggersInsight: true, timeToRerunJob: 0 }
} as const;

export const JOB_TYPE_CHILD = {
  ingest: null,
  detect: "detect-repo",
  "detect-repo": "detect-repo-scanner",
  "detect-repo-scanner": null,
  "activity-scan": null,
  mitigate: null,
  "revert-mitigation": null,
  "permission-grant": null
} as const satisfies Record<JobType, JobType | null>;

type NonNullValues<T> = {
  [K in keyof T]: T[K] extends null ? never : T[K];
}[keyof T];

export type ChildJobType = NonNullValues<typeof JOB_TYPE_CHILD>;
export const CHILD_JOBS: ChildJobType[] = Object.values(JOB_TYPE_CHILD).filter((j) => j !== null) as ChildJobType[];
const JOB_TYPES_THAT_SHOULD_RUN_ON_EC2: JobType[] = ["detect"];

const JOB_NAMES: Record<JobType, string> = {
  ingest: "Inventory Scan",
  detect: "Source Code Scan",
  "detect-repo": "Repository Source Code Scan",
  "detect-repo-scanner": "Repository Source Code Scan (By Detector)",
  "activity-scan": "Activity Scan",
  mitigate: "Mitigate",
  "revert-mitigation": "Revert",
  "permission-grant": "Permission Grant"
};

/**
 * @usage "The Arnica ${jobType} job has finished."
 */
const COMPLETED_JOB_NAMES: Record<JobType, string> = {
  ingest: "Inventory Scan",
  detect: "Source Code Scan",
  "detect-repo": "Repository Source Code Scan",
  "detect-repo-scanner": "Repository Source Code Scan (By Detector)",
  "activity-scan": "Activity Scan",
  mitigate: "Mitigation",
  "revert-mitigation": "Revert",
  "permission-grant": "Permission Grant"
};

export function jobTypeShouldRunOnEc2(jobType: JobType): boolean {
  return JOB_TYPES_THAT_SHOULD_RUN_ON_EC2.includes(jobType);
}

export function getJobTypeName(type: JobType): string {
  return JOB_NAMES[type];
}

// usage: "The Arnica ${jobType} job has finished."
export function getCompletedJobTypeName(type: JobType): string {
  return COMPLETED_JOB_NAMES[type];
}

/**
 * Tag along jobs are jobs that are scheduled and created together, but have their own status and run in parallel
 */
export const tagAlongJobTypesMapping: Record<JobType, JobType[] | null> = {
  ingest: ["detect", "activity-scan"],
  detect: null,
  "detect-repo": null,
  "detect-repo-scanner": null,
  "activity-scan": null,
  "revert-mitigation": null,
  mitigate: null,
  "permission-grant": null
};

export function jobTypeHasDependencies(jobType: JobType): boolean {
  const dependency = Job.dependencies[jobType];
  return dependency !== null;
}

export function getJobSortKey(job: Pick<Job, "type" | "integrationOrgId" | "integrationType" | "projectId" | "repoId" | "detectorTask">) {
  const { type, integrationOrgId, integrationType, projectId, repoId, detectorTask } = job;
  return `${type}_${integrationSortKey(integrationOrgId, integrationType)}${projectRepoDetectorSortKey(projectId, repoId, detectorTask)}`;
}

function projectRepoDetectorSortKey(projectId?: string, repoId?: string, detectorTask?: DetectorTask) {
  const projectSortKey = projectRepoSortKey(projectId, repoId);
  if (!projectSortKey) {
    return "";
  }
  return detectorTask ? `${projectSortKey}_${detectorTask}` : projectSortKey;
}

function projectRepoSortKey(projectId?: string, repoId?: string) {
  if (!projectId && !repoId) {
    return "";
  }
  if (!projectId) {
    return `/${repoId}`;
  }
  return `/${projectId}/${repoId}`;
}

export function getJobSortKeyFromJobTypeAndIntegration(jobType: JobType, integration: Pick<Integration, "integrationOrgId" | "integrationType">) {
  return `${jobType}_${integrationSortKey(integration.integrationOrgId, integration.integrationType)}`;
}

export function getIngestJobSortKeyFromIntegration(integration: Integration) {
  return `ingest_${integrationSortKey(integration.integrationOrgId, integration.integrationType)}`;
}

export function getIngestJobSortKeyFromIntegrationOrgIdAndType(integrationOrgIdAndType: string) {
  return `ingest_${integrationOrgIdAndType}`;
}

export function getJobHistorySortKey(job: Pick<Job, "arnicaOrgId" | "type" | "integrationOrgId" | "integrationType">) {
  const { type, integrationOrgId, integrationType } = job;
  return `${type}_${integrationSortKey(integrationOrgId, integrationType)}_${new Date().toISOString()}`;
}

export function isChildJobType(jobType: JobType): boolean {
  return !!JOB_TYPE_CHILD[jobType];
}

//TODO: hashKey should be arnicaOrgId, the rest should be a sort key

export enum DetectorTask {
  SAST = "SAST",
  SCA_LICENSE_AND_REPUTATION = "SCA_LICENSE_AND_REPUTATION",
  SECRET = "SECRET",
  IAC = "IAC",
  INFRASTRUCTURE = "INFRASTRUCTURE"
}
export const DETECTOR_TASK_NAMES: Record<DetectorTask, string> = {
  SAST: "SAST (Static Application Security Testing)",
  SCA_LICENSE_AND_REPUTATION: "SCA (Software Composition Analysis), License and Reputation",
  SECRET: "Secret Detection",
  INFRASTRUCTURE: "Prioritization Context",
  IAC: "Infrastructure as Code"
};

export interface Job {
  /**
   * HashKey: arnicaOrgId
   */
  arnicaOrgId: string;
  /**"type","integrationOrgId","integrationType","projectId?","repoId?","findingType"**/
  sortKey: string;
  status: JobStatus;

  type: JobType;

  integrationOrgIdAndType: IntegrationOrgIdAndType;
  integrationOrgId: string;
  integrationType: ScmIntegrationType;
  projectId?: string;
  projectName?: string;
  repoId?: string;
  repoName?: string;
  detectorTask?: DetectorTask;
  parentJobSortKey?: string;

  //NOTE: this should never come directly from a user!!!
  jobAttributes?: JobAttributes;

  //use Date.toISOString()
  createdAt: string;
  /**
   * for jobs that get restarted, keep the original createdAt of the previous job
   */
  originalCreatedAt?: string;
  //use Date.toISOString()
  updatedAt: string;
  error?: string;
  //TODO: ttl
  //ttl: number;
  createdBy: string;
  updatedBy?: string;
  isSelfHosted: boolean;
  /**
   * Lower number means higher priority
   */
  overridePriority?: number;
  /**
   * The number of times this job has failed (does not include child jobs).
   * Child job failures are counted in their relevant job object.
   */
  errorCount?: number;
  /**
   * If a job encountered an error (such as throttling), don't restart it until this date/time
   * The data should always be stored as an ISO 8601 string
   */
  errorRestartDate?: string;
  v?: number;
  v2Progress?: TaskProgress & { step: number; total: number };
}

export type RepoDetectorTaskJob = Job & RequiredPick<Job, "repoId" | "detectorTask" | "repoName"> & { integrationType: ScmIntegrationType };
export const RepoDetectorTaskJob = {
  is(job: Job): job is RepoDetectorTaskJob {
    return job.type === "detect-repo-scanner" && !!job.repoId && !!job.detectorTask && !!job.repoName;
  }
} as const;

export type JobHistory = Job;

export type RepoAndDataType = { projectId?: string; repoId: string; dataType: CodeRiskFindingType | SecretFindingType };
export type JobAttributes = {
  [key: string]: any;
  mitigationSortKey?: string;
  permissionGrantedSortKey?: string;
  startedByContainerId?: string;
};

export const Job = {
  TENANT_MAXIMUM_OCCUPANCY_FACTOR: 0.2 as const,
  /**
   * Jobs with dependencies require the previous job to be completed before they can run
   */
  dependencies: {
    detect: null,
    "detect-repo": null,
    "detect-repo-scanner": null,
    "activity-scan": "ingest",
    ingest: null,
    "revert-mitigation": null,
    mitigate: null,
    "permission-grant": null
  } as const satisfies Record<JobType, JobType | null>,
  sortForType(jobs: Job[], type: JobType): void {
    if (type !== "detect-repo-scanner") {
      return;
    }
    //Prioritizing completing all jobs for a given repo before moving to the next one
    jobs.sort((a, b) => {
      if (!a.repoId || !b.repoId) {
        return 0;
      }
      return a.repoId.localeCompare(b.repoId);
    });
  }
} as const;
