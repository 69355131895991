
<component :is="component" to="/" :href="href">
  <template v-if="background === 'dark'">
    <img v-show="mini" src="/img/ArnicaA-DarkMode-NoBG.png" alt="Arnica logo" style="width: 40px" />
    <img v-show="!mini" class="logo" src="/img/ArnicaFull-DarkMode-NoBG.png" alt="Arnica Logo" />
  </template>
  <template v-else>
    <img v-show="mini" src="/img/ArnicaA-OnWhite-NoBG.png" alt="Arnica logo" style="width: 40px" />
    <img v-show="!mini" class="logo" src="/img/ArnicaFull-OnWhite-NoBG.png" alt="Arnica Logo" />
  </template>
</component>
