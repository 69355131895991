import {
  IntegrationCategory,
  IntegrationMetadata,
  IntegrationOrgIdAndType,
  IntegrationStatistics,
  IntegrationType,
  ScmIntegrationType
} from "../../../dynamo";
import { IntegrationAttributes, ScmIntegrationAttributes } from "../../../dynamo/integration/integration-attributes";
import { SCMType } from "../../../scm-type";
import { capitalizeFirstLetter } from "../../../utils";

export type InstallationStatus = "installed" | "invalid" | "not found" | "unknown" | "unsupported";

export interface GetIntegrationResponse<T extends IntegrationType = IntegrationType, M extends IntegrationMetadata = Pick<IntegrationMetadata, "gitHub" | "slack">> {
  integrationOrgIdAndType: IntegrationOrgIdAndType<T>;
  integrationOrgId: string;
  integrationType: T;
  installationId?: number;
  metadata?: M;
  statistics?: Pick<IntegrationStatistics, "gitHub">;
  isSelfHosted?: boolean;
  status: InstallationStatus;
  category: IntegrationCategory;
  displayName?: string;
  baseUrl?: string;
}

export function getCalculatedStatus(item: GetIntegrationResponse): InstallationStatus {
  if (isUserAccount(item)) {
    return "unsupported";
  } else if (item.isSelfHosted) {
    return "installed";
  } else if (["communication", "issues", "ai"].includes(IntegrationAttributes[item.integrationType].category)) {
    //TODO: check installation status in the backend
    return "installed";
  }
  return item.status;
}

export function isUserAccount(item: GetIntegrationResponse): boolean {
  if (item.integrationType === "github") {
    if (item.metadata?.gitHub?.accountType === "User") {
      return true;
    }
  }
  return false;
}

export function organizationLabel(scmIntegration: ScmIntegrationType) {
  return IntegrationAttributes.get(scmIntegration).organizationLabel;
}


export function getIntegrationText(item: Pick<GetIntegrationResponse, "integrationType" | "metadata" | "isSelfHosted"> | IntegrationType): string {
  if (typeof item === "string") {
    item = { integrationType: item };
  }

  if (item.integrationType === "github") {
    let result = "GitHub";
    const gitHub = item.metadata?.gitHub;
    const planName = gitHub?.planName;
    if (planName) {
      result += ` ${capitalizeFirstLetter(planName)}`;
    }
    if (planName === "enterprise") {
      result += item.isSelfHosted ? " Server" : " Cloud";
    }
    const accountType = gitHub?.accountType;
    if (accountType === "User") {
      result += ` (${accountType})`;
    }
    return result;
  }

  return IntegrationAttributes[item.integrationType].displayName;
}

//TODO: move to ui-vue
export function getIntegrationIcon(item: { integrationType: IntegrationType } | IntegrationType | SCMType): `mdi-${string}` {
  if (typeof item === "string") {
    const integrationType = IntegrationAttributes.get(item)?.type;
    if (!integrationType) {
      throw new Error(`Unknown integration type: ${item}`);
    }
    item = { integrationType };
  }
  return IntegrationAttributes[item.integrationType].ui.icon;
}
