import { ApiBase } from "./api-base";
import axios, { AxiosRequestConfig } from "axios";
import {
  Dashboard2Activity,
  Dashboard2Asset,
  Dashboard2TrendByResource,
  Dashboard2TrendBySeverity,
  Dashboard2TrendByStatus,
  DashboardResponse
} from "$/dashboard/dashboard-response";
import { DashboardAssetFilter } from "$/dynamo/findingBase";
import { CommonStatusMeanings } from "$/finding-types";
import { GenerateInsightReportOptions } from "$/interfaces/ui-api/request/generate-insight-report-options";
import { GetInsightsReportResponse } from "$/interfaces/ui-api/response/get-insight-response-item";
import { Popup } from "@/state";

class InsightsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "insights", name: "InsightsApi" });
  }

  public async get(silent = false): Promise<GetInsightsReportResponse> {
    try {
      const res = await this.client.get<GetInsightsReportResponse>(``, { silent });
      return res.data;
    } catch (e) {
      void Popup.error(String(e));
      return {
        insights: [],
        dateGenerated: new Date().toISOString()
      };
    }
  }

  public async dashboard(silent = false): Promise<DashboardResponse[]> {
    try {
      const res = await this.client.get<DashboardResponse[]>(`dashboard`, { silent });
      return res.data;
    } catch (e) {
      void Popup.error(String(e));
      return [];
    }
  }

  public async regenerateDashboard(silent = false): Promise<void> {
    try {
      await this.client.post<void>(`dashboard`, {}, { silent });
    } catch (e) {
      void Popup.error(String(e));
    }
  }

  public async generate(generateInsightReportOptions?: GenerateInsightReportOptions): Promise<void> {
    try {
      await this.client.post<void>(``, generateInsightReportOptions, { silent: true });
    } catch (e) {
      void Popup.error(String(e));
    }
  }

  public async getDashboardAssets(
    page: number,
    pageSize: number,
    filter?: DashboardAssetFilter[],
    severities?: number[],
    statuses?: CommonStatusMeanings[],
    silent = false
  ): Promise<Dashboard2Asset[] | null> {
    try {
      const config: AxiosRequestConfig = {
        silent,
        params: {
          page,
          pageSize,
          severities,
          statuses
        }
      };
      const res = await this.client.post<Dashboard2Asset[]>(`dashboard/assets`, { filter }, config);
      return res.data;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getDashboardTrendsByResource(
    page: number,
    pageSize: number,
    filter?: DashboardAssetFilter[],
    severities?: number[],
    statuses?: CommonStatusMeanings[],
    silent = false
  ): Promise<Dashboard2TrendByResource[] | null> {
    try {
      const config: AxiosRequestConfig = {
        silent,
        params: {
          page,
          pageSize,
          severities,
          statuses
        }
      };
      const res = await this.client.post<Dashboard2TrendByResource[]>(`dashboard/trends/resource`, { filter }, config);
      return res.data;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getDashboardTrendsBySeverity(
    page: number,
    pageSize: number,
    filter?: DashboardAssetFilter[],
    severities?: number[],
    statuses?: CommonStatusMeanings[],
    silent = false
  ): Promise<Dashboard2TrendBySeverity[] | null> {
    try {
      const config: AxiosRequestConfig = {
        silent,
        params: {
          page,
          pageSize,
          severities,
          statuses
        }
      };
      const res = await this.client.post<Dashboard2TrendBySeverity[]>(`dashboard/trends/severity`, { filter }, config);
      return res.data;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getDashboardTrendsByStatus(
    page: number,
    pageSize: number,
    filter?: DashboardAssetFilter[],
    severities?: number[],
    silent = false
  ): Promise<Dashboard2TrendByStatus[] | null> {
    try {
      const config: AxiosRequestConfig = {
        silent,
        params: {
          page,
          pageSize,
          severities
        }
      };
      const res = await this.client.post<Dashboard2TrendByStatus[]>(`dashboard/trends/status`, { filter }, config);
      return res.data;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getDashboardActivity(filter?: DashboardAssetFilter[], severities?: number[], silent = false): Promise<Dashboard2Activity[] | null> {
    try {
      const config: AxiosRequestConfig = {
        silent,
        params: {
          severities
        }
      };
      const res = await this.client.post<Dashboard2Activity[]>(`dashboard/activity`, { filter }, config);
      return res.data;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }
}

export const Insights = new InsightsApi();
