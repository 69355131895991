// // @spellchecker: ignore notaloevera, notaloeveradev
import moment from "moment";
import { SLACK_SCOPES } from "$/constants/slack-constants";
import { AppSettings } from "@/state/app-settings";

const DEV_HOSTNAME = "dev.notaloevera.io";
const STAGING_HOSTNAME = "staging.notaloevera.io";
const PROD_HOSTNAME = "app.arnica.io";
const LOCAL_HOSTNAME = "localhost";

type Hostname = typeof DEV_HOSTNAME | typeof STAGING_HOSTNAME | typeof PROD_HOSTNAME | typeof LOCAL_HOSTNAME;

const GITHUB_APP_URL_RESOLVER: Readonly<Record<Hostname, string>> = {
  [LOCAL_HOSTNAME]: "notaloevera",
  [STAGING_HOSTNAME]: "notaloeverastaging",
  [DEV_HOSTNAME]: "notaloeveradev",
  [PROD_HOSTNAME]: "arnica-github-connector"
} as const;

const SLACK_CLIENT_ID_RESOLVER: Readonly<Record<Hostname, string>> = {
  // for arnica-local
  [LOCAL_HOSTNAME]: "2828620050563.3278753913728",
  // for arnica-local2
  //[LOCAL_HOSTNAME]: "2828620050563.3825439143799",
  [DEV_HOSTNAME]: "2828620050563.3207229799382",
  [STAGING_HOSTNAME]: "2828620050563.4929611954994",
  [PROD_HOSTNAME]: "2828620050563.2899731731831"
} as const;

const AUTH0_CONFIG_RESOLVE: Readonly<Record<Hostname, { domain: string; client_id: string }>> = {
  [LOCAL_HOSTNAME]: {
    domain: "dev-6ab2c2jb.us.auth0.com",
    client_id: "rHAIKZYFNs2IIjCwZvepy4zAuFI86jZs"
  },
  [STAGING_HOSTNAME]: {
    domain: "dev-6ab2c2jb.us.auth0.com",
    client_id: "rHAIKZYFNs2IIjCwZvepy4zAuFI86jZs"
  },
  [DEV_HOSTNAME]: {
    domain: "dev-6ab2c2jb.us.auth0.com",
    client_id: "rHAIKZYFNs2IIjCwZvepy4zAuFI86jZs"
  },
  [PROD_HOSTNAME]: {
    domain: "arnica-prod.us.auth0.com",
    client_id: "yp3AP4SVzUXMqAhsOqRqizp57BXc0M69"
  }
} as const;

export type Auth0Connection = "github" | "google-oauth2" | "AzureAD";

// --------------------
// custom domain config
// --------------------

const customAuth0DomainDev = "auth.dev.notaloevera.io";
const customAuth0DomainProd = "auth.app.arnica.io";

const AUTH0_CUSTOM_DOMAIN: Readonly<Record<Hostname, string>> = {
  [LOCAL_HOSTNAME]: customAuth0DomainDev,
  [STAGING_HOSTNAME]: customAuth0DomainDev,
  [DEV_HOSTNAME]: customAuth0DomainDev,
  [PROD_HOSTNAME]: customAuth0DomainProd
} as const;

// doesn't need to be observable as it's immutable

const TRENDS_TIME_UNIT: Readonly<Record<Hostname, moment.unitOfTime.DurationConstructor>> = {
  [LOCAL_HOSTNAME]: "minute",
  [DEV_HOSTNAME]: "hour",
  [STAGING_HOSTNAME]: "hour",
  [PROD_HOSTNAME]: "day"
} as const;

export class Env {
  public static readonly isLocalDev = import.meta.env.VITE_IS_LOCAL_DEV || location.hostname === LOCAL_HOSTNAME;

  public static readonly isDev = this.isLocalDev || location.hostname === DEV_HOSTNAME;
  public static readonly isStaging = location.hostname === STAGING_HOSTNAME;

  public static githubInstallationUrl(orgName: string, installationId: string) {
    return `https://github.com/organizations/${orgName}/settings/installations/${installationId}`;
  }

  public static async getGitHubAppUrl(): Promise<string> {
    const appSlug = (await AppSettings.getGitHubAppSlug()) || (this.isLocalDev ? GITHUB_APP_URL_RESOLVER[LOCAL_HOSTNAME] : GITHUB_APP_URL_RESOLVER[location.hostname as Hostname]);
    if (!appSlug) {
      throw new Error("Invalid appSlug");
    }

    return `https://github.com/apps/${appSlug}/installations/new`;
  }

  public static get teamsAppUrl(): string {
    return `https://docs.arnica.io/arnica-documentation/getting-started/chat-integrations/microsoft-teams`;
  }

  public static async getSlackAppUrl(): Promise<string> {
    const url = new URL("https://slack.com/oauth/v2/authorize");

    const state = window.crypto.randomUUID();

    localStorage.setItem("slackState", state);

    const clientId =
      (await AppSettings.getSlackClientId()) || (this.isLocalDev ? SLACK_CLIENT_ID_RESOLVER[LOCAL_HOSTNAME] : SLACK_CLIENT_ID_RESOLVER[location.hostname as Hostname]);

    if (!clientId) {
      throw new Error("Invalid hostname");
    }
    url.searchParams.set("client_id", clientId);
    url.searchParams.set("scope", SLACK_SCOPES);
    url.searchParams.set("user_scope", "");
    url.searchParams.set("state", state);
    url.searchParams.set("redirect_uri", window.location.origin + "/callback/slack");
    return url.toString();
  }

  public static async getAuth0Config(): Promise<{ domain: string; client_id: string }> {
    const auth0config = await AppSettings.getAuth0Config();
    const config = auth0config || (this.isLocalDev ? AUTH0_CONFIG_RESOLVE[LOCAL_HOSTNAME] : AUTH0_CONFIG_RESOLVE[location.hostname as Hostname]);
    if (!config?.domain) {
      throw new Error("Invalid Auth0 domain");
    }
    if (!config?.client_id) {
      throw new Error("Invalid Auth0 client_id");
    }
    return config;
  }

  public static async getAuth0CustomDomain(): Promise<string> {
    const domain = (await AppSettings.getAuth0CustomDomain()) || (this.isLocalDev ? AUTH0_CUSTOM_DOMAIN[LOCAL_HOSTNAME] : AUTH0_CUSTOM_DOMAIN[location.hostname as Hostname]);
    if (!domain) {
      throw new Error("Invalid hostname");
    }
    return domain;
  }

  public static get trendsTimeUnit(): moment.unitOfTime.DurationConstructor {
    const unit = import.meta.env.VITE_TRENDS_TIME_UNIT || (this.isLocalDev ? TRENDS_TIME_UNIT[LOCAL_HOSTNAME] : TRENDS_TIME_UNIT[location.hostname as Hostname]);
    if (!unit) {
      throw new Error("Invalid hostname");
    }
    return unit;
  }
}
