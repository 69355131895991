import axios, { AxiosInstance } from "axios";
import Vue from "vue";

import { applyInFlightGetRequestCaching } from "@/api/apply-in-flight-get-request-caching";
import { CachedProp } from "$/decorators/cached-property.decorator";

interface AppSettingsJson {
  apiOriginExternal?: string;
  auth0CustomDomain?: string;
  auth0Config?: string | { domain: string; client_id: string };
  slackClientId?: string;
  gitHubAppSlug?: string;
}

class AppSettingsState {
  private readonly axiosInstance: AxiosInstance;

  public constructor() {
    this.axiosInstance = applyInFlightGetRequestCaching("", axios);
    Vue.observable(this);
  }

  public async getApiOriginExternal(): Promise<string | null> {
    const jsonFile = await this.jsonFile;
    return jsonFile.apiOriginExternal || import.meta.env.VITE_API_ORIGIN_EXTERNAL || null;
  }

  public async getAuth0CustomDomain(): Promise<string | null> {
    const jsonFile = await this.jsonFile;
    return jsonFile.auth0CustomDomain || import.meta.env.VITE_AUTH0_CUSTOM_DOMAIN || null;
  }

  public async getAuth0Config(): Promise<{ domain: string; client_id: string } | null> {
    const jsonFile = await this.jsonFile;
    const value = jsonFile.auth0Config || import.meta.env.VITE_AUTH0_CONFIG || null;
    return typeof value === "string" ? JSON.parse(value) : value;
  }

  public async getSlackClientId(): Promise<string | null> {
    const jsonFile = await this.jsonFile;
    return jsonFile.slackClientId || import.meta.env.VITE_SLACK_CLIENT_ID || null;
  }

  public async getGitHubAppSlug(): Promise<string | null> {
    const jsonFile = await this.jsonFile;
    return jsonFile.gitHubAppSlug || import.meta.env.VITE_GITHUB_APP_URL || null;
  }

  @CachedProp()
  private get jsonFile(): Promise<AppSettingsJson> {
    return this.getJsonFile();
  }

  private async getJsonFile(): Promise<AppSettingsJson> {
    const response = await this.axiosInstance.get<AppSettingsJson>("/app-settings.json");
    return response.data;
  }
}

export const AppSettings = new AppSettingsState();
