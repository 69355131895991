export enum LicenseClassification {
  forbidden = "forbidden",
  restricted = "restricted",
  reciprocal = "reciprocal",
  exception = "exception",
  notice = "notice",
  permissive = "permissive",
  unencumbered = "unencumbered",
  unknown = "unknown",
  approved = "approved"
}
