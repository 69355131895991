import Vue from "vue";
import { Tier } from "$/interfaces/ui-api/billing-tiering/tier";

export type Casing = "upper" | "title" | "lower";
type Resolver = Record<Casing | "default", string>;

const RESOLVER: Record<Tier | -1, Resolver> = Object.fromEntries(
  Object.entries(Tier)
    .filter(([tier]) => isNaN(+tier)) // filters out numbered keys (`isNaN != Number.isNaN`)
    .map(([tier, value]) => [
      value,
      {
        upper: tier.toLocaleUpperCase(),
        lower: tier.toLocaleLowerCase(),
        title: `${tier.charAt(0).toLocaleUpperCase()}${tier.slice(1).toLocaleLowerCase()}`,
        default: tier
      }
    ])
    .concat([
      [
        -1,
        {
          upper: "INVALID",
          lower: "invalid",
          title: "Invalid",
          default: "invalid"
        }
      ]
    ])
);

/**
 * Converts a tier to its string representation.
 * @param tier The tier to resolve
 * @param casing Optional. Casing to resolve to
 * @returns A string representation of the tier
 * @example How to use:
 * ```
 * <span>Tier: {{ $state.Tier.current | tierName("title") }}</span>
    ```
 */
export function tierName(tier: Tier, casing?: Casing): string {
  return (RESOLVER[tier] ?? RESOLVER[-1])[casing ?? "default"];
}

tierName.RESOLVER = RESOLVER;
Vue.filter("tierName", tierName);
