// @spellchecker: ignore chartjs
import "./polyfills";
import "./api";
import "./chartjs";
import "./global-components";
import "./global-directives";
import "./global-filters";
import "./highlight";
import "./state";
import "./tier";
import "./utils";

export * from "./vuetify";
export * from "./router";

console.debug("plugins loaded");
