/**
 * @deprecated use Buffer.byteLength instead
 * Count bytes in a string (can be UTF8 / UTF16 etc... so it not exactly always 2 bytes)
 * @param str
 * Credit to: https://stackoverflow.com/a/39488643/239168
 */
export function bytes(str: string) {
  let bytes = 0,
    codePoint: number,
    next: number,
    i: number;
  const len = str.length;

  for (i = 0; i < len; i++) {
    codePoint = str.charCodeAt(i);

    // Lone surrogates cannot be passed to encodeURI
    if (codePoint >= 0xd800 && codePoint < 0xe000) {
      if (codePoint < 0xdc00 && i + 1 < len) {
        next = str.charCodeAt(i + 1);

        if (next >= 0xdc00 && next < 0xe000) {
          bytes += 4;
          i++;
          continue;
        }
      }
    }

    bytes += codePoint < 0x80 ? 1 : codePoint < 0x800 ? 2 : 3;
  }

  return bytes;
}

export function truncateByBytes(string: string, byteSize: number) {
  if (Buffer.byteLength(string) > byteSize) {
    const charsArray = string.split("");
    const truncatedStringArray: string[] = [];
    let bytesCounter = 0;
    for (const item of charsArray) {
      bytesCounter += Buffer.byteLength(item);
      if (bytesCounter <= byteSize) {
        truncatedStringArray.push(item);
      } else {
        break;
      }
    }
    return truncatedStringArray.join("");
  }
  return string;
}

export function capitalizeFirstLetter([first, ...rest]: string) {
  return `${first?.toUpperCase() ?? ""}${rest.join("")}`;
}

export function base64Encode(string: string): string {
  return Buffer.from(string).toString("base64");
}

export function base64Decode(string: string): string {
  return Buffer.from(string, "base64").toString();
}

/**
 * Returns a new string of format orgName/resource
 * @param orgName
 * @param resource
 */
export const resourcePath = (orgName: string, resource: string): string => `${orgName}/${resource}`;

export function truncateHash(hash: string, left = 3, right = 3, separator = ".."): string {
  return hash.length < left + right ? hash : `${hash.slice(0, left)}${separator}${hash.slice(-right)}`;
}

export function removeTrailingSlash(path: string): string {
  return path.replace(/\/$/, "");
}

export function middleEllipses(text: string, maxLength: number, ellipses = "...") {
  if (!text) {
    return text;
  }
  if (text.length <= maxLength) {
    return text;
  }
  maxLength -= ellipses.length;
  const middleFloor = Math.floor(maxLength / 2);
  const middleCeil = Math.ceil(maxLength / 2);
  return `${text.substring(0, middleCeil)}${ellipses}${text.substring(text.length - middleFloor)}`;
}

export function beginningEllipses(text: string, maxLength: number, ellipses = "...", splitBy = "/"): string {
  if (!text || text.length <= maxLength) {
    return text;
  }
  if (splitBy) {
    const lastWord = text.split(splitBy).at(-1);
    if (lastWord) {
      text = lastWord;
    }
  }
  text = text.trim();
  if (text.length <= maxLength) {
    return text;
  }
  return `${ellipses}${text.substring(text.length - maxLength)}`;
}
