import { TrivyCycloneDx } from "../../../../../sbom/interfaces/sbom-report-item";
import { PolicyItemType } from "../../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_cvss_score = "package_cvss_score"
  }
}

(PolicyConditionSubType as { package_cvss_score: "package_cvss_score" }).package_cvss_score = "package_cvss_score";

export interface PolicyConditionFindingPackageCvssScore extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_cvss_score;
  /** CVSS score (0.0-10.0) minimum (inclusive) */
  min: number;
  /** CVSS score (0.0-10.0) maximum (inclusive) */
  max: number;
}

export const PolicyConditionFindingPackageCvssScore = {
  TYPES: TrivyCycloneDx.PackageType,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_cvss_score
  }
} as const;
