import { GithubUrls } from "../../../../../../../packages/scm/github/src/github-urls";
import { GITHUB_ONLINE_BASE_URL } from "$/constants";
import { Group, GroupResource, generateGithubResourceId, generateResourceIdFor } from "$/dynamo";
import { UserInfo } from "$/dynamo/findingBase";
import { GITHUB_PERMISSION_RANK } from "$/github/github-permission-rank";
import { GitHubUserPermission } from "$/github/github-user-permission";
import { SCMType } from "$/scm-type";
import { MenuItem } from "@/components/table-items/menu-item";
import { scmRedirectTextFilter } from "@/filters/scm-redirect-text-filter";
import { GroupsModelBase } from "@/pages/inventory/details/groups/model/groups-model-base";
import { OrgUserIdentity } from "$/interfaces/assets/org-user-identity";

export class GithubGroupsModel extends GroupsModelBase<typeof SCMType.GITHUB> {
  public readonly assets = new Map<string, GroupResource>();
  private readonly resources = new Map<string, Map<string, GroupResource>>();

  public get scmType(): typeof SCMType.GITHUB {
    return SCMType.GITHUB;
  }

  public ownerMenuItems(id: string): MenuItem[] {
    return [
      {
        display: scmRedirectTextFilter(this.scmType),
        url: GithubUrls.identityURL(GITHUB_ONLINE_BASE_URL, id) ?? null
      },
      {
        display: `Filter by ${this.displayName(id)}`,
        icon: "mdi-filter",
        onClick: () => this.addFilter(id)
      }
    ];
  }
  public getUserAvatarUrl(login: string): string {
    return `${GITHUB_ONLINE_BASE_URL}/${login}.png`;
  }

  public getUserAccountUrl(id: string): string {
    return GithubUrls.identityURL(GITHUB_ONLINE_BASE_URL, id) ?? null;
  }

  public resourceText(item: Group<SCMType>): string {
    const organizationsCount = item.resources.filter((item) => item.org && !item.repo).length;
    const repositoriesCount = item.resources.filter((item) => item.repo).length;
    const resources: string[] = [];
    organizationsCount && resources.push(this.organizationsText(organizationsCount));
    repositoriesCount && resources.push(this.repositoriesText(repositoriesCount));
    return resources.join(" | ");
  }

  public displayPermission(permission: GitHubUserPermission | null): string {
    switch (permission) {
      case "orgOwner":
        return "Org Owner";
      case "admin":
        return "Admin";
      case "maintain":
        return "Maintain";
      case "push":
        return "Write";
      case "triage":
        return "Triage";
      case "pull":
        return "Read";
      default:
        return "None";
    }
  }

  public displayName(login: string): string {
    const displayName = this.state.ProfileNames.getGithubIdentityDisplayName(login);
    return displayName || login;
  }

  public override getDescription(groupUserId: string): string {
    return groupUserId;
  }

  protected usersMap: Map<string, OrgUserIdentity> | null = null;
  /**
   * The outer map's key is the user login and the key is another map where the key is the resourceId
   * and the value is the permission level the user has on that repo.
   * @protected
   */
  protected githubUsersAssetPermissionMap: Map<string, Map<string, GitHubUserPermission>> | null = null;

  public getAllGroupUserIds(): string[] {
    if (!this.usersMap) {
      return [];
    }
    return Array.from(this.usersMap.values()).map((user) => user.username);
  }

  public userExists(userInfo: UserInfo): boolean {
    const key = userInfo.login || userInfo.email;
    if (!key) {
      return false;
    }

    const identity = this.usersMap?.has(key);
    return identity || false;
  }

  public getUserLatestActivityTime(owner: string): number | null {
    return this.usersMap?.get(owner)?.daysSinceLastAction ?? null;
  }

  public getUserHighestPermissionInGroup(owner: string, group: Group<SCMType>): GitHubUserPermission | null {
    let highestPermission: GitHubUserPermission | null = null;
    for (const asset of group.resources) {
      const userPermissionToAsset = this.getUserPermissionLevelToAsset(owner, asset.org, asset.repo);
      if (!userPermissionToAsset) {
        continue;
      }
      if (!highestPermission || GITHUB_PERMISSION_RANK[userPermissionToAsset] > GITHUB_PERMISSION_RANK[highestPermission]) {
        highestPermission = userPermissionToAsset;
      }
    }
    return highestPermission;
  }

  private getUserPermissionLevelToAsset(owner: string, org: string, repo?: string): GitHubUserPermission | null {
    const resourceId = generateGithubResourceId(org, repo);
    return this.githubUsersAssetPermissionMap?.get(owner)?.get(resourceId) ?? null;
  }

  private generateUserTotalIdentityMap(): void {
    const userItems = this.state.Assets.users[this.integrationType];
    if (!userItems) {
      return;
    }
    this.usersMap = userItems.mapBy((item) => item.username);
  }

  public get loading(): boolean {
    return this.usersMap === null;
  }

  public generateAllAssetsMap(): void {
    const resources = this.resources;
    const resourceItems = this.state.Assets.resources[this.integrationType];
    if (!resourceItems) {
      return;
    }
    for (const org of resourceItems) {
      for (const repo of org.repos) {
        const resource = {
          org: org.integrationOrgId,
          repo: repo.repoName
        };
        this.assets.set(generateResourceIdFor.repository(org.integrationOrgId, undefined, repo.repoName), resource);
        resources.getOrSet(org.integrationOrgId, () => new Map()).set(repo.repoName, resource);
      }
    }
  }

  public override async init(initGroupsState = true): Promise<void> {
    await super.init(initGroupsState);
    try {
      await Promise.all([
        this.state.ProfileNames.init(),
        this.state.Assets.loadResources(this.integrationType),
        this.state.Assets.loadUsers(this.integrationType)
      ]);
      this.generateUserTotalIdentityMap();
      this.generateAllAssetsMap();
    } catch (e) {
      console.error(e);
    }
  }

  public getOrgs(): string[] {
    return Array.from(this.resources.keys());
  }

  public getProjects(/*_groupResource: GroupResource*/): string[] {
    throw new Error("Method not supported.");
  }

  public getRepos(groupResource: GroupResource): string[] {
    return (groupResource.org && Array.from(this.resources.get(groupResource.org)?.keys() || [])) || [];
  }
}
