import { ApiBase } from "./api-base";
import { JobDto, JobId, JobSchedule, JobStatus, TaskDtoUI, TaskId, TaskStatus } from "$/jobs-v2";
import { Dialog, Popup } from "@/state";

interface GetTasksOptions {
  page?: number;
  items?: number;
  parent?: TaskDtoUI["parent"] | "*";
  jobId?: JobId;
  schedule?: JobSchedule;
  options?: ("leaf" | "non-leaf")[];
  keywords?: string[];
}

class TasksApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "jobs-v2/tasks", name: "TasksApi" });
  }

  private static readonly defaultOptions: GetTasksOptions = {
    items: 20,
    page: 0
  };

  public async getForJob(job: JobDto, statuses?: TaskStatus[]): Promise<TaskDtoUI[]> {
    if (!job.schedule) {
      return [];
    }
    const res = await this.client.get<TaskDtoUI[]>("", {
      params: {
        status: statuses,
        schedule: job.schedule
      }
    });
    return res.data;
  }

  public async get(taskId: TaskId): Promise<TaskDtoUI> {
    const res = await this.client.get<TaskDtoUI>(taskId.encodeURI());
    return res.data;
  }

  public async getDependenciesFor(taskId: TaskId): Promise<TaskDtoUI[]> {
    const res = await this.client.get<TaskDtoUI[]>(`${taskId.encodeURI()}/dependencies`);
    return res.data;
  }

  public async getResultsFor(taskId: TaskId): Promise<unknown> {
    const res = await this.client.get<unknown>(`${taskId.encodeURI()}/results`);
    return res.data;
  }

  public async getAll(options?: GetTasksOptions): Promise<{ tasks: TaskDtoUI[]; counts: PartialRecord<TaskStatus, number> }> {
    options ??= {};
    options = { ...TasksApi.defaultOptions, ...options };
    const res = await this.client.get<TaskDtoUI[]>("", {
      params: options
    });
    const counts = JSON.parse(res.headers["x-task-counts"] || "");
    return { tasks: res.data, counts };
  }

  public async getCounts(options?: Omit<GetTasksOptions, "page" | "items">): Promise<PartialRecord<TaskStatus, number>> {
    options ??= {};
    options = { ...TasksApi.defaultOptions, ...options };
    const res = await this.client.get<PartialRecord<TaskStatus, number>>("counts", {
      params: options
    });
    return res.data;
  }

  public async restart(task: TaskDtoUI | TaskId): Promise<TaskDtoUI> {
    return await this.setStatus(task, TaskStatus.pending);
  }

  /**
   * Restart all leaf tasks by status
   * @param status
   */
  public async restartByStatus(status: TaskStatus): Promise<void> {
    try {
      if (await Dialog.confirm(`Are you sure you want to restart all ${status} tasks?`)) {
        await this.client.patch<void>(`leaf/status/${TaskStatus.pending}`, undefined, { params: { status } });
        await Popup.info(`Restarted all ${status} tasks`);
      }
    } catch (error) {
      await Popup.error(`Failed to restart ${status} tasks`, { error });
    }
  }

  public async cancel(task: TaskDtoUI | TaskId): Promise<TaskDtoUI> {
    return await this.setStatus(task, TaskStatus.cancelled);
  }

  public async archive(task: TaskDtoUI | TaskId): Promise<void> {
    const taskId = typeof task === "string" ? task : task.id;
    await this.client.delete<void>(taskId.encodeURI());
  }

  private async setStatus(task: TaskDtoUI | TaskId, status: TaskStatus): Promise<TaskDtoUI> {
    const taskId = typeof task === "string" ? task : task.id;
    const res = await this.client.patch<TaskDtoUI>(`${taskId.encodeURI()}/status/${status}`);
    return res.data;
  }
}

class JobsV2Api extends ApiBase {
  public constructor() {
    super({ pathPrefix: "jobs-v2", name: "JobsV2Api" });
  }

  public readonly tasks = new TasksApi();

  public async getAll(): Promise<JobDto[]> {
    const res = await this.client.get<JobDto[]>("");
    return res.data;
  }

  public async startAllTenantJobs(): Promise<void> {
    await this.client.post<void>("all");
  }

  public async tick(): Promise<void> {
    await this.client.post<void>("tick");
  }

  public async restart(job: JobDto | JobId): Promise<JobDto> {
    return await this.patchJob(job, JobStatus.pending);
  }

  public async cancel(job: JobDto | JobId): Promise<JobDto> {
    return await this.patchJob(job, JobStatus.cancelled);
  }

  public async delete(job: JobDto | JobId): Promise<void> {
    const jobId = typeof job === "string" ? job : job.id;
    await this.client.delete<void>(jobId.encodeURI());
  }

  private async patchJob(job: JobDto | JobId, status: JobStatus): Promise<JobDto> {
    const jobId = typeof job === "string" ? job : job.id;
    const res = await this.client.patch<JobDto>(`${jobId.encodeURI()}/status/${status}`);
    return res.data;
  }

  public async getEnabledJobNames(): Promise<string[]> {
    const response = await this.client.get<string[]>("names");
    return response.data;
  }

  public async startJob(jobName: string): Promise<JobDto> {
    const response = await this.client.post<JobDto>(`start/${jobName}`);
    return response.data;
  }

  public async getLastSettledDate(jobName: string): Promise<Date | null> {
    const response = await this.client.get<{ lastSettled: string | null }>(`${jobName.encodeURI()}/last-settled`);
    const data = response.data;
    return data?.lastSettled ? new Date(data.lastSettled) : null;
  }
}

export const JobsV2 = new JobsV2Api();
