import Vue from "vue";

type EventName = "auth.initialized";
type Callback = (event: EventName, ...args: any[]) => void | Promise<void>;

class EventsState {
  private readonly subscribers = new Map<EventName, Set<Callback>>();
  private readonly oneTimes = new Map<EventName, Set<Callback>>();

  constructor() {
    Vue.observable(this);
    this.emitOneTime = this.emitOneTime.bind(this);
  }

  private async executeCallbacks(event: EventName, callbacks: Iterable<Callback> | undefined, args: any[]): Promise<void> {
    if (!callbacks) return;
    const results = Array.from(callbacks).map((cb) => {
      const result = cb(event, ...args);
      return result instanceof Promise ? result : Promise.resolve(result);
    });
    await Promise.all(results);
  }

  private async emitOneTime(event: EventName, ...args: any[]): Promise<void> {
    const subs = this.oneTimes.get(event);
    const callbacks = subs && Array.from(subs);
    subs?.clear();
    this.oneTimes.delete(event);
    await this.executeCallbacks(event, callbacks, args);
  }

  public async emit(event: EventName, ...args: any[]): Promise<void> {
    const callbacks = this.subscribers.get(event);
    await this.executeCallbacks(event, callbacks, args);
  }

  public on(event: EventName, callback: Callback): void {
    const subs = this.subscribers.get(event) ?? new Set();
    this.subscribers.set(event, subs);
    subs.add(callback);
  }

  public once(event: EventName, callback: Callback): void {
    const subs = this.oneTimes.get(event) ?? new Set();
    this.oneTimes.set(event, subs);
    subs.add(callback);
    this.on(event, this.emitOneTime);
  }

  public off(event: EventName, callback?: Callback): void {
    const subs = [this.subscribers, this.oneTimes];
    if (callback) {
      subs.forEach((s) => s.get(event)?.delete(callback));
    } else {
      subs.forEach((s) => {
        s.get(event)?.clear();
        s.delete(event);
      });
    }
  }
}

export const Events = new EventsState();
