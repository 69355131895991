import { CallbackHandlerBase } from "@/services/auth/callback-handlers/callback-handler-base";
import { Auth } from "@/state";

type SSOErrorDescription = { error: "string"; connection: string };

export const CALLBACK_PATH = "/callback/auth0";
export class Auth0CallbackHandler extends CallbackHandlerBase {
  public static get path(): string {
    return CALLBACK_PATH;
  }

  public async handle(query: URLSearchParams): Promise<void> {
    try {
      // If the user is returning to the app after authentication..
      const error = query.get("error");
      const code = query.get("code");
      const state = query.get("state");
      const connection = query.get("connection");
      const error_description = query.get("error_description");
      if (error) {
        const ssoErrorDescription = Auth0CallbackHandler.tryParseErrorDescription(error_description);
        if (ssoErrorDescription) {
          console.warn("SSO error", ssoErrorDescription);
          Auth.redirectMessage = ["Your organization requires you to login with your corporate identity provider", "(Single Sign On)"];
          await Auth.loginWithRedirect({
            connection: ssoErrorDescription.connection
          });
        }

        return;
      }
      if (!Auth.auth0Client) {
        console.log("Auth0Client is not defined");
        throw new Error("Auth0 client not initialized");
      }

      if (code && state) {
        // regular redirect with PKCE
        await Auth.auth0Client.handleRedirectCallback();
        await Auth.setUserInternal();
        // we do this because if we use the router.replace method, the guards (who call Auth.init) will still consider this as a callback
        this.cleanupUrl();
      } else if (connection) {
        // IDP Initiated login
        //TODO: handle the case where it's an unknown connection
        await Auth.loginWithRedirect({
          connection
        });
      } else {
        throw new Error("Invalid Auth0 callback");
      }
    } catch (e) {
      this.cleanupUrl();
      console.error(e);
    }
  }

  protected override provideUserFeedback() {
    //no feedback
  }

  private static isSSOErrorDescription(obj: unknown): obj is SSOErrorDescription {
    return typeof obj === "object" && !!obj && "error" in obj && "connection" in obj;
  }

  private static tryParseErrorDescription(error_description?: string | null): SSOErrorDescription | null {
    if (!error_description) {
      console.error("Error description is not defined");
      return null;
    }
    try {
      const obj = JSON.parse(error_description);
      if (this.isSSOErrorDescription(obj)) {
        return obj;
      }
      console.error("Error description is not a valid SSO error description", error_description);
    } catch (e) {
      console.error("Error description is not a valid JSON", error_description);
    }
    return null;
  }
}
