import Vue from "vue";
import { PolicyRuleTypes } from "$/dynamo";
import { GetPolicyResponse, Rules } from "$/interfaces/ui-api/response/get-policy-response";
import { Policy as Api } from "@/api";

// import { PolicyRuleTypes } from "$/dynamo";

export const Policy = new (class PolicyState {
  public policy: GetPolicyResponse<PolicyRuleTypes> | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public async init() {
    this.policy = await Api.getAll();
  }

  public async getRules<T extends PolicyRuleTypes>(type: T) {
    const policy = (this.policy = this.policy ?? (await Api.getAll()));
    const filtered: GetPolicyResponse<T> = {
      rules: policy.rules.filter((rule): rule is Rules<T> => rule.type === type)
    };
    return filtered;
  }
})();
