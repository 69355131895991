import { RouteConfig } from "vue-router";
import { Scope } from "@/interfaces/scope";
import { productsPageRoutes } from "@/pages/products-v2/products-routes";
import { FlagCheck } from "@/plugins/route-guards/sidebar-routes";
import { InventoryV2IsEnabledAndCompletedCheck } from "@/pages/inventory-v2/inventory-v2-is-enabled-and-completed-check";

export const inventoryPageRoutes: RouteConfig[] = [
  {
    path: "/inventory",
    component: () => import(/* webpackChunkName: "inventory" */ "@/pages/inventory/inventory-page.vue"),
    meta: {
      scopes: [Scope.registered],
      sidebar: true,
      title: "Inventory",
      icon: "mdi-warehouse"
    },
    redirect: { name: "summary" },
    children: [
      {
        path: "summary",
        name: "summary",
        component: () => import(/* webpackChunkName: "inventory-summary" */ "@/pages/inventory/inventory-summary-page.vue"),
        meta: {
          scopes: [Scope.inventory_ro],
          title: "Summary",
          sidebar: InventoryV2IsEnabledAndCompletedCheck.isFalse,
          icon: "mdi-sitemap",
          scmDropDown: true
        }
      },
      {
        path: "repos-v2",
        name: "repos-v2",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory-v2/repos-table/repos-table.vue"),
        meta: {
          scopes: [Scope.inventory_ro],
          title: "Repositories",
          sidebar: InventoryV2IsEnabledAndCompletedCheck.isTrue,
          icon: "mdi-source-repository",
          scmDropDown: false
        }
      },
      {
        path: "users-v2",
        name: "users-v2",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory-v2/users-table/users-table.vue"),
        meta: {
          scopes: [Scope.inventory_ro],
          title: "Identities",
          sidebar: InventoryV2IsEnabledAndCompletedCheck.isTrue,
          icon: "mdi-account",
          scmDropDown: false
        }
      },
      {
        path: "sbom",
        name: "sbom",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory/details/sbom/pages/sbom-page.vue"),
        meta: {
          scopes: [Scope.report_ro],
          title: "SBOM",
          sidebar: true,
          icon: "mdi-clipboard-list",
          scmDropDown: false
        }
      },
      {
        path: "owners",
        name: "owners",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory/details/groups/pages/groups-page.vue"),
        meta: {
          scopes: [Scope.inventory_ro],
          title: "Product Owners",
          sidebar: FlagCheck.productsV2.front.isFalse,
          icon: "mdi-badge-account-outline",
          scmDropDown: true
        }
      },
      {
        path: "importance",
        name: "importance",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory/details/business-importance/temp-business-importance-page.vue"),
        meta: {
          scopes: [Scope.inventory_ro],
          title: "Business Importance",
          sidebar: FlagCheck.businessImportance.front.isTrue,
          icon: "mdi-priority-high",
          scmDropDown: false
        }
      },
      ...productsPageRoutes,
      {
        path: "*",
        name: "details",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory/inventory-details-page.vue"),
        meta: {
          scopes: [Scope.inventory_ro],
          title: "Details",
          sidebar: false,
          icon: "mdi-warehouse"
        }
      }
    ]
  }
];
