import { GitResourceBaseWithBranch } from "../report/interfaces/git-resource-base";
import { Semgrep } from "../sast/interfaces/semgrep-results";

import { EventContextName } from "../interfaces/event-context";

export enum ReachabilityConfidence {
  REACHABLE_HIGH = 2,
  REACHABLE_MEDIUM = 1,
  NOT_AVAILABLE = 0,
  PARTIALLY_UNREACHABLE = -0.5,
  UNREACHABLE = -1
}

export interface VulnerabilityReachability {
  sortKey: string;
  branch: string;
  path: string;
  lineNumber: number;
  result: Semgrep.Result;
}

export interface ReachabilityEntry extends VulnerabilityReachability {
  tenantId: string;
  fileSortKey: string;
  integrationType: string;
  orgId: string;
  baseUrl?: string | null;
  repoId: string;
  repo: string;
  projectId?: string | null;
  project?: string | null;
  cveIds: string[];
  updatedAt?: Date | null;
  eventContextName?: EventContextName | null;
}

export const ReachabilityEntry = {
  sortKey(resource: GitResourceBaseWithBranch, semgrepResult: Semgrep.Result): string {
    return `${this.fileSortKey(resource, semgrepResult.path)}_${semgrepResult.extra.fingerprint}`;
  },
  fileSortKey(resource: GitResourceBaseWithBranch, path: string): string {
    return [resource.integrationType, resource.baseUrl, resource.integrationOrgId, resource.projectId, resource.repoId, resource.branch, path]
      .map((x) => (!x ? "" : x.encodeURI()))
      .join("/");
  },
  toVulnerabilityReachability(entry: ReachabilityEntry): VulnerabilityReachability {
    return Object.pick(entry, "sortKey", "branch", "path", "lineNumber", "result");
  }
};

//in the form of a function to make it easier to export as a filter in the UI
export function getReachabilityConfidenceText(rc: ReachabilityConfidence | { reachabilityConfidence: ReachabilityConfidence }): string {
  if (typeof rc === "object") {
    rc = rc.reachabilityConfidence;
  }
  if (rc && typeof rc !== "number") {
    rc = Number(rc);
  }
  switch (rc) {
    case ReachabilityConfidence.REACHABLE_HIGH:
      return "high";
    case ReachabilityConfidence.REACHABLE_MEDIUM:
      return "medium";
    case ReachabilityConfidence.NOT_AVAILABLE:
      return "not available";
    case ReachabilityConfidence.UNREACHABLE:
      return "unreachable";
    case ReachabilityConfidence.PARTIALLY_UNREACHABLE:
      return "partially unreachable";
    default:
      return "";
  }
}
