import Vue from "vue";

class KeyboardState {
  // ctrl+click in macos triggers the context (right-click) menu
  public readonly modifierKey: "meta" | "ctrl" = (
    window.navigator.platform ||
    (window.navigator as Navigator & { userAgentData?: { platform?: string } })?.userAgentData?.platform ||
    ""
  )
    .toLowerCase()
    .includes("mac")
    ? "meta"
    : "ctrl";

  private state = { shiftKey: false, ctrlKey: false, altKey: false, metaKey: false };

  public constructor() {
    Vue.observable(this);
    window.addEventListener("keydown", this.onKeyDown.bind(this));
    window.addEventListener("keyup", this.onKeyUp.bind(this));
  }

  private onKeyDown(event: KeyboardEvent): void {
    const { shiftKey, ctrlKey, altKey, metaKey } = event;
    this.state = { shiftKey, ctrlKey, altKey, metaKey };
  }

  private onKeyUp(event: KeyboardEvent): void {
    const { shiftKey, ctrlKey, altKey, metaKey } = event;
    this.state = { shiftKey, ctrlKey, altKey, metaKey };
  }

  public get shift(): boolean {
    return this.state.shiftKey;
  }

  public get ctrl(): boolean {
    return this.state.ctrlKey;
  }

  public get alt(): boolean {
    return this.state.altKey;
  }

  public get meta(): boolean {
    return this.state.metaKey;
  }

  public get modifier(): boolean {
    return this[this.modifierKey];
  }
}

export const Keyboard = new KeyboardState();
