import { ApiBase } from "./api-base";
import type { PolicyRuleTypes } from "$/dynamo";
import type { SavePolicyRequest } from "$/interfaces/ui-api/request";
import type { GetPolicyResponse } from "$/interfaces/ui-api/response/get-policy-response";

class PolicyApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "policy", name: "PolicyApi" });
  }

  public async save<T extends PolicyRuleTypes>(policy: SavePolicyRequest<T>): Promise<void> {
    await this.client.post("", policy);
  }

  public async getAll<T extends PolicyRuleTypes>(): Promise<GetPolicyResponse<T>> {
    const res = await this.client.get<GetPolicyResponse<T>>("");
    return res.data;
  }

  public async get<T extends PolicyRuleTypes>(ruleType: PolicyRuleTypes): Promise<GetPolicyResponse<T>> {
    const res = await this.client.get<GetPolicyResponse<T>>(ruleType);
    return res.data;
  }
}

export const Policy = new PolicyApi();
