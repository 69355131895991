import { PolicyItemType } from "../../../policy-item-base";
import type { MatcherType } from "../../match-type";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_license_original = "package_license_original"
  }
}

(PolicyConditionSubType as { package_license_original: "package_license_original" }).package_license_original = "package_license_original";

export interface PolicyConditionFindingPackageLicenseOriginal extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_license_original;
  /**
   * One or more expressions to match against the package original license
   */
  matchers: string[];
  matcherType: MatcherType;
}

export const PolicyConditionFindingPackageLicenseOriginal = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_license_original
  }
} as const;
