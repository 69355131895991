<template>
  <v-row class="component loading-centered" align="center" justify="center">
    <v-col align="center">
      <v-progress-circular indeterminate color="#40bca3" size="100"></v-progress-circular>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export class LoadingCentered extends Vue {}

export default LoadingCentered;
</script>
