import { CodeRisk, Reports } from "@/api";
import Vue from "vue";
import { DeoptimizedADORiskReport } from "$/report/ado/ado-report-utils";
import { DismissedItemsFilter } from "$/report/dismissed-items-filter";
import { ADORiskReport, ADORiskReportQueryType, RiskReport, RiskReportType } from "$/report/risk-report/risk-report";
import { CodeRiskFinding } from "$/sast/code-risk-finding";
import { BaseRequestParams } from "@/api/api-base";
import { UIRiskReportItem } from "@/models/risks-page-assets";
import { Dismissals } from "@/state/dismissals-state";
import { ProfileNames } from "@/state/profile-names-state";

export const RiskReports = new (class ReportsState {
  public constructor() {
    Vue.observable(this);
  }

  private readonly dismissalItemFilterer = new DismissedItemsFilter();
  public riskReports = new Map<RiskReportType, RiskReport<RiskReportType>>();
  public filter: Partial<UIRiskReportItem> | null = null;

  public async initReport(riskReportType: RiskReportType, params: BaseRequestParams = {}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [report, _ignored] = await Promise.all([Reports.getRisk(riskReportType, params), ProfileNames.init()]);
    if (!report || "error" in report) {
      console.warn(`Failed to get risk report for ${riskReportType}`, report);
      return;
    }
    this.dismissReportItems([report]);
    this.riskReports.set(riskReportType, report);
  }

  public async init(params: BaseRequestParams = {}): Promise<void> {
    const [riskReportResponses] = await Promise.all([Reports.getAllRisks(params), ProfileNames.init()]);
    this.dismissReportItems(riskReportResponses);
    for (const reportResponse of riskReportResponses) {
      this.riskReports.set(reportResponse.summary.queryType, reportResponse);
    }
  }

  public async getRiskByProjectId(
    reportType: ADORiskReportQueryType,
    integrationOrgIdAndType: string,
    projectId: string,
    params: BaseRequestParams = {},
    optimize = true
  ): Promise<ADORiskReport | DeoptimizedADORiskReport | null> {
    const report = await Reports.getRiskByProjectId(reportType, integrationOrgIdAndType, projectId, params, optimize);
    if (!report) {
      return null;
    }
    //TODO: make dismissReportItems support DeoptimizedADORiskReport (reportItems is an iterable, and we can't convert it to an array because it will crash on large reports)
    //this.dismissReportItems([report]);
    return report;
  }

  private dismissReportItems(reports: RiskReport<RiskReportType>[]) {
    if (!Dismissals.recentlyDismissedItems.size) {
      return;
    }
    for (const report of reports) {
      this.dismissalItemFilterer.filterOutDismissedItems(report, Array.from(Dismissals.recentlyDismissedItems.values()));
    }
  }

  public async initSingleCodeRiskFinding(sortKey: string): Promise<CodeRiskFinding> {
    return await CodeRisk.getCodeRiskSingleFindingReport(sortKey).autoHandleError();
  }
})();
