import Router, { Route } from "vue-router";
import "vue-router";
import { SCMType } from "$/scm-type";
import { Integrations, Layout } from "@/state";

declare module "vue-router/types/router" {
  export interface RouteMeta {
    /**
     * Indicates if the route should display an SCM dropdown.
     */
    scmDropDown?: boolean;
  }
}

function scmRoutes(to: Route) {
  const scmTypeFromURL = to.params["scmType"];
  if (!scmTypeFromURL) {
    return;
  }

  const scmTypes = Integrations.scmTypes;
  if (scmTypes.some((x) => x.value === scmTypeFromURL)) {
    Layout.scmType = scmTypeFromURL as SCMType;
  }
}

export function install(router: Router): Router {
  router.afterEach(scmRoutes);
  return router;
}
