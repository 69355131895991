<template>
  <div class="component action-dialog">
    <v-dialog
      v-model="model"
      @click:outside="closeDialog"
      class="component action-dialog action-dialog--container"
      :content-class="`component action-dialog action-dialog--teleport ${fullscreen ? 'action-dialog--fullscreen' : ''}`"
      scrollable
      :persistent="dialog?.persistent ?? false">
      <template v-if="dialog">
        <v-form @submit.prevent="onSubmit" v-model="valid">
          <v-card>
            <v-card-title>{{ dialog.title }}</v-card-title>
            <v-divider></v-divider>
            <component v-if="component" :is="component" v-bind="dialog.bindData" :resolve="resolve" :reject="reject" :valid="valid"> </component>
            <v-card-text v-else-if="html" v-html-sanitize="html.html"> </v-card-text>
            <v-card-text class="content-row-container" v-else>
              <span v-for="(row, idx) of rows" :key="idx">{{ row }}</span>
            </v-card-text>
            <v-card-actions v-if="dialog.actions" ref="actions" class="justify-end">
              <v-btn
                v-for="action of dialog.actions"
                dense
                text
                @click="onAction(action)"
                :color="action.color"
                :key="action.name"
                :default="!!action.default"
                :type="!!action.submit ? 'submit' : null"
                :disabled="!!action.submit && !valid">
                {{ action.name }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </template>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import { Component, ProvideReactive, Vue } from "vue-property-decorator";
import { Dialog, HtmlContent } from "@/state/dialog-state";

type DialogConfig = NonNullable<(typeof Dialog)["config"]>;
type DialogAction = NonNullable<DialogConfig["actions"]>[number];

@Component
export class ActionDialog extends Vue {
  protected valid = false;

  protected get model(): boolean {
    const show = !!this.$state.Dialog.config;
    if (show) {
      this.setFocus();
    }
    return show;
  }

  protected set model(value: boolean) {
    if (!value) {
      this.closeDialog();
    }
  }

  protected get dialog(): DialogConfig | null {
    return this.$state.Dialog.config;
  }

  protected get fullscreen(): boolean {
    return this.dialog?.fullscreen ?? false;
  }

  protected closeDialog(): void {
    if (this.dialog?.persistent) {
      return;
    }
    this.resolve?.(null);
    this.$state.Dialog.dismiss();
  }

  protected get component(): Vue.Component | null {
    const content = this.dialog?.content;
    if (!content || typeof content === "string" || content instanceof HtmlContent) {
      return null;
    }
    return content;
  }

  protected get html(): HtmlContent | null {
    const content = this.dialog?.content;
    if (content instanceof HtmlContent) {
      return content;
    }
    return null;
  }

  protected get rows(): string[] {
    const content = this.dialog?.content;
    return typeof content === "string" ? content.split("\n") : [];
  }

  @ProvideReactive()
  protected get resolve(): DialogConfig["deferred"]["resolve"] | null {
    return this.dialog?.deferred.resolve ?? null;
  }

  @ProvideReactive()
  protected get reject(): DialogConfig["deferred"]["reject"] | null {
    return this.dialog?.deferred.reject ?? null;
  }

  protected setResult(result: unknown): void {
    this.dialog?.deferred.resolve(result);
  }

  protected async setFocus(): Promise<void> {
    await this.$nextTick();
    const actions = this.$refs["actions"] as HTMLElement;
    if (actions) {
      (actions.querySelector("button[default]") as HTMLButtonElement)?.focus();
    }
  }

  protected onAction(action: DialogAction): void {
    if (action.submit) {
      this.onSubmit(action);
    }
    this.setResult(action.value);
  }

  protected onSubmit(action?: DialogAction): void {
    if (!this.valid) {
      return;
    }
    this.setResult((action ?? this.dialog?.actions?.find((action) => action.submit))?.value);
  }
}

export default ActionDialog;
</script>

<style lang="scss">
.component.action-dialog {
  &.action-dialog--teleport {
    width: auto;
    min-width: 20rem;
  }

  &:not(.action-dialog--fullscreen) {
    max-width: 85rem;
  }

  &.action-dialog--fullscreen {
    max-width: 100%;
    max-height: 100%;
  }

  .v-card__text,
  .v-card__title {
    padding: 1rem 2rem;
  }

  .content-row-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
