import { PolicyItemType } from "../../../policy-item-base";
import type { MatcherType } from "../../match-type";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_name_and_version = "package_name_and_version"
  }
}

(PolicyConditionSubType as { package_name_and_version: "package_name_and_version" }).package_name_and_version = "package_name_and_version";

export interface PolicyConditionFindingPackageNameAndVersion extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_name_and_version;
  /**
   * One or more expressions to match against the package `name@version`
   */
  matchers: string[];
  matcherType: MatcherType;
  includeTransitive: boolean;
}

export const PolicyConditionFindingPackageNameAndVersion = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_name_and_version
  }
} as const;
