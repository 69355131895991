import { ApiBase } from "./api-base";
import { UILocation, UILocationDTO } from "$/ui-models/inventory-v2/ui-location";
import { uuid } from "$/ui-models/uuid";
import { UIRepository } from "$/ui-models/inventory-v2/ui-repository";

class InventoryApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "inventory", name: "InventoryApi" });
  }

  public async getRootLocations(search?: string): Promise<UILocation[]> {
    const url = await this.getUrl("/locations");
    if (search) {
      url.searchParams.set("search", search);
    }
    const res = await this.client.get<UILocationDTO[]>(url.toString());
    return res.data.map((dto) => new UILocation(dto));
  }

  public async getChildLocations(locationId: uuid, search?: string): Promise<UILocation[]> {
    const url = await this.getUrl(`/locations/${locationId.encodeURI()}/children`);
    if (search) {
      url.searchParams.set("search", search);
    }
    const res = await this.client.get<UILocationDTO[]>(url.toString());
    return res.data.map((dto) => new UILocation(dto));
  }

  public async getChildRepositories(locationId: uuid, search?: string): Promise<UIRepository[]> {
    const url = await this.getUrl(`/locations/${locationId.encodeURI()}/repositories`);
    if (search) {
      url.searchParams.set("search", search);
    }
    const res = await this.client.get<UIRepository[]>(url.toString());
    return res.data;
  }
}

export const Inventory = new InventoryApi();
