export type LazyFactory<T> = () => T;

export class Lazy<T> {
  #value: T | undefined = undefined;
  #factory: LazyFactory<T> | undefined;

  public constructor(factory: LazyFactory<T>) {
    if (!factory) {
      throw new Error("Invalid lazy factory");
    }
    this.#factory = factory;
  }

  public get value(): T {
    if (typeof this.#value === "undefined") {
      this.#value = this.#factory?.();
      if (typeof this.#value === "undefined") {
        this.#factory = () => {
          throw new Error("Invalid lazy factory");
        };
        throw new Error("Lazy factory produced no value");
      }
      this.#factory = undefined;
    }
    Object.defineProperty(this, "value", { value: this.#value });
    return this.#value;
  }

  public get isValueCreated(): boolean {
    return typeof this.#value !== "undefined";
  }
}
