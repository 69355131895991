import { Observer } from "./vue-helper";

class SetObserver extends Observer {
  public constructor(value: unknown, shallow?: boolean, mock?: boolean) {
    super(value, shallow, mock);
    if (!shallow && value instanceof Set) {
      this.observeArray(value);
    }
  }

  public override observeArray(value: unknown): void {
    // does not support nested Sets/Maps
    super.observeArray(value instanceof Set ? Array.from(value.values()) : value);
  }
}

export class ObservableSet<T> extends Set<T> {
  _length = super.size;

  _notify(method: string) {
    this._length = super.size;
    this.__ob__?.dep?.notify({
      type: "map mutation",
      target: this,
      key: method
    });
  }

  private readonly __ob__: SetObserver;

  public constructor(values?: readonly T[] | null | undefined);
  public constructor(iterable?: Iterable<T> | null | undefined) {
    super(iterable);
    this.__ob__ ??= new SetObserver(this);
  }

  public get length() {
    return this._length;
  }

  public override get size() {
    return this._length;
  }

  public override clear() {
    super.clear();
    this._notify("clear");
  }

  public override add(value: T): this {
    super.add(value);
    this._notify("add");
    return this;
  }

  public override delete(value: T): boolean {
    const result = super.delete(value);
    this._notify("delete");
    return result;
  }
}
