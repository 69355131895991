import { VerificationResults } from "../../../ui-api/response/get-secrets-response";
import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    validation = "validation"
  }
}

(PolicyConditionSubType as { validation: "validation" }).validation = "validation";

export interface PolicyConditionFindingSecretValidation extends PolicyCondition {
  sub: PolicyConditionSubType.validation;
  name: VerificationResults | "ERROR";
}

export const PolicyConditionFindingSecretValidation = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.validation
  } as const,
  generateDefault(): PolicyConditionFindingSecretValidation {
    return {
      sub: PolicyConditionSubType.validation,
      name: VerificationResults.VERIFIED_TRUE,
      id: `${PolicyItemType.condition}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.condition
    } satisfies PolicyConditionFindingSecretValidation;
  }
} as const;
