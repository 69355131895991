import { Group, GroupResource, UniversalScmIntegrationType, generateResourceIdFor } from "$/dynamo";
import { UserInfo } from "$/dynamo/findingBase";
import { UniversalScmInventoryReportQueryType } from "$/report/inventory-report/inventory-report";
import { UniversalScmUsersInventoryItem } from "$/report/inventory-report/universal-scm/universal-scm-users-inventory-item";
import { SCMType, UNIVERSAL_SCM_TYPE } from "$/scm-type";
import { ResourceSCMUrl } from "$/utils/links";
import { MenuItem } from "@/components/table-items/menu-item";
import { GroupsModelBase } from "@/pages/inventory/details/groups/model/groups-model-base";
import { OrgUserIdentity } from "$/interfaces/assets/org-user-identity";

export class UniversalScmGroupsModel<S extends UNIVERSAL_SCM_TYPE> extends GroupsModelBase<S> {
  public readonly assets = new Map<string, GroupResource>();
  protected usersMap: Map<string, OrgUserIdentity> | null = null;
  private readonly _integrationType: UniversalScmIntegrationType;
  private readonly resources = new Map<string, Map<string, Map<string, GroupResource>>>();

  public constructor(public readonly scmType: S) {
    super();
    this._integrationType = UniversalScmIntegrationType.fromSCMType(scmType);
  }

  public override get integrationType(): UniversalScmIntegrationType {
    return this._integrationType;
  }

  public override get orgHeaderName(): string {
    return "Workspace";
  }

  public override async init(initGroupsState = true): Promise<void> {
    await super.init(initGroupsState);
    try {
      await Promise.all([
        this.state.Assets.loadResources(this.integrationType),
        this.state.Assets.loadUsers(this.integrationType)
      ]);
      this.generateUsersMap();
      this.generateAllAssetsMap();
    } catch (e) {
      console.error(e);
    }
  }

  public ownerMenuItems(id: string): MenuItem[] {
    return [
      {
        display: `Filter by ${this.displayName(id)}`,
        icon: "mdi-filter",
        onClick: () => this.addFilter(id)
      }
    ];
  }

  public getUserAvatarUrl(userId: string): string | null {
    const user = this.usersMap?.get(userId);
    return user?.avatarUrl || null;
  }

  public getUserAccountUrl(userId: string): string {
    const user = this.usersMap?.get(userId);
    if (!user) {
      return "";
    }
    return ResourceSCMUrl.forUser({ userId: user.login, org: user.org, integrationType: user.scm, baseUrl: user.baseUrl })?.href || "";
  }

  public resourceText(item: Group<SCMType>): string {
    const organizationsCount = item.resources.filter((item) => item.org && !item.project && !item.repo).length;
    const repositoriesCount = item.resources.filter((item) => item.repo).length;
    const projectsCount = item.resources.filter((item) => item.project && !item.repo).length;
    const resources: string[] = [];
    organizationsCount && resources.push(this.organizationsText(organizationsCount));
    projectsCount && resources.push(this.projectsText(projectsCount));
    repositoriesCount && resources.push(this.repositoriesText(repositoriesCount));
    return resources.join(" | ");
  }

  public displayName(groupUserId: string): string {
    const user = this.usersMap?.get(groupUserId);
    const displayName = user?.names[0];
    if (user && displayName) {
      return `${displayName} - ${this.state.Integrations.getOrganizationName(user.org, this._integrationType)}`;
    }
    return groupUserId;
  }

  public override getDescription(groupUserId: string): string {
    const user = this.usersMap?.get(groupUserId);
    if (!user) {
      return groupUserId;
    }
    //TODO: Does this seem correct?
    return user.emails[0] || user.username;
  }

  public getAllGroupUserIds(): string[] {
    const report = this.state.Assets.users[this._integrationType];
    return report?.map((user) => UniversalScmUsersInventoryItem.getGroupUserId({id: user.login, integrationOrgIdAndType: user.integrationOrgIdAndType})) || [];
  }

  public userExists(userInfo: UserInfo): boolean {
    const identities: OrgUserIdentity[] = this.usersMap?.values().toArray() || [];
    return identities.some((i) => (userInfo.email && i.emails.includes(userInfo.email)) || i.login === userInfo.login);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getUserLatestActivityTime(_owner: string): number | null {
    //TODO when this information is available
    return null;
  }

  public generateAllAssetsMap(): void {
    const resourceItems = this.state.Assets.resources[this._integrationType];
    if (!resourceItems) {
      return;
    }
    const resources = this.resources;
    for (const org of resourceItems) {
      for (const repo of org.repos) {
        const resource = { org: org.integrationOrgId, project: repo.projectName, repo: repo.repoName };
        resources
          .getOrSet(resource.org, () => new Map())
          .getOrSet(resource.project, () => new Map())
          .set(resource.repo, resource);
        this.assets.set(generateResourceIdFor.organization(org.integrationOrgId), { org: org.integrationOrgId });
        this.assets.set(generateResourceIdFor.project(org.integrationOrgId, repo.projectName), {
          org: org.integrationOrgId,
          project: repo.projectName
        });
        this.assets.set(generateResourceIdFor.repository(org.integrationOrgId, repo.projectName, repo.repoName), {
          org: org.integrationOrgId,
          project: repo.projectName,
          repo: repo.repoName
        });
      }
    }
  }

  public getProjectId(item: GroupResource): string {
    return (
      this.state.InventoryReports.getInventoryReport(UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_REPOSITORIES)?.reportItems?.find(
        (reportItem) => reportItem.integrationType === this._integrationType && reportItem.integrationOrgId === item.org && reportItem.projectName === item.project
      )?.projectId || ""
    );
  }

  public getOrgs(): string[] {
    return Array.from(this.resources.keys());
  }

  public getProjects(groupResource: GroupResource): string[] {
    return (groupResource.org && Array.from(this.resources.get(groupResource.org)?.keys() || [])) || [];
  }

  public getRepos(groupResource: GroupResource): string[] {
    return (groupResource.org && groupResource.project && Array.from(this.resources.get(groupResource.org)?.get(groupResource.project)?.keys() || [])) || [];
  }

  protected projectsText(projectsCount: number): string {
    return `${projectsCount} project${projectsCount === 1 ? "" : "s"}`;
  }

  private generateUsersMap(): void {
    const userItems = this.state.Assets.users[this._integrationType];
    if (!userItems) {
      return;
    }
    this.usersMap = userItems.mapBy((item) => UniversalScmUsersInventoryItem.getGroupUserId({ id: item.login, integrationOrgIdAndType: item.integrationOrgIdAndType }));
  }

  public get loading(): boolean {
    return this.usersMap === null;
  }
}
