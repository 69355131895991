/**
 * Augment the typings of Vue.js
 */
import Vue from "vue";
import * as State from "@/state";

declare module "vue/types/vue" {
  interface Vue {
    $state: typeof State;
  }

  interface VueConstructor {
    state: typeof State;
  }
}

// declare module "vue/types/options" {
//     interface ComponentOptions<V extends Vue> {
//         state?: typeof State
//     }
// }

Vue.use({
  install() {
    Vue.prototype.$state = State;
    Vue.state = State;
  }
});
