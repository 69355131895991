import { PolicyConditionBoolean } from "../policy-conditions";
import { PolicyItemType } from "../policy-item-base";
import { PolicyItem, PolicySubType } from "./policy-item";

declare module "./policy-item" {
  enum PolicySubType {
    global = "global"
  }

  interface PolicySubTypeMapper {
    [PolicySubType.global]: PolicyItemGlobal;
  }
}

(PolicySubType as { global: "global" }).global = "global";

export interface PolicyItemGlobal extends PolicyItem<never> {
  sub: PolicySubType.global;
  v: 1;
}

export const PolicyItemGlobal = {
  is: (item?: PolicyItem | null): item is PolicyItemGlobal => item?.sub === PolicySubType.global,
  generateDefault(arnicaOrgId: string): PolicyItemGlobal {
    return {
      arnicaOrgId,
      id: PolicySubType.global,
      sub: PolicySubType.global,
      type: PolicyItemType.policy,
      v: 1,
      rules: [PolicyItemGlobal.generateDefaultRule()]
    };
  },
  generateDefaultRule(): PolicyItemGlobal["rules"][number] {
    return {
      type: PolicyItemType.rule,
      name: "Include all - no exclusions (default 💯)",
      id: `${PolicyItemType.policy}-${Math.floor(Date.now() * Math.random())}`,
      enabled: true,
      condition: PolicyConditionBoolean.generateDefault(),
      trigger: null,
      actions: []
    };
  }
} as const;
