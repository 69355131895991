import { Auth } from "./auth-state";
import { Events } from "./events-state";
import { Popup } from "./popup-state";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders } from "axios";
import Vue from "vue";
import { GithubMitigation, generateGithubResourceId, generateMitigationAssetIdIntegrationTypeAndResourceType, generateMitigationKey, getGithubResourceType } from "$/dynamo";
import { GitHubMitigationUtils } from "$/github/github-mitigation-utils";
import type { DemoEndpoint, EndpointMethod } from "$/interfaces/demo/endpoint";
import type { DemoGitHubRepoName } from "$/interfaces/demo/generate-mitigation-response";
import { CreateGithubMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-github-mitigation-request";
import { GenerateGithubMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/generate-github-mitigation-request";
import { RevertMitigationRequest } from "$/interfaces/ui-api/request/revert-mitigation-request";
import { GetInsightsReportResponse } from "$/interfaces/ui-api/response/get-insight-response-item";
import { CreateGithubMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-github-mitigation-response";
import { GetGithubMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/get-github-mitigation-response";
import { GetGithubMitigationToApply } from "$/interfaces/ui-api/response/mitigations/github/get-github-mitigation-to-apply";
import { InsightUtils } from "$/report/insight-report/insight-utils";
import { GitHubRiskReport, GitHubRiskReportQueryType, RiskReport, RiskReportType } from "$/report/risk-report/risk-report";
import { Lazy } from "$/utility-types";
import { Reports } from "@/api";
import { ApiBase } from "@/api/api-base";

interface VideoIndex {
  code_risk: string;
  secrets: string;
}

const DEMO_GITHUB_ORG_NAME = "demo-gitgoat";
const DEMO_DATA_PARAM = "demo";
const COMPLETED_MITIGATIONS = "COMPLETED_MITIGATIONS";
const EXCESSIVE_ADMIN_INSIGHT_TITLE = "Excessive Repository Admins Permissions";

class DemoError extends Error {
  public constructor(
    public readonly endpoint: DemoEndpoint,
    public readonly requestConfig: AxiosRequestConfig,
    public readonly handler: (config: AxiosRequestConfig) => Promise<AxiosResponse>
  ) {
    super(endpoint);
    this.name = "DemoError";
  }
}

class Response<TResponse, TRequest> implements AxiosResponse<TResponse, TRequest> {
  public readonly headers: AxiosResponseHeaders = {};
  public constructor(
    public readonly config: AxiosRequestConfig<TRequest>,
    public readonly data: TResponse,
    public readonly status: number = 200,
    public readonly statusText: string = "OK"
  ) {}
}

class ResponseError<TRequest, TResponse, TError> extends Error implements AxiosError<TResponse | TError | null, TRequest> {
  public readonly status?: string;
  public request?: any;
  public isAxiosError = true;
  public readonly code: string;
  public readonly response?: AxiosResponse<TResponse | TError | null, TRequest>;

  public constructor(public readonly config: AxiosRequestConfig<TRequest>, message: string, code: number, response?: AxiosResponse<TResponse | TError | null, TRequest>) {
    super(message);
    this.name = "ResponseError";
    this.code = String(code);
    this.status = String(code);
    this.response = response ?? new Response(config, null, code, message);
  }

  public toJSON() {
    return {
      code: this.code,
      message: this.message
    };
  }
}

class DemoApi extends ApiBase {
  static #instance = new Lazy(() => new DemoApi());

  public static get instance(): DemoApi {
    return this.#instance.value;
  }

  private constructor() {
    super({ pathPrefix: "demo", name: "DemoApi" });
  }

  public async getEndpoints(): Promise<DemoEndpoint[]> {
    try {
      const res = await this.client.get<DemoEndpoint[]>("endpoints");
      return res.data;
    } catch (e) {
      return [];
    }
  }

  public async generateMitigationResponses(queryType: GitHubRiskReportQueryType, repos: DemoGitHubRepoName[]): Promise<GetGithubMitigationToApply[]> {
    const res = await this.client.post<GetGithubMitigationToApply[]>(`mitigations/generate/${queryType}`, repos);
    return res.data;
  }
}

export const Demo = new (class DemoState {
  private _enabled = new URLSearchParams(window.location.search).has(DEMO_DATA_PARAM); // using _ instead of # for Vue observability purposes
  private readonly endpoints = new Set<DemoEndpoint>();
  private readonly localEndpoints = new Map<DemoEndpoint, (config: AxiosRequestConfig) => Promise<AxiosResponse>>();
  private readonly responseAugmenters = new Map<DemoEndpoint, (config: AxiosResponse) => Promise<AxiosResponse>>();
  private readonly completedMitigations: Record<string, GetGithubMitigationResponse>;
  private readonly wildcards: RegExp[] = [];

  // eslint-disable-next-line github/no-then
  public readonly videoIndex = new Lazy(() => Axios.get(import.meta.env.VITE_PRODUCT_TOUR_VIDEO_INDEX).then((r) => r.data as VideoIndex));

  public constructor() {
    this.completedMitigations = this.loadMitigations();
    Vue.observable(this);
    // NOTE: Demo data is disabled for now
    // this.init();
  }

  /** Resolves the endpoint from the request config */
  private static resolveEndpoint(config: AxiosRequestConfig): { org: URL; endpoint: DemoEndpoint } {
    // baseUrl may or may not exist, url may or may not exist and can be the full URL or just the path
    // need to conditionally merge them to get the full URL
    const parts = [
      config.baseURL, // e.g. https://host:4000/api/users/
      config.url // e.g. /me/
    ]
      .filter((i): i is string => !!i) // removes all the parts that are empty or undefined/null
      .map((i) => i.trim().replace(/(^\/)|(\/$)/, "")) // strips all leading/trailing slashes (e.g. ["https://host:4000/api/users/", "/me/"] -> ["https://host:4000/api/users", "me"])
      .join("/"); // joins all parts with a slash delimiter (e.g. ["https://host:4000/api/users", "me"] -> "https://host:4000/api/users/me")
    const org = new URL(parts);
    const endpoint = `${config.method?.toUpperCase() as EndpointMethod} ${org.pathname}` as DemoEndpoint; // resolves the endpoint string (e.g. "GET /users/me")
    return { org, endpoint };
  }

  private init() {
    Events.once("auth.initialized", async () => {
      const endpoints = await DemoApi.instance.getEndpoints();
      for (const endpoint of endpoints) {
        this.endpoints.add(endpoint);
      }
      this.wildcards.push(...this.getWildcards(endpoints));
    });
    if (this._enabled) {
      // prevents overwriting of preferences
      localStorage.setItem = () => {
        // no-op
      };
    }
    this.localEndpoints
      .set("GET /mitigations/github", this.getMitigations.bind(this))
      .set("POST /mitigations/github/generate", this.generateMitigationResponses.bind(this))
      .set("POST /mitigations/github", this.createMitigation.bind(this))
      .set("PATCH /mitigations", this.revertMitigation.bind(this));

    this.responseAugmenters.set("GET /demo/report/risk/GHREPOADMIN", this.augmentRiskReportGitHubRepoAdmin.bind(this)).set("GET /demo/insights", this.augmentInsights.bind(this));
  }

  private getWildcards(endpoints: DemoEndpoint[]): RegExp[] {
    endpoints = endpoints.filter((e) => e.includes(":"));
    const replacers = endpoints.map((e) => {
      const [method, path] = e.split(" ");
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const regex = new RegExp(`^${method} ${path!.replace(/:([^/]+)/g, "[^/]+")}`);
      // const replacer = e.replace(/:([^/]+)/g, (_, ...[match]) => match).split(" ")[1];
      return regex;
    });
    return replacers;
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public set enabled(value: boolean) {
    if (this._enabled === value) {
      return;
    }
    this._enabled = value;
    const url = new URL(window.location.href);
    if (value) {
      url.searchParams.set(DEMO_DATA_PARAM, "true");
    } else {
      sessionStorage.removeItem(COMPLETED_MITIGATIONS);
      url.searchParams.delete(DEMO_DATA_PARAM);
    }
    url.hash = "";
    location.href = url.href.replace(`${DEMO_DATA_PARAM}=true`, DEMO_DATA_PARAM);
  }

  public toggle(): boolean {
    return (this.enabled = !this.enabled);
  }

  public disable(): void {
    this.enabled = false;
  }

  public enable(): void {
    this.enabled = true;
  }

  public requestInterceptor(config: AxiosRequestConfig) {
    const endpoint = this.getEndpoint(config);
    if (!endpoint) {
      return config;
    }

    const demoUrl = new URL(endpoint.href);
    demoUrl.pathname = `demo${endpoint.pathname}`;
    console.debug(`demoInterceptRequest: ${endpoint.href} -> ${demoUrl.href}`);
    Reflect.deleteProperty(config, "baseURL");
    config.url = demoUrl.href;
    return config;
  }

  public async responseInterceptor(response: AxiosResponse<unknown>): Promise<AxiosResponse<unknown>> {
    const { endpoint } = DemoState.resolveEndpoint(response.config);
    const handler = this.responseAugmenters.get(endpoint);
    if (!handler) {
      return response;
    }
    return await handler(response);
  }

  public async errorInterceptor(error: unknown): Promise<unknown> {
    if (error instanceof DemoError) {
      return await error.handler(error.requestConfig);
    } else if (Axios.isAxiosError(error) && error.response?.status === 501) {
      const data = error.response.data;
      const message = data && typeof data === "object" && "message" in data ? (data.message as string) : null;
      void Popup.warn(message || "Sorry, this operation is not supported in demo mode", { timeout: 10_000 });
      throw error;
    }
    throw error;
  }

  private getEndpoint(config: AxiosRequestConfig): URL | null {
    const { org, endpoint } = DemoState.resolveEndpoint(config);
    const localHandler = this.localEndpoints.get(endpoint);
    if (localHandler) {
      throw new DemoError(endpoint, config, localHandler);
    }
    if (this.endpoints.has(endpoint)) {
      return org;
    }
    for (const regex of this.wildcards) {
      if (regex.test(endpoint)) {
        return org;
      }
    }
    return null;
  }

  private augmentRiskReportGitHubRepoAdmin(response: AxiosResponse<GitHubRiskReport>): Promise<AxiosResponse<RiskReport<RiskReportType>>> {
    const report = response.data;
    if (!report) {
      return Promise.resolve(response);
    }
    console.debug(`DemoState.augmentRiskReportGitHubRepoAdmin`);

    const mitigations = Object.values(this.completedMitigations);

    if (report && mitigations.length > 0) {
      // TODO: move this to the backend
      // TODO: this is an example of how to apply mitigations to the report for GH admin, similar logic should be applied to other reports as well

      GitHubMitigationUtils.removeMitigatedGithubResourcesFromRisksIfNoNewIngestionRunYet(
        report as GitHubRiskReport,
        mitigations.map((m) => {
          return {
            ...m,
            repoName: m.repoName ?? "",
            action: m.mitigationAction,
            status: m.mitigationStatus,
            usersKeepingLosingPermissions: {
              usersDirectlyLosingPermissions: m.usersLosingPermissions,
              usersDirectlyKeepingPermissions: m.usersKeepingPermissions,
              usersInTeamKeepingPermissions: [],
              usersInTeamLosingPermissions: []
            },
            isSelfHosted: false,
            arnicaOrgId: "demo-org",
            phase: 2,
            lastIngestionTimestamp: ""
          } as GithubMitigation;
        })
      );
    }

    return Promise.resolve(response);
  }

  private async augmentInsights(response: AxiosResponse<GetInsightsReportResponse>): Promise<AxiosResponse<GetInsightsReportResponse>> {
    const insights = response.data;
    if (!insights) {
      return Promise.resolve(response);
    }
    console.debug(`DemoState.augmentInsights`);

    const allRiskReports = await Reports.getAllRisks();
    const allGithubExcessiveRiskReports = allRiskReports.filter((report) => report.summary.queryType in GitHubRiskReportQueryType) as GitHubRiskReport[];
    const excessiveAdminInsight = InsightUtils.githubExcessiveRepoAdminFromReport(allGithubExcessiveRiskReports);

    const newInsights = insights.insights.map((insight) => {
      if (insight.title === EXCESSIVE_ADMIN_INSIGHT_TITLE) {
        return excessiveAdminInsight;
      }
      return insight;
    });
    insights.insights = newInsights.nonNullable();

    return response;
  }

  private async generateMitigationResponses(config: AxiosRequestConfig<GenerateGithubMitigationRequest>): Promise<AxiosResponse<GetGithubMitigationToApply[]>> {
    const generateMitigationRequest = config.data;
    if (!generateMitigationRequest) {
      throw new ResponseError(config, "Bad Request", 400);
    }

    const responses = await DemoApi.instance.generateMitigationResponses(
      generateMitigationRequest.queryType,
      generateMitigationRequest.resources.map((r) => r.repo).filter((r): r is DemoGitHubRepoName => !!r)
    );

    if (!responses.length) {
      void Popup.warn("This mitigation is not yet supported in demo mode", { timeout: Popup.INDEFINITELY });
    }
    return Promise.resolve(new Response(config, responses));
  }

  private getMitigations(config: AxiosRequestConfig): Promise<AxiosResponse<GetGithubMitigationResponse[]>> {
    const data = Object.values(this.completedMitigations);
    return Promise.resolve(new Response(config, data));
  }

  private loadMitigations(): Record<string, GetGithubMitigationResponse> {
    const mitigations = JSON.parse(sessionStorage.getItem(COMPLETED_MITIGATIONS) ?? "{}");
    return mitigations;
  }

  private saveMitigations() {
    sessionStorage.setItem(COMPLETED_MITIGATIONS, JSON.stringify(this.completedMitigations));
  }

  private createMitigation(config: AxiosRequestConfig<CreateGithubMitigationRequest[]>): Promise<AxiosResponse<CreateGithubMitigationResponse[]>> {
    const requests = config.data;
    if (!requests) {
      throw new ResponseError(config, "Bad Request", 400);
    }

    for (const request of requests) {
      const resourceId = generateGithubResourceId(request.orgName, request.repoName, request.branch);
      const resourceType = getGithubResourceType(request.repoName, request.branch);
      const assetIdSCMTypeAndResourceType = generateMitigationAssetIdIntegrationTypeAndResourceType("github", resourceType, resourceId);
      const lastJobCompletionDate = "2022-01-01T00:00:00Z";

      const example: GetGithubMitigationResponse = {
        assetIdSCMTypeAndResourceType,
        assetIdSCMTypeAndResourceTypeLastIngestionTimestamp: generateMitigationKey(
          assetIdSCMTypeAndResourceType,
          lastJobCompletionDate,
          request.typeOfPermission,
          request.mitigationAction
        ),
        repoName: request.repoName,
        usersLosingPermissions: [
          ...request.usersKeepingLosingPermissions.usersDirectlyLosingPermissions,
          ...request.usersKeepingLosingPermissions.usersInTeamLosingPermissions.flatMap((u) => u.users)
        ],
        usersKeepingPermissions: [
          ...request.usersKeepingLosingPermissions.usersDirectlyKeepingPermissions,
          ...request.usersKeepingLosingPermissions.usersInTeamKeepingPermissions.flatMap((u) => u.users)
        ],
        createdBy: Auth.user?.email ?? "demo-user@example.com",
        createdAt: new Date().toISOString(),
        resourceType,
        typeOfPermissionFixed: request.typeOfPermission,
        mitigationAction: request.mitigationAction,
        canBeReverted: true,
        integrationType: "github",
        integrationOrgId: DEMO_GITHUB_ORG_NAME,
        resourceId,
        mitigationStatus: "mitigated",
        updatedAt: new Date().toISOString(),
        baseUrl: "https://github.com"
      };

      this.completedMitigations[example.assetIdSCMTypeAndResourceTypeLastIngestionTimestamp] = example;
    }

    this.saveMitigations();

    const mitigations = requests.map((createMitigationRequest) => ({
      createMitigationRequest,
      mitigationError: null
    }));
    return Promise.resolve(new Response(config, mitigations));
  }

  private revertMitigation(config: AxiosRequestConfig<RevertMitigationRequest>): Promise<AxiosResponse> {
    const request = config.data;

    if (request && this.completedMitigations) {
      if (request.assetIdSCMTypeAndResourceTypeLastIngestionTimestamp in this.completedMitigations) {
        delete this.completedMitigations[request.assetIdSCMTypeAndResourceTypeLastIngestionTimestamp];
      }
      this.saveMitigations();
    }
    return Promise.resolve(new Response(config, undefined));
  }
})();
