//TODO: make this generic for all risk report types
import { MitigationStatus } from "../../dynamo";
import { ADOAsset, ADORiskReport, ADORiskReportIdentityItem, ADORiskReportQueryType, RiskReportSummary } from "../risk-report/risk-report";

const ADORiskReportIdentityItemIndexedColIndices = {
  identities: 0,
  group: 1,
  asset: {
    org: 2,
    repo: 3,
    project: 4,
    branch: 5
  },
  profileNames: 6,
  repositoryID: 7,

  groupDescriptor: 8,
  mitigateAsEnableReviewers: 9,
  isCrossRepoPolicy: 10,
  reverted: 11,
  mitigationStatus: 12
} as const;

type ADORiskReportIdentityItemIndexed = [
  //identities(0)
  number[],
  //group(1)
  number | null,
  //asset.org(2)
  number,
  //asset.repo (3)
  number | undefined,
  //asset.project (4)
  number | undefined,
  //asset.branch (5)
  number | undefined,
  //profileNames (6)
  [number, number][],
  //repositoryID (7)
  number | undefined,
  //groupDescriptor (8)
  number | null,
  //mitigateAsEnableReviewers (9)
  boolean | undefined,
  //isCrossRepoPolicy (10)
  boolean | undefined,
  //reverted (11)
  boolean | undefined,
  //mitigationStatus (12)
  MitigationStatus | undefined
];

type ADORiskReportIdentityItemIndex = {
  identities: string[];
  group: string[];
  asset: {
    org: string[];
    repo: string[];
    project: string[];
    branch: string[];
  };
  profileNames: {
    identity: string[];
    displayName: string[];
  };

  repositoryID: string[];

  groupDescriptor: string[];
};

export interface OptimizedADORiskReport {
  reportItems: ADORiskReportIdentityItemIndexed[];

  index: ADORiskReportIdentityItemIndex;

  summary: RiskReportSummary<ADORiskReportQueryType>;
}

function initReverseIndices() {
  const identitiesToIndex = new Map<string, number>();
  const groupToIndex = new Map<string, number>();
  const orgToIndex = new Map<string, number>();
  const repoToIndex = new Map<string, number>();
  const projectToIndex = new Map<string, number>();
  const branchToIndex = new Map<string, number>();
  const profileIdentityToIndex = new Map<string, number>();
  const profileDisplayNameToIndex = new Map<string, number>();
  const repositoryIDToIndex = new Map<string, number>();
  const groupDescriptorsToIndex = new Map<string, number>();
  return {
    identitiesToIndex,
    groupToIndex,
    orgToIndex,
    repoToIndex,
    projectToIndex,
    branchToIndex,
    profileIdentityToIndex,
    profileDisplayNameToIndex,
    repositoryIDToIndex,
    groupDescriptorsToIndex
  };
}

export async function optimizeADOReport(
  reportItems: AsyncIterable<ADORiskReportIdentityItem> | Iterable<ADORiskReportIdentityItem>,
  summary: RiskReportSummary<ADORiskReportQueryType>
): Promise<OptimizedADORiskReport> {
  const {
    identitiesToIndex,
    groupToIndex,
    orgToIndex,
    repoToIndex,
    projectToIndex,
    branchToIndex,
    profileIdentityToIndex,
    profileDisplayNameToIndex,
    repositoryIDToIndex,
    groupDescriptorsToIndex
  } = initReverseIndices();

  function index(reportItem: ADORiskReportIdentityItem) {
    const identities = reportItem.identities;
    for (const identity of identities) {
      if (!identitiesToIndex.has(identity)) {
        identitiesToIndex.set(identity, identitiesToIndex.size);
      }
    }

    if (reportItem.group) {
      if (!groupToIndex.has(reportItem.group)) {
        groupToIndex.set(reportItem.group, groupToIndex.size);
      }
    }

    if (reportItem.asset) {
      if (!orgToIndex.has(reportItem.asset.org)) {
        orgToIndex.set(reportItem.asset.org, orgToIndex.size);
      }

      if (reportItem.asset.repo) {
        if (!repoToIndex.has(reportItem.asset.repo)) {
          repoToIndex.set(reportItem.asset.repo, repoToIndex.size);
        }
      }

      if (reportItem.asset.project) {
        if (!projectToIndex.has(reportItem.asset.project)) {
          projectToIndex.set(reportItem.asset.project, projectToIndex.size);
        }
      }

      if (reportItem.asset.branch) {
        if (!branchToIndex.has(reportItem.asset.branch)) {
          branchToIndex.set(reportItem.asset.branch, branchToIndex.size);
        }
      }
    }

    if (reportItem.profileNames) {
      for (const profileName of reportItem.profileNames) {
        if (!profileIdentityToIndex.has(profileName.identity)) {
          profileIdentityToIndex.set(profileName.identity, profileIdentityToIndex.size);
        }

        if (!profileDisplayNameToIndex.has(profileName.displayName)) {
          profileDisplayNameToIndex.set(profileName.displayName, profileDisplayNameToIndex.size);
        }
      }
    }

    if (reportItem.repositoryID) {
      if (!repositoryIDToIndex.has(reportItem.repositoryID)) {
        repositoryIDToIndex.set(reportItem.repositoryID, repositoryIDToIndex.size);
      }
    }

    if (reportItem.groupDescriptor) {
      if (!groupDescriptorsToIndex.has(reportItem.groupDescriptor)) {
        groupDescriptorsToIndex.set(reportItem.groupDescriptor, groupDescriptorsToIndex.size);
      }
    }
  }

  const ret: OptimizedADORiskReport = {
    reportItems: [],
    index: {
      identities: [],
      group: [],
      asset: {
        org: [],
        repo: [],
        project: [],
        branch: []
      },
      profileNames: {
        identity: [],
        displayName: []
      },
      repositoryID: [],
      groupDescriptor: []
    },
    summary
  };

  const adoRiskReportIdentityItems = reportItems;
  for await (const reportItem of adoRiskReportIdentityItems) {
    index(reportItem);

    const newReportItem: ADORiskReportIdentityItemIndexed = [
      reportItem.identities.map((identity) => identitiesToIndex.get(identity)).nonNullable(),
      groupToIndex.get(reportItem.group ?? "") ?? null,
      orgToIndex.get(reportItem.asset.org) ?? -1,
      repoToIndex.get(reportItem.asset.repo ?? "") ?? undefined,
      projectToIndex.get(reportItem.asset.project ?? "") ?? undefined,
      branchToIndex.get(reportItem.asset.branch ?? "") ?? undefined,
      reportItem.profileNames?.map((profileName) => [profileIdentityToIndex.get(profileName.identity) ?? -1, profileDisplayNameToIndex.get(profileName.displayName) ?? -1]) ?? [],
      repositoryIDToIndex.get(reportItem.repositoryID ?? "") ?? undefined,
      groupDescriptorsToIndex.get(reportItem.groupDescriptor ?? "") ?? null,
      reportItem.mitigateAsEnableReviewers,
      reportItem.isCrossRepoPolicy,
      reportItem.reverted,
      reportItem.mitigationStatus
    ];
    ret.reportItems.push(newReportItem);
  }

  for (const [identity, index] of identitiesToIndex) {
    ret.index.identities[index] = identity;
  }

  for (const [group, index] of groupToIndex) {
    ret.index.group[index] = group;
  }

  for (const [org, index] of orgToIndex) {
    ret.index.asset.org[index] = org;
  }

  for (const [repo, index] of repoToIndex) {
    ret.index.asset.repo[index] = repo;
  }

  for (const [project, index] of projectToIndex) {
    ret.index.asset.project[index] = project;
  }

  for (const [branch, index] of branchToIndex) {
    ret.index.asset.branch[index] = branch;
  }

  for (const [identity, index] of profileIdentityToIndex) {
    ret.index.profileNames.identity[index] = identity;
  }

  for (const [displayName, index] of profileDisplayNameToIndex) {
    ret.index.profileNames.displayName[index] = displayName;
  }

  for (const [repositoryID, index] of repositoryIDToIndex) {
    ret.index.repositoryID[index] = repositoryID;
  }

  for (const [groupDescriptor, index] of groupDescriptorsToIndex) {
    ret.index.groupDescriptor[index] = groupDescriptor;
  }

  return ret;
}

export function deoptimizeADOReportItem(reportItem: ADORiskReportIdentityItemIndexed, reportIndex: ADORiskReportIdentityItemIndex) {
  //TODO: make this generic to any pojo (e.g. annotate attributes that need to be optimized / deoptimized)
  const indices = ADORiskReportIdentityItemIndexedColIndices;
  const {
    identitiesToIndex,
    groupToIndex,
    orgToIndex,
    repoToIndex,
    projectToIndex,
    branchToIndex,
    profileIdentityToIndex,
    profileDisplayNameToIndex,
    repositoryIDToIndex,
    groupDescriptorsToIndex
  } = initReverseIndices();

  function setOrg(value: string) {
    if (!orgToIndex.size) {
      for (let i = 0; i < reportIndex.asset.org.length; i++) {
        orgToIndex.set(reportIndex.asset.org[i]!, i);
      }
    }
    reportItem[indices.asset.org] = orgToIndex.get(value) ?? -1;
  }

  function setRepo(value: string | undefined) {
    if (!repoToIndex.size) {
      for (let i = 0; i < reportIndex.asset.repo.length; i++) {
        repoToIndex.set(reportIndex.asset.repo[i]!, i);
      }
    }
    reportItem[indices.asset.repo] = repoToIndex.get(value ?? "") ?? undefined;
    //reportItem[indices.asset.repo] = reportIndex.asset.repo.indexOf(value ?? "");
  }

  function setProject(value: string | undefined) {
    if (!projectToIndex.size) {
      for (let i = 0; i < reportIndex.asset.project.length; i++) {
        projectToIndex.set(reportIndex.asset.project[i]!, i);
      }
    }
    reportItem[indices.asset.project] = projectToIndex.get(value ?? "") ?? undefined;
    //reportItem[indices.asset.project] = reportIndex.asset.project.indexOf(value ?? "");
  }

  function setBranch(value: string | undefined) {
    if (!branchToIndex.size) {
      for (let i = 0; i < reportIndex.asset.branch.length; i++) {
        branchToIndex.set(reportIndex.asset.branch[i]!, i);
      }
    }
    reportItem[indices.asset.branch] = branchToIndex.get(value ?? "") ?? undefined;
    //reportItem[indices.asset.branch] = reportIndex.asset.branch.indexOf(value ?? "");
  }

  const newReportItem: ADORiskReportIdentityItem = {
    get identities() {
      return reportItem[indices.identities].map((index) => reportIndex.identities[index]).nonNullable();
    },
    set identities(value) {
      if (!identitiesToIndex.size) {
        for (let i = 0; i < reportIndex.identities.length; i++) {
          identitiesToIndex.set(reportIndex.identities[i]!, i);
        }
      }
      reportItem[indices.identities] = value.map((identity) => identitiesToIndex.get(identity)).nonNullable();
    },
    get group() {
      return reportIndex.group[reportItem[indices.group] ?? -1] ?? null;
    },
    set group(value) {
      if (!groupToIndex.size) {
        for (let i = 0; i < reportIndex.group.length; i++) {
          groupToIndex.set(reportIndex.group[i]!, i);
        }
      }
      reportItem[indices.group] = groupToIndex.get(value ?? "") ?? null;
      //reportItem[indices.group] = reportIndex.group.indexOf(value ?? "");
    },
    get asset() {
      const adoAsset: ADOAsset = {
        get org() {
          return reportIndex.asset.org[reportItem[indices.asset.org]] ?? "";
        },
        set org(value) {
          setOrg(value);
        },
        get repo() {
          return reportIndex.asset.repo[reportItem[indices.asset.repo] ?? -1] ?? undefined;
        },
        set repo(value) {
          setRepo(value);
        },
        get project() {
          return reportIndex.asset.project[reportItem[indices.asset.project] ?? -1] ?? undefined;
        },
        set project(value) {
          setProject(value);
        },
        get branch() {
          return reportIndex.asset.branch[reportItem[indices.asset.branch] ?? -1] ?? undefined;
        },
        set branch(value) {
          setBranch(value);
        }
      };
      return adoAsset;
    },
    set asset(value) {
      setOrg(value.org ?? "");
      setRepo(value.repo ?? undefined);
      setProject(value.project ?? undefined);
      setBranch(value.branch ?? undefined);
    },
    get profileNames() {
      return reportItem[indices.profileNames]
        ?.map((profileName) => ({
          get identity() {
            return reportIndex.profileNames.identity[profileName[0]] ?? "";
          },
          get displayName() {
            return reportIndex.profileNames.displayName[profileName[1]] ?? "";
          }
        }))
        .filter((profileName) => profileName.identity && profileName.displayName);
    },
    set profileNames(value) {
      if (!profileIdentityToIndex.size) {
        for (let i = 0; i < reportIndex.profileNames.identity.length; i++) {
          profileIdentityToIndex.set(reportIndex.profileNames.identity[i]!, i);
        }
      }
      if (!profileDisplayNameToIndex.size) {
        for (let i = 0; i < reportIndex.profileNames.displayName.length; i++) {
          profileDisplayNameToIndex.set(reportIndex.profileNames.displayName[i]!, i);
        }
      }
      reportItem[indices.profileNames] = value?.map((profileName) => [
        profileIdentityToIndex.get(profileName.identity) ?? -1,
        profileDisplayNameToIndex.get(profileName.displayName) ?? -1
      ]);
    },
    get repositoryID() {
      return reportIndex.repositoryID[reportItem[indices.repositoryID] ?? -1] ?? undefined;
    },
    set repositoryID(value) {
      if (!repositoryIDToIndex.size) {
        for (let i = 0; i < reportIndex.repositoryID.length; i++) {
          repositoryIDToIndex.set(reportIndex.repositoryID[i]!, i);
        }
      }
      reportItem[indices.repositoryID] = repositoryIDToIndex.get(value ?? "") ?? undefined;
    },
    get groupDescriptor() {
      return reportIndex.groupDescriptor[reportItem[indices.groupDescriptor] ?? -1] ?? null;
    },
    set groupDescriptor(value) {
      if (!groupDescriptorsToIndex.size) {
        for (let i = 0; i < reportIndex.groupDescriptor.length; i++) {
          groupDescriptorsToIndex.set(reportIndex.groupDescriptor[i]!, i);
        }
      }
      reportItem[indices.groupDescriptor] = groupDescriptorsToIndex.get(value ?? "") ?? null;
    },
    get mitigateAsEnableReviewers() {
      return reportItem[indices.mitigateAsEnableReviewers];
    },
    set mitigateAsEnableReviewers(value) {
      reportItem[indices.mitigateAsEnableReviewers] = value;
    },
    get isCrossRepoPolicy() {
      return reportItem[indices.isCrossRepoPolicy];
    },
    set isCrossRepoPolicy(value) {
      reportItem[indices.isCrossRepoPolicy] = value;
    },
    get reverted() {
      return reportItem[indices.reverted];
    },
    set reverted(value) {
      reportItem[indices.reverted] = value;
    },
    get mitigationStatus() {
      return reportItem[indices.mitigationStatus];
    },
    set mitigationStatus(value) {
      reportItem[indices.mitigationStatus] = value;
    }
  };

  if (reportItem[indices.mitigateAsEnableReviewers] === undefined) {
    delete newReportItem.mitigateAsEnableReviewers;
  }
  if (reportItem[indices.isCrossRepoPolicy] === undefined) {
    delete newReportItem.isCrossRepoPolicy;
  }
  if (reportItem[indices.reverted] === undefined) {
    delete newReportItem.reverted;
  }
  if (reportItem[indices.mitigationStatus] === undefined) {
    delete newReportItem.mitigationStatus;
  }

  return newReportItem;
}

export type DeoptimizedADORiskReport = ADORiskReport<Iterable<ADORiskReportIdentityItem>> & { optimizedReport: OptimizedADORiskReport };

export function deoptimizeADOReport(optimizedReport: OptimizedADORiskReport): DeoptimizedADORiskReport {
  const reportIndex = optimizedReport.index;

  function* reportItemGenerator() {
    for (const reportItem of optimizedReport.reportItems) {
      yield deoptimizeADOReportItem(reportItem, reportIndex);
    }
  }

  return {
    reportItems: reportItemGenerator(),
    summary: optimizedReport.summary,
    optimizedReport
  };
}

export function isDeoptimizedReport(report: any): report is DeoptimizedADORiskReport {
  return "optimizedReport" in report && report.summary.scmType === "AZURE_DEVOPS";
}

export function isOptimizedADOReport(report: unknown): report is OptimizedADORiskReport {
  return (
    !!report &&
    typeof report === "object" &&
    "index" in report &&
    "reportItems" in report &&
    Array.isArray(report.reportItems) &&
    "summary" in report &&
    typeof report.summary === "object" &&
    !!report.summary &&
    "scmType" in report.summary &&
    report.summary.scmType === "AZURE_DEVOPS"
  );
}
