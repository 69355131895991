/**
 * A list of all policy item types.
 * @note This enum should be augmented in modules to add new types of policy components.
 */
export enum PolicyItemType {}
(PolicyItemType as unknown) = {
  /** es-build minification workaround */
};

/**
 * A list of all policy components.
 * @note This interface should be augmented in modules to add new types of policy components.
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface -- augmented in modules
export interface PolicyComponents {}

export interface PolicyItemBase<T extends PolicyItemType> {
  id: string;
  type: T;
}
