import Router, { NavigationGuardNext, Route } from "vue-router";
import { Popup, Tier as TierState } from "@/state";

type TierPredicate = ($tier: typeof TierState) => boolean;
interface TierConfiguration {
  enabled: TierPredicate;
  message: string;
}

declare module "vue-router/types/router" {
  export interface RouteMeta {
    /**
     * Indicates a route tier configuration.
     * @optional default (not specified) is available for all tiers.
     * @example
     * ```
     * tier: $tier => $tier.min.business // allow route if tier is business or above
     * ```
     * @example
     * ```
     * tier: {
     *  predicate: $tier => $tier.min.business,
     *  message: "Upgrade to a business plan to access"
     * }
     * ```
     */
    tier?: TierConfiguration | TierPredicate;
  }
}

function isAllowed(route: Route): boolean | string {
  const config = route.meta?.tier;
  switch (typeof config) {
    case "undefined":
      return true;
    case "function":
      return config(TierState);
    case "object":
      return config.enabled(TierState);
    default:
      throw new Error(`Invalid route tier configuration`, config);
  }
}

async function tieredRoute<V extends Vue = Vue>(to: Route, from: Route, next: NavigationGuardNext<V>): Promise<void> {
  try {
    if (isAllowed(to)) {
      next();
    } else {
      throw new Error(`This page ('${to.meta?.title ?? to.name ?? to.path}') is not available for your current tier.<br>Please consider upgrading.`);
    }
  } catch (e) {
    if (e instanceof Error) {
      await Popup.error(e.message, {
        timeout: Popup.INDEFINITELY,
        html: true,
        route: "/upgrade"
      });
    }
  }
}

export function install(router: Router): Router {
  router.beforeEach(tieredRoute);
  return router;
}
