import { ScmIntegrationType } from "../dynamo";
import { CommonStatusMeanings, DashboardFindingType } from "../finding-types";
import { GitResourceBase } from "../report/interfaces/git-resource-base";
import { RiskSeverity } from "../risk-severity";
import { SumVulnerabilities } from "../sbom/interfaces/sbom-report-item";
import { RISK_CONVERTER } from "../sbom/sbom-utils";
import { IntegrationAttributes } from "../dynamo/integration/integration-attributes";

export interface DashboardAsset {
  //asset?: GitResourceBase;
  status: CommonStatusMeanings | null;
  type: DashboardFindingType;
  vulnerabilitySummary: SumVulnerabilities;
}

export interface DashboardTrend {
  //asset?: GitResourceBase;
  timeUnit: "minute" | "hour" | "day" | "week" | "month" | "year";
  severity: RiskSeverity;
  startDate: string;
  trends: StatusesMatrix;
}

export interface PrismaDashboard2AssetBySeverity {
  resourceId: string;
  // TODO: add back permissions
  //permissions: Record<VulnerabilitySeverity, number>,

  // note that the key is a number as in the DB risk is stored as a number
  secret: Record<string, number>;
  sast: Record<string, number>;
  sca: Record<string, number>;
  iac: Record<string, number>;
  license: Record<string, number>;
  reputation: Record<string, number>;
  permissions: Record<string, number>;
}

export interface Dashboard2TrendByResource {
  resourceId: string;
  date: string;
  total: number;
}

export interface Dashboard2TrendByStatus {
  statusMeaning: CommonStatusMeanings;
  date: string;
  total: number;
}

export interface Dashboard2TrendBySeverity {
  severity: RiskSeverity;
  date: string;
  total: number;
}

export interface Dashboard2Asset {
  resourceId: string;
  // note that the key is a number as in the DB risk is stored as a number
  permissions: Record<RiskSeverity, number>;
  secret: Record<RiskSeverity, number>;
  sast: Record<RiskSeverity, number>;
  sca: Record<RiskSeverity, number>;
  iac: Record<RiskSeverity, number>;
  license: Record<RiskSeverity, number>;
  reputation: Record<RiskSeverity, number>;
}

export interface Dashboard2Activity {
  category: DashboardActivityCategory;
  count: number;
}

export type DashboardActivityCategory =
  | "code-risks-fixed-feature-branches"
  | "code-risks-fixed-default-branches"
  | "secrets-fixed"
  | "kev-findings-open"
  | "high-epss-findings-open"
  | "pending-review-findings";

export interface DashboardActivity {
  severity: RiskSeverity;
  category: DashboardActivityCategory;
  findingSortKeys?: string[];
  packages?: string[];
  cves?: string[];
}

//TODO: pre-group-by items (save the group by in the UI + saves bandwidth)
export interface DashboardResponse {
  resource: GitResourceBase;
  dateGenerated: string;
  assets: DashboardAsset[];
  activity?: DashboardActivity[];
  trends?: DashboardTrend[];
}

/**
 * rows are status meanings, columns are aggregated counts by day
 */
export type StatusesMatrix = Record<CommonStatusMeanings, number[]>;

export const StatusesMatrix = {
  /**
   * returns the trend of a given dataset
   * @param matrix the dataset of number of findings per status per day
   * @param range the number of days back to calculate the trend
   */
  trends(matrix: StatusesMatrix, range: number): Record<CommonStatusMeanings, { total: number; trend: number }> {
    const fromEntries = Object.fromEntries(
      Object.entries(matrix).map(([status, dataset]) => {
        const statusKey = status as CommonStatusMeanings;
        return [statusKey, StatusesMatrix.trend(statusKey, dataset, range)];
      })
    );
    return fromEntries as Record<CommonStatusMeanings, { total: number; trend: number }>;
  },
  trend(status: CommonStatusMeanings, dataset: number[], range: number): { total: number; trend: number } {
    const slice = dataset.slice(-range);
    if (!slice.length || !range) {
      return {
        total: 0,
        trend: 0
      };
    }

    const slope = ((slice[0] ?? 0) - (slice[range - 1] ?? 0)) / range;
    return {
      total: slice.sum(),
      trend: slope
    };
  },
  mergeStatuses(matrix: StatusesMatrix, ...statuses: CommonStatusMeanings[]): number[] {
    return statuses.reduce(
      (acc, status) => {
        const statusValues = matrix[status];

        return acc.map((val, i) => val + (statusValues[i] ?? 0));
      },
      Array(StatusesMatrix.length(matrix)).fill(0)
    );
  },

  length(matrix: StatusesMatrix): number {
    return matrix["new"].length;
  },
  sum(matrices: StatusesMatrix[]): StatusesMatrix {
    const sumMatrix: StatusesMatrix = {
      dismissed: [],
      resolved: [],
      in_progress: [],
      new: []
    };

    for (const matrix of matrices) {
      for (const status in matrix) {
        const statusKey = status as CommonStatusMeanings;
        const statusValues = matrix[statusKey];

        for (let i = 0; i < statusValues.length; i++) {
          sumMatrix[statusKey][i] = (sumMatrix[statusKey][i] ?? 0) + (statusValues[i] ?? 0);
        }
      }
    }

    return sumMatrix;
  }
};

/**
 * DashboardActivity object to manage dashboard activities
 * @class
 * @property {Function} mergeActivities - Function to merge dashboard activities
 */
export const DashboardActivity = {
  /**
   * Function to merge an array of activities into a single array of aggregated activities
   * @function
   * @param {DashboardActivity[]} activities - The array of DashboardActivity instances to be merged
   * @returns {DashboardActivity[]} - The resulting array of merged DashboardActivity instances
   */
  mergeActivities(activities: DashboardActivity[]): DashboardActivity[] {
    // Group activities by category
    const activitiesByCategory = activities.groupBy((activity) => activity.category);

    // Iterate over each category
    return Array.from(activitiesByCategory).map(([category, categoryActivities]) => {
      const aggregatedActivities = {
        category,
        severity: categoryActivities[0].severity,
        findingSortKeys: new Set<string>(),
        cves: new Set<string>(),
        packages: new Set<string>()
      };

      // Iterate over each activity in the category
      for (const activity of categoryActivities) {
        // Update the aggregated severity if the current activity's severity is higher
        if (RISK_CONVERTER[activity.severity] < RISK_CONVERTER[aggregatedActivities.severity]) {
          aggregatedActivities.severity = activity.severity;
        }
        // Add activity's CVEs, packages and findingSortKeys to the aggregatedActivities set
        activity.cves?.forEach((cve) => aggregatedActivities.cves?.add(cve));
        activity.packages?.forEach((pkg) => aggregatedActivities.packages?.add(pkg));
        activity.findingSortKeys?.forEach((findingSortKey) => aggregatedActivities.findingSortKeys?.add(findingSortKey));
      }
      // Push the aggregated activity to the result
      return {
        category: aggregatedActivities.category,
        severity: aggregatedActivities.severity,
        findingSortKeys: Array.from(aggregatedActivities.findingSortKeys ?? []),
        cves: Array.from(aggregatedActivities.cves ?? []),
        packages: Array.from(aggregatedActivities.packages ?? [])
      };
    });
  }
};

/**
 * @note for GitHub, we need to always add an empty project as in the database, as the project key in the db will always include /integType/org/project/repo for the resourceId
 * for ADO, to support products with just an org but no project, we want undefined to be filtered out in the non-nullable
 * <br>
 * See the unit test (dashboard-response.spec.ts) for examples
 * TODO: fix in products v2 (should be based in joins, should not store empty string when it should be null, and should include repoId/projectId)
 */
export function getResourcePrefix(resource: { integrationType: ScmIntegrationType; orgId: string; project?: string; repo?: string }) {
  let project = IntegrationAttributes[resource.integrationType].project.isSupported ? resource.project : "";
  let repo = resource.repo;

  // Explanation: the asset ID is always /org/project/repo in the DB.
  // however the resource.project (due to historical reasons) can be sometimes undefined, sometimes empty string.
  // We need to build a prefix (e.g. either org/project/repo%, org//repo%, org/project/%, or org/% based on the product)

  if (!repo) {
    //this is a "trick" to ensure trailing slash, e.g. so that two orgs such as foo and foobar won't get foo% including both, but have foo/% to include only foo
    //(the alternative is to set repo to undefined, and add a boolean addTrailingSlash that is set to true here)
    repo = "";

    if (!project) {
      // if we have no repo, and the project is falsy, this is an org level resource (or a gitlab empty project with no repo, same thing), we shouldn't include the project in it
      project = undefined;
    }
    //otherwise it's a project level resource
  }
  return [resource.integrationType, resource.orgId, project, repo].nonNullable().join("/");
}
