import { IssuesIntegrationType } from "$/dynamo";
import { GetIntegrationResponse } from "$/interfaces/ui-api/response/get-integration-response";
import { FieldDescriptor, Issue, IssueType, Project, ProjectUser, Status } from "$/issues";
import { ApiBase } from "@/api/api-base";

abstract class IssuesApiBase extends ApiBase {
  public async listProjects(integrationOrgId: string): Promise<Project[]> {
    if (!integrationOrgId) {
      throw new Error("integrationOrgId is required");
    }
    const res = await this.client.get<Project[]>(`${integrationOrgId.encodeURI()}/projects`);
    return res.data;
  }

  public async listIssueTypes(integrationOrgId: string, projectId: string): Promise<IssueType[]> {
    if (!integrationOrgId) {
      throw new Error("integrationOrgId is required");
    }
    if (!projectId) {
      throw new Error("projectId is required");
    }
    const res = await this.client.get<IssueType[]>(`${integrationOrgId.encodeURI()}/${projectId.encodePathURI()}/types`);
    return res.data;
  }

  public async listStatuses(integrationOrgId: string, projectId: string, issueTypeId: string): Promise<Status[]> {
    if (!integrationOrgId) {
      throw new Error("integrationOrgId is required");
    }
    if (!projectId) {
      throw new Error("projectId is required");
    }
    if (!issueTypeId) {
      throw new Error("issueTypeId is required");
    }
    const res = await this.client.get<Status[]>(`${integrationOrgId.encodeURI()}/${projectId.encodePathURI()}/${issueTypeId.encodePathURI()}/statuses`);
    return res.data;
  }

  public async listProjectAssignees(integrationOrgId: string, projectId: string, search?: string): Promise<ProjectUser[]> {
    if (!integrationOrgId) {
      throw new Error("integrationOrgId is required");
    }
    if (!projectId) {
      throw new Error("projectId is required");
    }
    const res = await this.client.get<ProjectUser[]>(`${integrationOrgId.encodeURI()}/${projectId.encodePathURI()}/assignees`, {
      params: {
        search
      }
    });
    return res.data;
  }

  public async listFieldDescriptors(integrationOrgId: string, projectId: string, issueType: string): Promise<FieldDescriptor[]> {
    if (!integrationOrgId) {
      throw new Error("integrationOrgId is required");
    }
    if (!projectId) {
      throw new Error("projectId is required");
    }

    if (!projectId) {
      throw new Error("issueType is required");
    }
    const res = await this.client.get<FieldDescriptor[]>(`${integrationOrgId.encodeURI()}/${projectId.encodePathURI()}/${issueType.encodeURI()}/fields`);
    return res.data;
  }

  public async getUser(integrationOrgId: string, userId: string): Promise<ProjectUser> {
    if (!integrationOrgId) {
      throw new Error("integrationOrgId is required");
    }

    if (!userId) {
      throw new Error("userId is required");
    }
    const res = await this.client.get<ProjectUser>(`${integrationOrgId.encodeURI()}/users/${userId.encodeURI()}`);
    return res.data;
  }
}

class JiraIssuesApi extends IssuesApiBase {
  public constructor() {
    super({ pathPrefix: "bots/issues/jira", name: "JiraIssuesApi" });
  }

  public async getIntegrationUrl(): Promise<URL> {
    const res = await this.client.get<string>("/integration/url");
    const url = new URL(res.data);
    return url;
  }
}

class AzureDevopsIssuesApi extends IssuesApiBase {
  public constructor() {
    super({ pathPrefix: "bots/issues/azure-devops-boards", name: "AzureDevopsIssuesApi" });
  }
}

class IssuesApi extends ApiBase implements Record<IssuesIntegrationType, IssuesApiBase> {
  public constructor() {
    super({ pathPrefix: "issues", name: "IssuesApi" });
  }

  public readonly jira = new JiraIssuesApi();
  public readonly "azure-devops-boards" = new AzureDevopsIssuesApi();

  public async getFindingMarkdownSummary(sortKey: string): Promise<string> {
    const res = await this.client.get<string>(`finding/${sortKey.encodeURI()}/summary.md`);
    return res.data;
  }

  public async createIssue(integration: GetIntegrationResponse, sortKey: string): Promise<Issue> {
    const { integrationType, integrationOrgId } = integration;
    const res = await this.client.post<Issue>(`${integrationType.encodeURI()}/${integrationOrgId.encodeURI()}`, undefined, {
      params: {
        finding: sortKey
      }
    });
    const issue: Issue = {
      ...res.data,
      url: new URL(res.data.url).toString()
    };
    return issue;
  }
}

export const Issues = new IssuesApi();
