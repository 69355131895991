import Vue from "vue";

declare global {
  interface Promise<T> {
    observe(awaitingValue: T): ObservablePromise<T>;
  }
}

export abstract class ObservablePromiseBase<T> implements PromiseLike<T> {
  readonly #promise: Promise<T>;

  public constructor(promise: Promise<T>) {
    this.#promise = promise;
  }

  public abstract get value(): T;

  public then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined
  ): PromiseLike<TResult1 | TResult2> {
    // eslint-disable-next-line github/no-then
    return this.#promise.then(onfulfilled, onrejected);
  }
}

class ObservablePromise<T> extends ObservablePromiseBase<T> {
  public value: T;

  public constructor(promise: Promise<T>, awaitingValue: T) {
    super(promise);
    this.value = awaitingValue;
    Vue.observable(this);
    // eslint-disable-next-line github/no-then
    promise.then((value) => (this.value = value));
  }
}

Promise.prototype.observe ??= function observe<T>(this: Promise<T>, awaitingValue: T): ObservablePromiseBase<T> {
  return new ObservablePromise(this, awaitingValue);
};
