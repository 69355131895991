import { PolicyItemType } from "../policy-item-base";
import { PolicyTrigger, PolicyTriggerSubType } from "./policy-trigger";

declare module "./policy-trigger" {
  enum PolicyTriggerSubType {
    code_risk_fixed = "code_risk_fixed"
  }
}

(PolicyTriggerSubType as { code_risk_fixed: "code_risk_fixed" }).code_risk_fixed = "code_risk_fixed";

export interface PolicyTriggerCodeRiskFixed extends PolicyTrigger {
  sub: PolicyTriggerSubType.code_risk_fixed;
}

export const PolicyTriggerCodeRiskFixed = {
  component: {
    type: PolicyItemType.trigger,
    sub: PolicyTriggerSubType.code_risk_fixed
  },
  generateDefault(): PolicyTriggerCodeRiskFixed {
    return {
      type: PolicyItemType.trigger,
      sub: PolicyTriggerSubType.code_risk_fixed,
      id: `${PolicyItemType.trigger}-${Math.floor(Date.now() * Math.random())}`
    };
  }
} as const;
