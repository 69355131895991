export interface BitbucketServerRepositoryIdentifier {
  readonly projectKey: string;
  readonly repositorySlug: string;
}

export const BitbucketServerRepositoryIdentifier = {
  parse: (id: string): BitbucketServerRepositoryIdentifier => {
    const [projectKey, repositorySlug, ...rest] = id.split("/");
    if (!projectKey || !repositorySlug || rest.length) {
      throw new Error(`Invalid repository identifier: ${id}`);
    }
    return {
      projectKey: projectKey.decodeURI(),
      repositorySlug: repositorySlug.decodeURI()
    };
  },
  encode: ({projectKey, repositorySlug}: BitbucketServerRepositoryIdentifier): string => {
    return `${projectKey.encodeURI()}/${repositorySlug.encodeURI()}`;
  }
} as const;
