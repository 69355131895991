// @spellchecker: ignore EPSS

import { TrivyCycloneDx } from "../../../../../sbom/interfaces/sbom-report-item";
import { PolicyItemType } from "../../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_epss_score = "package_epss_score"
  }
}

(PolicyConditionSubType as { package_epss_score: "package_epss_score" }).package_epss_score = "package_epss_score";

export interface PolicyConditionFindingPackageEpssScore extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_epss_score;
  /** EPSS score (0.0-100.0) minimum (inclusive) */
  min: number;
  /** EPSS score (0.0-100.0) maximum (inclusive) */
  max: number;
}

export const PolicyConditionFindingPackageEpssScore = {
  TYPES: TrivyCycloneDx.PackageType,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_epss_score
  }
} as const;
