<template>
  <div class="component inline-select">
    <select v-bind="$attrs" :class="classes" v-bind:value="value" v-on:change="onChange" ref="el">
      <slot v-for="(item, idx) of items" v-bind:item="item" v-bind:idx="idx">
        <option :value="idx" :key="idx">
          {{ item }}
        </option>
      </slot>
    </select>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export class InlineSelect extends Vue {
  @Model("change")
  protected value!: string;

  @Prop({ type: Boolean, default: false })
  protected outlined!: boolean;

  @Prop({ type: Boolean, default: false })
  protected centered!: boolean;

  @Prop({ type: Array, required: true })
  protected items!: unknown[];

  protected get indexOfValue(): number {
    return this.items.indexOf(this.value);
  }

  // protected set indexOfValue(value: number) {
  //   this.value = this.items.indexOf(value);
  // }

  protected get classes(): string {
    return Object.entries({
      "text-align-center": this.centered,
      outlined: this.outlined
    })
      .filter(([, v]) => v)
      .map(([k]) => k)
      .join(" ");
  }

  protected onChange($event: Event): void {
    // const selectedIndex = ($event.target as HTMLSelectElement).selectedIndex;
    const value = ($event.target as HTMLSelectElement).value;
    this.$emit("change", value);
  }
}

export default InlineSelect;
</script>

<style lang="scss">
.component.inline-select {
  display: inline-block;

  select {
    -webkit-appearance: auto;
    -moz-appearance: auto;

    &[disabled] {
      background: whitesmoke;
    }

    &.outlined {
      border: 1px solid lightgray;
      border-radius: 4px;
      margin-left: 0.3em;
      margin-right: 0.3em;
    }
  }
}
</style>
