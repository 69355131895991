/**
 * Creates a new URL by appending the path to the baseURL
 * @param {string} baseURL The base URL
 * @param {string} [path] The path you would like to append
 * @returns {string} The combined URL
 */
export function appendToURL(baseURL: string | URL, path: string): URL {
  baseURL = baseURL.toString();
  if (!path) {
    return new URL(baseURL);
  }
  return new URL(path.replace(/^\/+/, ""), baseURL.replace(/\/*$/, "/"));
}

/**
 * Determines whether the specified URL is absolute
 *
 * @param {string} url The URL to test
 * @returns {boolean} True if the specified URL is absolute, otherwise false
 */
export function isAbsoluteURL(url: string): boolean {
  // A URL is considered absolute if it begins with "<scheme>://" or "//" (protocol-relative URL).
  // RFC 3986 defines scheme name as a sequence of characters beginning with a letter and followed
  // by any combination of letters, digits, plus, period, or hyphen.
  return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(url);
}

/**
 * Determine if a value is a URLSearchParams object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a URLSearchParams object, otherwise false
 */
export function isURLSearchParams(val: unknown): val is import("url").URLSearchParams {
  return typeof globalThis.URLSearchParams !== "undefined" && val instanceof globalThis.URLSearchParams;
}

function encode(val: string): string {
  return encodeURIComponent(val).replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%20/g, "+").replace(/%5B/gi, "[").replace(/%5D/gi, "]");
}

/**
 * Iterate over an Array or an Object invoking a function for each item.
 *
 * If `obj` is an Array callback will be called passing
 * the value, index, and complete array for each item.
 *
 * If 'obj' is an Object callback will be called passing
 * the value, key, and complete object for each property.
 *
 * @param {Object|Array} obj The object to iterate
 * @param {Function} fn The callback to invoke for each item
 */
function iterate<T>(obj: T, fn: (val: unknown, key: string | number, obj: T) => void): void {
  // Don't bother if no value provided
  if (obj === null || typeof obj === "undefined") {
    return;
  }

  // Force an array if not already something iterable
  if (typeof obj !== "object") {
    /*eslint no-param-reassign:0*/
    obj = [obj] as T;
  }

  if (Array.isArray(obj)) {
    // Iterate over array values
    for (let i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  } else {
    // Iterate over object keys
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        fn.call(null, obj[key], key, obj);
      }
    }
  }
}

/**
 * Build a URL by appending params to the end
 *
 * @param {string} url The base of the url (e.g., http://www.google.com)
 * @param {object} [params] The params to be appended
 * @param {function} [paramsSerializer] The function to serialize params
 * @returns {string} The formatted url
 */
export function buildURL(url: string, params?: unknown, paramsSerializer?: (params: unknown) => string): string {
  if (!params) {
    return url;
  }

  let serializedParams: string;
  if (paramsSerializer) {
    serializedParams = paramsSerializer(params);
  } else if (isURLSearchParams(params)) {
    serializedParams = params.toString();
  } else {
    const parts: string[] = [];

    iterate(params, function serialize(val, key) {
      if (val === null || typeof val === "undefined") {
        return;
      }

      if (Array.isArray(val)) {
        key = key + "[]";
      } else {
        val = [val];
      }

      iterate(val, function parseValue(v) {
        if (v instanceof Date) {
          v = v.toISOString();
        } else if (!!v && typeof v === "object") {
          v = JSON.stringify(v);
        }
        parts.push(`${encode(String(key))}=${encode(String(v))}`);
      });
    });

    serializedParams = parts.join("&");
  }

  if (serializedParams) {
    const hashIdx = url.indexOf("#");
    if (hashIdx !== -1) {
      url = url.slice(0, hashIdx);
    }

    url += (url.indexOf("?") === -1 ? "?" : "&") + serializedParams;
  }

  return url;
}
