export function parseIntSafe(string?: string): number | undefined {
  if (!string) {
    return undefined;
  }
  try {
    return parseInt(string);
  } catch (e) {
    console.warn(`parseIntSafe: Unable to parse ${string} as an integer`);
    return undefined;
  }
}

export const isWholeNumber = (value: number) => {
  return Number.isInteger(value);
};

export const toFixedIfNotWhole = (value: number, decimalPlaces: number) => {
  if (isWholeNumber(value)) {
    return value;
  }
  return value.toFixed(decimalPlaces);
};

export const shortenNumber = (number: number, decimalPlaces = 1): string => {
  if (number < 1000) {
    return `${toFixedIfNotWhole(number, decimalPlaces)}`;
  }
  if (number < 1_000_000) {
    return `${toFixedIfNotWhole(number / 1_000, decimalPlaces)}k`;
  }
  if (number < 1_000_000_000) {
    return `${toFixedIfNotWhole(number / 1_000_000, decimalPlaces)}m`;
  }
  return `${toFixedIfNotWhole(number / 1_000_000_000, decimalPlaces)}b`;
};

export const bytesToMegaBytes = (bytes: number) => Math.trunc(bytes / (1024 * 1024));
