import { FindingActivity } from "$/finding-types";

export const ACTIVITY_TO_ICON: Record<FindingActivity, `mdi-${string}`> = {
  [FindingActivity.SLACK_DM]: "mdi-slack",
  [FindingActivity.SLACK_CHANNEL]: "mdi-slack",
  [FindingActivity.MSTEAMS_DM]: "mdi-microsoft-teams",
  [FindingActivity.MSTEAMS_CHANNEL]: "mdi-microsoft-teams",
  [FindingActivity.PR_COMMENT]: "mdi-comment-text",
  [FindingActivity.FAILED_STATUS_CHECK]: "mdi-progress-close"
};

export const ACTIVITY_TO_SUB_ICON: Record<FindingActivity, string> = {
  [FindingActivity.SLACK_DM]: "mdi-account",
  [FindingActivity.SLACK_CHANNEL]: "mdi-pound",
  [FindingActivity.MSTEAMS_DM]: "mdi-account",
  [FindingActivity.MSTEAMS_CHANNEL]: "mdi-pound",
  [FindingActivity.PR_COMMENT]: "",
  [FindingActivity.FAILED_STATUS_CHECK]: ""
};

export const ACTIVITY_TO_TEXT: Record<FindingActivity, string> = {
  [FindingActivity.SLACK_DM]: "Slack DM",
  [FindingActivity.SLACK_CHANNEL]: "Slack Channel",
  [FindingActivity.MSTEAMS_DM]: "MS Teams DM",
  [FindingActivity.MSTEAMS_CHANNEL]: "MS Teams Channel",
  [FindingActivity.PR_COMMENT]: "PR Comment",
  [FindingActivity.FAILED_STATUS_CHECK]: "Failed Status Check"
};

export const ACTIVITY_DESCRIPTION: Record<FindingActivity, string> = {
  [FindingActivity.SLACK_DM]: "Slack Direct Message",
  [FindingActivity.SLACK_CHANNEL]: "Slack Channel",
  [FindingActivity.MSTEAMS_DM]: "MS Teams Direct Message",
  [FindingActivity.MSTEAMS_CHANNEL]: "MS Teams Channel",
  [FindingActivity.PR_COMMENT]: "Pull Request Comment",
  [FindingActivity.FAILED_STATUS_CHECK]: "Failed Status Check"
};

export function activityTypeDescription(activity: FindingActivity): string {
  return ACTIVITY_DESCRIPTION[activity] ?? "";
}

export function activityTypeIcon(activity: FindingActivity): string {
  return ACTIVITY_TO_ICON[activity] ?? "";
}

export function activityTypeSubIcon(activity: FindingActivity): string {
  return ACTIVITY_TO_SUB_ICON[activity] ?? "";
}

export function activityTypeText(activity: FindingActivity): string {
  return ACTIVITY_TO_TEXT[activity] ?? "";
}
