<template>
  <v-footer app v-if="$state.Layout.footer" class="component app-footer" :inset="$state.Layout.drawer.show" :height="$state.Layout.footer ? '35px' : 0">
    <v-layout justify-center>
      <small>
        &copy; {{ currentYear }}, Arnica Inc. All Rights Reserved.
        <!-- <v-chip color="primary" label x-small>Beta</v-chip> -->
        <template v-if="$state.Auth.authenticatedAndEmailVerified">
          <a href="javascript:" id="feedback">Feedback</a>
        </template>
      </small>
    </v-layout>
  </v-footer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import EditProfileDialog from "@/components/layout/user-menu/edit-profile-dialog.vue";

@Component({
  components: {
    EditProfileDialog
  }
})
export class AppFooter extends Vue {
  protected get currentYear(): number {
    return new Date().getFullYear();
  }

  // protected copy(): void {
  //   const orgId = this.$state.Auth.user?.userInfo?.org?.id;
  //   if (!orgId) return;
  //   try {
  //     navigator.clipboard.writeText(orgId);
  //     this.$state.Popup.info(`<code>${orgId}</code> copied to clipboard!`, { timeout: 1_000, html: true });
  //   } catch (e) {
  //     this.$state.Popup.error(String(e), { timeout: this.$state.Popup.INDEFINITELY });
  //   }
  // }
}

export default AppFooter;
</script>
