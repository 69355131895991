import { CommunicationIntegrationType } from "../../../dynamo";
import { PolicyItemType } from "../policy-item-base";
import type { MessageModifier } from "./message-modifier";
import { PolicyAction, PolicyActionSubType } from "./policy-action";

declare module "./policy-action" {
  enum PolicyActionSubType {
    instant_message = "instant_message",
    instant_message_lookup = "instant_message_lookup",
    instant_message_static = "instant_message_static"
  }
}

(PolicyActionSubType as { instant_message: "instant_message" }).instant_message = "instant_message";
(PolicyActionSubType as { instant_message_lookup: "instant_message_lookup" }).instant_message_lookup = "instant_message_lookup";
(PolicyActionSubType as { instant_message_static: "instant_message_static" }).instant_message_static = "instant_message_static";

interface IntegrationSpecificRecipient {
  /**
   * The integration info if applicable
   * @note Do not specify to perform a full lookup
   */
  integration?: {
    /**
     * Integration Org ID
     */
    org: string;

    /**
     * Integration Type
     */
    type: CommunicationIntegrationType;
  };
}

interface IdentitySpecificRecipient {
  /**
   * Recipient ID
   */
  id: string;

  /**
   * Recipient name
   */
  name?: string;
}

interface LookupRecipient<T extends string> {
  lookup: T;
}

interface RecipientBase<T extends string> {
  type: T;
}

interface ChannelRecipient extends RecipientBase<"channel">, IdentitySpecificRecipient, IntegrationSpecificRecipient {}
interface UserRecipient extends RecipientBase<"user">, IdentitySpecificRecipient, IntegrationSpecificRecipient {}
interface PusherRecipient extends RecipientBase<"user">, LookupRecipient<"pusher"> {}
interface AuthorRecipient extends RecipientBase<"user">, LookupRecipient<"author"> {}
interface CommitterRecipient extends RecipientBase<"user">, LookupRecipient<"committer"> {}
interface ProductRecipient extends RecipientBase<"user">, LookupRecipient<"product"> {}

export interface PolicyActionInstantMessage extends PolicyAction {
  sub: PolicyActionSubType;
  children: PolicyActionInstantMessageType[];
  modifiers?: MessageModifier[];
}

export interface PolicyActionInstantMessageLookup extends PolicyAction {
  sub: PolicyActionSubType.instant_message_lookup;

  /**
   * The recipient configuration
   */
  to: PusherRecipient | AuthorRecipient | CommitterRecipient | ProductRecipient | null;
}

export interface PolicyActionInstantMessageStatic extends PolicyAction {
  sub: PolicyActionSubType.instant_message_static;

  /**
   * The recipient configuration
   */
  to: ChannelRecipient | UserRecipient | null;
}

export type PolicyActionInstantMessageType = PolicyActionInstantMessageLookup | PolicyActionInstantMessageStatic;

export const PolicyActionInstantMessage = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.instant_message
  },
  is(item?: PolicyAction): item is PolicyActionInstantMessage {
    return item?.sub === PolicyActionSubType.instant_message;
  },
  generateDefault(): PolicyActionInstantMessage {
    const action = PolicyActionInstantMessage.generateDefaultPusherOnly();
    action.children.push(PolicyActionInstantMessageLookup.generateDefault("product"));
    return action;
  },
  generateDefaultPusherOnly(): PolicyActionInstantMessage {
    return {
      sub: PolicyActionSubType.instant_message,
      id: `${PolicyItemType.action}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.action,
      name: "",
      children: [PolicyActionInstantMessageLookup.generateDefault("pusher")]
    } satisfies PolicyActionInstantMessage;
  }
} as const;

export const PolicyActionInstantMessageLookup = {
  generateDefault(lookup: PusherRecipient["lookup"] | ProductRecipient["lookup"]): PolicyActionInstantMessageLookup {
    return {
      sub: PolicyActionSubType.instant_message_lookup,
      id: `${PolicyItemType.action}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.action,
      name: "",
      to: {
        type: "user",
        lookup
      }
    } satisfies PolicyActionInstantMessageLookup;
  }
} as const;
