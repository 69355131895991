import { PolicyItemBase, PolicyItemType } from "../policy-item-base";
import type { PolicyOption } from "../policy-item-options";
import type { PolicyRule } from "../policy-rule";

declare module "../policy-item-base" {
  interface PolicyComponents {
    [PolicyItemType.action]: PolicyAction;
  }

  enum PolicyItemType {
    action = "action"
  }
}

(PolicyItemType as { action: "action" }).action = "action";

/**
 * A list of all action sub types.
 * @note This enum should be augmented in modules to add new types of policy components.
 */
export enum PolicyActionSubType {}
(PolicyActionSubType as unknown) = {
  /** es-build minification workaround */
};

export interface PolicyAction extends PolicyItemBase<PolicyItemType.action> {
  name: string;
  sub: PolicyActionSubType;
  children?: PolicyAction[];
}

export type PolicyActionCategory = "Instant Messaging" | "Source Code Management" | "Issue Management" | "Mitigation";

export const PolicyAction = {
  isOrParentOf<S extends PolicyActionSubType>(action: PolicyAction, sub: S): action is PolicyAction & { sub: S } {
    return action.sub === sub || !!action.children?.some((c) => PolicyAction.isOrParentOf(c, sub));
  },
  inRule(rule: PolicyRule, sub: PolicyActionSubType): boolean {
    return !!rule.actions?.some((a) => PolicyAction.isOrParentOf(a, sub));
  },
  allowOnePerRule<S extends PolicyActionSubType>(
    rule: PolicyRule,
    component: PolicyOption<PolicyItemType.action> & { sub: S }
  ): (PolicyOption<PolicyItemType.action> & { sub: S })[] {
    const existing = rule.actions?.find((a) => a.sub === component.sub);
    return existing ? [] : [component];
  },
  sortProps(action: PolicyAction): PolicyAction {
    const { children, id, name, sub, type, ...rest } = action;
    return {
      sub,
      type,
      name,
      id,
      ...rest,
      children: children?.nonNullable().map((c) => PolicyAction.sortProps(c))
    };
  }
} as const;
