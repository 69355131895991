import type { IssuesIntegrationType, ScmIntegrationType } from "../integration";
import type { IntegrationAttributes } from "./integration-attributes";

interface IssuesIntegrationAttributes extends IntegrationAttributes {
  type: IssuesIntegrationType;
  hasJobs: false;
  /**
   * The associated SCM integration type, if any.
   * e.g. "azure-devops" for "azure-devops-boards"
   */
  associatedScm: ScmIntegrationType | null;
}

export const IssuesIntegrationAttributes = {
  jira: {
    type: "jira",
    category: "issues",
    displayName: "Jira",
    hasJobs: false,
    includeMetadata: false,
    associatedScm: null,
    ui: {
      icon: "mdi-jira",
      reactIcon: "mdi:jira",
      editable: false,
      removable: true
    }
  },
  "azure-devops-boards": {
    type: "azure-devops-boards",
    category: "issues",
    displayName: "Azure DevOps Boards",
    hasJobs: false,
    includeMetadata: false,
    associatedScm: "azure-devops",
    ui: {
      icon: "mdi-microsoft-azure-devops",
      reactIcon: "mdi:microsoft-azure-devops",
      editable: false,
      removable: true
    }
  }
} as const satisfies Record<IssuesIntegrationType, IssuesIntegrationAttributes>;
