import { CodeStatus } from "../code-status";
import { BusinessImportanceLabel } from "$/business-importance";
import { CodeRiskStates, FindingStatuses, SecretStates } from "$/finding-types";
import { PolicyConditionFindingSecretValidation } from "$/interfaces/policies";
import type { Filters } from "$/interfaces/ui-api/response/get-filters-response";
import { GetFindingsResponseItem } from "$/interfaces/ui-api/response/get-secrets-response";
import { MenuItem } from "@/components/table-items/menu-item";
import { getColorBySeverity } from "@/pages/inventory/details/sbom/components/sbom-component-common";
import { CODE_RISK_STATUSES } from "@/pages/risks/code/code-risk-constants";
import { GitLeaksDescriptions, GitLeaksIcons } from "@/pages/risks/secrets/gitleaks-descriptions";
import { SelectOption } from "@/state";

/**
 * Include both secrets and code risk statuses UI properties
 */
export const UNIFIED_FINDING_STATUSES: Readonly<Record<FindingStatuses, CodeStatus<FindingStatuses>>> = {
  [CodeRiskStates.REQUIRES_REVIEW]: CODE_RISK_STATUSES[CodeRiskStates.REQUIRES_REVIEW],
  [SecretStates.MITIGATION_FAILED]: {
    text: "Mitigation Failed",
    value: SecretStates.MITIGATION_FAILED,
    icon: "shield-remove-outline",
    color: getColorBySeverity("high"),
    fgColor: "white"
  },
  [CodeRiskStates.IN_PROGRESS]: CODE_RISK_STATUSES[CodeRiskStates.IN_PROGRESS],
  [CodeRiskStates.PENDING_REVIEW]: CODE_RISK_STATUSES[CodeRiskStates.PENDING_REVIEW],
  [SecretStates.AWAITING_USER_INPUT]: {
    text: "Awaiting User Input",
    value: SecretStates.AWAITING_USER_INPUT,
    icon: "clock-time-four-outline",
    color: getColorBySeverity("medium"),
    fgColor: "white"
  },
  [SecretStates.MITIGATION_IN_PROGRESS]: {
    text: "Mitigation In Progress",
    value: SecretStates.MITIGATION_IN_PROGRESS,
    icon: "lightning-bolt-outline",
    color: getColorBySeverity("medium"),
    fgColor: "white"
  },
  [SecretStates.MITIGATION_PENDING_PR]: {
    text: "Mitigation pending PR",
    value: SecretStates.MITIGATION_PENDING_PR,
    icon: "shield-alert-outline",
    color: getColorBySeverity("medium"),
    fgColor: "white",
    displayName: "Mitigation Pending PR"
  },
  [SecretStates.DISMISS_RISK_IS_TOLERABLE]: {
    //TODO: migrate this to CodeRiskStates.RISK_ACCEPTED when migrating the database and make it deprecated
    text: "Dismissed",
    value: SecretStates.DISMISS_RISK_IS_TOLERABLE,
    textChip: "Risk is tolerable",
    icon: "minus-circle-outline",
    color: getColorBySeverity("low"),
    fgColor: "white",
    displayName: "Dismissed - Risk Is Tolerable"
  },
  [SecretStates.DISMISS_SECRET_NOT_USED]: {
    //TODO: migrate this to CodeRiskStates.DISMISS_NOT_ACCURATE when migrating the database and make it deprecated
    text: "Dismissed",
    value: SecretStates.DISMISS_SECRET_NOT_USED,
    textChip: "Code not used",
    icon: "minus-circle-outline",
    color: getColorBySeverity("low"),
    fgColor: "white",
    displayName: "Dismissed - Code Not Used"
  },
  [CodeRiskStates.DISMISS_NO_CAPACITY]: CODE_RISK_STATUSES[CodeRiskStates.DISMISS_NO_CAPACITY],
  [CodeRiskStates.DISMISS_NOT_ACCURATE]: CODE_RISK_STATUSES[CodeRiskStates.DISMISS_NOT_ACCURATE],
  [SecretStates.DISMISS_NO_OPTION_CHOSEN_YET]: {
    //TODO: migrate this to CodeRiskStates.RISK_ACCEPTED when migrating the database and make it deprecated
    text: "Dismissed",
    value: SecretStates.DISMISS_NO_OPTION_CHOSEN_YET,
    color: getColorBySeverity("low"),
    icon: "minus-circle-outline",
    textChip: "No option chosen yet",
    fgColor: "white",
    displayName: "Dismissed - No Option Chosen Yet"
    //deprecated: true
  },
  [CodeRiskStates.RISK_ACCEPTED]: CODE_RISK_STATUSES[CodeRiskStates.RISK_ACCEPTED],
  [CodeRiskStates.RESOLVED]: CODE_RISK_STATUSES[CodeRiskStates.RESOLVED],
  [CodeRiskStates.RESOLVED_AUTOMATICALLY]: CODE_RISK_STATUSES[CodeRiskStates.RESOLVED_AUTOMATICALLY],
  [CodeRiskStates.DISMISSED_AUTOMATICALLY]: CODE_RISK_STATUSES[CodeRiskStates.DISMISSED_AUTOMATICALLY]
} as const;

export const SECRETS_DEFAULT_FILTERS: Filters = {
  status: [
    SecretStates.AWAITING_USER_INPUT,
    SecretStates.MITIGATION_IN_PROGRESS,
    SecretStates.MITIGATION_PENDING_PR,
    SecretStates.REQUIRES_REVIEW,
    SecretStates.MITIGATION_FAILED
    //    SecretStates.HISTORICAL_SECRET_FOUND
  ],
  integrationType: [],
  org: [],
  repo: [],
  branch: [],
  specificType: [],
  risk: [],
  sortKey: []
};

export type FilterPredicate = <K extends keyof Filters>(filters: Filters, item: GetFindingsResponseItem, key: K) => boolean;

export type UserDisplayData = {
  login: string;
  name: string;
  reason: string;
  avatar: string;
  menuItems: MenuItem[];
  url?: string;
};

export type OwnersDisplayData = {
  [key: string]: UserDisplayData[];
};

export interface SecretsPageTableItems extends GetFindingsResponseItem {
  id?: string;
  importanceValue?: -1 | 0 | 1 | 2;
}

export interface ImportanceData {
  color?: string;
  title?: string;
  priority?: -1 | 0 | 1 | 2;
  text?: string;
  item?: SecretsPageTableItems;
  readonly?: boolean;
  icon?: string;
  label?: BusinessImportanceLabel;
}

export function DEFAULT_PREDICATE<K extends keyof Filters & keyof GetFindingsResponseItem>(filters: Filters, item: GetFindingsResponseItem, key: K): boolean {
  const filter = filters[key];
  if (!filter?.length) {
    return true;
  }
  const value = item[key];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Type 'string' is not assignable to type 'ScmIntegrationType'.ts(2345)
  return value && filter.includes(value);
}

export const FILTER_PREDICATES: Partial<Record<keyof Filters, FilterPredicate>> = {
  status: (filters, item) => {
    if (!filters.status.length) {
      return true;
    }
    const status = item.metadata?.status;
    return !!status && filters.status.includes(status);
  }
} as const;

interface ValidationDescriptor extends SelectOption<PolicyConditionFindingSecretValidation["name"]> {
  value: PolicyConditionFindingSecretValidation["name"];
  title: string;
  description: string;
  order: number;
  icon: string;
  color: string;
}

export const SECRET_VALIDATION_RESULTS: Record<Exclude<PolicyConditionFindingSecretValidation["name"], "ui">, ValidationDescriptor> = {
  VERIFIED_TRUE: {
    value: "VERIFIED_TRUE",
    title: "Valid",
    description: "Secret validation succeeded",
    order: 0,
    icon: "mdi-check-decagram-outline",
    color: getColorBySeverity("high")
  },
  VERIFIED_FALSE: {
    value: "VERIFIED_FALSE",
    title: "Invalid",
    description: "Secret validation attempted and failed",
    order: 10,
    icon: "mdi-alert-decagram-outline",
    color: "green"
  },
  UNVERIFIED: {
    value: "UNVERIFIED",
    title: "None",
    description: "Secret validation was not attempted",
    order: 20,
    icon: "mdi-decagram-outline",
    color: getColorBySeverity("unknown")
  },
  ERROR: {
    value: "ERROR",
    title: "Error",
    description: "Secret validation was attempted and an error occurred",
    order: 30,
    icon: "mdi-close-octagon-outline",
    color: getColorBySeverity("medium")
  }
} as const;

const SecretTypeTitleCase = {
  AWS: "AWS",
  "Atlassian Credentials": "Atlassian Credentials",
  "Auth0 Client Secret": "Auth0 Client Secret",
  Azure: "Azure",
  "Azure Storage": "Azure Storage",
  CERTIFICATE: "Certificate",
  "DSA PRIVATE KEY": "DSA Private Key",
  "Databricks API token": "Databricks API Token",
  "Datadog API key": "Datadog API Key",
  "EC PRIVATE KEY": "EC Private Key",
  "ENCRYPTED PRIVATE KEY": "Encrypted Private Key",
  "GitHub Fine-Grained Personal Access Token": "GitHub Fine-Grained Personal Access Token",
  "GitHub Personal Access Token": "GitHub Personal Access Token",
  "GitHub Refresh Token": "GitHub Refresh Token",
  "Google (GCP) Service-account": "Google (GCP) Service-Account",
  "Google API Key": "Google API Key",
  JWT: "JWT",
  "Mailgun API Key": "Mailgun API Key",
  "OPENSSH PRIVATE KEY": "OpenSSH Private Key",
  "OpenAI API Key": "OpenAI API Key",
  "PGP PUBLIC KEY BLOCK": "PGP Public Key Block",
  "PRIVATE KEY": "Private Key",
  "PUBLIC KEY": "Public Key",
  "Poison Pill": "Poison Pill",
  "RSA PRIVATE KEY": "RSA Private Key",
  "RSA PUBLIC KEY": "RSA Public Key",
  "SendGrid API token": "SendGrid API Token",
  "Slack Token": "Slack Token",
  "Slack Webhook": "Slack Webhook",
  "Twilio SID": "Twilio SID",
  Square: "Square",
  "Certificate/Key": "Certificate/Key",
  "JFrog Artifactory": "JFrog Artifactory",
  "Bare Crypto": "Bare Crypto",
  "CosmosDB Key": "CosmosDB Key",
  "GCP Access Token": "GCP Access Token",
  "Sourcegraph Access Token": "Sourcegraph Access Token",
  "LinkedIn Client secret": "LinkedIn Client Secret",
  "LinkedIn Client ID": "LinkedIn Client ID",
  "PyPI upload token": "PyPI Upload Token",
  "Stripe Access Token": "Stripe Access Token",
  "Flutterwave Secret Key": "Flutterwave Secret Key",
  "CircleCI API Token": "CircleCI API Token",
  "New Relic API Key": "New Relic API Key",
  "Credentials in URL": "Credentials in URL",
  "": "Unknown"
} as const;

export const SecretTypeIcon: Record<keyof typeof SecretTypeTitleCase, `mdi-${string}`> = {
  AWS: "mdi-aws",
  "Atlassian Credentials": "mdi-atlassian",
  "Auth0 Client Secret": "mdi-auth0",
  Azure: "mdi-azure",
  "Azure Storage": "mdi-azure",
  CERTIFICATE: "mdi-certificate",
  "DSA PRIVATE KEY": "mdi-key",
  "Databricks API token": "mdi-databricks", // using a generic API icon
  "Datadog API key": "mdi-datadog",
  "EC PRIVATE KEY": "mdi-key",
  "ENCRYPTED PRIVATE KEY": "mdi-key-alert",
  "GitHub Fine-Grained Personal Access Token": "mdi-github",
  "GitHub Personal Access Token": "mdi-github",
  "GitHub Refresh Token": "mdi-github",
  "Google (GCP) Service-account": "mdi-google-cloud",
  "Google API Key": "mdi-google",
  JWT: "mdi-jwt",
  "Mailgun API Key": "mdi-mailgun",
  "OPENSSH PRIVATE KEY": "mdi-ssh",
  "OpenAI API Key": "mdi-open-api",
  "PGP PUBLIC KEY BLOCK": "mdi-key",
  "PRIVATE KEY": "mdi-key",
  "PUBLIC KEY": "mdi-key",
  "Poison Pill": "mdi-pill",
  "RSA PRIVATE KEY": "mdi-key",
  "RSA PUBLIC KEY": "mdi-key",
  "SendGrid API token": "mdi-sendgrid",
  "Slack Token": "mdi-slack",
  "Slack Webhook": "mdi-slack",
  "Twilio SID": "mdi-twilio",
  "Sourcegraph Access Token": "mdi-sourcegraph",
  Square: "mdi-square-inc",
  "Bare Crypto": "mdi-key",
  "Certificate/Key": "mdi-certificate",
  "JFrog Artifactory": "mdi-jfrog",
  "CosmosDB Key": "mdi-azure",
  "GCP Access Token": "mdi-google-cloud",
  "LinkedIn Client secret": "mdi-linkedin",
  "LinkedIn Client ID": "mdi-linkedin",
  "PyPI upload token": "mdi-python",
  "Stripe Access Token": "mdi-stripe",
  "Flutterwave Secret Key": "mdi-credit-card-outline",
  "CircleCI API Token": "mdi-circleci",
  "New Relic API Key": "mdi-newrelic",
  "Credentials in URL": "mdi-web",
  "": "mdi-key"
} as const;

export function getSecretTypeTitleCase(secretType?: string): string {
  // we want to keep the gitleaks descriptions as is
  if (secretType && secretType in GitLeaksDescriptions) {
    secretType;
  }
  return SecretTypeTitleCase[(secretType as keyof typeof SecretTypeTitleCase) ?? ""] || secretType?.titleCase() || "Unknown";
}

export function getSecretTypeIcon(secretType?: string): string {
  if (secretType && secretType in GitLeaksIcons) {
    return GitLeaksIcons[secretType as keyof typeof GitLeaksIcons] || "mdi-key";
  }
  return SecretTypeIcon[(secretType as keyof typeof SecretTypeTitleCase) ?? ""] || "mdi-key";
}
