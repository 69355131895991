import { PolicyItemBase, PolicyItemType } from "../policy-item-base";
import type { PolicyRule } from "../policy-rule";

declare module "../policy-item-base" {
  interface PolicyComponents {
    [PolicyItemType.trigger]: PolicyTrigger;
  }

  enum PolicyItemType {
    trigger = "trigger"
  }
}

(PolicyItemType as { trigger: "trigger" }).trigger = "trigger";

/**
 * A list of all trigger sub types.
 * @note This enum should be augmented in modules to add new types of policy components.
 */
export enum PolicyTriggerSubType {}
(PolicyTriggerSubType as unknown) = {
  /** es-build minification workaround */
};

export interface PolicyTrigger extends PolicyItemBase<PolicyItemType.trigger> {
  sub: PolicyTriggerSubType;
  description?: string;
}

export type PolicyTriggerCategory = "Workflow" | "Real-Time Scanning" | "Routine Scanning";

export const PolicyTrigger = {
  isOrParentOf<S extends PolicyTriggerSubType>(trigger: PolicyTrigger, sub: S): trigger is PolicyTrigger & { sub: S } {
    return trigger.sub === sub;
  },
  inRule(rule: PolicyRule, sub: PolicyTriggerSubType): boolean {
    return !!(rule.trigger && PolicyTrigger.isOrParentOf(rule.trigger, sub));
  }
} as const;
