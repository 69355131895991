// includes polyfills to support vite instead of webpack

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Window {
  //global: Record<string, unknown>;
  global: Window;
}

// @ts-ignore
window.global = window;
