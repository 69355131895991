interface CacheOptions<K, V> {
  resolver?: (key: K) => V | Promise<V>;
  /**
   * The number of seconds to keep the value in the cache.
   * If not specified, the value will be cached indefinitely (`Infinity`).
   */
  ttlSeconds?: number;
}

interface ValueWrapper<V> {
  value: V | Promise<V>;
  expiresAt: number;
}

export class Cache<K, V> {
  readonly #cache = new Map<K, ValueWrapper<V>>();
  readonly #options: RequiredAttributes<CacheOptions<K, V>, "ttlSeconds">;

  public constructor(options: CacheOptions<K, V> = { resolver: undefined, ttlSeconds: Infinity }) {
    options.ttlSeconds ??= Infinity;
    this.#options = options as RequiredAttributes<CacheOptions<K, V>, "ttlSeconds">;
  }

  public async entries(): Promise<readonly [K, V][]> {
    const entries = Array.from(this.#cache.entries());
    const resolved = await entries.mapAsync(async ([key, wrapper]) => [key, await wrapper.value] as [K, V]);
    return resolved;
  }

  public tryGet(key: K): undefined | Promise<V | undefined> {
    const wrapper = this.#cache.get(key);
    if (!wrapper) {
      return undefined;
    }
    if (Date.now() > wrapper.expiresAt) {
      this.purge(key);
      return undefined;
    }
    return wrapper.value instanceof Promise ? wrapper.value : Promise.resolve(wrapper.value);
  }

  public async get(key: K): Promise<V> {
    const value = await this.tryGet(key);
    if (typeof value === "undefined") {
      throw new Error("Cannot resolve key");
    }
    return value;
  }

  /**
   * set a value in the cache
   *
   * @param key key to cache
   * @param value value to cache
   * @param ttl seconds to keep the value in the cache. If not specified, the value will be taken from the default instance options.
   */
  public set(key: K, value: V | Promise<V>, ttl?: number): this {
    if (typeof value === "undefined") {
      throw new Error("Invalid value");
    }
    this.#cache.set(key, { value, expiresAt: this.getExpiration(ttl) });
    this.setPurge(key);
    return this;
  }

  /**
   * Get the value from the cache, or resolve it if it's not there
   * @param key
   * @param ttl seconds to keep the value in the cache. If not specified, the value will be cached indefinitely.
   * @param resolver
   */

  public async getOrResolve(key: K, ttl?: number, resolver?: CacheOptions<K, V>["resolver"]): Promise<V> {
    const value = this.tryGet(key);
    const result = value instanceof Promise ? await value : value;
    if (result !== undefined) {
      return result;
    }
    resolver ??= this.#options.resolver;
    if (!resolver) {
      throw new Error("no resolver defined");
    }
    const resolved = resolver(key);
    this.set(key, resolved, ttl);
    return await resolved;
  }

  public delete(key: K): void {
    this.#cache.delete(key);
  }

  private setPurge(key: K): void {
    if (this.#options.ttlSeconds === Infinity) {
      return;
    }
    setTimeout(this.purge.bind(this, key), this.#options.ttlSeconds * 1000);
  }

  private purge(key: K): void {
    const wrapper = this.#cache.get(key);
    if (!wrapper || Date.now() < wrapper.expiresAt) {
      return;
    }
    this.#cache.delete(key);
  }

  /**
   * @param ttl time to live in seconds
   * @private
   */
  private getExpiration(ttl?: number): number {
    ttl ??= this.#options.ttlSeconds;
    if (ttl === Infinity) {
      return Infinity;
    }
    return Date.now() + ttl * 1000;
  }
}
