import { CallbackHandlerBase } from "./callback-handler-base";
import { IntegrationType } from "$/dynamo";
import { Integrations as Api } from "@/api";
import { CALLBACK_MESSAGE_KEY, ICallbackMessage } from "@/interfaces/callback-message";
import { router } from "@/plugins";
import { Auth, Integrations, Popup } from "@/state";

interface Args {
  code: string | null;
  action: "install" | "postInstall";
  name: string;
}

const CALLBACK_PATH = "/callback/scanner-agent";
const CODE_PARAM = "code";
const NAME_PARAM = "name";
const ACTION_PARAM = "action";

export class AgentCallbackHandler extends CallbackHandlerBase {
  public static get path(): string {
    return CALLBACK_PATH;
  }

  protected provideUserFeedback(): void {
    void Popup.info("Integration added successfully");
  }

  public async handle(query: URLSearchParams): Promise<void> {
    try {
      const integrationType = await this.createIntegration(query);

      Auth.authenticated && (await Integrations.init());

      this.sendToAnalytics(integrationType);

      await this.redirect();

      this.provideUserFeedback();
    } catch (e) {
      await this.handleIntegrationError(e);
    }
  }

  private async createIntegration(query: URLSearchParams): Promise<IntegrationType> {
    const action = query.get(ACTION_PARAM) as Args["action"] | null;
    const name = query.get(NAME_PARAM) as Args["name"];
    const actionHandler = action && this[`on_${action}`];
    if (!actionHandler) {
      throw new Error("Invalid callback");
    }

    const code = query.get(CODE_PARAM);
    window.history.replaceState(null, "", window.location.origin);
    return await actionHandler.call(this, {
      code,
      action,
      name
    });
  }

  private async redirect(): Promise<void> {
    window.history.replaceState(null, "", window.location.origin);
    await router.push({ name: "loading" });
    try {
      await router.push({ name: Integrations.hasAtLeastOneSourceControlIntegration ? "admin-integrations" : "home", ignoreRedirectError: true });
    } catch (error) {
      console.warn("Failed to redirect", error);
    }
  }

  private async on_install({ code, action, name }: Args): Promise<IntegrationType> {
    if (!code) {
      throw new Error("Invalid callback");
    }
    if (Auth.authenticated) {
      return await this.on_postInstall({ code, action, name });
    } else {
      this.saveCallbackMessage(code);
    }
    return "scanner-agent";
  }

  private async on_postInstall({ code, name }: Args): Promise<IntegrationType> {
    if (!code) {
      throw new Error("Invalid callback");
    }

    if (!Auth.authenticated) {
      this.saveCallbackMessage(code);
      await router.replace({ name: "login", ignoreRedirectError: true });
    } else {
      await Api.create({ type: "scanner-agent", code, displayName: name });
    }

    return "scanner-agent";
  }

  private saveCallbackMessage(code: string): void {
    const callbackMessage: ICallbackMessage = {
      redirect: `${CALLBACK_PATH}?${ACTION_PARAM}=postInstall&${CODE_PARAM}=${code}`
    };
    window.sessionStorage.setItem(CALLBACK_MESSAGE_KEY, JSON.stringify(callbackMessage));
  }
}
