import { chunk, max, maxBy, min, minBy } from "lodash";

//TODO: move to array.ts in polyfills
declare global {
  interface Array<T> {
    groupBy<K>(keyExtractor: (v: T) => K): Map<K, NonEmptyArray<T>>;

    /**
     * Creates a map from an array.
     * @param keyExtractor - function that extracts a key from the element
     * @param throwOnDuplicateKey - if `true` (default is false), throws an error if a duplicate key is found
     */
    mapBy<K>(keyExtractor: (v: T) => K, throwOnDuplicateKey?: boolean): Map<K, T>;

    /**
     * Finds the item with the maximum value of the key extracted by the keyExtractor function.
     * @param keyExtractor a key extractor function or a key of the object
     */
    maxBy<K>(keyExtractor: keyof T | ((v: T) => K)): T | undefined;

    /**
     * Finds the item with the minimum value of the key extracted by the keyExtractor function.
     * @param keyExtractor a key extractor function or a key of the object
     */
    minBy<K>(keyExtractor: keyof T | ((v: T) => K)): T | undefined;

    any(predicate?: (item: T) => boolean): boolean;

    /**
     * Creates an array of elements split into groups the length of size. If collection can’t be split evenly, the
     * final chunk will be the remaining elements.
     *
     * @param array The array to process.
     * @param size The length of each chunk.
     * @return Returns the new array containing chunks.
     */
    chunk(size?: number): T[][];

    distinct<K>(keyExtractor?: (v: T) => K): T[];

    /**
     * Sums the values of an array of numbers
     * @param keyExtractor a function that extracts a number from the element or a key of the element corresponding to a number-value property
     * @returns The sum of the numbers in the array or `0` if the array is empty
     */
    sumOf(keyExtractor: KeysOfType<T, number> | ((v: T) => number)): number;

    sum(this: (T extends number ? number : never)[]): T extends number ? number : never;

    min(this: ArrayLike<T>): T | undefined;

    max(this: ArrayLike<T>): T | undefined;
  }

  //TODO: move to polyfills
  interface Map<K, V> {
    mapValues<T>(mapper: (v: V) => T): Map<K, T>;

    map<T>(mapper: (k: K, v: V) => T): T[];

    toRecord<P extends PropertyKey = K extends PropertyKey ? K : string>(): Record<P, V>;
  }

  //TODO: move to polyfills
  interface MapConstructor {
    fromRecord<V, K extends string = string>(dict: Record<K, V>): Map<K, V>;
  }
}

/**
 * replaces map values with other values
 * @param map
 * @param mapper
 */
export function mapValues<K, V, V2>(map: Map<K, V>, mapper: (value: V) => V2): Map<K, V2> {
  const newMap = new Map<K, V2>();
  for (const [key, value] of map) {
    newMap.set(key, mapper(value));
  }
  return newMap;
}

Map.prototype.mapValues ||= function <K, V, V2>(this: Map<K, V>, mapper: (value: V) => V2): Map<K, V2> {
  return mapValues(this, mapper);
};

Map.prototype.map ||= function <K, V, T>(this: Map<K, V>, mapper: (key: K, value: V) => T): T[] {
  const result: T[] = [];
  for (const [key, value] of this) {
    result.push(mapper(key, value));
  }
  return result;
};
//TODO: move to polyfills
Map.prototype.toRecord ||= function <K, V, P extends PropertyKey = K extends PropertyKey ? K : string>(this: Map<K, V>): Record<P, V> {
  return Object.fromEntries(this.entries());
};

export const chunkArrayInGroups = <T>(array: ArrayLike<T> | null | undefined, size?: number): T[][] => {
  return chunk(array, size);
};

export function isEmpty<T>(collection: T[] | undefined): collection is undefined {
  return !collection || collection.length === 0;
}

export function getMax(arr: number[]) {
  let len = arr.length;
  let max = -Infinity;

  while (len--) {
    if (arr[len]! > max) {
      max = arr[len]!;
    }
  }
  return max;
}

export function getMin(arr: number[]) {
  let len = arr.length;
  let min = +Infinity;

  while (len--) {
    if (arr[len]! < min) {
      min = arr[len]!;
    }
  }
  return min;
}

/**
 * Group an array by a key.
 *
 * Usage
 * ```
 * class Person {
 *   constructor(public name: string,
 *             public city: string) {
 * }
 *
 *
 * const persons = [
 *   new Person("Alice", "NY"),
 *   new Person("Bob", "NY")
 * ];
 * const peopleByCity = groupBy(persons, v => v.name);
 * ```
 *
 * @param arr
 * @param keyExtractor
 * @param throwOnDuplicateKey (default false)
 */
export const groupBy = <K, V>(arr: V[], keyExtractor: (v: V) => K): Map<K, NonEmptyArray<V>> => {
  const ret = new Map<K, NonEmptyArray<V>>();
  if (!arr) {
    return ret;
  }
  for (const item of arr) {
    const key = keyExtractor(item);
    const valArr = ret.get(key);
    if (!valArr) {
      ret.set(key, [item]);
    } else {
      valArr.push(item);
    }
  }
  return ret;
};

export const mapBy = <K, V>(arr: V[], keyExtractor: (v: V) => K, throwOnDuplicateKey = false): Map<K, V> => {
  const ret = new Map<K, V>();
  if (!arr) {
    return ret;
  }
  for (const item of arr) {
    const key = keyExtractor(item);
    if (ret.has(key)) {
      if (throwOnDuplicateKey) {
        throw new Error("Duplicate key found");
      }
      console.debug(`Duplicate key found: ${key}`);
      continue;
    }

    ret.set(key, item);
  }
  return ret;
};

Array.prototype.groupBy ||= function <T, K>(this: T[], keyExtractor: (v: T) => K): Map<K, NonEmptyArray<T>> {
  return groupBy(this, keyExtractor);
};

/**
 * Same as group by but creates a hash map assuming the key is unique
 */
Array.prototype.mapBy ||= function <T, K>(this: T[], keyExtractor: (v: T) => K, throwOnDuplicateKey?: boolean): Map<K, T> {
  return mapBy(this, keyExtractor, throwOnDuplicateKey);
};

Array.prototype.maxBy ||= function <T, K>(this: T[], keyExtractor: keyof T | ((v: T) => K)): T | undefined {
  return maxBy(this, keyExtractor);
};

Array.prototype.minBy ||= function <T, K>(this: T[], keyExtractor: keyof T | ((v: T) => K)): T | undefined {
  return minBy(this, keyExtractor);
};

Array.prototype.chunk ||= function <T>(this: T[], size?: number): T[][] {
  return chunk(this, size);
};

Array.prototype.sumOf ||= function sumOf<T>(this: T[], keyExtractor: KeysOfType<T, number> | ((v: T) => number)): number {
  let sum = 0;
  if (!this.length) {
    return sum;
  }
  if (typeof keyExtractor !== "function") {
    const key = keyExtractor;
    keyExtractor = (o: T) => o[key] as number;
  }
  for (const item of this) {
    sum += keyExtractor(item);
  }
  return sum;
};

Array.prototype.sum ||= function <T extends number>(this: T[]): number {
  return this.reduce((acc, v) => acc + v, 0);
};

Array.prototype.min ||= function <T>(this: ArrayLike<T>): T | undefined {
  return min(this);
};

Array.prototype.max ||= function <T>(this: ArrayLike<T>): T | undefined {
  return max(this);
};

Array.prototype.any ||= function any<T>(this: T[], predicate?: (item: T) => boolean): boolean {
  return this.some(predicate ?? (() => true));
};

Array.prototype.distinct ||= function <T>(this: T[], keyExtractor?: (v: T) => T): T[] {
  const seen = new Set<T>();
  keyExtractor ??= (v) => v;
  return this.filter((item) => {
    const key = keyExtractor!(item);
    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  });
};

Map.fromRecord ||= <V, K extends string = string>(dict: Record<K, V>): Map<K, V> => {
  return new Map<K, V>(Object.entries(dict) as [K, V][]);
};
