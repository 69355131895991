//TODO: replace permission-error back to error, and add a errorType field to the job (e.g. {status: "error", errorType: "permission"} )
export enum JobStatusEnum {
  "pending" = "pending",
  "running" = "running",
  "complete" = "complete",
  "awaiting-sub-jobs" = "awaiting-sub-jobs",
  "error" = "error",
  "permission-error" = "permission-error"
}

export type JobStatus = keyof typeof JobStatusEnum;

export function isOngoingJobStatus(status: JobStatus): status is Extract<JobStatus, "pending" | "running" | "awaiting-sub-jobs"> {
  return status === "pending" || status === "running" || status === "awaiting-sub-jobs";
}

export function isConcludedJobStatus(status: JobStatus): status is Exclude<JobStatus, "pending" | "running" | "awaiting-sub-jobs"> {
  return !isOngoingJobStatus(status);
}
export function isErrorJobStatus(status: JobStatus): status is Extract<JobStatus, "error" | "permission-error"> {
  return status === "permission-error" || status === "error";
}

export function isSuccessJobStatus(status: JobStatus): status is Extract<JobStatus, "complete"> {
  return status === "complete";
}
