// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface BigInt {
  toJSON(): number | string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface BigIntConstructor {
  jsonParseReviver(this: unknown, key: string, value: unknown): unknown;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface JSON {
  parseWithBigInt(text: string, reviver?: (this: unknown, key: string, value: unknown) => unknown): unknown;
}

BigInt.prototype.toJSON = function BigIntToJSON(this: bigint) {
  return this >= Number.MIN_SAFE_INTEGER && this <= Number.MAX_SAFE_INTEGER ? Number(this) : `$BigInt:${this}`;
};

BigInt.jsonParseReviver = (_key: string, value: unknown): unknown => (typeof value === "string" && value.startsWith("$BigInt:") ? BigInt(value.substring(8)) : value);

JSON.parseWithBigInt = function parseWithBigInt(text: string, reviver?: (this: unknown, key: string, value: unknown) => unknown): unknown {
  return JSON.parse(
    text,
    reviver
      ? function (this: unknown, key: string, value: unknown): unknown {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return reviver!.call(this, key, BigInt.jsonParseReviver(key, value));
        }
      : BigInt.jsonParseReviver
  );
};
