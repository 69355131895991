import { RiskSeverity } from "../../risk-severity";
import { capitalizeFirstLetter } from "../../utils";
import type { Semgrep } from "../interfaces/semgrep-results";

type Confidence = Semgrep.Confidence;

export function getSemgrepFindingTitle(result: Semgrep.Result) {
  const owaspOrCWE = removeOwaspPrefix(result.extra.metadata?.owasp?.[0]) ?? removeCWEPrefix(result.extra.metadata?.cwe?.[0]);
  const checkIdFriendlyName = semgrepIdToDisplayName(result.check_id);
  const prefix = owaspOrCWE ? `${owaspOrCWE}: ` : "";
  return `${prefix}${checkIdFriendlyName}`;
}

export function semgrepIdToDisplayName(checkId: string) {
  let ruleName = checkId.split(".").pop();
  if (!ruleName) {
    return checkId;
  }
  ruleName = ruleName.replaceAll("v-html", "vhtml").replaceAll("-", " ").replaceAll("_", " ");
  ruleName = capitalizeFirstLetter(ruleName);
  return tlaToProperCase(ruleName);
}

export function removeOwaspPrefix(str?: string) {
  return str?.replace(/^[A-Z]\d+:\d+ -/, "").replace(/^[A-Z]\d+: /, "");
}

export function removeCWEPrefix(str?: string) {
  return str?.replace(/^CWE-\d+: /, "");
}

function tlaToProperCase(str: string) {
  const words = str.split(/\s+/);
  const result: string[] = [];
  for (const word of words) {
    const searchWord = word.toLowerCase();
    const keepUpperElement = keepUpper[searchWord];
    if (keepUpperElement) {
      result.push(keepUpperElement);
    } else {
      result.push(word);
    }
  }
  return result.join(" ");
}

const keepUpper: Record<string, string> = {
  api: "API",
  sqli: "SQLi",
  sql: "SQL",
  xss: "XSS",
  csrf: "CSRF",
  xsrf: "xSRF",
  ssrf: "SSRF",
  nosqli: "NoSQLi",
  nosql: "NoSQL",
  lfi: "LFI",
  rfi: "RFI",
  os: "OS",
  ssl: "SSL",
  jwt: "JWT",
  saml: "SAML",
  sso: "SSO",
  ldap: "LDAP",
  s3: "S3",
  aws: "AWS",
  gcp: "GCP",
  html: "HTML",
  xml: "XML",
  json: "JSON",
  yaml: "YAML",
  yml: "YML",
  http: "HTTP",
  https: "HTTPS",
  tls: "TLS",
  ecb: "ECB",
  csp: "CSP",
  cors: "CORS",
  xxe: "XXE",
  js: "JS",
  des: "DES",
  aes: "AES",
  rsa: "RSA",
  ssh: "SSH",
  sftp: "SFTP",
  ftp: "FTP",
  smtp: "SMTP",
  pop3: "POP3",
  imap: "IMAP",
  md5: "MD5",
  sha1: "SHA1",
  sha256: "SHA256",
  sha512: "SHA512",
  sha3: "SHA3",
  "sha3-256": "SHA3-256",
  "sha3-512": "SHA3-512",
  "sha3-224": "SHA3-224",
  "sha3-384": "SHA3-384",
  sha2: "SHA2",
  sha224: "SHA224",
  sha384: "SHA384",
  "sha512/224": "SHA512/224",
  "sha512/256": "SHA512/256",
  openssl: "OpenSSL",
  sax: "SAX",
  httponly: "HttpOnly",
  oauth: "OAuth",
  mws: "MWS",
  numpy: "NumPy",
  pytorch: "PyTorch",
  sagemaker: "SageMaker",
  tensorflow: "TensorFlow",
  secretsmanager: "SecretsManager",
  sqs: "SQS",
  sns: "SNS",
  kms: "KMS",
  dynamodb: "DynamoDB",
  iam: "IAM",
  ec2: "EC2",
  emr: "EMR",
  elasticsearch: "ElasticSearch",
  nodetonode: "node-to-node",
  efs: "EFS",
  eks: "EKS",
  db: "DB",
  rds: "RDS",
  codebuild: "CodeBuild",
  docdb: "DocDB",
  codecommit: "CodeCommit",
  codepipeline: "CodePipeline",
  cloudwatch: "CloudWatch",
  cloudformation: "CloudFormation",
  cve: "CVE",
  cwe: "CWE",
  rce: "RCE",
  erb: "ERB",
  gcm: "GCM",
  soapformatter: "SoapFormatter",
  github: "GitHub",
  readrequestbody: "ReadRequestBody",
  servercodec: "ServerCodec",
  vhtml: "v-html",
  var: "var",
  jdbc: "JDBC",
  cmk: "CMK"
} as const;

export function fixSemgrepSingleItemsInArray(semgrepResult: Semgrep.Result) {
  if (semgrepResult.extra.metadata?.cwe) {
    semgrepResult.extra.metadata.cwe = Array.guarantee(semgrepResult.extra.metadata.cwe);
  }
  if (semgrepResult.extra.metadata?.owasp) {
    semgrepResult.extra.metadata.owasp = Array.guarantee(semgrepResult.extra.metadata.owasp);
  }
}

const SEMGREP_SEVERITY_MAP: Record<Semgrep.Severity, RiskSeverity> = {
  CRITICAL: "critical",
  ERROR: "high",
  WARNING: "medium",
  INFO: "low",
  INVENTORY: "info",
  EXPERIMENT: "unknown"
} as const;

const SEMGREP_CONFIDENCE_MAP: Record<Confidence, number> = {
  HIGH: 3,
  MEDIUM: 2,
  LOW: 1
} as const;

export function getSemgrepSeverity(semgrepResultExtra: { severity: Semgrep.Severity; metadata?: Pick<Semgrep.Metadata, "likelihood" | "impact" | "confidence" | "arnica"> }): RiskSeverity {
  const riskSeverity = semgrepResultExtra.metadata?.arnica?.["risk-severity"];
  if (RiskSeverity.is(riskSeverity)) {
    return riskSeverity;
  }
  const likelihood = getRiskByMetadataValue(semgrepResultExtra.metadata?.likelihood);
  const impact = getRiskByMetadataValue(semgrepResultExtra.metadata?.impact);
  const confidence = getRiskByMetadataValue(semgrepResultExtra.metadata?.confidence);
  const risk = likelihood * impact * confidence;
  switch (true) {
    case risk >= 27:
      return SEMGREP_SEVERITY_MAP.CRITICAL;
    case risk >= 18:
      return SEMGREP_SEVERITY_MAP.ERROR;
    case risk >= 8:
      return SEMGREP_SEVERITY_MAP.WARNING;
    case risk > 0:
      return SEMGREP_SEVERITY_MAP.INFO;
    default:
      return SEMGREP_SEVERITY_MAP[semgrepResultExtra.severity] || "unknown";
  }
}

function getRiskByMetadataValue(value?: Confidence): number {
  return value ? SEMGREP_CONFIDENCE_MAP[value] : 0;
}

export function getAllSemgrepLocations(semgrepResult: Semgrep.Result, locationField: (location?: Semgrep.Location | Semgrep.Result) => number | undefined): number[] {
  const result: number[] = [];
  const dataflowTrace = semgrepResult?.extra.dataflow_trace;
  if (dataflowTrace?.intermediate_vars) {
    const intermediateVars = dataflowTrace?.intermediate_vars?.map((v) => locationField(v.location)) ?? [];
    result.pushAll(intermediateVars.nonNullable());
  }
  const semgrepResultLocation = locationField(semgrepResult);
  if (semgrepResultLocation) {
    result.push(semgrepResultLocation);
  }

  const taintSourceLocation = locationField(dataflowTrace?.taint_source.location);
  if (taintSourceLocation) {
    result.push(taintSourceLocation);
  }
  return result;
}

export function getFirstLineOfSemgrepResult(semgrepResult: Semgrep.Result): number {
  const allSemgrepLocations = getAllSemgrepLocations(semgrepResult, (location) => location?.start?.line);
  return Math.min(...allSemgrepLocations);
}

export function getLastLineOfSemgrepResult(semgrepResult: Semgrep.Result): number {
  const allSemgrepLocations = getAllSemgrepLocations(semgrepResult, (location) => location?.end?.line);
  return Math.max(...allSemgrepLocations);
}

export function getFirstLineOffsetOfSemgrepResult(semgrepResult: Semgrep.Result): number {
  // cols are 1 based
  const allSemgrepLocations = getAllSemgrepLocations(semgrepResult, (location) => (location ? location.start.offset - (location.start.col - 1) : undefined));
  return Math.min(...allSemgrepLocations);
}

//TODO: this is a bit more tricky
// export function getLastLineOffsetOfSemgrepResult(semgrepResult: Semgrep.Result): number {
//
//   const allSemgrepLocations = getAllSemgrepLocations(semgrepResult, (location) => location ? location.end.offset - location.end.col : undefined);
//   return Math.max(...allSemgrepLocations);
// }

export function addHighlightMarkersToResult(semgrepResult: Semgrep.Result): WrapWithTagsIndex[] {
  if (!semgrepResult || !semgrepResult.start.offset) {
    return [];
  }
  const startOffset = getFirstLineOffsetOfSemgrepResult(semgrepResult);
  const cssClass = "no-keep-markup";
  const intermediates =
    semgrepResult?.extra.dataflow_trace?.intermediate_vars?.map((iv) => ({
      startIndex: iv.location.start.offset - startOffset,
      endIndex: iv.location.end.offset - startOffset,
      class: `${cssClass} intermediate-var`,
      tooltip: "Intermediate Var",
      content: iv.content
    })) ?? [];
  const taintSource = semgrepResult?.extra.dataflow_trace?.taint_source;

  const finding: WrapWithTagsIndex = {
    startIndex: semgrepResult.start.offset - startOffset,
    endIndex: semgrepResult.end.offset - startOffset,
    class: `${cssClass} finding`,
    tooltip: "Taint Sink",
    content: semgrepResult.extra.lines.substring(semgrepResult.start.col - 1, semgrepResult.end.col - 1)
  };
  const highlights: WrapWithTagsIndex[] = [];
  highlights.push(finding);
  highlights.pushAll(intermediates);
  if (taintSource) {
    const source = {
      startIndex: taintSource.location.start.offset - startOffset,
      endIndex: taintSource.location.end.offset - startOffset,
      class: `${cssClass} taint-source`,
      tooltip: "Taint Source",
      content: taintSource.content
    };
    highlights.push(source);
  }

  return highlights;
}

export type WrapWithTagsIndex = { startIndex: number; endIndex: number; class?: string; content?: string; tooltip?: string };

/**
 * NOTE: HTML escapes (except the mark tags)
 * @param code
 * @param indices
 */
export function wrapWithTags(code: string, indices: WrapWithTagsIndex[]): string {
  indices.sort((a, b) => a.startIndex - b.startIndex);
  const filteredIndices: WrapWithTagsIndex[] = [];
  // we currently don't want to support a nested highlighting, the index ranges must be exclusive
  // we don't throw an error because Semgrep sometimes does this (taint source is nested in overall result)
  for (let i = 0; i < indices.length; i++) {
    const item = indices[i]!;
    let nextStartIndex = indices[i + 1]?.startIndex;
    while (nextStartIndex && nextStartIndex < item.endIndex) {
      console.error("nextStartIndex is smaller than endIndex", nextStartIndex, item.endIndex);
      i++;
      nextStartIndex = indices[i + 1]?.startIndex;
    }
    filteredIndices.push(item);
  }
  indices = filteredIndices;
  const result: string[] = [];
  const beforeFirstWord = code.substring(0, indices[0]?.startIndex);
  result.push(beforeFirstWord.escapeHtml());
  for (let i = 0; i < indices.length; i++) {
    const item = indices[i]!;
    const word = code.substring(item.startIndex, item.endIndex);
    const nextStartIndex = indices[i + 1]?.startIndex;
    const afterWord = code.substring(item.endIndex, nextStartIndex);

    const itemClass = item.class;
    const itemTooltip = item.tooltip;
    if (itemClass?.includes('"') || itemClass?.includes("'")) {
      throw new Error("class name cannot contain \" or '");
    }
    if (itemTooltip?.includes('"') || itemTooltip?.includes("'")) {
      throw new Error("tooltip name cannot contain \" or '");
    }

    const classAttribute = itemClass ? ` class="${itemClass}"` : "";
    const tooltopAttribute = itemTooltip ? ` title="${itemTooltip}"` : "";
    result.push(`<mark${classAttribute}${tooltopAttribute}>`);
    result.push(word.escapeHtml());
    result.push("</mark>");
    result.push(afterWord.escapeHtml());
  }
  return result.join("");
}

/**
 * @deprecated (moved to SQL)
 * helper const to determine which attributes to project in the "select * from SemgrepFinding" query
 */
export const codeRiskFindingAttributes: Record<string, boolean> = {
  title: true,
  scanner: true,
  dataType: true,
  detectedOn: true,
  metadata: true,
  risk: true,
  path: true,
  lineNumber: true,
  // not included in the first load, (lazy loaded asynchronously)
  arnicaOrgId: true,
  sortKey: true,
  context: true,
  integrationOrgId: true,
  integrationType: true,
  baseUrl: true,
  project: true,
  projectId: true,
  repo: true,
  repoId: true,
  branch: true,
  firstDetectedOn: true,
  isOnPush: true,
  slaStart: true,
  policy: false,
  integrationTypeAndFindingTypeAndIntegrationOrgId: false,
  integrationTypeAndFindingTypeAndIntegrationOrgIdAndRepoAndDetectedOn: false,
  fileSortKey: false,
  v: true,
  license: true,
  packageType: true,
  lastUpdateDate: true,
  highestEPSSCVE: true,
  kevCVEs: true,
  highestEPSSScore: true
};

/**
 * @deprecated (moved to SQL)
 */
export const codeRiskFindingProjectionExpressionExpressionAttributeNames = Object.entries(codeRiskFindingAttributes)
  .filter(([, v]) => v)
  .map(([k]) => k)
  .reduce((prev, current) => ({ ...prev, [`#${current}`]: current }), {});
export const codeRiskFindingProjectionExpression = Object.keys(codeRiskFindingProjectionExpressionExpressionAttributeNames).join(", ");
