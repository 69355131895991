import { Assets } from "./assets-state";
import { Popup } from "./popup-state";
import Axios from "axios";
import Vue from "vue";
import { Payment } from "@/api";
import { BillingSummaryResponse } from "@/interfaces/api/response/billing-summary-response";

export const Billing = new (class BillingState {
  private _summary: BillingSummaryResponse | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public get summary(): BillingSummaryResponse | null {
    return this._summary;
  }

  public get identityCount(): number {
    void this.initIdentityCount();
    return Assets.getBillableUsers()?.count ?? 0;
  }

  public async initIdentityCount() {
    await Assets.loadBillableUserCount(true);
    return Assets.getBillableUsers()?.count ?? 0;
  }

  public async refresh(): Promise<void> {
    try {
      this._summary = await Payment.getBillingSummary();
    } catch (e) {
      if (Axios.isAxiosError(e) && e.message) {
        void Popup.error((e.response?.data as any)?.message ?? e.message, { timeout: Popup.INDEFINITELY });
      }
    }
  }
})();
