//TODO: add date generate
import type { ScmIntegrationType } from "../../../dynamo";
import type { FindingBase } from "../../../dynamo/findingBase";
import type { CommitStatusTypes, FindingsHistory, HistoryType, MetadataOptionsTypes, SecretStatesType, SecretsHistoryType } from "../../../finding-types";
import { GitResourceBase } from "../../../report/interfaces/git-resource-base";
import { RiskSeverity } from "../../../risk-severity";
import type { GitBlameDetails } from "./git-blame-details";

export type GetSecretsReportResponse = {
  findings: GetFindingsResponseItem[];
  dateGenerated: string;
};

//DO NOT CHANGE THE ENUM VALUES! (enum values MUST have the same value as the enum key to avoid issues. Display names can be set using filters)
export enum VerificationResults {
  UNVERIFIED = "UNVERIFIED",
  VERIFIED_TRUE = "VERIFIED_TRUE",
  VERIFIED_FALSE = "VERIFIED_FALSE"
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace VerificationResults {
  export const ui: Record<VerificationResults, { displayName: string }> = {
    [VerificationResults.VERIFIED_TRUE]: { displayName: "Valid" },
    [VerificationResults.VERIFIED_FALSE]: { displayName: "Invalid" },
    [VerificationResults.UNVERIFIED]: { displayName: "None" }
  } as const satisfies Record<VerificationResults, { displayName: string }>;

  Object.hideProps(VerificationResults, (k, v) => typeof v !== "string");
}

/**
 * NOTE: do not modify without also changing the python code that defines this interface!
 * TODO: use GitResourceBase instead
 * @deprecated use GitResourceBase instead
 */
export interface GitLinkInfo {
  org: string;
  project?: string;
  projectId?: string;
  repo: string;
  repoId: string;
  branch: string;
  /**
   * @deprecated use `path` instead
   */
  filename: string;
  /**
   * this entire interface is deprecated, however, this is a step toward not using `filename` for secrets, stopgap till we move it all to SQL
   */
  path?: string;
  lineNumber: number;
}

export type SecretAccessInfo = {
  login: string;
  name?: string;
  timestamp?: string;
  action?: string;
};

export type IntegrationTypes = "slack";

export interface Metadata<TStatus extends CommitStatusTypes = CommitStatusTypes, THistory extends HistoryType = HistoryType> {
  type: MetadataOptionsTypes;

  /**
   * The list of branches in which a fix for the finding exists
   * For example, if the finding is in "main" but a fix is in "develop", "develop" will be in the list of resolvedBranches
   * Optional for backwards compatibility
   */
  resolvedBranches?: string[];
  /**
   * The list of commit shas, which represent a HEAD commit of push event in which a fix for the finding created
   * For example, if the finding is in "main", a fix is in "develop" (SHA = 12345678), and we check out "feature-branch" from "develop"
   * when we push to "feature-branch" the resolvedPushes will contain "12345678" and therefore won't include the "feature-branch" as one of the resolvedBranches
   */
  resolvedPushes?: string[];
  additionalInfo?: string;
  deciderName?: string | null;
  integrationType?: IntegrationTypes;
  integrationDisplayName?: string;
  /**
   * @deprecated use `FindingsHistory` table instead
   */
  history?: FindingsHistory<THistory>[];
  status?: TStatus;
  pendingStatus?: TStatus;
  previousStatus?: TStatus;
  commitHidden?: boolean;
}

export interface GetFindingsResponseItem extends FindingBase, GitLinkInfo, GitResourceBase {
  integrationOrgId: string;
  integrationType: ScmIntegrationType;
  isVerified: boolean;
  verificationResult: VerificationResults;
  metadata: Metadata<SecretStatesType, SecretsHistoryType>;
  category: string;
  specificType: string;
  url: string;
  baseUrl?: string;
  /**
   * @deprecated use `context`
   */
  gitBlame?: string;
  /**
   * @deprecated use `context`
   */
  gitBlameDetails?: GitBlameDetails;

  usersWithAccessToSecret?: SecretAccessInfo[];
  //used to prevent adding duplicates
  userLoginsWithAccessToSecret?: Set<string>;
  indices: [number, number][];
  fingerprint: string;
  /** @deprecated use `detectedOn` */
  dateFound?: string;
  detectedOn?: string;
  branch: string;

  risk?: RiskSeverity;
}

export type SecretType = string;

export function getSecretRiskReportItemId(item: GetFindingsResponseItem): string {
  return [item.org, item.project, item.repo, item.branch, item.filename]
    .nonNullable()
    .map((x) => encodeURIComponent(x!))
    .join("/");
}

export const Metadata = {
  is(metadata: unknown): metadata is Metadata {
    return typeof metadata === "object" && metadata !== null && "type" in metadata;
  },
  hasMetadata(item: FindingBase | undefined | null): item is FindingBase & { metadata: Metadata } {
    return !!item && "metadata" in item && Metadata.is(item.metadata);
  }
};
