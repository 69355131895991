import { ApiBase } from "./api-base";
import Axios from "axios";
import { GetUserInfoResponse } from "$/interfaces/ui-api/response/get-user-info-response";
import { UnauthorizedError } from "@/errors/unauthorized-error";
import { Scope } from "@/interfaces/scope";
import { User } from "@/models/user";
import { router } from "@/plugins";
import { Layout } from "@/state";
import { Auth as AuthState } from "@/state/auth-state";

class AuthApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "auth", name: "AuthApi" });
  }

  protected override async getIdToken(): Promise<string> {
    try {
      const idToken = await AuthState.getIdToken();
      if (!idToken) {
        throw new UnauthorizedError("No ID token");
      }
      return idToken;
    } catch (e) {
      //Popup.error("You are no longer signed in, redirecting to login page");
      await router.replace({ name: "login" });
      //TODO: save state so users can go back to where they left off
      //TODO: redirect to login...
      throw e;
    }
  }

  protected override async onResponseError(error: unknown): Promise<unknown> {
    try {
      return await super.onResponseError(error);
    } catch (e) {
      if (!Axios.isAxiosError(error) || error.response?.status !== 401) {
        throw error;
      }
      await AuthState.signOut();
      Layout.reloadHome();
      return null;
    }
  }

  /**
   * Gets the server-resolved user information
   * @returns resolved user information
   */
  public async getUserInfo(): Promise<User | null> {
    const res = await this.client.get<GetUserInfoResponse<Scope> | null>("user");
    if (!res.data) {
      return null;
    }
    const user = new User(
      {
        email: res.data.email,
        email_verified: true
      },
      res.data
    );
    return user;
  }
}

export const Auth = new AuthApi();
