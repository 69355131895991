import Vue from "vue";
import { IdentityOverrideItemResponseRequest } from "$/interfaces/identity-override-item-response-request";
import { SCMType } from "$/scm-type";
import { Admin } from "@/api";

export const IdentityOverride = new (class IdentifyOverride {
  public isInitializedAtLeastOnce = false;
  public identityOverrides: Map<string, IdentityOverrideItemResponseRequest> = new Map();

  constructor() {
    Vue.observable(this);
  }

  /**
   * Populate the Overrides Map with persisted data.
   */
  public async init() {
    if (this.isInitializedAtLeastOnce) {
      return;
    }
    this.identityOverrides = new Map<string, IdentityOverrideItemResponseRequest>();
    const identityOverrideList: IdentityOverrideItemResponseRequest[] = await Admin.getIdentityOverrides();
    for (const identityOverrideItemResponseRequest of identityOverrideList) {
      this.identityOverrides.set(identityOverrideItemResponseRequest.identityId, identityOverrideItemResponseRequest);
    }
    this.isInitializedAtLeastOnce = true;
  }

  public async update(identityOverride: IdentityOverrideItemResponseRequest): Promise<void> {
    const key = IdentityOverrideItemResponseRequest.sortKey(SCMType.GITHUB, identityOverride.identityId);

    const identityToUpdate: IdentityOverrideItemResponseRequest = this.identityOverrides.get(key)
      ? Object.assign({}, this.identityOverrides.get(key), identityOverride)
      : { ...identityOverride, ...{ namesToRemove: [] } };

    this.identityOverrides.set(key, identityToUpdate);

    await Admin.setIdentityOverrideItem(identityToUpdate);
  }

  public get(scmType: SCMType, identityId: string): IdentityOverrideItemResponseRequest | null {
    if (!this.identityOverrides || this.identityOverrides.size === 0) {
      return null;
    }
    const key = IdentityOverrideItemResponseRequest.sortKey(scmType, identityId);
    const target = this.identityOverrides.get(key); // multiple matches possible
    if (!target) {
      return null;
    }
    return target || null;
  }
})();
