import { LineRange, ScmUrlGenerator } from "../../data-access/src/scm-url-generator";
import { AdoRepositoryIdentifier } from "./api/ado-repository-identifier";

export class AdoUrlGenerator implements ScmUrlGenerator {
  public getOrganizationUrl(organizationId: string): string {
    return `https://dev.azure.com/${organizationId.encodeURI()}`;
  }
  public getProjectUrl(organizationId: string, projectId: string): string {
    return `https://dev.azure.com/${organizationId.encodeURI()}/${projectId.encodeURI()}`;
  }
  public getRepositoryUrl(organizationId: string, repositoryId: string): string {
    const { projectId, repositoryId: adoRepositoryId } = AdoRepositoryIdentifier.parse(repositoryId);
    return `https://dev.azure.com/${organizationId.encodeURI()}/${projectId.encodeURI()}/_git/${adoRepositoryId.encodeURI()}`;
  }
  public getRepositoryBranchUrl(organizationId: string, repositoryId: string, branch: string): string {
    const { projectId, repositoryId: adoRepositoryId } = AdoRepositoryIdentifier.parse(repositoryId);
    const repositoryUrl = new URL(`https://dev.azure.com/${organizationId.encodeURI()}/${projectId.encodeURI()}/_git/${adoRepositoryId.encodeURI()}`);
    repositoryUrl.searchParams.set("version", `GB${branch}`);
    return repositoryUrl.toString();
  }
  public getRepositoryCommitUrl(organizationId: string, repositoryId: string, commitSha: string): string {
    const { projectId, repositoryId: adoRepositoryId } = AdoRepositoryIdentifier.parse(repositoryId);
    const repositoryUrl = new URL(`https://dev.azure.com/${organizationId.encodeURI()}/${projectId.encodeURI()}/_git/${adoRepositoryId.encodeURI()}`);
    repositoryUrl.searchParams.set("version", `GC${commitSha}`);
    return repositoryUrl.toString();
  }

  public getRepositoryBranchFileUrl(organizationId: string, repositoryId: string, branch: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, `GB${branch}`, path, lines);
  }
  public getRepositoryCommitFileUrl(organizationId: string, repositoryId: string, commitSha: string, path: string, lines?: LineRange | undefined): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, `GC${commitSha}`, path, lines);
  }
  private getRepositoryFileUrl(organizationId: string, repositoryId: string, version: `GB${string}` | `GC${string}`, path: string, lines?: LineRange) {
    const { projectId, repositoryId: adoRepositoryId } = AdoRepositoryIdentifier.parse(repositoryId);
    const repositoryUrl = new URL(`https://dev.azure.com/${organizationId.encodeURI()}/${projectId.encodeURI()}/_git/${adoRepositoryId.encodeURI()}`);
    repositoryUrl.searchParams.set("version", version);
    repositoryUrl.searchParams.set("path", path);
    if (lines?.start) {
      repositoryUrl.searchParams.set("line", String(lines.start));
      repositoryUrl.searchParams.set("lineEnd", String(lines?.end ? lines.end : lines.start + 1));
    }
    repositoryUrl.searchParams.set("lineStartColumn", String(1));
    repositoryUrl.searchParams.set("lineEndColumn", String(1));
    repositoryUrl.searchParams.set("lineStyle", "plain");
    repositoryUrl.searchParams.set("_a", "contents");
    return repositoryUrl.toString();
  }

  public getRepositoryPullRequestUrl(organizationId: string, repositoryId: string, pullRequestId: number): string {
    const { projectId, repositoryId: adoRepositoryId } = AdoRepositoryIdentifier.parse(repositoryId);
    return `https://dev.azure.com/${organizationId.encodeURI()}/${projectId.encodeURI()}/_git/${adoRepositoryId.encodeURI()}/pullrequest/${pullRequestId}`;
  }
  public getRepositoryPullRequestCommentUrl(organizationId: string, repositoryId: string, pullRequestId: number, commentId: string): string {
    // commentId is the first 10 digits of the comment created date in milliseconds
    // const commentCreatedInMilli = commentCreatedDate.getTime();
    // const commentId = commentCreatedInMilli.toString().substring(0, 10);
    const { projectId, repositoryId: adoRepositoryId } = AdoRepositoryIdentifier.parse(repositoryId);
    return `https://dev.azure.com/${organizationId.encodeURI()}/${projectId.encodeURI()}/_git/${adoRepositoryId.encodeURI()}/pullrequest/${pullRequestId}#${commentId}`;
  }

  public getUserUrl(organizationId: string, _userId: string): string {
    return `https://dev.azure.com/${organizationId.encodeURI()}/_settings/users`;
  }

  public getRepositoryId(projectId: string | undefined, repoId: string): string {
    return AdoRepositoryIdentifier.getId(projectId, repoId);
  }
}
