export class Deferred<TValue, TError = Error> implements PromiseLike<TValue> {
  readonly #promise: Promise<TValue>;
  #state: "pending" | "fulfilled" | "rejected" = "pending";

  public constructor() {
    this.#promise = new Promise<TValue>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    this.promise.then(() => this.#state = "fulfilled", () => this.#state = "rejected");
  }

  public resolve!: (value: TValue) => void;
  public reject!: (reason: TError) => void;

  public get promise(): Promise<TValue> {
    return this.#promise;
  }

  public get settled(): boolean {
    return this.#state !== "pending";
  }

  public get fulfilled(): boolean {
    return this.#state === "fulfilled";
  }

  public get rejected(): boolean {
    return this.#state === "rejected";
  }

  public get pending(): boolean {
    return this.#state === "pending";
  }

  public then<TResult1 = TValue, TResult2 = never>(
    onfulfilled?: ((value: TValue) => TResult1 | PromiseLike<TResult1>) | null | undefined,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined
  ): PromiseLike<TResult1 | TResult2> {
    return this.#promise.then(onfulfilled, onrejected);
  }
}
