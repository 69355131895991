import { IssuesIntegrationType } from "../../../dynamo";
import { PolicyItemType } from "../policy-item-base";
import { PolicyAction, PolicyActionSubType } from "./policy-action";
import { FieldMapping } from "../../../issues/field-mapping";

declare module "./policy-action" {
  enum PolicyActionSubType {
    create_issue = "create_issue"
  }
}

(PolicyActionSubType as { create_issue: "create_issue" }).create_issue = "create_issue";

export interface PolicyActionCreateIssue extends PolicyAction {
  sub: PolicyActionSubType;
  integration: {
    orgId: string;
    type: IssuesIntegrationType;
  };
  issue: {
    /** Project ID */
    project: string;
    /** Issue type ID */
    type: string;
    status: {
      /** Open status ID */
      open: string;
      /** Closed status ID */
      close: string;
    };
    /** Optional assignee */
    assignee: string | null;
    /** Field mapping */
    fields: Record<string, FieldMapping>;
  };
  /** Optional footer for created issue */
  footer: string | null;
}

export const PolicyActionCreateIssue = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.create_issue
  },
  is(action: PolicyAction): action is PolicyActionCreateIssue {
    return action.sub === PolicyActionSubType.create_issue;
  }
} as const;

