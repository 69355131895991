import { DirectiveBinding } from "vue/types/options";
import Vue from "vue";
Vue.directive("custom-word-break", {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    //don't break on https:// prefix for example
    const minCharsAtStart = 10;
    const breakChars: string[] = binding.value ? Array.guarantee(binding.value) : ["/", "_", "!", "?", "#", "$", "%", "^", "&", "*", "|", ";", ":", ",", ".", "~", "`", '"', "'"];
    const innerHTML = el.innerHTML;
    const beforeCutoff = innerHTML.slice(0, minCharsAtStart);
    let afterCutoff = innerHTML.slice(minCharsAtStart);
    const isBefore = binding.modifiers.before || false;
    for (const breakChar of breakChars) {
      afterCutoff = afterCutoff.replaceAll(breakChar, (breakChar) => (isBefore ? `<wbr>${breakChar}` : `${breakChar}<wbr>`));
    }
    el.innerHTML = `${beforeCutoff}${afterCutoff}`;
  }
});
