import { CancelledError } from "../errors";
import moment from "moment";

declare global {
  interface CancellationObject {
    cancel(): void;
    handle: ReturnType<typeof setTimeout>;
  }

  type CancellablePromise<T> = Promise<T> & CancellationObject;

  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace setTimeout {
    function async(timeout: moment.Duration): CancellablePromise<void>;
    function async(timeout: number): CancellablePromise<void>;
    function async(): CancellablePromise<void>;
  }
}

globalThis.setTimeout.async ??= function timeoutAsync(timeout?: number | moment.Duration): CancellablePromise<void> {
  const timeoutInMs = moment.duration(timeout).asMilliseconds();

  let cancel: () => void;
  let handle: ReturnType<typeof setTimeout>;
  const promise = new Promise<void>((res, rej) => {
    handle = globalThis.setTimeout(res, timeoutInMs);
    cancel = () => {
      clearTimeout(handle);
      rej(new CancelledError());
    };
  }) as CancellablePromise<void>;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  promise.cancel = cancel!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  promise.handle = handle!;
  return promise;
};
