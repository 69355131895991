
  <v-app-bar
    v-if="showFullAppBar"
    app
    class="component layout-component app-bar app-bar-full"
    elevation="0"
    :expand-left="!$state.Layout.drawer.show"
    :sfa="$state.Auth.isNonDefaultOrgId"
    :light="$state.Auth.isNonDefaultOrgId || !$vuetify.theme.dark">
    <v-progress-linear :active="$state.Layout.loading" indeterminate color="primary" absolute bottom height="5px"></v-progress-linear>

    <arnica-logo v-if="!$state.Layout.drawer.show" class="logo" :background="$vuetify.theme.dark ? 'dark' : 'light'" />

    <v-icon left dense v-if="routeIcon">{{ routeIcon }}</v-icon>

    <!-- https://stackoverflow.com/questions/66607167/vuetify-v-app-bar-title-component-is-truncated-with-plenty-of-room-to-spare -->
    <v-app-bar-title class="text-no-wrap">
      {{ $state.Layout.title }}
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <!--    <job-progress></job-progress>-->

    <template v-if="$state.Auth.isNonDefaultOrgId">
      <v-chip light label outlined @click.stop="copyOrgId">
        {{ $state.Auth.user?.userInfo?.org?.name }} <code>{{ $state.Auth.user?.userInfo?.org?.id }}</code>
        <v-icon small right>mdi-content-copy</v-icon>
        <v-icon small right @click.stop="$state.Layout.userMenu.editProfile = true">mdi-pencil</v-icon>
        <edit-profile-dialog></edit-profile-dialog>
      </v-chip>
      <v-spacer></v-spacer>
    </template>

    <!-- SCM Type -->
    <v-select v-if="scmTypes" v-model="$state.Layout.scmType" :items="scmTypes" style="max-width: 200px" class="ml-4 mr-4" dense filled hide-details outlined>
      <template v-slot:item="{ item }">
        <v-icon class="mr-2">{{ item.icon }}</v-icon>
        {{ item.text }}
      </template>
      <template v-slot:selection="{ item }">
        <v-icon class="mr-2">{{ item.icon }}</v-icon>
        {{ item.text }}
      </template>
    </v-select>

    <!-- Demo Data / Login-->
    <template v-if="$state.Auth.authenticated">
      <!-- <v-btn
        v-if="$state.DemoData.isDemoData"
				icon
				title="Remove Demo Data"
        cx-id="demo-data-remove"
				@click="$state.DemoData.isDemoData = false"
			>
				<v-icon>mdi-database-off</v-icon>
			</v-btn>
			<v-btn v-else cx-id="demo-data-start" icon title="See Demo Data" @click="$state.DemoData.isDemoData = true">
				<v-icon>mdi-database</v-icon>
			</v-btn> -->
    </template>
    <template v-else>
      <v-btn to="/login" title="Sign In / Sign Up" color="primary" class="sign-in-btn"> Sign In / Sign Up </v-btn>
    </template>

    <!-- Help -->
    <v-btn icon @click.stop="toggleHelp" v-if="showHelp">
      <v-icon>mdi-help-circle</v-icon>
    </v-btn>

    <!-- Theme -->
    <v-btn icon @click.stop="toggleTheme">
      <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
      <v-icon v-else>mdi-weather-night</v-icon>
    </v-btn>

    <!-- Notifications -->
    <v-btn v-scope.notifications_ro v-if="$state.Layout.onboarding.isDoneOnboarding" icon :to="{ name: 'notifications' }">
      <v-icon>mdi-bell</v-icon>
      <v-badge v-if="$state.Notifications.unreadCount > 0" :content="$state.Notifications.unreadCount" class="mr-0" color="red"></v-badge>
    </v-btn>

    <!-- User Menu -->
    <app-menu></app-menu>
  </v-app-bar>
  <div v-else class="component layout-component app-bar app-bar-partial">
    <v-btn icon @click.stop="toggleTheme">
      <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
      <v-icon v-else>mdi-weather-night</v-icon>
    </v-btn>
  </div>
