import { ScmUrlGeneratorFactory } from "../../../packages/scm/data-access-factory/src/scm-url-generator-factory";
import { ScmUrlGenerator } from "../../../packages/scm/data-access/src/scm-url-generator";
import { LineRange } from "../detectors/utils/package-line-number-finder";
import { ScmIntegrationType, UniversalScmIntegrationType } from "../dynamo";
import { GitResourceBase } from "../report/interfaces/git-resource-base";
import { CodeRiskFinding } from "../sast/code-risk-finding";

//TODO: extend GitLinkInfo and GitResourceBase / adapt these interfaces to a single one
export interface ResourceSCMUrl extends Partial<GitResourceBase> {
  integrationType: ScmIntegrationType;
  org?: string | undefined;
  integrationOrgId?: string | undefined;
  project?: string | undefined;
  repo?: string | undefined;
  branch?: string | undefined;
  baseUrl?: string | undefined;
  path?: string | undefined;
  lineNumber?: number | undefined;
  lineRange?: LineRange | undefined;
  commitHash?: string | undefined;
  pullRequestId?: number | undefined;
  commitLineNumber?: number | undefined;
  commitLineRange?: LineRange | undefined;
  userId?: string | undefined;
  context?: CodeRiskFinding["context"] | undefined;
}

export type SCMLinkIdentityType = "org" | "project" | "repo" | "branch" | "path" | "commit" | "pull-request" | "user";

/**
 * @deprecated use ResourceSCMUrl.forUser instead
 */
export function getResourceSCMUrl(item: ResourceSCMUrl, identity: "user"): string | null;
/**
 * @deprecated use ResourceSCMUrl.forXXX instead
 */
export function getResourceSCMUrl(item: ResourceSCMUrl, identity: Exclude<SCMLinkIdentityType, "user">): string;
/**
 * @deprecated use ResourceSCMUrl.forXXX instead
 */
export function getResourceSCMUrl(item: ResourceSCMUrl, identity: SCMLinkIdentityType): string | null {
  const normalizedItem = getNormalizedItem(item);

  if (identity === "user" && !normalizedItem.userId) {
    return null;
  }
  return defaultUrlFactory(normalizedItem, identity);
}

function getNormalizedItem(item: ResourceSCMUrl): ResourceSCMUrl {
  const normalizedItem = Object.deepClone(item);
  // backward compatibility with GitResourceBase
  if (!normalizedItem.org && normalizedItem.integrationOrgId) {
    normalizedItem.org = normalizedItem.integrationOrgId;
  }

  normalizedItem.commitHash ??= normalizedItem.context?.commitDetails?.hash || undefined;
  normalizedItem.commitLineNumber ??= normalizedItem.context?.commitDetails?.lineRange?.start;
  return normalizedItem;
}

const generatorFunctionMap = {
  org: (generator: ScmUrlGenerator, item: ResourceSCMUrl) => {
    if (!item.org) {
      throw new Error("org is required");
    }
    return generator.getOrganizationUrl(item.org);
  },
  project: (generator: ScmUrlGenerator, item: ResourceSCMUrl) => {
    if (!item.org) {
      throw new Error("org is required");
    }
    if (!item.projectId) {
      throw new Error("projectId is required");
    }
    return generator.getProjectUrl(item.org, item.projectId);
  },
  repo: (generator: ScmUrlGenerator, item: ResourceSCMUrl) => {
    if (!item.org) {
      throw new Error("org is required");
    }
    if (!item.repoId) {
      throw new Error("repoId is required");
    }
    const repositoryId = generator.getRepositoryId(item.projectId, item.repoId);
    return generator.getRepositoryUrl(item.org, repositoryId);
  },
  branch: (generator: ScmUrlGenerator, item: ResourceSCMUrl) => {
    if (!item.org) {
      throw new Error("org is required");
    }
    if (!item.repoId) {
      throw new Error("repoId is required");
    }
    if (!item.branch) {
      throw new Error("branch is required");
    }
    const repositoryId = generator.getRepositoryId(item.projectId, item.repoId);
    return generator.getRepositoryBranchUrl(item.org, repositoryId, item.branch);
  },
  commit: (generator: ScmUrlGenerator, item: ResourceSCMUrl) => {
    if (!item.org) {
      throw new Error("org is required");
    }
    if (!item.repoId) {
      throw new Error("repoId is required");
    }
    if (!item.commitHash) {
      throw new Error("commitHash is required");
    }
    const repositoryId = generator.getRepositoryId(item.projectId, item.repoId);
    if (item.path) {
      const lines = typeof item.commitLineNumber === "number" ? { start: item.commitLineNumber } : undefined;
      return generator.getRepositoryCommitFileUrl(item.org, repositoryId, item.commitHash, item.path, lines);
    }
    return generator.getRepositoryCommitUrl(item.org, repositoryId, item.commitHash);
  },
  "pull-request": (generator: ScmUrlGenerator, item: ResourceSCMUrl) => {
    if (!item.org) {
      throw new Error("org is required");
    }
    if (!item.repoId) {
      throw new Error("repoId is required");
    }
    if (item.pullRequestId === undefined) {
      throw new Error("pullRequestId is required");
    }
    const repositoryId = generator.getRepositoryId(item.projectId, item.repoId);
    return generator.getRepositoryPullRequestUrl(item.org, repositoryId, item.pullRequestId);
  },
  path: (generator: ScmUrlGenerator, item: ResourceSCMUrl) => {
    if (!item.org) {
      throw new Error("org is required");
    }
    if (!item.repoId) {
      throw new Error("repoId is required");
    }
    if (!item.path) {
      throw new Error("path is required");
    }
    if (!item.branch) {
      throw new Error("branch is required");
    }
    const repositoryId = generator.getRepositoryId(item.projectId, item.repoId);
    const lines = typeof item.lineNumber === "number" ? { start: item.lineNumber } : undefined;
    return generator.getRepositoryBranchFileUrl(item.org, repositoryId, item.branch, item.path, lines);
  },
  user: (generator: ScmUrlGenerator, item: ResourceSCMUrl) => {
    if (!item.org) {
      throw new Error("org is required");
    }
    if (!item.userId) {
      throw new Error("userId is required");
    }
    return generator.getUserUrl(item.org, item.userId);
  }
} as const satisfies Record<SCMLinkIdentityType, (generator: ScmUrlGenerator, item: ResourceSCMUrl) => string>;

function defaultUrlFactory(item: ResourceSCMUrl, identity: SCMLinkIdentityType): string {
  if (!UniversalScmIntegrationType.is(item.integrationType)) {
    throw new Error(`Integration Type ${item.integrationType} is not supported`);
  }

  const factory = new ScmUrlGeneratorFactory();
  const generator = factory.build(item.integrationType, item.baseUrl);
  const generatorFunction = generatorFunctionMap[identity];
  return generatorFunction(generator, item);
}

type OrganizationResource = (RequiredPick<ResourceSCMUrl, "org"> | RequiredPick<ResourceSCMUrl, "integrationOrgId">) &
  RequiredPick<ResourceSCMUrl, "integrationType"> &
  Pick<ResourceSCMUrl, "baseUrl">;
type ProjectResource = RequiredPick<ResourceSCMUrl, "projectId"> & OrganizationResource;
type RepositoryResource = RequiredPick<ResourceSCMUrl, "repoId" | "projectId"> & OrganizationResource;

export const ResourceSCMUrl = {
  forOrganization(item: OrganizationResource): URL {
    const normalizedItem = getNormalizedItem(item);
    const url = defaultUrlFactory(normalizedItem, "org");
    if (!url) {
      throw new Error("Failed to generate organization SCM url");
    }
    return new URL(url);
  },
  forProject(item: ProjectResource): URL {
    const normalizedItem = getNormalizedItem(item);
    const url = defaultUrlFactory(normalizedItem, "project");
    if (!url) {
      throw new Error("Failed to generate project SCM url");
    }
    return new URL(url);
  },
  forRepository(item: RepositoryResource): URL {
    const normalizedItem = getNormalizedItem(item);
    const url = defaultUrlFactory(normalizedItem, "repo");
    if (!url) {
      throw new Error("Failed to generate repository SCM url");
    }
    return new URL(url);
  },
  forBranch(item: RequiredPick<ResourceSCMUrl, "repoId" | "projectId" | "branch"> & Pick<ResourceSCMUrl, "baseUrl"> & OrganizationResource): URL {
    const normalizedItem = getNormalizedItem(item);
    const url = defaultUrlFactory(normalizedItem, "branch");
    if (!url) {
      throw new Error("Failed to generate repository SCM url");
    }
    return new URL(url);
  },
  forPath(
    item: RequiredPick<ResourceSCMUrl, "repoId" | "projectId" | "branch" | "path"> & Pick<ResourceSCMUrl, "lineNumber" | "baseUrl"> & OrganizationResource
  ): URL {
    const normalizedItem = getNormalizedItem(item);
    const url = defaultUrlFactory(normalizedItem, "path");
    if (!url) {
      throw new Error("Failed to generate SCM path url");
    }
    return new URL(url);
  },
  forCommit(
    item: RequiredPick<ResourceSCMUrl, "repoId" | "projectId" | "branch" | "path" | "commitHash"> &
      Pick<ResourceSCMUrl, "lineNumber" | "baseUrl"> &
      OrganizationResource
  ): URL {
    const normalizedItem = getNormalizedItem(item);
    const url = defaultUrlFactory(normalizedItem, "commit");
    if (!url) {
      throw new Error("Failed to generate SCM path url");
    }
    return new URL(url);
  },
  forUser(item: RequiredPick<ResourceSCMUrl, "userId"> & Pick<ResourceSCMUrl, "baseUrl"> & OrganizationResource): URL | null {
    const normalizedItem = getNormalizedItem(item);
    const url = defaultUrlFactory(normalizedItem, "user");
    if (!url) {
      return null;
    }
    return new URL(url);
  }
} as const;

export function tryGetResourceSCMUrl(item: ResourceSCMUrl, identity: Exclude<SCMLinkIdentityType, "user">, logger: { warn: (message: string) => void } = console): string | null {
  try {
    return getResourceSCMUrl(item, identity);
  } catch (e) {
    logger.warn(`tryGetResourceSCMUrl: ${Error.stringify(e)}`);
    return null;
  }
}
