import { CodeRiskFinding } from "$/sast/code-risk-finding";
import { UICodeRiskFinding } from "@/pages/risks/code/ui-code-risk-item";

/*
<div :title="item.title" class="text-truncate" style="max-width: 14vw">
  <template v-if="item.license">
    {{ item.title }} uses <template v-if="item.license"> {{ item.license }} </template>
  </template>
  <template v-else-if="item.dataType === 'SCA'"> {{ item.title }} has vulnerabilities </template>
  <template v-else-if="item.dataType === 'REPUTATION'"> {{ item.title }} has low reputation </template>
  <template v-else>
    {{ item.title }}
  </template>
</div>
*/
const GENERATORS: Record<CodeRiskFinding["dataType"], (item: CodeRiskFinding | UICodeRiskFinding) => string> = {
  IAC: (item) => item.title,
  LICENSE: (item) => ("license" in item && item.license ? `${item.title} uses the ${item.license} license type` : item.title),
  REPUTATION: (item) => `${item.title} has low reputation`,
  SCA: (item) => ("extendedDescription" in item && item.extendedDescription ? item.extendedDescription : `${item.title} has vulnerabilities`),
  SAST: (item) => item.title,
  SECRET: (item) => item.title
} as const;

export function codeRiskTitleFilter(item?: CodeRiskFinding | UICodeRiskFinding): string {
  if (!item) {
    return "";
  }
  return GENERATORS[item.dataType]?.(item) ?? item.title;
}
