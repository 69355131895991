import { ScmIntegrationCallbackHandlerBase } from "./scm-integration-callback-handler-base";
import { IntegrationType } from "$/dynamo";
import { GitHubIntegrationCallbackRequest } from "$/interfaces/ui-api/request/integrations/integration-initiation-request";
import { Integrations } from "@/api";
import { CALLBACK_MESSAGE_KEY, ICallbackMessage } from "@/interfaces/callback-message";
import { router } from "@/plugins";
import { Layout, Popup } from "@/state";

interface Args {
  code: string | null;
  installationId: number | null;
  setupAction: GitHubIntegrationCallbackRequest["setupAction"];
}

const CALLBACK_PATH = "/callback/github";
const CODE_PARAM = "code";
const INSTALLATION_ID_PARAM = "installation_id";
const SETUP_ACTION_PARAM = "setup_action";

export class GitHubCallbackHandler extends ScmIntegrationCallbackHandlerBase {
  public static get path(): string {
    return CALLBACK_PATH;
  }

  #awaitingApproval = false;
  #awaitingIntegration = false;

  protected async createIntegration(query: URLSearchParams): Promise<IntegrationType> {
    const setupAction = query.get(SETUP_ACTION_PARAM) as Args["setupAction"] | null;
    const actionHandler = setupAction && this[`on_${setupAction}`];
    if (!actionHandler) {
      throw new Error("Invalid callback state");
    }

    const code = query.get(CODE_PARAM);
    const installationId = Number.parseInt(query.get(INSTALLATION_ID_PARAM) ?? "");
    window.history.replaceState(null, "", window.location.origin);
    return await actionHandler.call(this, {
      code,
      installationId,
      setupAction
    });
  }

  protected override provideUserFeedback(): void {
    if (this.#awaitingApproval) {
      void Popup.info(`Your integration is being created. Awaiting organization owner to approve...`, { timeout: Popup.INDEFINITELY, html: true });
    } else if (this.#awaitingIntegration) {
      void Popup.warn(
        `Your integration is being created in the background...<br>
      It appears that GitHub is taking longer than expected to process,<br>
      in the meantime you can add more integrations`,
        { timeout: Popup.INDEFINITELY, html: true }
      );
    } else {
      void Popup.info(`Integration added successfully`, { timeout: Popup.INDEFINITELY, html: true });
    }
  }

  protected override async redirect(): Promise<void> {
    if (this.#awaitingApproval) {
      await router.push({ name: "loading" });
      await router.push({ name: Layout.onboarding.dismissed ? "admin-integrations" : "product-tour" });
    } else if (this.#awaitingIntegration) {
      await router.push({ name: "loading" });
      await router.push({ name: Layout.onboarding.dismissed ? "onboarding" : "product-tour" });
    } else {
      await super.redirect();
    }
  }

  private async on_install({ code, installationId }: Args): Promise<IntegrationType> {
    if (!code || !installationId || Number.isNaN(installationId)) {
      throw new Error("Invalid callback state");
    }

    const res = await Integrations.registerCallback({
      type: "github",
      code,
      installationId,
      setupAction: "install"
    });

    this.#awaitingIntegration = res?.created === false;

    if (res && res.uuid) {
      const callbackMessage: ICallbackMessage = {
        redirect: `${CALLBACK_PATH}?${SETUP_ACTION_PARAM}=postInstall&${CODE_PARAM}=${res.uuid}`
      };
      window.sessionStorage.setItem(CALLBACK_MESSAGE_KEY, JSON.stringify(callbackMessage));
    }

    return "github";
  }

  private async on_request({ code }: Args): Promise<IntegrationType> {
    if (!code) {
      throw new Error("Invalid callback state");
    }

    this.#awaitingApproval = true;

    await Integrations.registerCallback({
      type: "github",
      code,
      setupAction: "request"
    });
    return "github";
  }

  private async on_postInstall({ code }: Args): Promise<IntegrationType> {
    if (!code) {
      throw new Error("Invalid callback state");
    }

    const res = await Integrations.registerCallback({
      type: "github",
      code,
      setupAction: "postInstall"
    });

    this.#awaitingIntegration = res?.created === false;

    // if (res?.choices) {
    //     debugger;
    //     console.info("Choices:", res.choices);
    // }

    return "github";
  }
}
