import { Auth } from "./auth-state";
import Vue from "vue";
import { Tier, TierName } from "$/interfaces/ui-api/billing-tiering/tier";
import { Casing, tierName } from "@/filters";

abstract class TierBase implements Readonly<Record<TierName, boolean>> {
  readonly #state: { readonly current: Tier };

  public constructor(tierState: { readonly current: Tier }) {
    this.#state = tierState;
  }

  public abstract get free(): boolean;
  public abstract get team(): boolean;
  public abstract get business(): boolean;
  public abstract get enterprise(): boolean;

  public abstract of(...tiers: Tier[]): boolean;

  protected get current(): Tier {
    return this.#state.current;
  }
}

/** Checks for underage of current tier. */
class UnderTier extends TierBase {
  /** `true` if the current tier is lower than free */
  public get free(): boolean {
    return this.current < Tier.free;
  }

  /** `true` if the current tier is lower than team */
  public get team(): boolean {
    return this.current < Tier.team;
  }

  /** `true` if the current tier is lower than business */
  public get business(): boolean {
    return this.current < Tier.business;
  }

  /** `true` if the current tier is lower than enterprise */
  public get enterprise(): boolean {
    return this.current < Tier.enterprise;
  }

  /**
   * Check all the given tiers are lower than the current tier.
   * @param tiers The tiers to check
   */
  public of(...tiers: Tier[]): boolean {
    const current = this.current;
    return !tiers.some((tier) => tier >= current);
  }
}

/** Checks for overage of current tier. */
class OverTier extends TierBase {
  /** `true` if the current tier is higher than free */
  public get free(): boolean {
    return this.current > Tier.free;
  }

  /** `true` if the current tier is higher than team */
  public get team(): boolean {
    return this.current > Tier.team;
  }

  /** `true` if the current tier is higher than business */
  public get business(): boolean {
    return this.current > Tier.business;
  }

  /** `true` if the current tier is higher than enterprise */
  public get enterprise(): boolean {
    return this.current > Tier.enterprise;
  }

  /**
   * Check all the given tiers are higher than the current tier.
   * @param tiers The tiers to check
   */
  public of(...tiers: Tier[]): boolean {
    const current = this.current;
    return !tiers.some((tier) => tier <= current);
  }
}

/** Checks for maximal current tier. */
class MaxTier extends TierBase {
  /** `true` if the current tier is equal or lower than free */
  public get free(): boolean {
    return this.current <= Tier.free;
  }

  /** `true` if the current tier is equal or lower than team */
  public get team(): boolean {
    return this.current <= Tier.team;
  }

  /** `true` if the current tier is equal or lower than business */
  public get business(): boolean {
    return this.current <= Tier.business;
  }

  /** `true` if the current tier is equal or lower than enterprise */
  public get enterprise(): boolean {
    return this.current <= Tier.enterprise;
  }

  /**
   * Check all the given tiers are equal to or lower than the current tier.
   * @param tiers The tiers to check
   */
  public of(...tiers: Tier[]): boolean {
    const current = this.current;
    return !tiers.some((tier) => tier > current);
  }
}

/** Checks for minimal current tier. */
class MinTier extends TierBase {
  /** `true` if the current tier is equal or higher than free */
  public get free(): boolean {
    return this.current >= Tier.free;
  }

  /** `true` if the current tier is equal or higher than team */
  public get team(): boolean {
    return this.current >= Tier.team;
  }

  /** `true` if the current tier is equal or higher than business */
  public get business(): boolean {
    return this.current >= Tier.business;
  }

  /** `true` if the current tier is equal or higher than enterprise */
  public get enterprise(): boolean {
    return this.current >= Tier.enterprise;
  }

  /**
   * Check all the given tiers are equal to or higher than the current tier.
   * @param tiers The tiers to check
   */
  public of(...tiers: Tier[]): boolean {
    const current = this.current;
    return !tiers.some((tier) => tier < current);
  }
}

/** Checks equality to the current tier */
class IsTier extends TierBase {
  /** `true` if the current tier is free */
  public get free(): boolean {
    return this.current === Tier.free;
  }

  /** `true` if the current tier is team */
  public get team(): boolean {
    return this.current === Tier.team;
  }

  /** `true` if the current tier is business */
  public get business(): boolean {
    return this.current === Tier.business;
  }

  /** `true` if the current tier is enterprise */
  public get enterprise(): boolean {
    return this.current === Tier.enterprise;
  }

  /**
   * Check all the given tiers are equal to the current tier.
   * @param tiers The tiers to check
   */
  public of(...tiers: Tier[]): boolean {
    const current = this.current;
    return !tiers.some((tier) => tier !== current);
  }
}

/**
 * @example How to use:
 * ```ts
 * $tier.is.business // -> `true` if current tier is `business`
 * $tier.min.business // -> `true` if current tier is at LEAST `business` (i.e. `business`, `enterprise`)
 * $tier.max.business // -> `true` if current tier is at MOST `business` (i.e. `free`, `teams`, `business`)
 * $tier.over.business // -> `true` if current tier is OVER `business` (i.e. `enterprise`)
 * $tier.under.business // -> `true` if current tier is UNDER `business` (i.e. `free`, `teams`)
 * ```
 * ```html
 * <span v-if="$tier.is.business">Tier: Business</span>
 * <span v-else>Tier: not business</span>
 * <span v-if="$tier.min.business">Tier: at least business</span>
 * <span>Tier: {{ $tier.upper }}</span>
 * ```
 */
class TierState implements Record<Casing, string> {
  /** Checks for underage of current tier. */
  public readonly under: UnderTier;

  /** Checks for overage of current tier. */
  public readonly over: OverTier;

  /** Checks for maximal current tier. */
  public readonly max: MaxTier;

  /** Checks for minimal current tier. */
  public readonly min: MinTier;

  /** Checks equality to the current tier */
  public readonly is: IsTier;

  public constructor() {
    this.is = new IsTier(this);
    this.min = new MinTier(this);
    this.max = new MaxTier(this);
    this.over = new OverTier(this);
    this.under = new UnderTier(this);
    Vue.observable(this);
  }

  public override: Tier | null = null; // TODO: remove

  /**
   * Gets all the tiers.
   */
  public get all(): typeof Tier {
    return Tier;
  }

  /**
   * Gets the current tier.
   */
  public get current(): Tier {
    if (!import.meta.env.PROD) {
      // TODO: remove
      if (typeof this.override === "number") {
        return this.override;
      }
    }
    return Auth.user?.userInfo?.org?.tier ?? Tier.free;
  }

  /**
   * Gets the current tier name (default case).
   */
  public get name(): string {
    return tierName(this.current);
  }

  /**
   * Gets the current tier name as upper-case.
   */
  public get upper(): string {
    return tierName(this.current, "upper");
  }

  /**
   * Gets the current tier name as lower-case.
   */
  public get lower(): string {
    return tierName(this.current, "lower");
  }

  /**
   * Gets the current tier name as title-case.
   */
  public get title(): string {
    return tierName(this.current, "title");
  }

  /**
   * Gets the current tier name as provided casing.
   * @param casing Casing of the tier name, default is unchanged.
   * @returns Tier name
   */
  public as(casing: Casing): string {
    return tierName(this.current, casing);
  }
}

const tierState = new TierState();
export { tierState as Tier };
