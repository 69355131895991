import Vue from "vue";
import * as Api from "@/api";

/**
 * Augment the typings of Vue.js
 */
declare module "vue/types/vue" {
  interface Vue {
    $api: typeof Api;
  }

  interface VueConstructor {
    api: typeof Api;
  }
}

Vue.use({
  install() {
    Vue.prototype.$api = Api;
    Vue.api = Api;
  }
});
