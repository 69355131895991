import { AxiosRequestConfig } from "axios";
import { FindingsHistory } from "$/finding-types";
import { ApiBase } from "@/api/api-base";

class FindingsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "risk/findings", name: "FindingsApi" });
  }

  public async getFindingHistory(sortKey: string, silent = false): Promise<FindingsHistory[]> {
    const config: AxiosRequestConfig = {
      silent
    };
    const res = await this.client.get<FindingsHistory[]>(`${sortKey.encodeURI()}/history`, config);
    const history = res.data ?? [];
    return history;
  }
}

export const Findings = new FindingsApi();
