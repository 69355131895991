import { SCMType, UNIVERSAL_SCM_TYPE } from "../../../scm-type";
import { CredentialsType } from "../credentials";
import type { ScmIntegrationType, UniversalScmIntegrationType } from "../integration";
import type { IntegrationAttributes } from "./integration-attributes";

/** SCM Integration Attributes */
export interface ScmIntegrationAttributes extends IntegrationAttributes {
  /**
   * How to reference the organization in the UI.
   * @example "Organization" for GitHub, "Workspace" for Bitbucket Cloud.
   */
  organizationLabel: string;
  type: ScmIntegrationType;
  category: "source-control";
  /** The integration supports a project hierarchy. */
  project: {
    isSupported: boolean;
    isRequired: boolean;
  };
  scmType: SCMType;
  hasJobs: true;
  /**
   * Supports recording status checks
   */
  supportsRecordingStatusChecks: boolean;
  /**
   * Supports ChatOps over PR comments
   */
  supportsPrChatOps: boolean;
  ui: IntegrationAttributes["ui"] & {
    showBranchRestrictions: boolean;
    /**
     * above this number we'll show `> number`
     */
    cutOffDaysSinceLastAction: number;
  };
}

/** Universal SCM Integration Attributes */
export interface UniversalScmIntegrationAttributes extends ScmIntegrationAttributes {
  type: UniversalScmIntegrationType;
  scmType: UNIVERSAL_SCM_TYPE;
  defaultBaseUrl: string | undefined;
  ui: ScmIntegrationAttributes["ui"] & {
    showIfUserIsServiceAccount: boolean;
    defaultCredType: CredentialsType;
    credentialLabels: Record<CredentialsType, string>;
    allowIntegratingWithAnAccessToken: boolean;
    cutOffDaysSinceLastAction: number;
    baseUrl: {
      requirement: BaseUrlRequirement;
      default?: string;
    };
  };
}

export enum BaseUrlRequirement {
  "required" = "required",
  "optional" = "optional",
  "not_supported" = "not_supported"
}

export const ScmToIntegrationType = {
  [SCMType.AZURE_DEVOPS]: "azure-devops",
  [SCMType.BITBUCKET_CLOUD]: "bitbucket-cloud",
  [SCMType.BITBUCKET_SERVER]: "bitbucket-server",
  [SCMType.GITHUB]: "github",
  [SCMType.GITLAB]: "gitlab"
} as const satisfies Record<SCMType, ScmIntegrationType>;

const UniversalScmIntegrationAttributes = {
  "bitbucket-cloud": {
    type: "bitbucket-cloud",
    category: "source-control",
    displayName: "Bitbucket Cloud",
    organizationLabel: "Workspace",
    scmType: SCMType.BITBUCKET_CLOUD,
    project: {
      isSupported: true,
      isRequired: true
    },
    hasJobs: true,
    includeMetadata: false,
    defaultBaseUrl: undefined,
    supportsRecordingStatusChecks: true,
    supportsPrChatOps: false,
    ui: {
      icon: "mdi-bitbucket",
      reactIcon: "mdi:bitbucket",
      editable: false,
      removable: true,
      showIfUserIsServiceAccount: true,
      showBranchRestrictions: true,
      defaultCredType: CredentialsType.login,
      allowIntegratingWithAnAccessToken: false, // TODO https://github.com/arnica-internal/arnica/issues/3782
      cutOffDaysSinceLastAction: 90,
      credentialLabels: {
        [CredentialsType.access_token]: "Access Token",
        [CredentialsType.login]: "App Password"
      },
      baseUrl: {
        requirement: BaseUrlRequirement.not_supported
      }
    }
  },
  "bitbucket-server": {
    type: "bitbucket-server",
    category: "source-control",
    displayName: "Bitbucket Server",
    organizationLabel: "Host",
    scmType: SCMType.BITBUCKET_SERVER,
    project: {
      isSupported: true,
      isRequired: true
    },
    hasJobs: true,
    includeMetadata: false,
    defaultBaseUrl: undefined,
    supportsRecordingStatusChecks: true,
    supportsPrChatOps: false,
    ui: {
      icon: "mdi-bitbucket",
      reactIcon: "mdi:bitbucket",
      editable: false,
      removable: true,
      showIfUserIsServiceAccount: true,
      showBranchRestrictions: true,
      defaultCredType: CredentialsType.access_token,
      allowIntegratingWithAnAccessToken: true,
      cutOffDaysSinceLastAction: 90,
      credentialLabels: {
        [CredentialsType.access_token]: "Access Token",
        [CredentialsType.login]: "Login"
      },
      baseUrl: {
        requirement: BaseUrlRequirement.required
      }
    }
  },
  gitlab: {
    type: "gitlab",
    category: "source-control",
    displayName: "GitLab",
    organizationLabel: "Group",
    scmType: SCMType.GITLAB,
    project: {
      isSupported: true,
      isRequired: false
    },
    hasJobs: true,
    includeMetadata: false,
    defaultBaseUrl: "https://gitlab.org",
    supportsRecordingStatusChecks: true,
    supportsPrChatOps: false,
    ui: {
      icon: "mdi-gitlab",
      reactIcon: "mdi:gitlab",
      editable: false,
      removable: true,
      showIfUserIsServiceAccount: true,
      showBranchRestrictions: true,
      defaultCredType: CredentialsType.login,
      allowIntegratingWithAnAccessToken: true,
      cutOffDaysSinceLastAction: 90,
      credentialLabels: {
        [CredentialsType.access_token]: "Access Token",
        [CredentialsType.login]: "Login"
      },
      baseUrl: {
        requirement: BaseUrlRequirement.optional,
        default: "gitlab.com"
      }
    }
  },
  "azure-devops": {
    type: "azure-devops",
    category: "source-control",
    displayName: "Azure DevOps",
    organizationLabel: "Organization",
    scmType: SCMType.AZURE_DEVOPS,
    project: {
      isSupported: true,
      isRequired: true
    },
    hasJobs: true,
    includeMetadata: false,
    defaultBaseUrl: undefined,
    supportsRecordingStatusChecks: false,
    supportsPrChatOps: true,
    ui: {
      icon: "mdi-microsoft-azure-devops",
      reactIcon: "mdi:microsoft-azure-devops",
      editable: true,
      removable: true,
      editTooltip: `Click here to update the integration, this will take you to the Azure DevOps page where you can click "Manage authorizations" on the bottom left corner`,
      showBranchRestrictions: true,
      cutOffDaysSinceLastAction: 180,
      showIfUserIsServiceAccount: true,
      defaultCredType: CredentialsType.login,
      allowIntegratingWithAnAccessToken: false,
      credentialLabels: {
        [CredentialsType.access_token]: "Access Token",
        [CredentialsType.login]: "Login"
      },
      baseUrl: {
        requirement: BaseUrlRequirement.not_supported
      }
    }
  },
  github: {
    type: "github",
    category: "source-control",
    displayName: "GitHub",
    organizationLabel: "Organization",
    scmType: SCMType.GITHUB,
    project: {
      isSupported: false,
      isRequired: false
    },
    hasJobs: true,
    includeMetadata: true,
    defaultBaseUrl: undefined,
    supportsRecordingStatusChecks: true,
    supportsPrChatOps: true,
    ui: {
      icon: "mdi-github",
      reactIcon: "mdi:github",
      editable: true,
      removable: true,
      editTooltip: "Click here to change your integration settings with GitHub",
      showBranchRestrictions: true,
      cutOffDaysSinceLastAction: 90,
      showIfUserIsServiceAccount: false,
      allowIntegratingWithAnAccessToken: false,
      defaultCredType: CredentialsType.login,
      credentialLabels: {
        [CredentialsType.access_token]: "Access Token",
        [CredentialsType.login]: "Login"
      },
      baseUrl: {
        requirement: BaseUrlRequirement.not_supported
      }
    }
  }
} as const satisfies Record<UniversalScmIntegrationType, UniversalScmIntegrationAttributes>;

export const ScmIntegrationAttributes = {
  ...UniversalScmIntegrationAttributes
} as const satisfies Record<ScmIntegrationType, ScmIntegrationAttributes>;
