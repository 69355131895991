/**
 * Augment the typings of Vue.js
 */
import Vue from "vue";
import { Tier } from "@/state/tier-state";

declare module "vue/types/vue" {
  interface Vue {
    $tier: typeof Tier;
  }

  interface VueConstructor {
    tier: typeof Tier;
  }
}

Vue.use({
  install() {
    Vue.prototype.$tier = Tier;
    Vue.tier = Tier;
  }
});
