import moment, { Duration } from "moment";

declare module "moment" {
  interface Duration {
    /**
     * Converts the duration to a human readable string.
     * @example "1y2m3d 4h5m6.78s"
     */
    toReadable(): string;
  }
}

const prototype = Object.getPrototypeOf(moment.duration()) as Duration;

prototype.toReadable ||= function toReadable(this: Duration): string {
  if (this.asMilliseconds() < 0.5) return "0h0m0s";
  const parts: string[] = [];

  this.years() && parts.push(`${this.years()}y`);
  (parts.length || this.months()) && parts.push(`${this.months()}m`);
  (parts.length || this.days()) && parts.push(`${this.days()}d`);

  parts.length && parts.push(" ");

  parts.push(`${this.hours()}h`);
  parts.push(`${this.minutes()}m`);
  const seconds = Number.parseFloat((this.seconds() + Math.round(this.milliseconds()) / 1000).toFixed(3));
  parts.push(`${seconds}s`);

  return parts.join("").trim();
};
