import { ApiBase } from "./api-base";
import { Cache } from "$/utility-types/cache";
import moment from "moment";

class SecretsApi extends ApiBase {
  readonly #cache = new Cache<"types", string[]>({ ttlSeconds: moment.duration(5, "minutes").asSeconds() });

  public constructor() {
    super({ pathPrefix: "risks/secrets", name: "SecretsApi" });
  }

  public async getTypes(): Promise<string[]> {
    return await this.#cache
      .getOrResolve("types", undefined, async () => {
        const res = await this.client.get<string[]>("types");
        return res.data;
      })
      .autoHandleError([]);
  }
}

export const Secrets = new SecretsApi();
