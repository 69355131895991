export const MAX_ORG_ID_SIZE = 256;
export const MAX_ORG_NAME_SIZE = 100;

// Allows characters of capital A thru Z and lowercase a thru z and number digits and underscore, space, or dash.
export const ARNICA_ORG_NAME_REGEX = /^[A-Za-z _\-0-9\\.]*$/;
export const ARNICA_ORG_ID_REGEX = /^[a-z][a-z\-0-9]*$/;

export const orgNameRules = [
  (v: string): string | boolean => !!v || "Organization name is required",
  (v: string): string | boolean => (v && v.length <= MAX_ORG_NAME_SIZE) || `Organization name must be less than ${MAX_ORG_NAME_SIZE} characters`,
  (v: string): string | boolean => (v && v.length > 2) || "Organization name must be more than 2 characters",
  (v: string): string | boolean =>
    (v && ARNICA_ORG_NAME_REGEX.test(v)) || "Organization name must use only uppercase, lowercase, numbers, spaces ( ), underscore (_), dashes (-) and periods (.)"
];

export const orgIdRules = [
  (v: string): string | boolean => !!v || "Organization id is required",
  (v: string): string | boolean => (v && v.length <= MAX_ORG_ID_SIZE) || `Organization id must be less than ${MAX_ORG_ID_SIZE} characters`,
  (v: string): string | boolean => (v && v.length > 2) || "Organization id must be more than 2 characters",
  (v: string): string | boolean => (v && ARNICA_ORG_ID_REGEX.test(v)) || "Organization id must use only lowercase characters, numbers and dashes"
];

export function validateOrgName(orgName: string) {
  for (const rule of orgNameRules) {
    const result = rule(orgName);
    if (typeof result === "boolean" && !result) {
      throw new Error("Invalid orgName format");
    }
    if (typeof result === "string") {
      throw new Error("Invalid orgName format: " + result);
    }
  }
}
