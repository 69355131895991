import moment from "moment";
import Vue from "vue";

//TODO: enable after review
// moment.locale('en', {
//   relativeTime: {
//     future: 'in %s',
//     past: '%s ago',
//     s:  '1s',
//     ss: '%ss',
//     m:  '1m',
//     mm: '%dm',
//     h:  '1h',
//     hh: '%dh',
//     d:  '1d',
//     dd: '%dd',
//     M:  '1M',
//     MM: '%dM',
//     y:  '1Y',
//     yy: '%dY'
//   }
// });

// @spellchecker: disable-next-line
//TODO: we should use locale time format, not a hardcoded one for display.
export function dateMMMfilter(date: string | number | Date | moment.Moment | null | undefined): string {
  if (!date) {
    return "";
  }
  return moment(date).format("MMM DD, YYYY HH:mm:ss");
}

/**
 * Formats a date to a local string.
 * @param value The date to format.
 * @returns Formatted date (local string) or "-" if the date is null.
 */
export function dateTimeFilter(value: string | number | Date | null): string {
  if (value === null) {
    return "-";
  }
  const date = new Date(value);
  return Number.isNaN(date.getTime()) ? "Invalid" : date.toLocaleString();
}

export function dateTimeFormatFilter(value: string | number | null, format: string): string {
  if (value === null) {
    return "-";
  }
  const date = moment(value);
  return date.isValid() ? date.format(format) : "-";
}

export function dateFromNowFilter(value: string | number | Date | moment.Moment): string {
  return moment(value).fromNow();
}

export function durationHumanizeFilter(value: number): string {
  return moment.duration(value, "millisecond").humanize();
}
Vue.filter("dateMMM", dateMMMfilter);
Vue.filter("dateTime", dateTimeFilter);
Vue.filter("dateTimeFormat", dateTimeFormatFilter);
Vue.filter("dateFromNow", dateFromNowFilter);
Vue.filter("humanizeMillis", durationHumanizeFilter);
