import { Integrations } from "./integrations-state";
import Vue from "vue";
import { Filters } from "$/interfaces/ui-api/response/get-filters-response";
import { GetSecretsReportResponse } from "$/interfaces/ui-api/response/get-secrets-response";
import { Reports } from "@/api";
import { SECRETS_DEFAULT_FILTERS } from "@/pages/risks/secrets/secrets-constants";
import { LocalStorageItem } from "@/utils/local-storage-item";

export const SecretsReport = new (class SecretsReportState {
  private readonly filterStorage = new LocalStorageItem<Filters>("secrets-filters");
  public secretsReport: GetSecretsReportResponse | null = null;

  public constructor() {
    Vue.observable(this);
  }

  /**
   * List of the columns that can be filtered
   */
  public get filters(): Filters {
    return {
      // we do this here in case we change the defaults (e.g. add a new filter) but users already have preferences saved without them.
      ...SECRETS_DEFAULT_FILTERS,
      ...this.filterStorage.item
    };
  }
  public set filters(filters: Filters) {
    this.filterStorage.item = filters;
  }

  public resetFilters() {
    this.filterStorage.item = null;
    return this.filters;
  }

  public async init() {
    await Integrations.init();
    const items = await Reports.get("secrets");
    for (const finding of items?.findings ?? []) {
      finding.org = Integrations.getOrganizationName(finding);
    }
    this.secretsReport = items;
  }
})();
