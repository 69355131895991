import {
  GithubMitigation,
  GithubMitigationAction,
  generateGithubResourceId,
  getUsersKeepingPermissions,
  isCompletedMitigation,
  sumExcessivePermissionsInMitigation,
  sumValidPermissionsInMitigation
} from "../dynamo";
import { GitHubRiskReport, GitHubRiskReportIdentityItem, GitHubRiskReportQueryType } from "../report/risk-report/risk-report";

export class GitHubMitigationUtils {
  //There can only be 1 mitigation per resource for each ingestion
  public static generateGithubMitigationsMapFromCompletedMitigationItemsAfterIngestion(mitigations: GithubMitigation[]): Map<string, GithubMitigation> {
    const mitigationsMap = new Map<string, GithubMitigation>();
    mitigations.forEach((mitigation) => {
      const resourceId = generateGithubResourceId(mitigation.integrationOrgId, mitigation.repoName, mitigation.branchName);
      mitigationsMap.set(resourceId, mitigation);
    });
    return mitigationsMap;
  }
  public static removeMitigatedGithubResourcesFromRisksIfNoNewIngestionRunYet(report: GitHubRiskReport, mitigations: GithubMitigation[]) {
    const mitigationsAfterMostRecentReport = mitigations.filter((mitigation) => new Date(mitigation.createdAt) > new Date(report.summary.dateGenerated));
    const mitigationsAfterMostRecentReportForReportType = mitigationsAfterMostRecentReport.filter((mitigation) => mitigation.typeOfPermissionFixed === report.summary.queryType);
    const mitigationsMap = this.generateGithubMitigationsMapFromCompletedMitigationItemsAfterIngestion(mitigationsAfterMostRecentReportForReportType);
    /*
      If an excessive permission has both excessive direct permissions and excessive teams permissions,
      this shows up as multiple report item rows. However, each mitigation already has information encompassing
      all the identities and teams within it. Therefore, only the first time a report item is found matching mitigations
      is there a need to adjust the total permission and excessive permission values and determine if the item should be removed.
      This map keeps track of these resources to determine if it should be removed.
    */
    const resourceIdsAlreadyAdjusted = new Map<string, boolean>();
    report.reportItems = (report.reportItems as GitHubRiskReportIdentityItem[]).filter((reportItem) => {
      if (!reportItem.identities || !reportItem.asset) {
        //Should never happen, but if it does these items will be removed.
        return false;
      }
      const resourceId = generateGithubResourceId(reportItem.asset.org, reportItem.asset.repo, reportItem.asset.branch);
      if (!mitigationsMap.has(resourceId)) {
        //No mitigations found for the report item
        return true;
      } else {
        if (resourceIdsAlreadyAdjusted.has(resourceId)) {
          return resourceIdsAlreadyAdjusted.get(resourceId)!;
        }
        const mitigation = mitigationsMap.get(resourceId)!;
        if (isCompletedMitigation(mitigation)) {
          report.summary.excessiveNumberOfPermissions -= sumExcessivePermissionsInMitigation(mitigation.usersKeepingLosingPermissions);
          report.summary.totalNumberOfPermissions -= sumExcessivePermissionsInMitigation(mitigation.usersKeepingLosingPermissions);
          if (report.summary.queryType === GitHubRiskReportQueryType.GHWRITE && mitigation.action === GithubMitigationAction.CREATE_CODEOWNERS) {
            report.summary.totalNumberOfPermissions -= getUsersKeepingPermissions(mitigation.usersKeepingLosingPermissions).length;
          }
          //Item should be removed as all mitigations are complete
          resourceIdsAlreadyAdjusted.set(resourceId, false);
          return false;
        } else {
          resourceIdsAlreadyAdjusted.set(resourceId, true);
          return true;
        }
      }
    });
    //For the excessive Create CodeOwners Mitigation, the valid user permissions become valid CodeOwners permissions
    if (report.summary.queryType === GitHubRiskReportQueryType.GHCODEOWNERS) {
      for (const mitigation of mitigationsAfterMostRecentReport) {
        if (
          isCompletedMitigation(mitigation) &&
          mitigation.typeOfPermissionFixed === GitHubRiskReportQueryType.GHWRITE &&
          (mitigation.action === GithubMitigationAction.CREATE_CODEOWNERS || mitigation.action === GithubMitigationAction.ENABLE_AND_MODIFY_CODEOWNERS)
        ) {
          report.summary.totalNumberOfPermissions += sumValidPermissionsInMitigation(mitigation.usersKeepingLosingPermissions);
        }
      }
    }
  }
}
