import { ApiBase } from "./api-base";
import Axios, { AxiosResponse } from "axios";
import { GetSignedUrlRequest } from "$/interfaces/ui-api/request";
import { GetSignedUrlResponse } from "$/interfaces/ui-api/response/get-signed-url-response";

class UploadsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "uploads", name: "UploadsApi" });
  }

  protected override followSignedUrlDownload(response: AxiosResponse<unknown>): Promise<AxiosResponse<unknown>> {
    return Promise.resolve(response);
  }

  private async getGitHubAuditsPreSignedURL(request: GetSignedUrlRequest): Promise<GetSignedUrlResponse> {
    // TODO: make sure that .json.gz is handled
    // TODO: if git events, handle merging
    // TODO: authorization header usage?

    try {
      const res = await this.client.post<GetSignedUrlResponse>(`github/audits`, request);
      return res.data;
    } catch (err) {
      console.error(`getGitHubAuditsPreSignedURL`, request, err);
      throw err;
    }
  }

  public async file(url: string, file: File): Promise<unknown> {
    const res = await Axios.put(url, file);
    return res.data;
  }

  public async gitHubAudits(request: GetSignedUrlRequest, file: File): Promise<unknown> {
    const { signedUrl } = await this.getGitHubAuditsPreSignedURL(request);
    if (!signedUrl) {
      return;
    }
    return await this.file(signedUrl, file);
  }
}

export const Uploads = new UploadsApi();
