// @spellchecker: ignore vnode
import Vue, { VNode } from "vue";
import type { DirectiveBinding } from "vue/types/options";

interface TooltipHandlers {
  enter(ev: MouseEvent): void;
  leave(ev: MouseEvent): void;
  update(binding: DirectiveBinding): void;
}

export interface TooltipDirectiveConfigurations {
  /**
   * Delay in milliseconds before hiding the tooltip after mouse leave.
   */
  off?: number;

  /**
   * Delay in milliseconds before showing the tooltip after mouse enter.
   */
  on?: number;

  /**
   * Left offset in pixels.
   */
  left?: number;

  /**
   * Top offset in pixels.
   */
  top?: number;

  /**
   * Text alignment.
   */
  align?: "left" | "right" | "center";

  /**
   * Denotes if the tooltip content is HTML.
   */
  html?: boolean;
}

const DEFAULTS: Readonly<Required<TooltipDirectiveConfigurations>> = {
  off: 500,
  on: 50,
  left: 0,
  top: -5,
  align: "center",
  html: false
} as const;

const KEY_RESOLVER: Readonly<Record<keyof TooltipDirectiveConfigurations, (value: string | undefined) => TooltipDirectiveConfigurations[keyof TooltipDirectiveConfigurations]>> = {
  off(value) {
    return value ? Number.parseInt(value, 10) : DEFAULTS.off;
  },
  on(value) {
    return value ? Number.parseInt(value, 10) : DEFAULTS.on;
  },
  left(value) {
    return value ? Number.parseInt(value, 10) : DEFAULTS.left;
  },
  top(value) {
    return value ? Number.parseInt(value, 10) : DEFAULTS.top;
  },
  align(value) {
    return typeof value === "string" ? (value as TooltipDirectiveConfigurations["align"]) : DEFAULTS.align;
  },
  html(value) {
    return value !== "false";
  }
} as const;

class TooltipDirective {
  private static handlers = new WeakMap<HTMLElement, TooltipHandlers>();

  private static resolveConfigurations(binding: DirectiveBinding): Required<TooltipDirectiveConfigurations> {
    const keys = Object.keys(binding.modifiers).concat(binding.arg || "") as string[];
    const entries = keys
      .map((key) => key.split(":") as [keyof TooltipDirectiveConfigurations, string | undefined])
      .map(
        ([key, value]) =>
          [key, KEY_RESOLVER[key]?.(value)] as [keyof TooltipDirectiveConfigurations, Required<TooltipDirectiveConfigurations>[keyof TooltipDirectiveConfigurations]]
      );
    const config = Object.fromEntries(entries) as Required<TooltipDirectiveConfigurations>;
    return {
      ...DEFAULTS,
      ...config
    };
  }

  public static componentUpdated(el: HTMLElement, binding: DirectiveBinding): void {
    TooltipDirective.handlers.get(el)?.update(binding);
  }

  public static bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode, oldVnode: VNode): void {
    const root = vnode.context?.$root;
    if (!root) {
      console.warn("TooltipDirective: no root");
      return;
    }

    const config = TooltipDirective.resolveConfigurations(binding);
    const handlers = {
      enter(ev: MouseEvent) {
        root.$emit("tooltip.enter", el, binding, vnode, oldVnode, config, ev);
      },
      leave(ev: MouseEvent) {
        root.$emit("tooltip.leave", el, binding, vnode, oldVnode, config, ev);
      },
      update(newBinding: DirectiveBinding) {
        binding = newBinding;
      }
    };
    el.addEventListener("mouseenter", handlers.enter);
    el.addEventListener("mouseleave", handlers.leave);
    TooltipDirective.handlers.set(el, handlers);
  }

  public static unbind(el: HTMLElement): void {
    const handlers = TooltipDirective.handlers.get(el);
    if (!handlers) return;
    el.removeEventListener("mouseenter", handlers.enter);
    el.removeEventListener("mouseleave", handlers.leave);
    TooltipDirective.handlers.delete(el);
  }

  // public static update(
  //   el: HTMLElement,
  //   binding: DirectiveBinding,
  //   vnode: VNode,
  //   oldVnode: VNode
  // ): void {

  //   debugger;
  // }
}

Vue.directive("tooltip", {
  bind: TooltipDirective.bind,
  // update: TooltipDirective.update,
  unbind: TooltipDirective.unbind,
  componentUpdated: TooltipDirective.componentUpdated
});
