import { CallbackHandlerBase } from "./callback-handler-base";
import { IntegrationType } from "$/dynamo";
import { Integrations as Api } from "@/api";
import { CALLBACK_MESSAGE_KEY, ICallbackMessage } from "@/interfaces/callback-message";
import { router } from "@/plugins";
import { Auth, Integrations, Popup } from "@/state";

interface Args {
  code: string | null;
  action: "install" | "postInstall";
}

const CALLBACK_PATH = "/callback/msteams";
const CODE_PARAM = "code";
const ACTION_PARAM = "action";

export class MsTeamsCallbackHandler extends CallbackHandlerBase {
  public static get path(): string {
    return CALLBACK_PATH;
  }

  protected provideUserFeedback(): void {
    void Popup.info("Integration added successfully");
  }

  public async handle(query: URLSearchParams): Promise<void> {
    try {
      const integrationType = await this.createIntegration(query);

      Auth.authenticated && (await Integrations.init());

      this.sendToAnalytics(integrationType);

      await this.redirect();

      this.provideUserFeedback();
    } catch (e) {
      await this.handleIntegrationError(e);
    }
  }

  private async createIntegration(query: URLSearchParams): Promise<IntegrationType> {
    const action = query.get(ACTION_PARAM) as Args["action"] | null;
    const actionHandler = action && this[`on_${action}`];
    if (!actionHandler) {
      throw new Error("Invalid callback");
    }

    const code = query.get(CODE_PARAM);
    window.history.replaceState(null, "", window.location.origin);
    return await actionHandler.call(this, {
      code,
      action
    });
  }

  private async redirect(): Promise<void> {
    window.history.replaceState(null, "", window.location.origin);
    await router.push({ name: "loading" });
    try {
      await router.push({ name: Integrations.hasAtLeastOneSourceControlIntegration ? "admin-integrations" : "home", ignoreRedirectError: true });
    } catch (error) {
      console.warn("Failed to redirect", error);
    }
  }

  private async on_install({ code, action }: Args): Promise<IntegrationType> {
    if (!code) {
      throw new Error("Invalid callback");
    }
    if (Auth.authenticated) {
      return await this.on_postInstall({ code, action });
    } else {
      this.saveCallbackMessage(code);
    }
    return "msteams";
  }

  private async on_postInstall({ code }: Args): Promise<IntegrationType> {
    if (!code) {
      throw new Error("Invalid callback");
    }

    if (!Auth.authenticated) {
      this.saveCallbackMessage(code);
      await router.replace({ name: "login", ignoreRedirectError: true });
    } else {
      await Api.create({ type: "msteams", code });
    }

    return "msteams";
  }

  private saveCallbackMessage(code: string): void {
    const callbackMessage: ICallbackMessage = {
      redirect: `${CALLBACK_PATH}?${ACTION_PARAM}=postInstall&${CODE_PARAM}=${code}`
    };
    window.sessionStorage.setItem(CALLBACK_MESSAGE_KEY, JSON.stringify(callbackMessage));
  }

  // public async slackHandle(query: URLSearchParams): Promise<void> {
  //   try {
  //     const code = query.get(CODE_PARAM);
  //     const action = query.get(ACTION_PARAM);

  //     if (!code || !action) {
  //       throw new Error("Invalid callback");
  //     }

  //     await Api.create({ type: "msteams", code });

  //     await Integrations.init();

  //     this.provideUserFeedback();

  //     this.sendToAnalytics("msteams");

  //     window.history.replaceState(null, "", window.location.origin);
  //     await router.push({ name: Integrations.hasAtLeastOneSourceControlIntegration ? "admin-integrations" : "home" });
  //   } catch (e) {
  //     window.history.replaceState(null, "", window.location.origin);
  //     await this.handleIntegrationError(e);
  //   }
  // }
}
