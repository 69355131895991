import Vue from "vue";
import Router, { NavigationGuardNext, Route } from "vue-router";
import { ScopeDirective } from "@/directives/scope-directive";
import { Scope } from "@/interfaces/scope";
import { Auth, Popup } from "@/state";

declare module "vue-router/types/router" {
  export interface RouteMeta {
    /**
     * Indicates a route user scope configuration.
     * @optional default (not specified) is available only for admins and owners.
     */
    scopes?: Scope[] | ((scopes: PartialRecord<Scope, true | undefined>) => boolean);
  }
}

function isAllowed(route: Route): boolean {
  return Auth.isArnicaAdmin || hasRequiredScopes(Auth.scopes, route.meta?.scopes);
}

export function hasRequiredScopes(
  availableScopes: PartialRecord<Scope, true | undefined>,
  requiredScopes?: Scope[] | ((scopes: PartialRecord<Scope, true | undefined>) => boolean)
): boolean {
  switch (typeof requiredScopes) {
    case "function":
      return requiredScopes(availableScopes);
    case "object":
      return ScopeDirective.hasAll(availableScopes, requiredScopes);
    case "undefined":
      return false;
    default:
      throw new Error("Invalid route scopes");
  }
}

const REDIRECT_LOCAL_STORAGE_KEY = "redirect";

//TODO: do we even need this? hopefully vue router has this protection already
function safeRedirect<V extends Vue = Vue>(next: NavigationGuardNext<V>, to: Route, name: string, params?: { [key: string]: string }): void {
  if (to.name === name) {
    console.log(`User already redirecting to the ${name} page, continue`);
    debugger;
    return next();
  }
  return next({ name, params });
}

async function scopedRoutes<V extends Vue = Vue>(to: Route, from: Route, next: NavigationGuardNext<V>): Promise<void> {
  if (to.name === "error") {
    return next();
  }
  await Auth.init();
  try {
    if (isAllowed(to)) {
      return loadRedirect(next);
    }

    console.warn(`The \`${to.meta?.title ?? to.name ?? to.path}\` page is not available for the user`);

    // if the user is simply not authenticated, we should redirect to the login page, and save the URL they were trying to access
    if (!Auth.scopes.authenticated) {
      saveRedirect();
      return safeRedirect(next, to, "login");
    }

    //TODO: should we show the user a 403 page with a timed redirect to home? Or at least a popup?

    // this is when navigating from an existing page (including pasting a URL in an existing allowed tab)
    // the isAllowed(from) is more for sanity and since they can't be in the "from" if not allowed, isAllowed(from) should always be true
    // but just in case... (same with the ? in from?.name, it should never be falsy, but just in case)
    if (from?.name && isAllowed(from)) {
      console.debug(`Staying in the current (${from.name}) page`);
      return next(false);
    }

    // this is when there is no "from" page (e.g. a new tab pasting a link, or clicking a link with target _blank or outside of arnica)
    console.debug(`Redirecting to the home page`);

    return safeRedirect(next, to, "forbidden", { originalPath: to.path, originalName: to.name || "", originalTitle: to.meta?.title || ""});
  } catch (e) {
    if (e instanceof Error) {
      await Popup.error(e.message);
    }
  }
}

function saveRedirect() {
  const currentHash = window.location.hash;
  localStorage.setItem(REDIRECT_LOCAL_STORAGE_KEY, JSON.stringify({ hash: currentHash }));
}

function loadRedirect<V extends Vue = Vue>(next: NavigationGuardNext<V>) {
  try {
    // this if is to prevent a refresh on the login page, a magic link token (which is only allowed to be on one page), or any unauthenticated page, from storing the redirect after login location
    // e.g. if a user refreshes the login page, we want to not override the redirect location etc.
    // NOTE: magic link users are authenticated, hance we check for signedToken (which is only available for magic link logins)
    if (!Auth.scopes.authenticated  || Auth.signedToken) {
      return next();
    }
    const redirectValue = localStorage.getItem(REDIRECT_LOCAL_STORAGE_KEY);
    // if there is no redirect data, this is just normal behavior from a regular sign in
    if (!redirectValue) {
      return next();
    }

    const { hash } = JSON.parse(redirectValue);
    const pathFromHash = hash ? hash.replace(/^#/, "") : "";
    localStorage.removeItem(REDIRECT_LOCAL_STORAGE_KEY);
    return next({ path: pathFromHash });
  } catch (e) {
    console.error(`Error loading redirect`, e);
    return next();
  }
}

export function install(router: Router): Router {
  router.beforeEach(scopedRoutes);
  return router;
}
