import { PolicyItemType } from "../policy-item-base";
import { PolicyTrigger, PolicyTriggerSubType } from "./policy-trigger";

declare module "./policy-trigger" {
  enum PolicyTriggerSubType {
    user_create_issue = "user_create_issue"
  }
}

(PolicyTriggerSubType as { user_create_issue: "user_create_issue" }).user_create_issue = "user_create_issue";

export interface PolicyTriggerUserCreateIssue extends PolicyTrigger {
  sub: PolicyTriggerSubType.user_create_issue;
}

export const PolicyTriggerUserCreateIssue = {
  component: {
    type: PolicyItemType.trigger,
    sub: PolicyTriggerSubType.user_create_issue
  }
} as const;
