import { PolicyItemType } from "../policy-item-base";
import { PolicyItem, PolicySubType } from "./policy-item";

declare module "./policy-item" {
  enum PolicySubType {
    permissions = "permissions"
  }

  interface PolicySubTypeMapper {
    [PolicySubType.permissions]: PolicyItemPermissions;
  }
}

(PolicySubType as { permissions: "permissions" }).permissions = "permissions";

interface PolicyItemPermissionsConfig {
  /**
   * Defines permission excessiveness thresholds
   */
  excessive: {
    /**
     * Threshold for contributors excessiveness
     */
    contributor: {
      /**
       * If a **Code Contributor** did not **push code** or **review a Pull Request** in the last `stale` day(s)
       */
      stale: number;

      /**
       * And their permission was given more than `received` day(s) ago
       */
      received: number;
    };

    /**
     * Threshold for admins excessiveness
     */
    admin: {
      /**
       * If an **Administrator** did not perform **administrative actions** in the last `stale` day(s)
       */
      stale: number;

      /**
       * And their administrative permission was given more than `received` day(s) ago
       */
      received: number;
    };
  };
}

export interface PolicyItemPermissions extends PolicyItem<PolicyItemPermissionsConfig> {
  sub: PolicySubType.permissions;
  config: PolicyItemPermissionsConfig;
}

export const PolicyItemPermissions = {
  generateDefault(arnicaOrgId: string): PolicyItemPermissions {
    return {
      arnicaOrgId,
      id: PolicySubType.permissions,
      sub: PolicySubType.permissions,
      type: PolicyItemType.policy,
      rules: [],
      config: PolicyItemPermissions.generateDefaultConfig()
    };
  },
  generateDefaultConfig(): PolicyItemPermissionsConfig {
    return {
      excessive: {
        admin: {
          received: 10,
          stale: 10
        },
        contributor: {
          received: 10,
          stale: 10
        }
      }
    };
  }
} as const;
