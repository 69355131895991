import { CodeRiskFindingType, SecretFindingType } from "$/finding-types";

export type FindingLabel = CodeRiskFindingType | SecretFindingType;

export const FINDING_LABEL_TO_ICON: Record<FindingLabel, `mdi-${string}`> = {
  IAC: "mdi-factory",
  SCA: "mdi-package-variant-closed",
  SAST: "mdi-shield-bug-outline",
  LICENSE: "mdi-license",
  REPUTATION: "mdi-search-web",
  SECRET: "mdi-form-textbox-password"
};

export const CODE_RISK_TYPE_TO_TEXT: Record<CodeRiskFindingType | SecretFindingType | "PERMISSIONS" | "HARDENING", string> = {
  [CodeRiskFindingType.SAST]: "SAST",
  [CodeRiskFindingType.SCA]: "SCA",
  [CodeRiskFindingType.IAC]: "IaC",
  [CodeRiskFindingType.LICENSE]: "License",
  [CodeRiskFindingType.REPUTATION]: "Reputation",
  [SecretFindingType.SECRET]: "Secrets",
  PERMISSIONS: "Permissions",
  HARDENING: "Hardening"
};

export const FINDING_LABEL_DESCRIPTION: Record<FindingLabel, string> = {
  IAC: "Infrastructure as Code: Infrastructure as code (IaC) is the process of managing and provisioning computer data centers through machine-readable definition files, rather than physical hardware configuration or interactive configuration tools",
  SCA: "Software Composition Analysis: Software Composition Analysis (SCA) is a practice in the fields of Information technology and software engineering for analyzing custom-built software applications to detect embedded open-source software and detect if they are up-to-date, contain security flaws, or have licensing requirements",
  SAST: "Static Application Security Testing: Static analysis tools examine the text of a program syntactically. They look for a fixed set of patterns or rules in the source code.",
  LICENSE: "Open Source License Violations",
  REPUTATION: "Open Source Package Reputation",
  SECRET: "Hard-coded secrets in code"
};
export function findingTypeDescription(label: FindingLabel): string {
  return FINDING_LABEL_DESCRIPTION[label] ?? "";
}

export function findingTypeIcon(label: FindingLabel): string {
  return FINDING_LABEL_TO_ICON[label] ?? "";
}

export function findingTypeText(riskType: CodeRiskFindingType | SecretFindingType | "PERMISSIONS" | "HARDENING"): string {
  return CODE_RISK_TYPE_TO_TEXT[riskType] ?? "";
}
