<template>
  <v-chip :color="color">
    <v-icon left>{{ icon }}</v-icon>
    <slot>
      {{ label }}
    </slot>
  </v-chip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class RoleChip extends Vue {
  @Prop({ type: String, default: "" })
  protected color!: string;

  @Prop({ type: String, required: true })
  protected icon!: string;

  @Prop({ type: String, required: true })
  protected label!: string;
}

export default RoleChip;
</script>
