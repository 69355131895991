import { ApiBase } from "./api-base";
import { AxiosRequestConfig } from "axios";
import { GithubUserInfo } from "$/interfaces/ui-api/response/github-user-info";

class GithubApi extends ApiBase {
  readonly #githubUserInfoCache = new Map<string, GithubUserInfo | null>();

  public constructor() {
    super({ pathPrefix: "", name: "GithubApi" });
  }

  protected override getBaseUrl(): Promise<string> {
    return Promise.resolve("https://api.github.com");
  }

  protected override onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    config.anonymous = true;
    return super.onRequest(config);
  }

  public async tryGetUser(login: string, useCache = true): Promise<GithubUserInfo | null> {
    const cached = useCache ? this.#githubUserInfoCache.get(login) : undefined;
    if (cached !== undefined) {
      return cached;
    }

    try {
      const res = await this.client.get<GithubUserInfo>(`users/${login}`);
      const user = res.data.login === login ? res.data : null;
      this.#githubUserInfoCache.set(login, user);
      return user;
    } catch (e) {
      this.#githubUserInfoCache.set(login, null);
      return null;
    }
  }
}

export const Github = new GithubApi();
