import { LineRange, ScmUrlGenerator } from "../../data-access/src/scm-url-generator";
import { BitbucketServerRepositoryIdentifier } from "./api/bitbucket-server-repository-identifier";

export class BitbucketServerUrlGenerator implements ScmUrlGenerator {
  public getOrganizationUrl(organizationId: string): string {
    return organizationId;
  }

  public getProjectUrl(organizationId: string, projectId: string): string {
    return `${organizationId}/projects/${projectId.encodeURI()}`;
  }

  public getRepositoryUrl(organizationId: string, repositoryId: string): string {
    const { projectKey, repositorySlug } = BitbucketServerRepositoryIdentifier.parse(repositoryId);
    return `${organizationId}/projects/${projectKey.encodeURI()}/repos/${repositorySlug.encodeURI()}`;
  }

  public getRepositoryBranchUrl(organizationId: string, repositoryId: string, branch: string): string {
    const { projectKey, repositorySlug } = BitbucketServerRepositoryIdentifier.parse(repositoryId);
    return `${organizationId}/projects/${projectKey.encodeURI()}/repos/${repositorySlug.encodeURI()}/browse?at=${branch.encodeURI()}`;
  }

  public getRepositoryCommitUrl(organizationId: string, repositoryId: string, commitSha: string): string {
    const { projectKey, repositorySlug } = BitbucketServerRepositoryIdentifier.parse(repositoryId);
    return `${organizationId}/projects/${projectKey.encodeURI()}/repos/${repositorySlug.encodeURI()}/commits/${commitSha.encodeURI()}`;
  }

  public getRepositoryBranchFileUrl(organizationId: string, repositoryId: string, branch: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, branch, path, lines);
  }
  public getRepositoryCommitFileUrl(organizationId: string, repositoryId: string, commitSha: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, commitSha, path, lines);
  }
  public getRepositoryFileUrl(organizationId: string, repositoryId: string, commitReference: string, path: string, lines?: LineRange): string {
    const { projectKey, repositorySlug } = BitbucketServerRepositoryIdentifier.parse(repositoryId);
    const url = new URL(
      `${organizationId}/projects/${projectKey.encodeURI()}/repos/${repositorySlug.encodeURI()}/browse/${path.encodePathURI()}?at=${commitReference.encodeURI()}`
    );
    if (lines) {
      if (lines.end) {
        url.hash = `${lines.start}-${lines.end}`;
      } else {
        url.hash = `${lines.start}`;
      }
    }
    return url.toString();
  }

  public getRepositoryPullRequestUrl(organizationId: string, repositoryId: string, pullRequestId: number): string {
    const { projectKey, repositorySlug } = BitbucketServerRepositoryIdentifier.parse(repositoryId);
    return `${organizationId}/projects/${projectKey.encodeURI()}/repos/${repositorySlug.encodeURI()}/pull-requests/${pullRequestId}`;
  }

  public getRepositoryPullRequestCommentUrl(organizationId: string, repositoryId: string, pullRequestId: number, commentId: string): string {
    const { projectKey, repositorySlug } = BitbucketServerRepositoryIdentifier.parse(repositoryId);
    return `${organizationId}/projects/${projectKey.encodeURI()}/repos/${repositorySlug.encodeURI()}/pull-requests/${pullRequestId}/overview?commentId=${commentId}`;
  }

  public getUserUrl(organizationId: string, userId: string): string {
    return `${organizationId}/users/${userId.encodeURI()}`;
  }

  public getRepositoryId(_projectId: string | undefined, repoId: string): string {
    return repoId;
  }
}
