import { GetInsightResponseItem } from "../../interfaces/ui-api/response/get-insight-response-item";
import { SCMType } from "../../scm-type";
import { ADORiskReport, ADORiskReportQueryType, GitHubRiskReport, GitHubRiskReportQueryType, RiskReport, RiskReportType } from "../risk-report/risk-report";

export class InsightUtils {
  public static generateExcessivePermissionInsightFromReport(
    reports: RiskReport<RiskReportType>[],
    queryType: RiskReportType,
    action: string,
    title: string,
    scmType: SCMType,
    severity: number,
    singularItemName = "identity",
    pluralItemName = "identities"
  ): GetInsightResponseItem | null {
    let countTotalExcessive = 0;
    let countExcessiveX = 0;
    let countTotalX = 0;
    let countUniqueIdentities = 0;

    if (!reports.length) {
      return null;
    }
    for (const report of reports) {
      countTotalExcessive += report.summary.excessiveNumberOfPermissions;

      if (report.summary.queryType === queryType) {
        countExcessiveX = report.summary.excessiveNumberOfPermissions;
        if (report.reportItems) {
          const uniqueIdentitiesSet = new Set<string>(
            report.reportItems.flatMap((ri) => {
              return "identities" in ri ? ri.identities : [];
            })
          );
          countUniqueIdentities = uniqueIdentitiesSet.size;
        }
        countTotalX = report.summary.totalNumberOfPermissions;
      }
    }

    const percentReduction = countTotalExcessive == 0 ? 0 : Math.ceil((countExcessiveX / countTotalExcessive) * 100);

    const excessivePercentage = countTotalX !== 0 ? countExcessiveX / countTotalX : undefined;

    const identityFormat = countUniqueIdentities == 1 ? singularItemName : pluralItemName;

    const summary =
      countUniqueIdentities == 0
        ? "All existing identities are valid, no excessive permissions were identified at this time."
        : `${countUniqueIdentities} ${identityFormat} ${action}. ` + `Remove these permissions to reduce the total excessive permissions by ${percentReduction}%.`;

    return {
      title,
      shortSummary: summary,
      percentage: excessivePercentage,
      page: "permissions",
      countItem: countExcessiveX,
      countTotal: countTotalX,
      type: queryType,
      scmType,
      severity
    };
  }

  // Permission Insights
  public static githubExcessiveWriteFromReport(githubExcessiveReports: GitHubRiskReport[]): GetInsightResponseItem | null {
    return this.githubExcessivePermissionFromReport(
      githubExcessiveReports,
      GitHubRiskReportQueryType.GHWRITE,
      "did not push code to branches without enforced pull requests recently",
      "Excessive Write Permissions",
      2
    );
  }

  public static githubExcessiveCodeOwnersFromReport(githubExcessiveReports: GitHubRiskReport[]): GetInsightResponseItem | null {
    return this.githubExcessivePermissionFromReport(
      githubExcessiveReports,
      GitHubRiskReportQueryType.GHCODEOWNERS,
      "in CODEOWNERS did not review pull requests recently",
      "Excessive CODEOWNERS Permissions",
      2
    );
  }

  public static githubExcessiveOrgOwnerFromReport(githubExcessiveReports: GitHubRiskReport[]): GetInsightResponseItem | null {
    return this.githubExcessivePermissionFromReport(
      githubExcessiveReports,
      GitHubRiskReportQueryType.GHORGOWNER,
      "did not perform administrative actions recently",
      "Excessive Org Owners Permissions",
      1
    );
  }

  public static githubExcessiveRepoAdminFromReport(githubExcessiveReports: GitHubRiskReport[]): GetInsightResponseItem | null {
    return this.githubExcessivePermissionFromReport(
      githubExcessiveReports,
      GitHubRiskReportQueryType.GHREPOADMIN,
      "did not perform administrative actions recently",
      "Excessive Repository Admins Permissions",
      1
    );
  }

  public static githubExcessiveMaintainFromReport(githubExcessiveReports: GitHubRiskReport[]): GetInsightResponseItem | null {
    return this.githubExcessivePermissionFromReport(
      githubExcessiveReports,
      GitHubRiskReportQueryType.GHMAINTAIN,
      "did not perform any repository maintenance actions recently",
      "Excessive Maintain Permissions",
      2
    );
  }

  public static adoExcessiveAdminFromReport(adoExcessiveReports: ADORiskReport[]): GetInsightResponseItem | null {
    return this.adoExcessivePermissionFromReport(
      adoExcessiveReports,
      ADORiskReportQueryType.ADOADMIN,
      "did not perform administrative actions recently",
      "Excessive Admin Permissions",
      1
    );
  }

  public static adoExcessiveCodeContributorFromReport(adoExcessiveReports: ADORiskReport[]): GetInsightResponseItem | null {
    return this.adoExcessivePermissionFromReport(
      adoExcessiveReports,
      ADORiskReportQueryType.ADOCODECONTRIBUTORS,
      "did not push code recently to branches without enforced pull requests",
      "Excessive Code Contributor Permissions",
      2
    );
  }

  public static adoExcessivePRApproverFromReport(adoExcessiveReports: ADORiskReport[]): GetInsightResponseItem | null {
    return this.adoExcessivePermissionFromReport(
      adoExcessiveReports,
      ADORiskReportQueryType.ADOPRAPPROVERS,
      "did not approve any PRs recently",
      "Excessive Pull Request Approver Permissions",
      2
    );
  }

  public static adoExcessivePermissionFromReport(
    report: ADORiskReport[],
    queryType: ADORiskReportQueryType,
    action: string,
    title: string,
    severity: number,
    singularItemName?: string,
    pluralItemName?: string
  ): GetInsightResponseItem | null {
    return this.generateExcessivePermissionInsightFromReport(report, queryType, action, title, SCMType.AZURE_DEVOPS, severity, singularItemName, pluralItemName);
  }

  public static githubExcessivePermissionFromReport(
    report: GitHubRiskReport[],
    queryType: GitHubRiskReportQueryType,
    action: string,
    title: string,
    severity: number,
    singularItemName?: string,
    pluralItemName?: string
  ): GetInsightResponseItem | null {
    return this.generateExcessivePermissionInsightFromReport(report, queryType, action, title, SCMType.GITHUB, severity, singularItemName, pluralItemName);
  }
}
