import { IntegrationType } from "$/dynamo";
import { AnalyticsService } from "@/services/analytics-service";
import { Auth, Popup } from "@/state";

export type CallbackIntegrationType = IntegrationType | "cognito";

export abstract class CallbackHandlerBase {
  public abstract handle(query: URLSearchParams): Promise<void>;

  protected abstract provideUserFeedback(): void;

  protected sendToAnalytics(integrationType: string): void {
    AnalyticsService.logEvent("Onboarding Integration Complete", { integrationType }, { group: "Onboarding" });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  protected handleIntegrationError(error: any): Promise<void> {
    console.warn(error);
    const response = typeof error === "object" && "response" in error ? error.response : null;
    const message = response ? response.data?.message || response.data?.error || response.statusText : error instanceof Error ? error.message : String(error);

    // in the case of a GitHub callback while the user is not signed in, avoid showing a brief error message here...
    if (Auth.authenticated) {
      //TODO: is there a better way to tell? e.g. if we can tell whether the router is redirecting to login, it will be a better way to tell if we need to show the error or not

      void Popup.error(`Sorry, unable to add integration.\n${message}`, { timeout: Popup.INDEFINITELY });
    }
    return Promise.resolved;
  }

  protected cleanupUrl(): void {
    window.history.replaceState(null, "", window.location.origin);
  }
}
