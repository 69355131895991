interface Map<K, V> {
  /**
   * Gets a value from the map or sets it if it doesn't exist.
   * @param key The key to get or set.
   * @param valueFactory A value factory to create the value if it doesn't exist.
   * @returns The value found or created.
   */
  getOrSet(key: K, valueFactory: (key: K) => V): V;

  /**
   * Sets a new value or updates an existing value in the map.
   * @param key The key to set or update.
   * @param setFactory A value factory to create the value if it doesn't exist.
   * @param updateFactory An update factory to update the value it does exist.
   * @returns The updated value.
   */
  setOrUpdate(key: K, setFactory: (key: K) => V, updateFactory: (key: K, value: V) => V): V;

  // /**
  //  * Calls a defined callback function on each entry of the map, and returns an array that contains the results.
  //  * @param callbackfn A function that accepts up to three arguments. The map method calls the callbackfn function one time for each entry in the array.
  //  */
  // map<O>(callbackfn: (value: V, key: K, map: Map<K, V>) => O): O[];

  /**
   * Returns an iterable of key, value pairs for every entry in the map.
   */
  entries(): MapIterator<[K, V]>;

  /**
   * Returns an iterable of keys in the map
   */
  keys(): MapIterator<K>;

  /**
   * Returns an iterable of values in the map
   */
  values(): MapIterator<V>;

  /**
   * Pops a value from the map.
   * @param key The key to pop.
   * @returns The value removed or undefined if it didn't exist.
   * @note This method will try to find a key in the map. If found, it will remove it and return the value. If not found, it will return undefined.
   */
  pop(key: K): V | undefined;
}

interface MapIterator<T> extends IterableIterator<T> {
  filter(predicate: (item: T) => boolean): Generator<T, void, unknown>;
  map<S>(factory: (item: T) => S): Generator<S, void, unknown>;
  toArray(): T[];
}

const MapIteratorPrototype = Object.getPrototypeOf(new Map().entries()) as MapIterator<[unknown, unknown]>;

MapIteratorPrototype.filter ??= function* filter<T>(this: MapIterator<T>, predicate: (item: T) => boolean): Generator<T, void, unknown> {
  for (const item of this) {
    if (predicate(item)) {
      yield item;
    }
  }
};

MapIteratorPrototype.map ??= function* map<T, S>(this: MapIterator<T>, factory: (item: T) => S): Generator<S, void, unknown> {
  for (const item of this) {
    yield factory(item);
  }
};

MapIteratorPrototype.toArray ??= function toArray<T>(this: MapIterator<T>): T[] {
  return Array.from(this);
};

Map.prototype.getOrSet ??= function getOrSet<K, V>(this: Map<K, V>, key: K, valueFactory: (key: K) => V): V {
  const found = this.get(key);
  if (found) {
    return found;
  }

  const value = valueFactory(key);
  this.set(key, value);
  return value;
};

Map.prototype.setOrUpdate ??= function setOrUpdate<K, V>(this: Map<K, V>, key: K, setFactory: (key: K) => V, updateFactory: (key: K, value: V) => V): V {
  const found = this.get(key);
  if (found) {
    const updated = updateFactory(key, found);
    this.set(key, updated);
    return updated;
  }

  const value = setFactory(key);
  this.set(key, value);
  return value;
};

Map.prototype.pop ??= function pop<K, V>(this: Map<K, V>, key: K): V | undefined {
  const found = this.get(key);
  found !== undefined && this.delete(key);
  return found;
};

// Map.prototype.map ??= function map<K, V, O>(this: Map<K, V>, callbackfn: (value: V, key: K, map: Map<K, V>) => O): O[] {
//   const result: O[] = [];
//   for (const [key, value] of this) {
//     result.push(callbackfn(value, key, this));
//   }

//   return result;
// };
