type Type = "undefined" | "record" | "function" | "boolean" | "number" | "string" | "symbol" | "NaN" | "null" | "array" | "date" | "regexp";

function getType(obj: unknown): Type {
  // non-primitive types are commented out for performance reasons
  const type = typeof obj;
  switch (type) {
    case "undefined":
      return type;
    case "boolean":
      return type;
    case "string":
      return type;
    case "number":
      return Number.isNaN(obj) ? "NaN" : type;
    // case "function": return type;
    // case "symbol": return type;
    case "object": {
      if (obj === null) {
        return "null";
      }
      if (Array.isArray(obj)) {
        return "array";
      }
      // if (obj instanceof Date) return "date";
      // if (obj instanceof RegExp) return "regexp";
      return "record";
    }
    default:
      throw new Error(`Invalid type: ${type}`);
  }
}

/**
 * Deep merge two objects, merging the right object onto the left
 * @param left The left object
 * @param right The right object
 * @param clearOnNull If true, the left object will be unset if the right object is null (default is false)
 */
export default function deepMerge<T1, T2>(left: T1, right: T2 | undefined, clearOnNull = false): T1 | T2 | (T1 & T2) | undefined {
  // non-primitive types are commented out for performance reasons
  switch (getType(right)) {
    case "null":
      return clearOnNull ? undefined : left; // unset left
    case "undefined":
      return left; // no override
    case "string":
      return right;
    case "number":
      return right;
    case "boolean":
      return right;
    case "array":
      return right;
    case "record": {
      switch (getType(left)) {
        case "undefined":
          return right;
        case "string":
          return right;
        case "number":
          return right;
        case "boolean":
          return right;
        case "null":
          return right;
        case "array":
          return right;
        case "record": {
          const result = { ...left }; // create a copy of left
          for (const [key, rightValue] of Object.entries(right as unknown as object)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const item = ((result as any)[key] = deepMerge((result as any)[key], rightValue, clearOnNull));
            if (typeof item === "undefined") {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              delete (result as any)[key];
            }
          }
          return result;
        }
        default:
          throw new Error(`Invalid type: ${getType(left)}`);
      }
    }
    // case "NaN": return right;
    // case "date": return right;
    // case "function": return right;
    // case "symbol": return right;
    // case "regexp": return right;
    default:
      throw new Error(`Invalid type: ${getType(right)}`);
  }
}
