<template>
  <v-overlay class="component overlay-simple-message text-center" :value="true">
    <v-container>
      <slot name="header"></slot>
      <slot>
        <v-row>
          <v-col>
            <h3 class="text-uppercase">{{ title }}</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <p>{{ text }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="primary" @click="close()"> {{ buttonText }}</v-btn>
          </v-col>
        </v-row>
      </slot>
      <slot name="footer"></slot>
    </v-container>
  </v-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class OverlaySimpleMessage extends Vue {
  @Prop({ type: String, required: true })
  protected title!: string;

  @Prop({ type: String, required: true })
  protected text!: string;

  @Prop({ type: String, required: true })
  protected buttonText!: string;

  protected close() {
    this.$emit("close");
  }
}

export default OverlaySimpleMessage;
</script>

<style lang="scss">
.component.overlay-simple-message {
  overflow: auto;
}
</style>
