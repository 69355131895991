import type { GitResourceBase } from "../../../shared/src/report/interfaces/git-resource-base";
import { scmOrgNameFilter } from "./scm-org-name-filter";

export function scmAssetTextFilter(asset: GitResourceBase, options?: { showOrg?: boolean; showBranch?: boolean }): string {
  const { showOrg = true, showBranch = true } = options || {};
  let text = [showOrg ? scmOrgNameFilter(asset) : null, asset.project || null, asset.repo].nonNullable().join("/");
  if (showBranch && asset.branch) {
    text += `#${asset.branch}`;
  }
  return text;
}
