import type { CodeStatus } from "../code-status";
import { CodeRiskStates, SecretStates, SemgrepStates, StatusUtils } from "$/finding-types";
import { statusMeaningColor } from "@/pages/dashboard/dashboard-consts";
import { getColorBySeverity } from "@/pages/inventory/details/sbom/components/sbom-component-common";

//TODO: this should be taken from SAST_STATUSES
export const DISMISSAL_OPTIONS = [
  {
    value: CodeRiskStates.IN_PROGRESS,
    description: "Finding in progress",
    showDialog: true
  },
  {
    value: CodeRiskStates.RESOLVED,
    description: "Finding has been resolved",
    showDialog: false
  },
  {
    value: CodeRiskStates.DISMISS_NOT_ACCURATE,
    description: "Finding has been dismissed as not accurate",
    showDialog: true
  },
  {
    value: CodeRiskStates.DISMISS_NO_CAPACITY,
    description: "Finding has been dismissed due to no capacity",
    showDialog: true
  },
  {
    value: CodeRiskStates.RISK_ACCEPTED,
    description: "Finding risk has been accepted",
    showDialog: true
  },
  {
    value: SecretStates.DISMISS_RISK_IS_TOLERABLE,
    description: "Risk is tolerable",
    showDialog: true
  },
  {
    value: CodeRiskStates.REQUIRES_REVIEW,
    description: "Finding requires review",
    showDialog: false
  }
] as const;

export type CodeRiskDecisionData = { status: SemgrepStates; suggestionAccepted: boolean; suggestionDate: string };

type AllStatuses = CodeRiskStates | typeof SecretStates.DISMISS_RISK_IS_TOLERABLE;
const statusesWithoutColor: Readonly<Record<AllStatuses, Omit<CodeStatus<AllStatuses>, "color" | "value">>> = {
  [CodeRiskStates.REQUIRES_REVIEW]: {
    text: "Open",
    icon: "shield-key-outline",
    fgColor: "white"
  },
  [CodeRiskStates.IN_PROGRESS]: {
    text: "In Progress",
    icon: "clock-time-four-outline",
    fgColor: "white"
  },
  [CodeRiskStates.PENDING_REVIEW]: {
    text: "Pending Review",
    icon: "exclamation-thick",
    fgColor: "white"
  },
  [CodeRiskStates.DISMISS_NO_CAPACITY]: {
    text: "Dismissed",
    textChip: "No capacity",
    icon: "minus-circle-outline",
    fgColor: "white",
    displayName: "Dismissed - No Capacity"
  },
  [CodeRiskStates.DISMISS_NOT_ACCURATE]: {
    text: "Dismissed",
    textChip: "Not accurate",
    icon: "minus-circle-outline",
    fgColor: "white",
    displayName: "Dismissed - Not Accurate"
  },
  [CodeRiskStates.RISK_ACCEPTED]: {
    text: "Dismissed",
    textChip: "Risk Accepted",
    icon: "minus-circle-outline",
    fgColor: "white",
    displayName: "Dismissed - Risk Accepted"
  },
  [SecretStates.DISMISS_RISK_IS_TOLERABLE]: {
    text: "Dismissed",
    textChip: "Risk is Tolerable",
    icon: "minus-circle-outline",
    fgColor: "white",
    displayName: "Dismissed - Risk is Tolerable"
  },
  [CodeRiskStates.RESOLVED]: {
    text: "Resolved",
    icon: "check-circle-outline",
    fgColor: "white"
  },
  [CodeRiskStates.RESOLVED_AUTOMATICALLY]: {
    text: "Resolved Automatically",
    icon: "check-circle-outline",
    fgColor: "white"
  },
  [CodeRiskStates.DISMISSED_AUTOMATICALLY]: {
    text: "Dismissed Automatically",
    icon: "minus-circle-outline",
    fgColor: "white"
  }
};
export const CODE_RISK_STATUSES: Readonly<Record<AllStatuses, CodeStatus<AllStatuses>>> = Object.freeze(
  Object.fromEntries(
    Object.entries(statusesWithoutColor).map(([k, v]) => {
      const key = k as keyof typeof statusesWithoutColor;
      const newVar: CodeStatus<AllStatuses> = {
        ...v,
        value: key,
        get color() {
          return statusMeaningColor(StatusUtils.getStatusMeaning(key));
        }
      } as const;
      return [key, newVar];
    })
  ) as Record<AllStatuses, CodeStatus<AllStatuses>>
);
export function isCodeRiskStatusDisplayKey(status?: string): status is keyof typeof CODE_RISK_STATUSES {
  return !!status && status in CODE_RISK_STATUSES;
}
