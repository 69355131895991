import { PolicyAction } from "./policy-actions";
import { PolicyCondition } from "./policy-conditions";
import { PolicyItemBase, PolicyItemType } from "./policy-item-base";
import { PolicyTrigger } from "./policy-triggers";

declare module "./policy-item-base" {
  interface PolicyComponents {
    [PolicyItemType.rule]: PolicyRule;
  }

  enum PolicyItemType {
    rule = "rule"
  }
}

(PolicyItemType as { rule: "rule" }).rule = "rule";

export const PolicyRuleItems = [PolicyItemType.trigger, PolicyItemType.condition, PolicyItemType.action] as const;
export type PolicyRuleItemKey = (typeof PolicyRuleItems)[number];

export interface PolicyRule extends PolicyItemBase<PolicyItemType.rule> {
  name: string;
  enabled: boolean;
  trigger: PolicyTrigger | null;
  condition: PolicyCondition | null;
  actions: PolicyAction[];
}

export const PolicyRule = {
  hasAction(rule: PolicyRule, sub: PolicyAction["sub"]): boolean {
    return !!rule.actions?.some((a) => PolicyAction.isOrParentOf(a, sub));
  },
  hasCondition(rule: PolicyRule, sub: PolicyCondition["sub"]): boolean {
    return !!rule.condition && (rule.condition.sub === sub || !!rule.condition.children?.some((c) => PolicyCondition.isOrParentOf(c, sub)));
  },
  generateDefault(): PolicyRule {
    return {
      id: `${PolicyItemType.rule}-${Math.floor(Date.now() * Math.random())}`,
      type: PolicyItemType.rule,
      enabled: true,
      name: "Default Rule",
      trigger: null,
      condition: null,
      actions: []
    };
  },
  sortProps(rule: PolicyRule): PolicyRule {
    const { actions, condition, enabled, id, name, trigger, type } = rule;
    return {
      name,
      type,
      id,
      enabled,
      trigger,
      condition: condition ? PolicyCondition.sortProps(condition) : null,
      actions: actions.map((a) => PolicyAction.sortProps(a))
    };
  }
} as const;
