import { ApiBase } from "./api-base";
import { DismissedRiskReportItem } from "$/dynamo";
import { AnomalyDismissOptionsTypes, CommonDismissOptionsTypes, SecretDismissOptionsType, SemgrepStates } from "$/finding-types";
import { CreateDismissItemRequest } from "$/interfaces/ui-api/request";
import { SCMType } from "$/scm-type";

class DismissalsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "dismiss", name: "DismissalsApi" });
  }

  public async get(scmType: SCMType): Promise<DismissedRiskReportItem[]> {
    const res = await this.client.get<DismissedRiskReportItem[]>(scmType);
    return res.data;
  }

  public async create(...params: CreateDismissItemRequest[]): Promise<DismissedRiskReportItem[]> {
    const res = await this.client.post<DismissedRiskReportItem[]>("", params);
    return res.data;
  }

  public async delete(dismissedItemKey: string): Promise<DismissedRiskReportItem[]> {
    const res = await this.client.delete<DismissedRiskReportItem[]>(`${encodeURIComponent(dismissedItemKey)}`);
    return res.data;
  }

  public async updateDismissalStatus(
    status: CommonDismissOptionsTypes | AnomalyDismissOptionsTypes | SemgrepStates | SecretDismissOptionsType,
    item: { sortKey: string },
    reason?: string
  ): Promise<void> {
    const url = `${encodeURIComponent(item.sortKey)}/${encodeURIComponent(status)}`;
    await this.client.put(url, { reason });
  }

  public async takeDecision(item: { sortKey: string }, reason: string, suggestionDate: string, decisionAccepted: boolean): Promise<void> {
    const url = `decision/${encodeURIComponent(item.sortKey)}`;
    await this.client.put(url, { reason, suggestionDate, decisionAccepted });
  }
}

export const Dismissals = new DismissalsApi();
