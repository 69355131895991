/**
 * Decorator that makes a getter property cached.
 * @param ttl The time-to-live (in milliseconds) of the cached value.
 * @default 0 (for the life of the object instance)
 * @note The property will only be **evaluated on first access**.
 * Subsequent access to this (instance) property will return the **cached** value.
 */
export function CachedProp(ttl = 0) {
  return function (prototype: object, propertyKey: PropertyKey, descriptor: PropertyDescriptor | undefined): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const getter = descriptor?.get;
    if (!getter) {
      throw new Error("The LazyProp decorator is only applicable to getter properties");
    }

    if (ttl <= 0) {
      descriptor.get = function (this: object) {
        const cached = getter.call(this) as (typeof this)[keyof typeof this];
        Object.defineProperty(this, propertyKey, {
          get(this: object) {
            return cached;
          }
        });
        return cached;
      };
    } else {
      descriptor.get = function (this: object) {
        const resolver = getter.bind(this) as () => (typeof this)[keyof typeof this];
        let cached = resolver();
        let accessed = Date.now();
        Object.defineProperty(this, propertyKey, {
          get(this: object) {
            const now = Date.now();
            if (now - accessed > ttl) {
              cached = resolver();
            }
            accessed = now;
            return cached;
          }
        });
        return cached;
      };
    }
  };
}
