// @spellchecker: ignore vnode
import Vue, { VNode } from "vue";
import type { DirectiveBinding } from "vue/types/options";
import { Tier, TierName } from "$/interfaces/ui-api/billing-tiering/tier";
import { Tier as TierState } from "@/state";

type BehaviorCallback = (el: HTMLElement, vnode: VNode) => void;
type TierDirectiveModifierKeys = keyof TierDirectiveModifiers;

interface TierDirectiveModifiers {
  exact?: boolean;
  max?: boolean;
  show?: boolean;
  enable?: boolean;
  hide?: boolean;
  disable?: boolean;
  remove?: boolean;
  [passClass: `class*${string}`]: boolean | undefined;
  [failClass: `class!${string}`]: boolean | undefined;
}

interface TierDirectiveBinding extends DirectiveBinding {
  readonly modifiers: DirectiveBinding["modifiers"] & TierDirectiveModifiers;
}

/**
 * Create comment node
 *
 * @private
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
function commentNode(el: HTMLElement, vnode: VNode) {
  const comment = document.createComment(" ");

  Object.defineProperty(comment, "setAttribute", {
    value: () => undefined
  });

  vnode.text = " ";
  vnode.elm = comment;
  vnode.isComment = true;
  delete vnode.context; // = undefined;
  delete vnode.tag; // = undefined;
  delete vnode.data?.directives; // = undefined;

  if (vnode.componentInstance) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Cannot assign to '$el' because it is a read-only property.ts(2540)
    vnode.componentInstance.$el = comment;
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el);
  }
}

const PASS_BEHAVIORS: Partial<Record<TierDirectiveModifierKeys, BehaviorCallback>> = {
  show(el: HTMLElement) {
    el.style.display = "";
  },
  enable(el: HTMLElement & { disabled?: boolean }) {
    el.disabled = false;
  }
};

const FAIL_BEHAVIORS: Partial<Record<TierDirectiveModifierKeys, BehaviorCallback>> = {
  hide(el: HTMLElement) {
    el.style.display = "none";
  },
  disable(el: HTMLElement & { disabled?: boolean }) {
    el.disabled = true;
  },
  remove(el: HTMLElement, vnode: VNode) {
    commentNode(el, vnode);
  }
};

const DEFAULT_BEHAVIORS: Record<string, ((vnode: VNode) => TierDirectiveModifierKeys[] | false)[]> = {
  "v-btn": [() => ["disable", "class!v-btn--disabled"]],
  "*": [
    // if not specified, default to hide
    () => ["hide"]
  ]
};

class TierDirectiveConfig {
  static readonly #elements = new WeakMap<HTMLElement, TierDirectiveConfig>();
  readonly #exact: boolean;
  readonly #max: boolean;
  readonly #passBehaviors: BehaviorCallback[];
  readonly #failBehaviors: BehaviorCallback[];
  readonly #passClasses: string[];
  readonly #failClasses: string[];
  readonly #tier: Tier | null;

  constructor(binding: TierDirectiveBinding, el: HTMLElement, vnode: VNode) {
    TierDirectiveConfig.#applyDefaultBehaviors(binding.modifiers, vnode);
    this.#exact = !!binding.modifiers.exact;
    this.#max = !!binding.modifiers.max;
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    this.#passBehaviors = Object.entries(PASS_BEHAVIORS)
      .filter(([key]) => binding.modifiers[key])
      .map(([, behavior]) => behavior!);

    this.#failBehaviors = Object.entries(FAIL_BEHAVIORS)
      .filter(([key]) => binding.modifiers[key])
      .map(([, behavior]) => behavior!);
    /* eslint-enable @typescript-eslint/no-non-null-assertion */

    this.#passClasses = TierDirectiveConfig.#getClasses(binding.modifiers, "*");
    this.#failClasses = TierDirectiveConfig.#getClasses(binding.modifiers, "!");

    this.#tier = TierDirectiveConfig.#getTier(binding.arg, el);
  }

  get #pass(): boolean {
    if (this.#tier === null) {
      return false;
    }
    const current = TierState.current;
    return this.#exact ? current == this.#tier : this.#max ? current <= this.#tier : current >= this.#tier;
  }

  static #applyDefaultBehaviors(modifiers: TierDirectiveBinding["modifiers"], vnode: VNode): void {
    if (Object.keys(modifiers).length > 0) {
      return;
    }
    const defaults = DEFAULT_BEHAVIORS[vnode.componentOptions?.tag ?? "*"] ?? DEFAULT_BEHAVIORS["*"];
    if (!defaults) {
      return;
    }
    for (const factory of defaults) {
      const behaviors = factory(vnode);
      if (!behaviors) {
        continue;
      }
      behaviors.forEach((behavior) => (modifiers[behavior] = true));
    }
  }

  static #getClasses(modifiers: TierDirectiveBinding["modifiers"], kind: "*" | "!"): string[] {
    return (Object.keys(modifiers) as string[]).map((mod) => mod.match(`^class\\${kind}(.*)`)?.[1]).filter((className: string | undefined): className is string => !!className);
  }

  static #getTier(arg: TierDirectiveBinding["arg"], el: HTMLElement): Tier | null {
    if (!arg || !(arg in Tier)) {
      console.warn("Invalid tier name", el);
      return null;
    }

    const tier = Tier[arg as TierName];
    if (Number.isNaN(tier)) {
      console.error("Invalid tier", el);
      return null;
    }
    return tier;
  }

  public static bind(el: HTMLElement, binding: TierDirectiveBinding, vnode: VNode): void {
    const config = new TierDirectiveConfig(binding, el, vnode);
    TierDirectiveConfig.#elements.set(el, config);
    config.#execute(el, vnode);
  }

  public static update(el: HTMLElement, binding: TierDirectiveBinding, vnode: VNode): void {
    const config = TierDirectiveConfig.#elements.get(el);
    if (!config) {
      console.warn("tier directive missing element config", el);
      return;
    }
    config.#execute(el, vnode);
  }

  public static unbind(el: HTMLElement): void {
    TierDirectiveConfig.#elements.delete(el);
  }

  #apply(behaviors: BehaviorCallback[], addClasses: string[], removeClasses: string[], el: HTMLElement, vnode: VNode): void {
    behaviors.forEach((behavior) => behavior(el, vnode));
    addClasses.forEach((className) => el.classList.add(className));
    removeClasses.forEach((className) => el.classList.remove(className));
  }

  #execute(el: HTMLElement, vnode: VNode): void {
    if (!this.#tier) {
      return;
    }

    if (this.#pass) {
      this.#apply(this.#passBehaviors, this.#passClasses, this.#failClasses, el, vnode);
    } else {
      this.#apply(this.#failBehaviors, this.#failClasses, this.#passClasses, el, vnode);
    }
  }
}

Vue.directive("tier", {
  bind: TierDirectiveConfig.bind,
  update: TierDirectiveConfig.update,
  unbind: TierDirectiveConfig.unbind
});
