import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    sca_has_fix = "sca_has_fix"
  }
}

(PolicyConditionSubType as { sca_has_fix: "sca_has_fix" }).sca_has_fix = "sca_has_fix";

export interface PolicyConditionFindingScaHasFix extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.sca_has_fix;
}

export const PolicyConditionFindingScaHasFix = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.sca_has_fix
  } as const,
  generateDefault(): PolicyConditionFindingScaHasFix {
    return {
      id: `${PolicyItemType.condition}-${Math.floor(Date.now() * Math.random())}`,
      sub: PolicyConditionSubType.sca_has_fix,
      type: PolicyItemType.condition
    };
  }
} as const;
