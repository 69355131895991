import { PathToCodeOwners } from "../../../../shared/src/github/interfaces/code-owners-info";

// TODO merge with GitHubUrlGenerator
export class GithubUrls {
  public static organizationURL(baseUrl: string, orgLogin: string): string {
    return `${baseUrl}/${orgLogin}`;
  }

  public static repoUrl(baseUrl: string, orgLogin: string, repoName: string): string {
    return `${baseUrl}/${orgLogin}/${repoName}`;
  }

  public static repoBranchProtectionSettings(baseUrl: string, orgLogin: string, repoName: string): string {
    return `${baseUrl}/${orgLogin}/${repoName}/settings/branches`;
  }

  public static repoAdmins(baseUrl: string, orgLogin: string, repoName: string): string {
    return `${baseUrl}/${orgLogin}/${repoName}/settings/access?query=role%3Aadmin`;
  }

  public static identityURL(baseUrl: string, userLogin: string): string {
    return `${baseUrl}/${userLogin}`;
  }

  public static appURL(baseUrl: string, appId: string, orgLogin: string): string {
    return `${baseUrl}/organizations/${orgLogin}/settings/installations/${appId}`;
  }

  public static teamMembers(baseUrl: string, orgLogin: string, teamSlug: string): string {
    return `${baseUrl}/orgs/${orgLogin}/teams/${teamSlug}/members`;
  }

  public static allOrgMembers(baseUrl: string, orgLogin: string): string {
    return `${baseUrl}/orgs/${orgLogin}/people`;
  }

  public static repoAccessSettings(baseUrl: string, orgLogin: string, repoName: string): string {
    return `${baseUrl}/${orgLogin}/${repoName}/settings/access`;
  }

  public static branchSettingsURL(baseUrl: string, orgLogin: string, repoName: string): string {
    return `${baseUrl}/${orgLogin}/${repoName}/settings/branches`;
  }

  public static pullRequest(baseUrl: string, orgLogin: string, repoName: string, pullRequestNumber: number): string {
    return `${baseUrl}/${orgLogin}/${repoName.encodeURI()}/pull/${pullRequestNumber}`;
  }

  public static codeOwners(baseUrl: string, orgLogin: string, repoName: string, branch: string, pathToCodeOwners: PathToCodeOwners, lineNumber?: number | string): string {
    return `${baseUrl}/${orgLogin}/${repoName}/blob/${branch}/${GithubUrls.getPathFromPathToCodeOwners(pathToCodeOwners)}${lineNumber ? `#L${lineNumber})` : ""}`;
  }
  public static getPathFromPathToCodeOwners(pathToCodeOwners: PathToCodeOwners | null) {
    if (!pathToCodeOwners) {
      throw new Error("pathToCodeOwners was not defined");
    }
    if (pathToCodeOwners === "docs") {
      return "docs/CODEOWNERS";
    } else if (pathToCodeOwners === ".github") {
      return ".github/CODEOWNERS";
    } else {
      return "CODEOWNERS";
    }
  }

  public static file(baseUrl: string, orgLogin: string, repoName: string, branch: string, path: string, lineNumber?: number | string): string {
    return `${baseUrl}/${orgLogin.encodeURI()}/${repoName.encodeURI()}/blob/${branch.encodeURI()}/${path.encodePathURI()}${lineNumber ? `#L${lineNumber}` : ""}`;
  }

  public static checkId(baseUrl: string, orgLogin: string, repoName: string, pullRequestNumber: number, checkId: number): string {
    return `${this.pullRequest(baseUrl, orgLogin, repoName, pullRequestNumber)}/checks?check_run_id=${checkId}`;
  }

  /**
   * Link to a comment on specific lines of the pull request (during a pull request review)
   */
  public static pullRequestAnnotationComment(baseUrl: string, orgLogin: string, repoName: string, pullRequestNumber: number, commentId: number): string {
    return `${this.pullRequest(baseUrl, orgLogin, repoName, pullRequestNumber)}#discussion_r${commentId}`;
  }

  /**
   * Link to a general comment in the pull request (not posted during review)
   */
  public static pullRequestIssueComment(baseUrl: string, orgLogin: string, repoName: string, pullRequestNumber: number, commentId: number): string {
    return `${this.pullRequest(baseUrl, orgLogin, repoName, pullRequestNumber)}#issuecomment-${commentId}`;
  }

  /**
   * Link to a general comment in the pull request (not posted during review)
   */
  public static webhook(baseUrl: string, orgLogin: string, repoName: string, webhookId: number): string {
    return `${this.repoUrl(baseUrl, orgLogin, repoName)}/settings/hooks/${webhookId}`;
  }
}
