export interface AdoRepositoryIdentifier {
  readonly projectId: string;
  readonly repositoryId: string;
}

export const AdoRepositoryIdentifier = {
  parse: (id: string): AdoRepositoryIdentifier => {
    const [projectId, repositoryId, ...rest] = id.split("/");
    if (!projectId || !repositoryId || rest.length) {
      throw new Error(`Invalid repository identifier: ${id}`);
    }
    return {
      projectId: projectId.decodeURI(),
      repositoryId: repositoryId.decodeURI()
    };
  },
  canParse: (id: string): boolean => {
    try {
      const [projectId, repositoryId, ...rest] = id.split("/");
      return !!projectId && !!repositoryId && !rest.length;
    } catch {
      return false;
    }
  },
  encode: ({ projectId, repositoryId }: AdoRepositoryIdentifier): string => {
    return `${projectId.encodeURI()}/${repositoryId.encodeURI()}`;
  },
  getId: (projectId: string | undefined, repositoryId: string): string => {
    if (!projectId || AdoRepositoryIdentifier.canParse(repositoryId)) {
      return repositoryId;
    }
    return AdoRepositoryIdentifier.encode({ projectId, repositoryId });
  }
} as const;
