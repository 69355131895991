import Vue from "vue";
import { Labels as Api } from "@/api";

class LabelsState {
  private labels: Promise<string[]> | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public list(refresh = false): Promise<string[]> {
    if (refresh || !this.labels) {
      this.labels = Api.list();
    }
    return this.labels;
  }
}

export const Labels = new LabelsState();
