import { Organization } from "../../../dynamo";

export enum Tier {
  free = 0,
  team = 10,
  business = 20,
  enterprise = 30
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Tier {
  export function fromOrg(org?: Organization | null, fallback = Tier.free): Tier {
    return org?.tier ?? fallback;
  }
  export function is(value: unknown): value is Tier {
    return typeof value === "number" && VALUES.has(value as Tier);
  }
  export function tryParse(value?: string | number): Tier | null {
    if (Tier.is(value)) {
      return value;
    }
    if (typeof value === "string") {
      const tier = Tier[value as keyof typeof Tier];
      return Tier.is(tier) ? tier : null;
    }
    return null;
  }
  Object.hideFunctions(Tier);
  const VALUES = new Set(Object.values(Tier).filter((v) => typeof v === "number")) as Set<Tier>;
}

export type TierName = keyof PickPropertiesImplementing<typeof Tier, number>;

export const TierName = {
  fromTier(tier: Tier): TierName {
    return typeof tier === "number" ? (Tier[tier] as TierName) : tier;
  }
};

/**
 * @deprecated Use `Tier` instead.
 */
export const Tiers = {
  /**
   * @deprecated Use `Tier.fromOrg` instead.
   */
  fromOrg(org?: Organization | null, fallback = Tier.free): Tier {
    return org?.tier ?? fallback;
  }
};
