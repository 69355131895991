import { WebhookPushContext } from "../github/interfaces/commit-info";
import { Mitigation } from "./mitigation";

// import {
//   CommentOnCommit,
//   CommentOnPR,
//   NotifyInChannelIfNoResponseWithinXMinutes,
//   SecretDetectionNotifySlackChannel,
//   SendDmToUser,
//   SendDmToUserAndRevertIfNoAnswerWithinXMinutes,
// } from "../default-policy";

export function policyRuleTypeAndRankSortKey(ruleType: PolicyRuleTypes, ruleRank: number): string {
  // ruleType has to go first, so we can only use "BEGINS_WITH" in dynamodb
  return `${ruleType}_${ruleRank}`;
}

export interface PolicyRuleDynamoData<TAction extends PolicyActions> {
  ruleTypeAndRank: string;
  arnicaOrgId: string;
  rule: PolicyRule<TAction>;
  defaultRule: boolean;
  defaultRuleIsModified?: boolean;
}

export interface PolicyRuleScope {
  type: ScopeType;
  organizationTheRepositoryBelongsTo?: string;
  name: string;
  allOrganizations: boolean;
}

export type PolicyActionLookup = {
  [PolicyRuleTypes.ANOMALOUS_COMMITS_FOUND]: FindingsPolicyActions;
  [PolicyRuleTypes.ANOMALOUS_COMMIT_CONFIGURATION]: AnomalousCommitConfiguration;
  [PolicyRuleTypes.EXCESSIVE_PERMISSIONS]: unknown;
  [PolicyRuleTypes.SECRET_DETECTION]: SecretDetectionCommitActions;
  [PolicyRuleTypes.SELF_SERVICE]: SelfServiceActions;
};

export type PolicyActions = PolicyActionLookup[keyof PolicyActionLookup];

export interface PolicyRule<T extends PolicyActions> {
  scope: PolicyRuleScope;
  condititons: {
    [RuleConditionTypes.AND]?: PolicyRuleCondition[];
    [RuleConditionTypes.OR]?: PolicyRuleCondition[];
  };
  actions: T[];
}

export enum RuleConditionTypes {
  AND = "AND",
  OR = "OR"
}

//************************************************************************
// IMPORTANT NOTE: Adding an underscore("_") in new policy types,
// will require a logic change in "policyRuleTypeAndRankSortKey"
//************************************************************************
export enum PolicyRuleTypes {
  EXCESSIVE_PERMISSIONS = "excessive-permissions",
  SELF_SERVICE = "self-service",
  SECRET_DETECTION = "secret-detection",
  ANOMALOUS_COMMITS_FOUND = "anomalous-commits-found",
  ANOMALOUS_COMMIT_CONFIGURATION = "anomalous-commit-configuration"
}
export type ScopeType = "repository" | "organization";

type DefinitionPoliciesConditions = CodeContributorNoActionDaysAgo | AdminNoActionDaysAgo;

type SelfServicePoliciesConditions =
  | AdminRequestsPermission
  | AdminRequestsPermissionThatWasMitigated
  | CodeContributorRequestsPermission
  | CodeContributorRequestsPermissionThatWasMitigated;

type SecretDetectionPoliciesConditions = VerifiedSecretFoundInCommit;

type AnomalousCommitsConfigurationPoliciesConditions = AnomalousCommitConfiguration;

export type AnomalousCommitPoliciesConditions = AnomalousCommitRiskyCodeTermsFound | AnomalousCommitAuthorBehavior | AnomalousCommitPusher;

export type PolicyRuleCondition =
  | DefinitionPoliciesConditions
  | SelfServicePoliciesConditions
  | SecretDetectionPoliciesConditions
  | AnomalousCommitPoliciesConditions
  | AnomalousCommitsConfigurationPoliciesConditions;

export enum AnomalyTypes {
  RISKY_CODE_TERM = "risky_code_term",
  AUTHOR_BEHAVIOR = "author_behavior",
  ANOMALOUS_PUSHER = "anomalous_pusher"
}

export enum ConfigurationTypes {
  ANOMALOUS_COMMITS_CONFIGURATION = "anomalous-commit-configuration"
}

interface ExcessivePermissionPolicyRules {
  noActionInTheLast: number;
  userWasAddedToRepo: number;
}

interface CodeContributorNoActionDaysAgo extends ExcessivePermissionPolicyRules {
  type: "code-contributor-no-action";
}
interface AdminNoActionDaysAgo extends ExcessivePermissionPolicyRules {
  type: "admin-no-action";
}

interface CodeContributorRequestsPermission {
  type: "code-contributor-requests-permission";
}

export interface CodeContributorRequestsPermissionThatWasMitigated {
  type: "code-contributor-requests-permission-that-was-mitigated";
  mitigatedDaysAgo: number;
}

interface AdminRequestsPermission {
  type: "admin-requests-permission";
}
export interface AdminRequestsPermissionThatWasMitigated {
  type: "admin-requests-permission-that-was-mitigated";
  mitigatedDaysAgo: number;
}
export interface VerifiedSecretFoundInCommit {
  type: "verified-secret-was-found-in-commit";
}
export interface AnomalousCommitRiskyCodeTermsFound {
  type: AnomalyTypes.RISKY_CODE_TERM;
}
export interface AnomalousCommitAuthorBehavior {
  type: AnomalyTypes.AUTHOR_BEHAVIOR;
}
export interface AnomalousCommitPusher {
  type: AnomalyTypes.ANOMALOUS_PUSHER;
}
export interface AnomalousCommitConfiguration {
  type: ConfigurationTypes.ANOMALOUS_COMMITS_CONFIGURATION;
  accuracy: number;

  numberOfTimesUserCanBeNotifiedAfterConfirming: number;
  numberOfDaysConfirmationLimitAppliesTo: number;

  numberOfTimesUserCanBeNotified: number;
  numberOfDaysNotificationLimitAppliesTo: number;
}

export type PolicyEvaluationEventContext = SelfServiceEventContext | SecretDetectionEventContext | AnomalousCommitEventContext;

export interface SelfServiceEventContext {
  type: PolicyRuleTypes.SELF_SERVICE;
  orgName: string;
  repositoryName: string;
  selectedAccessTypeGitHubApiValue: SelfServiceAccessInternalType;
  mitigations: Mitigation[];
  githubUsernameOfRequester: string;
}

export interface SecretDetectionEventContext extends WebhookPushContext {
  type: PolicyRuleTypes.SECRET_DETECTION;
}

export interface AnomalousCommitEventContext {
  type: PolicyRuleTypes.ANOMALOUS_COMMITS_FOUND;
  anomalousTypes: AnomalyTypes[];
  orgName: string;
  repositoryName: string;
}

export interface SecretDetectionEventContextPartial {
  type: PolicyRuleTypes.SECRET_DETECTION;
  secretIsVerified: boolean;
  verificationResult: string;
  repositoryName: string;
  orgName: string;
}

export interface SecretDetectionEventContextFull extends SecretDetectionEventContextPartial {
  // commitMessageOfCommitWithSecret: string;
  branchName: string;
  // author: string;
  // category: string;
  // specificType: string;
  filename: string;
  lineNumber: number;
  arnicaOrgId: string;
  integrationOrgIdAndType: string;
  commitSha: string;
  filePathFromRepositoryRoot: string;
  secretIsVerified: boolean;
}

export enum SelfServiceAccessInternalType {
  RepositoryWrite = "push",
  RepositoryMaintain = "maintain",
  RepositoryAdmin = "admin",
  OrganizationOwner = "org-owner"
}

export enum SelfServiceAccessDisplayType {
  RepositoryWrite = "Repository Write",
  RepositoryMaintain = "Repository Maintain",
  RepositoryAdmin = "Repository Admin",
  OrganizationOwner = "Organization Owner"
}

export enum SelfServiceActionTypes {
  NOTIFY_APPROVER_OF_REQUEST_BY_SLACK = "notify-approver-of-request-by-slack",
  GRANT_ACCESS = "grant-access"
}

export type SelfServiceActions = SelfServiceNotifyApproverOfRequestBySlack | SelfServiceGrantAccess;

export interface SelfServiceNotifyApproverOfRequestBySlack {
  type: SelfServiceActionTypes.NOTIFY_APPROVER_OF_REQUEST_BY_SLACK;
  slackChannelName: string;
  integrationOrgId: string;
  isPrivateChannel: boolean;
  integrationDisplayName: string;
}

interface SelfServiceGrantAccess {
  type: SelfServiceActionTypes.GRANT_ACCESS;
}

export interface OptionGroups {
  label: {
    type: "plain_text";
    text: string;
    emoji?: boolean;
  };
  options: OptionsForGroups[];
}

export interface OptionsForGroups {
  text: {
    type: "plain_text";
    text: string;
    emoji?: boolean;
  };
  value?: string;
  url?: string;
}

export type FindingsPolicyActions =
  | CommentOnCommit
  | CommentOnPRIfApplicable
  | AddAsAdditionalReviewerOnPRIfApplicable
  | CommentAndTagInACommit
  | CommentAndTagInACommentOnPRIfApplicable
  | SlackNotifyWithAction
  | SlackNotifyNoAction;

export type SecretDetectionCommitActions = FindingsPolicyActions | RewriteHistory | CreateMitigatedBranchWithMaskedSecrets;
export type AnomalousCommitActions = FindingsPolicyActions;

export interface SecretDetectSCMEntitiesGeneric {
  type: "user" | "team";
  identifier: string;
}

export interface SecretDetectSCMEntitiesWithIntegrationOrgId extends SecretDetectSCMEntitiesGeneric {
  integrationOrgId: string;
}

interface EntitiesBase {
  identifier?: string;
  integrationOrgId?: string;
  channelIsPrivate?: boolean;
}

export interface SecretDetectEntities extends EntitiesBase {
  type: "commit-pusher" | "channel" | "user";
}
export interface GithubCommentEntities extends EntitiesBase {
  type: "commit-pusher" | "team" | "user";
}

export interface EntitiesToTag {
  type: "user" | "team" | "commit-pusher";
  identifier: string;
}

export enum SlackNotificationActionTypes {
  SEND_INTERACTIVE_MESSAGE = "send-interactive-message",
  NOTIFY = "notify",
  REWRITE_HISTORY = "rewrite-history",
  CREATE_MITIGATED_BRANCH_WITH_MASKED_SECRETS = "create-mitigated-branch-with-masked-secrets",
  COMMENT_ON_COMMIT = "comment-on-commit",
  COMMENT_ON_PR_IF_APPLICABLE = "comment-on-pr-if-applicable",
  ADD_ADDITIONAL_REVIEWER_ON_PR_IF_APPLICABLE = "add-additional-reviewer-on-pr-if-applicable",
  COMMENT_AND_TAG_IN_A_COMMENT_ON_COMMIT = "comment-and-tag-in-a-comment-on-commit",
  COMMENT_AND_TAG_IN_A_COMMENT_ON_PR_IF_APPLICABLE = "comment-and-tag-in-a-comment-on-pr-if-applicable"
}

export type PolicyActionType = SelfServiceActionTypes | SlackNotificationActionTypes;

export interface SlackNotifyNoAction {
  type: SlackNotificationActionTypes.NOTIFY;
  entities: SecretDetectEntities[];
}

// (4) Send notification [in slack] and request action from [a single user / channel]
export interface SlackNotifyWithAction {
  type: SlackNotificationActionTypes.SEND_INTERACTIVE_MESSAGE;
  entity: SecretDetectEntities;
}

interface RewriteHistory {
  type: SlackNotificationActionTypes.REWRITE_HISTORY;
}

interface CreateMitigatedBranchWithMaskedSecrets {
  type: SlackNotificationActionTypes.CREATE_MITIGATED_BRANCH_WITH_MASKED_SECRETS;
}

interface CommentOnCommit {
  type: SlackNotificationActionTypes.COMMENT_ON_COMMIT;
}
interface CommentOnPRIfApplicable {
  type: SlackNotificationActionTypes.COMMENT_ON_PR_IF_APPLICABLE;
}

export interface AddAsAdditionalReviewerOnPRIfApplicable {
  type: SlackNotificationActionTypes.ADD_ADDITIONAL_REVIEWER_ON_PR_IF_APPLICABLE;
  entities: SecretDetectSCMEntitiesWithIntegrationOrgId[];
}

export interface CommentAndTagInACommit {
  type: SlackNotificationActionTypes.COMMENT_AND_TAG_IN_A_COMMENT_ON_COMMIT;
  entities: GithubCommentEntities[];
}
export interface CommentAndTagInACommentOnPRIfApplicable {
  type: SlackNotificationActionTypes.COMMENT_AND_TAG_IN_A_COMMENT_ON_PR_IF_APPLICABLE;
  entities: GithubCommentEntities[];
}

// export type SecretDetectionConditions = VerifiedSecretFoundInCommit | AnomalousCommitFound;

export interface PartialCommitData {
  findingUniqueTypes: string[];
  containsSecret: boolean;
  sha: string;
  author?: string;
  timestamp: number;
}
