import { QueryResult } from "neo4j-driver";
import { IntegrationOrgIdAndType, UniversalScmIntegrationType } from "../../../dynamo";
import { InventoryReportItem } from "../inventory-report";

export class UniversalScmUsersInventoryItem implements InventoryReportItem {
  public static readonly userId = "userId";
  public static readonly username = "username";
  public static readonly displayName = "displayName";
  public static readonly mentionTag = "mentionTag";
  public static readonly emailAddress = "emailAddress";
  public static readonly avatarUrl = "avatarUrl";
  public static readonly isServiceAccount = "isServiceAccount";
  public static readonly integrationOrgId = "integrationOrgId";
  public static readonly integrationType = "integrationType";
  public static readonly integrationOrgIdAndType = "integrationOrgIdAndType";
  public static readonly baseUrl = "baseUrl";

  public constructor(
    public id: string,
    public username: string,
    public displayName: string,
    public mentionTag: string,
    public emailAddress: string | null,
    public avatarUrl: string | null,
    public isServiceAccount: boolean,
    public integrationOrgId: string,
    public integrationType: UniversalScmIntegrationType,
    public integrationOrgIdAndType: IntegrationOrgIdAndType,
    public baseUrl: string
  ) {}

  public static fromQueryResult(queryResult: QueryResult): UniversalScmUsersInventoryItem[] {
    const usersInventory: UniversalScmUsersInventoryItem[] = [];
    for (const record of queryResult.records) {
      const id: string = record.get(UniversalScmUsersInventoryItem.userId);
      const username: string = record.get(UniversalScmUsersInventoryItem.username);
      const displayName: string = record.get(UniversalScmUsersInventoryItem.displayName);
      const mentionTag: string = record.get(UniversalScmUsersInventoryItem.mentionTag);
      const emailAddress: string | null = record.get(UniversalScmUsersInventoryItem.emailAddress);
      const avatarUrl: string | null = record.get(UniversalScmUsersInventoryItem.avatarUrl);
      const isServiceAccount: boolean = record.get(UniversalScmUsersInventoryItem.isServiceAccount);
      const integrationOrgId: string = record.get(UniversalScmUsersInventoryItem.integrationOrgId);
      const integrationType: UniversalScmIntegrationType = record.get(UniversalScmUsersInventoryItem.integrationType);
      const integrationOrgIdAndType: IntegrationOrgIdAndType = record.get(UniversalScmUsersInventoryItem.integrationOrgIdAndType);
      const baseUrl: string = record.get(UniversalScmUsersInventoryItem.baseUrl);

      const universalScmUserInventory = new UniversalScmUsersInventoryItem(
        id,
        username,
        displayName,
        mentionTag,
        emailAddress,
        avatarUrl,
        isServiceAccount,
        integrationOrgId,
        integrationType,
        integrationOrgIdAndType,
        baseUrl
      );
      usersInventory.push(universalScmUserInventory);
    }
    return usersInventory;
  }

  public static getGroupUserId(user: Pick<UniversalScmUsersInventoryItem, "integrationOrgIdAndType" | "id">): string {
    return `${user.integrationOrgIdAndType.encodeURI()}/${user.id.encodeURI()}`;
  }
}
