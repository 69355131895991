import { AxiosError } from "axios";
import { IngestionErrorProperties } from "../ingestion-complete";
import { bytes, truncateByBytes } from "./strings.backend";

export type MultiErrorItem = { context: string; message: string };

//TODO: do the same for octokit errors
/**
 * Axios sends a LOT of information in an Axios Error, we don't want to send all this to the UI, this removes extra details.
 */
export class AxiosErrorWrapper extends Error {
  /**
   * e.g. ETIMEDOUT etc (axiosError.code)
   */
  public errorCode: string | undefined;
  /**
   * e.g. 404 etc. (e.response.status)
   */
  public httpStatus: number | undefined;
  /**
   * e.g. 404 etc. (e.response.statusText)
   */
  public httpStatusText: string | undefined;
  /**
   * the JSON.stringify of e.response.data
   */
  public responseData: Record<string, any> | undefined;

  /**
   * ADO Specific (backward compatibility)
   */
  public typeKey?: string;

  public constructor(e: AxiosError) {
    const responseData = AxiosErrorWrapper.getResponseData(e);
    super(AxiosErrorWrapper.getMessageFromAxiosError(e, responseData));
    this.responseData = responseData;

    this.errorCode = e.code;
    this.httpStatus = e.response?.status;
    this.httpStatusText = e.response?.statusText;
    Object.assign(this, responseData); // may override stuff from above
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, AxiosErrorWrapper.prototype);
  }

  private static getMessageFromAxiosError(e: AxiosError, responseData: Record<string, any> | undefined) {
    //TODO: remove a field if it's undefined
    let responseDataString;
    if (typeof responseData === "object") {
      responseDataString = JSON.stringify(responseData);
    } else {
      responseDataString = String(responseData);
    }
    return `responseData: ${responseDataString}, status: ${e.response?.status}, statusText: ${e.response?.statusText}, message: ${e?.message}, code: ${e?.code}`;
  }

  private static getResponseData(e: AxiosError): Record<string, any> | undefined {
    let responseData = e?.response?.data;
    if (responseData && typeof responseData === "string") {
      try {
        responseData = JSON.parse(responseData);
      } catch (e) {}
    }
    return responseData;
  }
}

/**
 * @deprecated use Error.stringify instead
 */
export function stringifyError(e?: unknown) {
  return Error.stringify(e);
}

export class MultiError extends Error {
  public constructor(public messages: MultiErrorItem[]) {
    super(messages.map((m) => `Error in [${m.context}]: ${m.message}`).join(", "));
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, MultiError.prototype);
  }
}

export function truncateErrorMessage(ingestionCompleteData: IngestionErrorProperties, e: Error): string {
  const messageSoFarBytes = bytes(JSON.stringify(ingestionCompleteData));
  const maxSQSBytes = 256_000;
  const buffer = 1_000;
  const bytesLeft = maxSQSBytes - buffer - messageSoFarBytes;
  return truncateByBytes(e.message, bytesLeft);
}
