import { LicenseClassification } from "../../../../../licenses";
import { PolicyItemType } from "../../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_license_classification = "package_license_classification"
  }
}

(PolicyConditionSubType as { package_license_classification: "package_license_classification" }).package_license_classification = "package_license_classification";

export interface PolicyConditionFindingPackageLicenseClassification extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_license_classification;
  /**
   * One or more license classifications
   */
  classifications: LicenseClassification[];
}

export const PolicyConditionFindingPackageLicenseClassification = {
  CLASSIFICATIONS: LicenseClassification,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_license_classification
  }
} as const;
