import { Dialog, HtmlContent } from "@/state";
import { Popup } from "@/state";

class ClipboardApi {
  /**
   * Tries to write to the clipboard.
   * @param content The content to write to the clipboard.
   * @returns Whether writing was successful.
   */
  public async tryWrite(content: string | ClipboardItems): Promise<boolean> {
    try {
      if (typeof content === "string") {
        await window.navigator.clipboard.writeText(content);
      } else {
        await window.navigator.clipboard.write(content);
      }
      return true;
    } catch {
      return false;
    }
  }

  /**
   * Writes to the clipboard.
   * @param content The content to write to the clipboard.
   * @throws {Error} If writing was unsuccessful.
   */
  public async write(content: string | ClipboardItems): Promise<void> {
    if (!(await this.tryWrite(content))) {
      throw new Error("Failed to write to clipboard");
    }
  }

  /**
   * Tries to write to the clipboard, and if unsuccessful, displays a dialog with the fallback content.
   * @param type The type (name) of content being copied.
   * @param content The content to write to the clipboard.
   * @param fallbackPopContent Optional. The fallback content to display in a dialog if writing was unsuccessful. @default `<pre>${content}</pre>`
   */
  public async writeOrPop(type: string, content: string, fallbackPopContent?: string | HtmlContent): Promise<void> {
    if (await this.tryWrite(content)) {
      void Popup.info(`${type} copied to clipboard`);
      return;
    }
    await Dialog.open({
      title: type,
      content: fallbackPopContent ?? new HtmlContent(`<pre>${content}</pre>`),
      actions: [{ name: "OK", submit: true, value: true, color: "success" }]
    });
  }
}

export const Clipboard = new ClipboardApi();
