import { uuid } from "../uuid";
import { UILocationAsset } from "./ui-location-asset";
import { UIRepositoryAsset } from "./ui-repository-asset";
import { UIProductOwner, UIProductOwnerDTO } from "./ui-product-owner";
import { UIProductLabel } from "./ui-product-label";
import { BusinessImportanceLabel } from "../../business-importance";

export type UIProductManagement = "manual" | "automatic";

export interface UIProductDTO {
  readonly tenantId: string;
  readonly id: uuid;
  readonly name: string;
  readonly management: UIProductManagement;
  readonly hasDeprecatedAssets: boolean;
  readonly lastUpdated: string;
  readonly businessImportance: BusinessImportanceLabel;

  readonly locationAssets: UILocationAsset[];
  readonly repositoryAssets: UIRepositoryAsset[];
  readonly owners: UIProductOwnerDTO[];
  readonly labels: UIProductLabel[];
}

export class UIProduct {
  public readonly tenantId: string;
  public readonly id: uuid;
  public readonly name: string;
  public readonly management: UIProductManagement;
  public readonly hasDeprecatedAssets: boolean;
  public readonly lastUpdated: Date;
  public readonly labels: UIProductLabel[];
  public readonly businessImportance: BusinessImportanceLabel;

  public readonly locationAssets: UILocationAsset[];
  public readonly repositoryAssets: UIRepositoryAsset[];
  public readonly owners: UIProductOwner[];

  public get securityChampions(): UIProductOwner[] {
    return this.owners.filter((o) => o.type === "securityChampion");
  }

  public get productOwners(): UIProductOwner[] {
    return this.owners.filter((o) => o.type === "productOwner");
  }

  public constructor(dto: UIProductDTO) {
    Object.assign(this, dto);
    this.lastUpdated = new Date(dto.lastUpdated);
    this.owners = dto.owners.map((owner) => new UIProductOwner(owner));
  }
}

export interface FieldConfiguration {
  title: string;
  showByDefault: boolean;
  sortable: boolean;
  filterable: boolean;
}
export const ProductFieldConfigs: PartialRecord<keyof UIProduct, FieldConfiguration> = {
  name: {
    title: "Name",
    showByDefault: true,
    sortable: true,
    filterable: false
  },
  management: {
    title: "Management",
    showByDefault: true,
    sortable: true,
    filterable: true
  },
  hasDeprecatedAssets: {
    title: "Has Deprecated Assets",
    showByDefault: false,
    sortable: false,
    filterable: true
  },
  lastUpdated: {
    title: "Last Updated",
    showByDefault: true,
    sortable: true,
    filterable: false
  },
  labels: {
    title: "Labels",
    showByDefault: true,
    sortable: false,
    filterable: true
  },
  businessImportance: {
    title: "Business Importance",
    showByDefault: true,
    sortable: true,
    filterable: true
  },
  securityChampions: {
    title: "Security Champions",
    showByDefault: false,
    sortable: false,
    filterable: true
  },
  productOwners: {
    title: "Product Owners",
    showByDefault: false,
    sortable: false,
    filterable: true
  }
} as const;
export type ProductHeaderType = keyof typeof ProductFieldConfigs;

/* Filtering */
const filterFields = Object.entries(ProductFieldConfigs)
  .filter(([, config]) => config.filterable)
  .map(([name]) => name);
export type ProductFilterField = (typeof filterFields)[number];
export interface ProductFilter {
  management: UIProductManagement[];
  hasDeprecatedAssets: boolean | undefined;
  businessImportance: BusinessImportanceLabel[];
  labels: string[];
  securityChampions: uuid[];
  productOwners: uuid[];
}
export type ProductBooleanFilterKeys = KeysOfType<ProductFilter, boolean | undefined>;

export function isProductFilterField(key: unknown): key is keyof ProductFilterField {
  return typeof key === "string" && filterFields.includes(key as keyof ProductFilter);
}
export const defaultProductFilters = {
  management: [] as UIProductManagement[],
  hasDeprecatedAssets: undefined,
  businessImportance: [] as BusinessImportanceLabel[],
  labels: [] as string[],
  securityChampions: [],
  productOwners: []
} as const satisfies ProductFilter;

/* Sorting */
type SortType = "asc" | "desc";
export interface ProductSort {
  name: SortType;
  management: SortType;
  lastUpdated: SortType;
  businessImportance: SortType;
}
export type ProductSortField = keyof ProductSort;
const sortFields: ProductSortField[] = ["name", "management", "lastUpdated", "businessImportance"];
export function isProductSortField(key: unknown): key is keyof ProductSortField {
  return typeof key === "string" && sortFields.includes(key as keyof ProductSort);
}
