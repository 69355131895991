import {
  DismissedRiskReportItem,
  RiskReportItemType,
  generateADOBranchRiskItemId,
  generateADOProjectRiskItemId,
  generateADORepoRiskItemId,
  generateGithubBranchRiskItemId,
  generateGithubRepoRiskItemId,
  generateOrgRiskItemId
} from "../dynamo";
import { SCMType } from "../scm-type";
import { resourcePath } from "../utils";
import { isRiskreportAppItem, isRiskreportIdentityItem } from "./report-utils";
import { ADOAsset, GitHubRiskReportAppItem, GitHubRiskReportIdentityItem, RiskReport, RiskReportIdentityItem, RiskReportType } from "./risk-report/risk-report";

export class DismissedItemsFilter {
  public filterOutDismissedItems(report: RiskReport<RiskReportType>, dismissals: DismissedRiskReportItem[]): void {
    report.reportItems = this.filterDismissedIdentities(report.reportItems, dismissals);
    if (report.summary.scmType === SCMType.GITHUB) {
      report.reportItems = this.githubReportItemsWithoutDismissedItems(report.reportItems as GitHubRiskReportIdentityItem[], dismissals);
    } else if (report.summary.scmType === SCMType.AZURE_DEVOPS) {
      if (report.reportItems.length !== 0 && !(report.reportItems as RiskReportIdentityItem[]).every((rep) => isRiskreportIdentityItem(rep))) {
        throw new Error("Missing expected risk report item properties");
      }
      report.reportItems = this.adoReportItemsWithoutDismissedItems(report.reportItems as RiskReportIdentityItem[], dismissals);
    } else {
      throw new Error("Unknown SCM type in report");
    }
  }

  private filterDismissedIdentities(
    reportItems: RiskReportIdentityItem[] | GitHubRiskReportAppItem[],
    dismissals: DismissedRiskReportItem[]
  ): RiskReportIdentityItem[] | GitHubRiskReportAppItem[] {
    const identitiesDismissalsMap = this.generateDismissalsMapFromIdentities(dismissals);
    const appsDismissalsMap = this.generateDismissalsMapFromApps(dismissals);
    if ((reportItems as RiskReportIdentityItem[]).every((item) => isRiskreportIdentityItem(item))) {
      return (reportItems as RiskReportIdentityItem[]).filter((reportItem) => {
        const removedIds = new Set<string>();
        reportItem.identities = (reportItem.identities ?? []).filter((identity) => {
          //Not dismissed
          const dismissedOrgsForIdentity = identitiesDismissalsMap.get(identity);
          if (!dismissedOrgsForIdentity) {
            return true;
          }
          //Dismissed for all orgs
          if (dismissedOrgsForIdentity.size === 0) {
            removedIds.add(identity);
            return false;
          }
          //Dismissed to an org which matches the org in this report item
          if (dismissedOrgsForIdentity.has(reportItem.asset!.org)) {
            removedIds.add(identity);
            return false;
          }
          //Not dismissed to this org
          return true;
        });
        //Filtering out the removed profile names
        reportItem.profileNames = reportItem.profileNames?.filter((profileName) => !removedIds.has(profileName.identity));
        return reportItem.identities.length !== 0;
      });
    } else if ((reportItems as GitHubRiskReportAppItem[]).every((item) => isRiskreportAppItem(item))) {
      return (reportItems as GitHubRiskReportAppItem[]).filter((reportItem) => {
        return !appsDismissalsMap.get(resourcePath(reportItem.asset.org, reportItem.appFullName));
      });
    } else {
      throw new Error("Risk Item type is not supported");
    }
  }

  private githubReportItemsWithoutDismissedItems(
    reportItems: GitHubRiskReportIdentityItem[] | GitHubRiskReportAppItem[],
    dismissals: DismissedRiskReportItem[]
  ): RiskReportIdentityItem[] | GitHubRiskReportAppItem[] {
    const dismissalsMap = this.generateDismissalsMapFromDismissalItems(dismissals);
    if (reportItems && reportItems.length && reportItems.length !== 0) {
      if ((reportItems as RiskReportIdentityItem[]).every((rep) => isRiskreportIdentityItem(rep))) {
        return (reportItems as GitHubRiskReportIdentityItem[]).filter((reportItem) => {
          const dismissedGroups = dismissalsMap.get(RiskReportItemType.GROUP);
          const dismissedOrgs = dismissalsMap.get(RiskReportItemType.ORG);
          const dismissedRepos = dismissalsMap.get(RiskReportItemType.REPO);
          const dismissedBranches = dismissalsMap.get(RiskReportItemType.BRANCH);

          if (reportItem.group && reportItem.teamSlug && dismissedGroups) {
            if (dismissedGroups.has(resourcePath(reportItem.asset.org, reportItem.teamSlug))) {
              return false;
            }
          }
          if (dismissedOrgs?.has(generateOrgRiskItemId(reportItem.asset.org))) {
            return false;
          }
          if (reportItem.asset.repo && dismissedRepos) {
            if (dismissedRepos.has(generateGithubRepoRiskItemId(reportItem.asset.org, reportItem.asset.repo))) {
              return false;
            }
          }
          if (reportItem.asset.repo && reportItem.asset.branch && dismissedBranches) {
            if (dismissedBranches.has(generateGithubBranchRiskItemId(reportItem.asset.org, reportItem.asset.repo, reportItem.asset.branch))) {
              return false;
            }
          }
          return true;
        });
      } else if ((reportItems as GitHubRiskReportAppItem[]).every((rep) => isRiskreportAppItem(rep))) {
        const dismissedAppFullNames = dismissalsMap.get(RiskReportItemType.APP_FULL_NAME);
        const dismissedOrgs = dismissalsMap.get(RiskReportItemType.ORG);
        return (reportItems as GitHubRiskReportAppItem[]).filter((reportItem) => {
          if (reportItem.appFullName && dismissedAppFullNames) {
            if (dismissedAppFullNames.has(resourcePath(reportItem.asset.org, reportItem.appFullName))) {
              return false;
            }
          }
          return !dismissedOrgs?.has(generateOrgRiskItemId(reportItem.asset.org));
        });
      } else {
        throw new Error("Risk report item type is not supported");
      }
    }

    return [];
  }

  private adoReportItemsWithoutDismissedItems(reportItems: RiskReportIdentityItem[], dismissals: DismissedRiskReportItem[]): RiskReportIdentityItem[] {
    const dismissalsMap = this.generateDismissalsMapFromDismissalItems(dismissals);
    return reportItems.filter((reportItem) => {
      const dismissedGroups = dismissalsMap.get(RiskReportItemType.GROUP);
      const dismissedOrgs = dismissalsMap.get(RiskReportItemType.ORG);
      const dismissedProjects = dismissalsMap.get(RiskReportItemType.PROJECT);
      const dismissedRepos = dismissalsMap.get(RiskReportItemType.REPO);
      const dismissedBranches = dismissalsMap.get(RiskReportItemType.BRANCH);
      if (reportItem.group && dismissedGroups) {
        if (dismissedGroups.has(resourcePath(reportItem.asset.org, reportItem.group))) {
          return false;
        }
      }
      if (dismissedOrgs?.has(generateOrgRiskItemId(reportItem.asset.org))) {
        return false;
      }
      const adoAsset = reportItem.asset as ADOAsset;
      if (adoAsset.project && dismissedProjects) {
        if (dismissedProjects.has(generateADOProjectRiskItemId(adoAsset.org, adoAsset.project))) {
          return false;
        }
      }
      if (adoAsset.project && adoAsset.repo && dismissedRepos) {
        if (dismissedRepos.has(generateADORepoRiskItemId(adoAsset.org, adoAsset.project, adoAsset.repo))) {
          return false;
        }
      }
      if (adoAsset.project && adoAsset.repo && adoAsset.branch && dismissedBranches) {
        if (dismissedBranches.has(generateADOBranchRiskItemId(adoAsset.org, adoAsset.project, adoAsset.repo, adoAsset.branch))) {
          return false;
        }
      }
      return true;
    });
  }

  private generateDismissalsMapFromIdentities(dismissals: DismissedRiskReportItem[]): Map<string, Set<string>> {
    const identityDismissalsMap = new Map<string, Set<string>>();
    for (const dismissal of dismissals) {
      if (dismissal.riskReportItemType === RiskReportItemType.IDENTITY) {
        identityDismissalsMap.set(dismissal.riskReportItemId, new Set<string>(dismissal.orgNames));
      }
    }
    return identityDismissalsMap;
  }

  private generateDismissalsMapFromApps(dismissals: DismissedRiskReportItem[]): Map<string, Set<string>> {
    const appNameDismissalsMap = new Map<string, Set<string>>();
    for (const dismissal of dismissals) {
      if (dismissal.riskReportItemType === RiskReportItemType.APP_FULL_NAME) {
        appNameDismissalsMap.set(dismissal.riskReportItemId, new Set<string>(dismissal.orgNames));
      }
    }
    return appNameDismissalsMap;
  }

  private generateDismissalsMapFromDismissalItems(dismissals: DismissedRiskReportItem[]): Map<RiskReportItemType, Set<string>> {
    const dismissalsMap = new Map<RiskReportItemType, Set<string>>();
    dismissals.forEach((dismissal) => {
      dismissalsMap.setOrUpdate(
        dismissal.riskReportItemType,
        () => new Set<string>([dismissal.riskReportItemId]),
        (_, curr) => curr.add(dismissal.riskReportItemId)
      );
    });
    return dismissalsMap;
  }
}
