//TODO: make sure every module uses this (we have some other places with lowercase etc...)
export const SCMType = {
  AZURE_DEVOPS: "AZURE_DEVOPS",
  GITHUB: "GITHUB",
  BITBUCKET_SERVER: "BITBUCKET_SERVER",
  BITBUCKET_CLOUD: "BITBUCKET_CLOUD",
  GITLAB: "GITLAB"
} as const;

export const ALL_SCM_TYPES = Object.values(SCMType);

export type SCMType = (typeof SCMType)[keyof typeof SCMType];

/**
 * Note: not using the const / interface pattern because SCMType is already a const
 * @param value
 */
export function isSCMType(value: unknown): value is SCMType {
  return typeof value === "string" && value in SCMType;
}

const UNIVERSAL_SCM_VALUES = [SCMType.BITBUCKET_SERVER, SCMType.BITBUCKET_CLOUD, SCMType.GITLAB, SCMType.GITHUB, SCMType.AZURE_DEVOPS] as const;
export type UNIVERSAL_SCM_TYPE = (typeof UNIVERSAL_SCM_VALUES)[number];
export const UNIVERSAL_SCM_TYPE = {
  values: UNIVERSAL_SCM_VALUES,
  is(value: unknown): value is UNIVERSAL_SCM_TYPE {
    return UNIVERSAL_SCM_VALUES.includes(value as UNIVERSAL_SCM_TYPE);
  },
  some(scmTypes: SCMType[]): boolean {
    return scmTypes.some((t) => UNIVERSAL_SCM_TYPE.is(t));
  }
} as const;
