import { uuid } from "../uuid";

export type ProductOwnerType = "securityChampion" | "productOwner";

export type ProductOwnerAssignmentReason = "codeAuthor" | "codeReviewer" | "activeAdmin" | "manuallyAssigned";

export interface UIProductOwnerDTO {
  readonly type: ProductOwnerType;
  readonly identityId: uuid;
  readonly displayName: string | null;
  readonly emailAddress: string | null;
  readonly avatarUrl: string | null;
  readonly reason: ProductOwnerAssignmentReason;
  readonly comment: string | null;
  readonly lastActivityDate: string | null;
}

export class UIProductOwner {
  public readonly type: ProductOwnerType;
  public readonly identityId: uuid;
  public readonly displayName: string | null;
  public readonly emailAddress: string | null;
  public readonly avatarUrl: string | null;
  public readonly reason: ProductOwnerAssignmentReason;
  public readonly comment: string | null;
  public readonly lastActivityDate: Date | null;

  public constructor(dto: UIProductOwnerDTO) {
    Object.assign(this, dto);
    this.lastActivityDate = dto.lastActivityDate ? new Date(dto.lastActivityDate) : null;
  }
}

export interface CreateOwnersParameters {
  readonly securityChampions?: {
    readonly identityId: uuid;
  }[];

  readonly productOwners?: {
    readonly identityId: uuid;
    readonly comment?: string;
  }[];
}