import { CallbackHandlerBase } from "./callback-handler-base";
import { Integrations as Api } from "@/api";
import { router } from "@/plugins";
import { Integrations, Popup } from "@/state";

const CALLBACK_PATH = "/callback/slack";
const CODE_PARAM = "code";
const STATE_PARAM = "state";

export class SlackCallbackHandler extends CallbackHandlerBase {
  public static get path(): string {
    return CALLBACK_PATH;
  }

  protected provideUserFeedback(): void {
    void Popup.info("Integration added successfully");
  }

  public async handle(query: URLSearchParams): Promise<void> {
    try {
      const code = query.get(CODE_PARAM);
      const state = query.get(STATE_PARAM);

      if (!code && state) {
        // if you open the slack page where you must choose to "accept" or "cancel" the app authorization, if you click "cancel", it shouldn't error
        console.warn("Invalid callback");
        return;
      } else if (!code || !state) {
        throw new Error("Invalid callback");
      }

      //TODO: ensure we don't init twice in a very short time
      const storedState = localStorage.getItem("slackState");
      if (storedState !== state) {
        throw new Error("There was an error trying to integrate with Slack");
      }

      await Api.create({ type: "slack", code });

      await Integrations.init();

      this.provideUserFeedback();

      this.sendToAnalytics("slack");

      //TODO: redirect to the right place (can't do this without removing the callback first)
      window.history.replaceState(null, "", window.location.origin);
      await router.push({ name: Integrations.hasAtLeastOneSourceControlIntegration ? "admin-integrations" : "home" });
    } catch (e) {
      window.history.replaceState(null, "", window.location.origin);
      await this.handleIntegrationError(e);
    }
  }
}
