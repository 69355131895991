import Vue from "vue";
import { DEFAULT_DEMO_DATA_DETECTED_AFTER_DAYS, DEFAULT_DETECTED_AFTER_DAYS } from "$/constants/anomalies-constants";
import { InferenceResponse } from "$/dynamo/inference";
import { Reports } from "@/api";
import { Demo } from "@/state/demo-state";
import { ProfileNames } from "@/state/profile-names-state";

export const InferenceReport = new (class InferenceReportsState {
  public inferenceReport: InferenceResponse[] | null = null;

  public selectedDetectedAfterDays: number | null = null;

  constructor() {
    Vue.observable(this);
  }

  public async init() {
    if (!this.selectedDetectedAfterDays) {
      this.selectedDetectedAfterDays = Demo.enabled ? DEFAULT_DEMO_DATA_DETECTED_AFTER_DAYS : DEFAULT_DETECTED_AFTER_DAYS;
    }
    const [inferenceReport] = await Promise.all([Reports.get("inference", this.selectedDetectedAfterDays), ProfileNames.init()]);
    this.inferenceReport = inferenceReport ?? [];
  }
})();
