import Vue from "vue";
import { Topics as Api } from "@/api";

class TopicsState {
  private topics: Promise<string[]> | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public list(refresh = false): Promise<string[]> {
    if (refresh || !this.topics) {
      this.topics = Api.list();
    }
    return this.topics;
  }
}

export const Topics = new TopicsState();
