import type { PolicyTrigger, PolicyCondition, PolicyAction, PolicyItemType, PolicyItem, PolicyRule } from "$/interfaces/policies";
import { POLICY_CONDITION_DESCRIPTIONS, POLICY_ACTION_DESCRIPTIONS, POLICY_TRIGGER_DESCRIPTIONS } from "$/interfaces/policies/policy-descriptions";
import Vue from "vue";

export class PolicyRuleWrapper implements PolicyRule {
  private _keywords: string;
  public name!: string;
  public enabled!: boolean;
  public trigger!: PolicyTrigger | null;
  public condition!: PolicyCondition | null;
  public actions!: PolicyAction[];
  public id!: string;
  public type!: typeof PolicyItemType.rule;

  public constructor(rule: PolicyRule, policy: PolicyItem) {
    Object.assign(this, rule);
    new Vue({
      data: { rule: this },
      watch: {
        rule: {
          deep: true,
          handler: (curr: PolicyRule) => {
            const keywords = PolicyRuleWrapper.buildKeywords(curr, policy);
            this._keywords = keywords;
          }
        }
      }
    });
    this._keywords = PolicyRuleWrapper.buildKeywords(rule, policy);
  }

  public get keywords(): string {
    return this._keywords;
  }

  public static fromPolicy(policy: PolicyItem): PolicyRuleWrapper[] {
    return policy.rules.map((rule) => new PolicyRuleWrapper(rule, policy));
  }

  private static buildKeywords(rule: PolicyRule, policy: PolicyItem): string {
    const { trigger, actions, condition } = rule;
    return [
      `rule.id:${rule.id}`,
      `rule.enabled:${!!rule.enabled}`,
      `rule.name:${rule.name.replaceAll(" ", "")}`,
      ...this.buildTriggerKeywords(trigger, policy),
      ...this.buildConditionKeywords(condition ? [condition] : [], policy),
      ...this.buildActionsKeywords(actions, policy)
    ]
      .map((term) => term.trim().toLowerCase())
      .distinct()
      .join(" ");
  }

  private static buildTriggerKeywords(trigger: PolicyTrigger | null, policy: PolicyItem): string[] {
    const { sub, id } = trigger || {};
    // @ts-ignore - Element implicitly has an 'any' type
    const name = trigger ? POLICY_TRIGGER_DESCRIPTIONS[policy.sub][trigger.sub]?.name.replaceAll(" ", "") : "none";
    return [`trigger.sub:${sub || "none"}`, `trigger.id:${id || "none"}`, `trigger.name:${name}`];
  }

  private static buildConditionKeywords(conditions: PolicyCondition[], policy: PolicyItem): string[] {
    const conditionDescriptions = POLICY_CONDITION_DESCRIPTIONS[policy.sub];
    return conditions.flatMap((condition) => {
      const { sub, id, name, type, children, ...rest } = condition || {};
      return [
        `condition.sub:${sub || "none"}`,
        `condition.id:${id || "none"}`,
        // @ts-ignore - Element implicitly has an 'any' type
        `condition.name:${conditionDescriptions[sub]?.name.replaceAll(" ", "")}`,
        `condition[name]:${name?.replaceAll(" ", "") || "-"}`,
        ...Object.entries(rest).map(([key, value]) => `condition[${key}]:${String(value)}`),
        ...(children ? this.buildConditionKeywords(children, policy) : [])
      ];
    });
  }

  private static buildActionsKeywords(actions: PolicyAction[], policy: PolicyItem): string[] {
    const actionDescriptions = POLICY_ACTION_DESCRIPTIONS[policy.sub];
    return [
      `actions.count:${actions.length}`,
      ...actions.flatMap(({ sub, id, children, type, name, ...rest }) => [
        `action.sub:${sub}`,
        `action.id:${id}`,
        // @ts-ignore - Element implicitly has an 'any' type
        `action.name:${actionDescriptions[sub]?.name.replaceAll(" ", "")}`,
        `action[name]:${name.replaceAll(" ", "")}`,
        ...Object.entries(rest).map(([key, value]) => `action[${key}]:${String(value)}`),
        ...(children ? this.buildActionsKeywords(children, policy) : [])
      ])
    ];
  }

  public toJSON(): Omit<this, "keywords" | "_keywords" | "toJSON"> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { keywords, _keywords, toJSON, ...rest } = this;
    return rest;
  }
}
