import { DETECTOR_TASK_NAMES, DetectorTask, JobStatus, JobType, getJobTypeName } from "$/dynamo";

const STATUS_DESCRIPTORS: Record<
  JobStatus,
  {
    color: string;
    icon: string;
  }
> = {
  "permission-error": {
    color: "error",
    icon: "mdi-alert-circle"
  },
  error: {
    color: "error",
    icon: "mdi-alert-circle"
  },
  pending: {
    color: "warning",
    icon: "mdi-timer-sand"
  },
  running: {
    color: "info",
    icon: "mdi-run-fast"
  },
  complete: {
    color: "success",
    icon: "mdi-check"
  },
  "awaiting-sub-jobs": {
    color: "warning",
    icon: "mdi-clock"
  }
} as const;

const JOB_TYPE_ICONS: Record<JobType, string> = {
  "activity-scan": "mdi-graph",
  "revert-mitigation": "mdi-text-box-remove",
  "permission-grant": "mdi-account-reactivate",
  ingest: "mdi-warehouse",
  detect: "mdi-code-braces",
  mitigate: "mdi-text-box-check",
  "detect-repo": "mdi-code-braces",
  "detect-repo-scanner": "mdi-code-braces"
};

//not using satisfies because it causes a compilation issue
const JOB_STATUS_NAMES: { [key in JobStatus]?: string }= {
  "permission-error": "permission error",
  "complete": "completed",
  "running": "in progress"
} as const;

export function jobStatusColorFilter(status: JobStatus): string {
  return STATUS_DESCRIPTORS[status]?.color || "";
}

export function jobStatusIconFilter(status: JobStatus): string {
  return STATUS_DESCRIPTORS[status]?.icon || "";
}

export function jobStatusNameFilter(status: JobStatus): string {
  return JOB_STATUS_NAMES[status] || status;
}

export function jobTypeIconFilter(type: JobType): string {
  return JOB_TYPE_ICONS[type];
}

export function jobTypeNameFilter(type: JobType): string {
  return getJobTypeName(type);
}

export function detectorTypeNameFilter(type: DetectorTask): string {
  return DETECTOR_TASK_NAMES[type];
}
