<template>
  <v-card-text class="component video-dialog-content">
    <video controls disablepictureinpicture controlslist="nodownload" preload>
      <source :src="src" :type="type" />
      <p>Your browser doesn't support HTML video. Here is a <a :href="src" target="_blank">link to the video</a> instead.</p>
    </video>
  </v-card-text>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class VideoDialogContent extends Vue {
  @Prop({
    type: String,
    required: true
  })
  protected src!: string;

  @Prop({
    type: String,
    required: true
  })
  protected title!: string;

  protected get type(): string {
    return `video/${this.src.split(".").pop()}`;
  }
}
</script>
<style lang="scss">
.component.video-dialog-content {
  text-align: center;
  overflow-y: hidden !important;
  
  video {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
