import { PolicyConditionBoolean } from "../policy-conditions";
import { PolicyItemType } from "../policy-item-base";
import { PolicyRule } from "../policy-rule";
import { PolicyTriggerDetectedOnScan } from "../policy-triggers";
import { PolicyItem, PolicySubType } from "./policy-item";

declare module "./policy-item" {
  enum PolicySubType {
    prioritization = "prioritization"
  }

  interface PolicySubTypeMapper {
    [PolicySubType.prioritization]: PolicyItemPrioritization;
  }
}

(PolicySubType as { prioritization: "prioritization" }).prioritization = "prioritization";

export interface PolicyItemPrioritization extends PolicyItem {
  sub: PolicySubType.prioritization;
}

export const PolicyItemPrioritization = {
  is: (item?: PolicyItem | null): item is PolicyItemPrioritization => item?.sub === PolicySubType.prioritization,
  generateDefault(arnicaOrgId: string): PolicyItemPrioritization {
    return {
      arnicaOrgId,
      id: PolicySubType.prioritization,
      sub: PolicySubType.prioritization,
      type: PolicyItemType.policy,
      rules: [
        {
          ...PolicyRule.generateDefault(),
          condition: PolicyConditionBoolean.generateDefault(),
          trigger: PolicyTriggerDetectedOnScan.generateDefault()
        }
      ]
    };
  }
} as const;
