// @spellchecker: ignore Scms
import { ApiBase } from "./api-base";
import { CreateADOMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/create-ado-mitigation-request";
import { CreateADOStaleRepoMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/create-ado-stale-repo-mitigation-request";
import { CreateADOStaleUserMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/create-ado-stale-user-mitigation-request";
import { CreateADOUnenforceablePolicyMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/create-ado-unenforceable-policy-mitigation-request";
import { GenerateADOMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/generate-ado-mitigation-request";
import { CreateGithubMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-github-mitigation-request";
import { CreateGithubStaleRepoMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-github-stale-repo-mitigation-request";
import { CreateGithubStaleUserMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-github-stale-user-mitigation-request";
import { CreateMisconfiguredCodeownersMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-misconfigured-codeowners-mitigation-request";
import { CreateOrgOwnerMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-org-owner-mitigation-request";
import { CreateGithubUnenforceablePolicyMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-unenforceable-policy-mitigation-request";
import { DeleteMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/delete-mitigation-request";
import { GenerateGithubMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/generate-github-mitigation-request";
import { GenerateOrgOwnerMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/generate-org-owner-mitigation-request";
import { RevertMitigationRequest } from "$/interfaces/ui-api/request/revert-mitigation-request";
import { CreateADOMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/create-ado-mitigation-response";
import { CreateADOStaleRepoMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/create-ado-stale-repo-mitigation-response";
import { CreateADOStaleUserMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/create-ado-stale-user-mitigation-response";
import { CreateADOUnenforceablePolicyMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/create-ado-unenforceable-policy-response";
import { GetADOMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/get-ado-mitigation-response";
import { GetADOMitigationToApply } from "$/interfaces/ui-api/response/mitigations/ado/get-ado-mitigation-to-apply";
import { CreateGithubMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-github-mitigation-response";
import { CreateGithubStaleRepoMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-github-stale-repo-mitigation-response";
import { CreateGithubStaleUserMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-github-stale-user-mitigation-response";
import { CreateMisconfiguredCodeownersMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-misconfigured-codeowners-mitigation-response";
import { CreateOrgOwnerMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-org-owner-mitigation-response";
import { CreateGithubUnenforceablePolicyMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-unenforceable-policy-response";
import { GetGithubMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/get-github-mitigation-response";
import { GetGithubMitigationToApply, GetOrgOwnerExcessiveMitigation } from "$/interfaces/ui-api/response/mitigations/github/get-github-mitigation-to-apply";

type OpMaps = {
  github: {
    get: GetGithubMitigationResponse[];
    generate: GetGithubMitigationToApply[];
    generateOrgOwners: GetOrgOwnerExcessiveMitigation[];
    create: CreateGithubMitigationResponse[];
    createOrgOwner: CreateOrgOwnerMitigationResponse[];
    createStaleUser: {
      params: CreateGithubStaleUserMitigationRequest[];
      returns: CreateGithubStaleUserMitigationResponse[];
    };
    createStaleRepo: {
      params: CreateGithubStaleRepoMitigationRequest[];
      returns: CreateGithubStaleRepoMitigationResponse[];
    };
    createMisconfiguredCodeowners: CreateMisconfiguredCodeownersMitigationResponse[];
    createUnenforceablePolicy: {
      params: CreateGithubUnenforceablePolicyMitigationRequest[];
      returns: CreateGithubUnenforceablePolicyMitigationResponse[];
    };
  };
  ado: {
    get: GetADOMitigationResponse[];
    generate: GetADOMitigationToApply[];
    generateOrgOwners: never;
    create: CreateADOMitigationResponse[];
    createOrgOwner: never;
    createStaleUser: {
      params: CreateADOStaleUserMitigationRequest[];
      returns: CreateADOStaleUserMitigationResponse[];
    };
    createStaleRepo: {
      params: CreateADOStaleRepoMitigationRequest[];
      returns: CreateADOStaleRepoMitigationResponse[];
    };
    createMisconfiguredCodeowners: never;
    createUnenforceablePolicy: {
      params: CreateADOUnenforceablePolicyMitigationRequest[];
      returns: CreateADOUnenforceablePolicyMitigationResponse[];
    };
  };
};

type OpScms = keyof OpMaps;

class MitigationsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "mitigations", name: "MitigationsApi" });
  }

  public async get<T extends OpScms>(scm: T): Promise<OpMaps[T]["get"]> {
    const res = await this.client.get<OpMaps[T]["get"]>(scm);
    return res.data;
  }

  public async generate<T extends OpScms>(scm: T, generateMitigationRequest: GenerateGithubMitigationRequest | GenerateADOMitigationRequest): Promise<OpMaps[T]["generate"]> {
    const res = await this.client.post<OpMaps[T]["generate"]>(`${scm}/generate`, generateMitigationRequest);
    return res.data;
  }

  public async generateOrgOwners<T extends OpScms>(scm: T, generateMitigationRequest: GenerateOrgOwnerMitigationRequest[]): Promise<OpMaps[T]["generateOrgOwners"]> {
    const res = await this.client.post<OpMaps[T]["generateOrgOwners"]>(`${scm}/generate-org-owner`, generateMitigationRequest);
    return res.data;
  }

  public async create<T extends OpScms>(scm: T, params: CreateGithubMitigationRequest[] | CreateADOMitigationRequest[]): Promise<OpMaps[T]["create"]> {
    const res = await this.client.post<OpMaps[T]["create"]>(scm, params);
    return res.data;
  }

  public async createOrgOwner<T extends OpScms>(scm: T, params: CreateOrgOwnerMitigationRequest[]): Promise<OpMaps[T]["createOrgOwner"]> {
    const res = await this.client.post<OpMaps[T]["createOrgOwner"]>(`${scm}/org-owner`, params);
    return res.data;
  }

  public async createStaleUser<T extends OpScms>(scm: T, params: OpMaps[T]["createStaleUser"]["params"]): Promise<OpMaps[T]["createStaleUser"]["returns"]> {
    const res = await this.client.post<OpMaps[T]["createStaleUser"]["returns"]>(`${scm}/hardening/stale-user`, params);
    return res.data;
  }

  public async createStaleRepo<T extends OpScms>(scm: T, params: OpMaps[T]["createStaleRepo"]["params"]): Promise<OpMaps[T]["createStaleRepo"]["returns"]> {
    const res = await this.client.post<OpMaps[T]["createStaleRepo"]["returns"]>(`${scm}/hardening/stale-repo`, params);
    return res.data;
  }

  public async createUnenforceablePolicy<T extends OpScms>(
    scm: T,
    params: OpMaps[T]["createUnenforceablePolicy"]["params"]
  ): Promise<OpMaps[T]["createUnenforceablePolicy"]["returns"]> {
    const res = await this.client.post<OpMaps[T]["createUnenforceablePolicy"]["returns"]>(`${scm}/hardening/unenforceable-policy`, params);
    return res.data;
  }

  public async createMisconfiguredCodeowners<T extends OpScms>(
    scm: T,
    params: CreateMisconfiguredCodeownersMitigationRequest[]
  ): Promise<OpMaps[T]["createMisconfiguredCodeowners"]> {
    const res = await this.client.post<OpMaps[T]["createMisconfiguredCodeowners"]>(`${scm}/hardening/misconfigured-codeowners`, params);
    return res.data;
  }

  public async revert(params: RevertMitigationRequest): Promise<void> {
    await this.client.patch("", params);
  }

  public async delete(params: DeleteMitigationRequest): Promise<void> {
    await this.client.delete("", { data: params });
  }
}

export const Mitigations = new MitigationsApi();
