import { PolicyItemType } from "../policy-item-base";
import { PolicyTrigger, PolicyTriggerSubType } from "./policy-trigger";

declare module "./policy-trigger" {
  enum PolicyTriggerSubType {
    detected_on_pr = "detected_on_pr"
  }
}

(PolicyTriggerSubType as { detected_on_pr: "detected_on_pr" }).detected_on_pr = "detected_on_pr";

export interface PolicyTriggerDetectedOnPR extends PolicyTrigger {
  sub: PolicyTriggerSubType.detected_on_pr;
}

export const PolicyTriggerDetectedOnPR = {
  component: {
    type: PolicyItemType.trigger,
    sub: PolicyTriggerSubType.detected_on_pr
  },
  generateDefault(): PolicyTriggerDetectedOnPR {
    return {
      type: PolicyItemType.trigger,
      sub: PolicyTriggerSubType.detected_on_pr,
      id: `${PolicyItemType.trigger}-${Math.floor(Date.now() * Math.random())}`
    };
  }
} as const;
