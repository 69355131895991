import type { GitResourceBase } from "../../../shared/src/report/interfaces/git-resource-base";
import { Integrations } from "@/state";

export function scmOrgNameFilter(asset: GitResourceBase): string {
  //backward compatibility for old assets
  if ("org" in asset && typeof asset.org === "string") {
    return Integrations.getOrganizationName(asset.org, asset.integrationType);
  }
  return Integrations.getOrganizationName(asset);
}
