import moment from "moment";
import { DisposedError, TimeoutError } from "../errors";
import { Deferred } from "./deferred";

interface SignalLogger {
  (message: string, level: "debug" | "warn" | "error"): void;
}

const TIMEOUT_SIGNAL = Symbol("Signal.Timeout");

export class Signal<T> implements Disposable, PromiseLike<T> {
  readonly #logger: SignalLogger;
  #disposed = false;
  #deferred: Deferred<T | typeof TIMEOUT_SIGNAL> | null = null;

  public constructor(logger?: SignalLogger) {
    this.#logger = logger || (() => {});
  }

  /**
   * Same as calling `waitOne()` with no arguments.
   */
  public then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null | undefined,
    onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null | undefined
  ): PromiseLike<TResult1 | TResult2> {
    return this.waitOne().then(onfulfilled, onrejected);
  }

  /**
   * A symbol that denotes a timeout.
   */
  public static get TimeoutSymbol(): typeof TIMEOUT_SIGNAL {
    return TIMEOUT_SIGNAL;
  }

  /**
   * Denotes whether the signal has been disposed.
   */
  public get disposed(): boolean {
    return this.#disposed;
  }

  static #throwOnTimeout<T>(): T {
    throw new TimeoutError();
  }

  /**
   * Waits for the next signal.
   * @returns A promise that resolves to the next signal.
   */
  public waitOne(): Promise<T>;
  waitOne(timeout?: moment.Duration): Promise<T>;
  /**
   * Waits for the next signal.
   * @param timeout The promise will reject (TimeoutError) after the timeout, if a signal is not received.
   * @returns A promise that resolves to the next signal.
   * @throws TimeoutError If a signal is not received within the timeout.
   */
  public waitOne(timeout: moment.Duration): Promise<T>;
  /**
   * Waits for the next signal.
   * @param timeout If a signal is not received within the timeout, the promise will return the result of the onTimeout callback.
   * @param onTimeout Value factory called on timeout.
   * @returns An async generator that yields the next signal or the result of the onTimeout callback.
   */
  public waitOne(timeout: moment.Duration, onTimeout: () => T): Promise<T>;
  waitOne(timeout?: moment.Duration, onTimeout?: () => T): Promise<T>;
  async waitOne(timeout?: moment.Duration, onTimeout?: () => T): Promise<T> {
    onTimeout ||= Signal.#throwOnTimeout;
    this.#logger(`waitOne[${timeout?.asMilliseconds()}]`, "debug");
    const result = await this.tryWaitOne(timeout);
    return result === TIMEOUT_SIGNAL ? onTimeout() : result;
  }

  /**
   * Tries to wait for the next signal.
   * @param timeout Optional. If provided, the promise will return a `TimeoutSymbol` after the timeout, if a signal is not received.
   * @note if timeout is `0` duration, it is the same as not providing a timeout (i.e. waiting indefinitely)
   * @returns A promise that resolves to the next signal, or a `TimeoutSymbol`.
   * @throws DisposedError If the signal has been disposed.
   */
  public tryWaitOne(timeout?: moment.Duration): Promise<T | typeof TIMEOUT_SIGNAL> {
    this.#throwOnDisposed();
    this.#logger(`tryWaitOne[${timeout?.asMilliseconds()}]`, "debug");
    return (this.#deferred ||= this.#buildDeferred(timeout)).promise;
  }

  /**
   * Iteratively waits for signals.
   * @returns An async generator that yields the next signal.
   */
  public waitMany(): AsyncGenerator<T, void, unknown>;
  /**
   * Iteratively waits for signals.
   * @param timeout Each iteration will reject (TimeoutError) after the timeout, if a signal is not received.
   * @returns An async generator that yields the next signal.
   * @throws TimeoutError If a signal is not received within the timeout.
   */
  public waitMany(timeout: moment.Duration): AsyncGenerator<T, void, unknown>;
  /**
   * Iteratively waits for signals.
   * @param timeout If a signal is not received within the timeout, the promise will return the result of the onTimeout callback.
   * @param onTimeout Value factory called on timeout.
   * @returns An async generator that yields the next signal or the result of the onTimeout callback.
   */
  public waitMany(timeout: moment.Duration, onTimeout: () => T): AsyncGenerator<T, void, unknown>;
  async *waitMany(timeout?: moment.Duration, onTimeout?: () => T): AsyncGenerator<T, void, unknown> {
    this.#logger(`waitMany[${timeout?.asMilliseconds()}]`, "debug");
    while (true) {
      const signal = await this.waitOne(timeout, onTimeout);
      yield signal;
    }
  }

  /**
   * Signals the next awaiter.
   * @param value The value to signal.
   */
  public signal(value: T): void {
    this.#throwOnDisposed();
    this.#logger(`signal[${String(value)}]`, "debug");
    this.#deferred?.resolve(value);
  }

  /**
   * Disposes the signal, rejecting all awaiters.
   */
  public dispose(): void {
    this.#logger(`dispose`, "debug");
    this.waitOne = () => {
      this.#logger(`waitOne: Signal disposed.`, "warn");
      throw new Error("Signal disposed.");
    };
    this.dispose = () => {};
    this.#deferred?.reject(new DisposedError("Signal disposed."));
    this.#disposed = true;
  }

  /**
   * Disposes the signal, rejecting all awaiters.
   */
  [Symbol.dispose] = this.dispose.bind(this);

  #throwOnDisposed(): void {
    if (this.#disposed) {
      throw new DisposedError("Signal disposed.");
    }
  }

  #buildDeferred(timeout?: moment.Duration): Deferred<T | typeof TIMEOUT_SIGNAL> {
    this.#logger(`buildDeferred[${timeout?.asMilliseconds()}]`, "debug");
    const deferred = new Deferred<T | typeof TIMEOUT_SIGNAL>();
    void this.#clearDeferredOnSettled(deferred);
    this.#setTimeout(deferred, timeout);
    return deferred;
  }

  async #clearDeferredOnSettled(deferred: Deferred<T | typeof TIMEOUT_SIGNAL, Error>): Promise<void> {
    try {
      await deferred.promise;
    } catch {
      // noop
    } finally {
      this.#logger(`#clearDeferredOnSettled: Clearing deferred.`, "debug");
      this.#deferred === deferred && (this.#deferred = null);
    }
  }

  #setTimeout(deferred: Deferred<T | typeof TIMEOUT_SIGNAL>, timeout?: moment.Duration): void {
    const ms = timeout?.asMilliseconds() ?? 0;
    if (ms === 0) {
      return;
    }
    const logCtx = `#setTimeout[${ms}]`;
    if (Number.isNaN(ms) || ms < 0) {
      this.#logger(`${logCtx}: Timeout must be a valid positive duration.`, "error");
      throw new Error("Timeout must be a valid positive duration.");
    }

    const timeoutId = setTimeout(() => {
      this.#logger(`${logCtx}: Timed out`, "debug");
      deferred.settled || deferred.resolve(TIMEOUT_SIGNAL);
    }, ms);

    void this.#clearTimeoutOnSettled(deferred, timeoutId);
  }

  async #clearTimeoutOnSettled(deferred: Deferred<T | typeof TIMEOUT_SIGNAL, Error>, timeoutId: ReturnType<typeof setTimeout>): Promise<void> {
    try {
      await deferred.promise;
    } catch {
      // noop
    } finally {
      this.#logger(`#clearTimeoutOnComplete: Clearing timeout`, "debug");
      clearTimeout(timeoutId);
    }
  }
}

export class TickerSignal extends Signal<void> {
  readonly #logger?: SignalLogger;
  #interval: Disposable;

  public constructor(interval: moment.Duration, logger?: SignalLogger) {
    const ms = interval.asMilliseconds();
    super(logger);
    this.#logger = logger;
    this.#interval = this.#buildDisposableInterval(ms);
  }

  public updateInterval(interval: moment.Duration): void {
    const ms = interval.asMilliseconds();
    const newInterval = this.#buildDisposableInterval(ms);
    this.#interval[Symbol.dispose]();
    this.#interval = newInterval;
  }

  public override dispose(): void {
    this.#interval[Symbol.dispose]();
    super.dispose();
  }

  #validateInterval(ms: number): void {
    if (Number.isNaN(ms) || ms <= 0) {
      this.#logger?.(`validateInterval[${ms}]: Interval must be a valid positive duration.`, "error");
      throw new Error("Interval must be a valid positive duration.");
    }
  }

  #buildDisposableInterval(ms: number): Disposable {
    this.#validateInterval(ms);
    const intervalId = setInterval(() => this.signal(), ms);
    const interval = {
      [Symbol.dispose]: () => {
        this.#logger?.(`clearInterval`, "debug");
        clearInterval(intervalId);
      }
    };
    return interval;
  }
}
