import { LicenseNames, type LicenseName } from "../../../../../licenses/license-list";
import { PolicyItemType } from "../../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_license_spdx = "package_license_spdx"
  }
}

(PolicyConditionSubType as { package_license_spdx: "package_license_spdx" }).package_license_spdx = "package_license_spdx";

export interface PolicyConditionFindingPackageLicenseSpdx extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_license_spdx;
  /**
   * One or more standardized SPDX license names
   */
  licenses: LicenseName[];
}

export const PolicyConditionFindingPackageLicenseSpdx = {
  LICENSES: LicenseNames,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_license_spdx
  }
} as const;
