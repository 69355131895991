import { TrivyCycloneDx } from "../../../../../sbom/interfaces/sbom-report-item";
import { PolicyItemType } from "../../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_kev = "package_kev"
  }
}

(PolicyConditionSubType as { package_kev: "package_kev" }).package_kev = "package_kev";

export interface PolicyConditionFindingPackageKev extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_kev;
}

export const PolicyConditionFindingPackageKev = {
  TYPES: TrivyCycloneDx.PackageType,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_kev
  }
} as const;
