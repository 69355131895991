import Vue from "vue";
import { ScmIntegrationType } from "$/dynamo";
import { OrgUserIdentity } from "$/interfaces/assets/org-user-identity";
import { OrganizationRepoData } from "$/interfaces/assets/organization-repo-data";
import { Assets as AssetsApi } from "@/api";
import { UserInfo } from "$/dynamo/findingBase";
import { BillableUsers } from "$/interfaces/assets/billable-users";

export interface OrganizationInfo {
  id: string;
  name: string;
}

export type ProjectInfo = {
  org: string;
  id: string;
  name: string;
  fullName: string;
};

export type RepoInfo = {
  org: string;
  id: string;
  name: string;
  project?: string;
  projectId?: string;
  fullName: string;
};

export const Assets = new (class InventoryReportsState {
  public constructor() {
    Vue.observable(this);
  }

  public resources: Record<ScmIntegrationType, OrganizationRepoData[] | null> = {
    "github": null,
    "azure-devops": null,
    "gitlab": null,
    "bitbucket-cloud": null,
    "bitbucket-server": null
  };


  public users: Record<ScmIntegrationType, OrgUserIdentity[] | null> = {
    "github": null,
    "azure-devops": null,
    "gitlab": null,
    "bitbucket-cloud": null,
    "bitbucket-server": null
  };

  public billableUsers: BillableUsers | null = null;

  public get hasInventory(): boolean {
    return !!this.getAllUsers().length;
  }

  public getAllUsers(): OrgUserIdentity[] {
    const users = Object.values(this.users).nonNullable().flat();
    return users;
  }

  public getResources(integrationType: ScmIntegrationType): OrganizationRepoData[] | null {
    return this.resources[integrationType];
  }

  public getUsers(integrationType: ScmIntegrationType): OrgUserIdentity[] | null {
    return this.users[integrationType];
  }

  public getBillableUsers(): BillableUsers | null {
    return this.billableUsers;
  }

  public async loadBillableUserCount(reload = false): Promise<void> {
    if (this.billableUsers && !reload) {
      return;
    }
    const billableUsers = await AssetsApi.getBillableUsersCount();
    this.billableUsers = billableUsers;
  }

  public async loadResources(integrationType: ScmIntegrationType, reload = false): Promise<void> {
    if (this.resources[integrationType] && !reload) {
      return;
    }
    this.resources[integrationType] = await AssetsApi.getResources(integrationType);
  }

  public async loadUsers(integrationType: ScmIntegrationType, reload = false): Promise<void> {
    if (this.users[integrationType] && !reload) {
      return;
    }
    this.users[integrationType] = await AssetsApi.getUsers(integrationType);
  }

  public async loadAllResources(reload = false): Promise<void> {
    await Promise.all(ScmIntegrationType.all().map((integrationType) => this.loadResources(integrationType, reload)));
  }

  public async loadAllUsers(reload = false): Promise<void> {
    await Promise.all(ScmIntegrationType.all().map((integrationType) => this.loadUsers(integrationType, reload)));
  }

  public getAllOrganizationNames(scmIntegrationTypes?: ScmIntegrationType[]): string[] {
    const orgNames = this.getAllOrganizationInfo(scmIntegrationTypes || ScmIntegrationType.all())
      .map((o) => o.name)
      .distinct();
    return orgNames;
  }

  public getAllProjectNames(scmIntegrationTypes?: ScmIntegrationType[]): string[] {
    scmIntegrationTypes ??= ScmIntegrationType.all();
    // GitHub has no projects
    scmIntegrationTypes = scmIntegrationTypes.filter((s) => s !== "github");
    return this.getAllProjects(scmIntegrationTypes)
      .map((p) => p.name)
      .distinct();
  }

  public getAllRepoNames(scmIntegrationTypes?: ScmIntegrationType[]): string[] {
    return this.getAllRepos(scmIntegrationTypes || ScmIntegrationType.all())
      .map((r) => r.name)
      .distinct();
  }

  public getAllOrganizationInfo(): OrganizationInfo[];
  public getAllOrganizationInfo(scmIntegrationTypes: ScmIntegrationType[]): OrganizationInfo[];
  public getAllOrganizationInfo(scmIntegrationTypes?: ScmIntegrationType[]): OrganizationInfo[] {
    scmIntegrationTypes ??= ScmIntegrationType.all();
    const organizations: OrganizationInfo[] = [];
    for (const integrationType of scmIntegrationTypes) {
      const items = this.resources[integrationType] || [];
      organizations.pushAll(items.map((org) => ({
        id: org.integrationOrgId,
        name: org.name
      })));
    }
    return organizations;
  }

  public getAllProjects(): ProjectInfo[];
  public getAllProjects(scmIntegrationTypes: ScmIntegrationType[]): ProjectInfo[];
  public getAllProjects(scmIntegrationTypes?: ScmIntegrationType[]): ProjectInfo[] {
    scmIntegrationTypes ??= ScmIntegrationType.all();
    // GitHub has no projects
    scmIntegrationTypes.filter((s) => s !== "github");
    const allProjects: ProjectInfo[] = [];
    for (const integrationType of scmIntegrationTypes) {
      const items = this.resources[integrationType] || [];
      const projects = items.map((org) =>
        org.repos.map((r) => ({
          org: org.integrationOrgId,
          id: r.projectId,
          name: r.projectName,
          fullName: r.projectFullName
        }))
          .distinct((p) => p.id)
      ).flat();
      allProjects.pushAll(projects);
    }
    return allProjects;
  }


  public getAllRepos(scmIntegrationTypes?: ScmIntegrationType[]): RepoInfo[] {
    scmIntegrationTypes ??= ScmIntegrationType.all();
    const repositories: RepoInfo[] = [];
    for (const integrationType of scmIntegrationTypes) {
      const items = this.resources[integrationType] || [];
      const repos = items.map((org) => org.repos.map((r) => ({
          org: org.integrationOrgId,
          id: r.repoId,
          name: r.repoName,
          project: r.projectName,
          projectId: r.projectId,
          fullName: r.fullName
        }))
      ).flat();
      repositories.pushAll(repos);
    }
    return repositories;
  }

  public tryFindIdentityByUserInfo(userInfo: UserInfo, integrationType: ScmIntegrationType): OrgUserIdentity | null {
    if (userInfo?.login) {
      const user = this.tryFindIdentityById(userInfo?.login, integrationType);
      if (user) {
        return user;
      }
    }
    if (userInfo?.email) {
      const user = this.tryFindIdentityByEmailAddress(userInfo?.email, integrationType);
      if (user) {
        return user;
      }
    }
    // Sometimes the name is equivalent to the login
    if (userInfo?.name) {
      const user = this.tryFindIdentityById(userInfo?.name, integrationType);
      if (user) {
        return user;
      }
    }
    return null;
  }

  public tryFindIdentityByDatum(datum: string, integrationType: ScmIntegrationType): OrgUserIdentity | null {
    return this.tryFindIdentityById(datum, integrationType) ?? this.tryFindIdentityByEmailAddress(datum, integrationType);
  }

  public tryFindIdentityById(id: string, integrationType: ScmIntegrationType): OrgUserIdentity | null {
    const users = this.users[integrationType];
    if (!users) {
      return null;
    }
    id = id.toLowerCase();
    return users.find((u) => u.login.toLowerCase() === id) || null;
  }

  public tryFindIdentityByEmailAddress(email: string, integrationType: ScmIntegrationType): OrgUserIdentity | null {
    email = email.toLowerCase();
    const users = this.users[integrationType];
    if (!users) {
      return null;
    }
    return users.find((u) => u.emails.some((e) => e.toLowerCase() === email)) || null;
  }

})();