import { Dialog } from "./dialog-state";
import { Flags } from "./flags-state";
import { Integrations } from "./integrations-state";
import Vue from "vue";
import { LlmIntegrationMetadata, LlmIntegrationType } from "$/dynamo";
import { IntegrationAttributes } from "$/dynamo/integration/integration-attributes";
import { AiSolutionResponse } from "$/interfaces/ai-solution";
import { GetIntegrationResponse } from "$/interfaces/ui-api/response/get-integration-response";
import { Llm } from "@/api";
import { UICodeRiskFinding } from "@/pages/risks/code/ui-code-risk-item";
import { ObservableMap } from "@/utility-types/observable-map";

class AiState {
  private readonly solutions = new ObservableMap<string, AiSolutionResponse | null>();
  private _enabled = false;
  private _loading = false;

  public constructor() {
    Vue.observable(this);
  }

  public get integrations(): GetIntegrationResponse<LlmIntegrationType>[] {
    void this.init();
    return Integrations.integrations?.filter((i): i is GetIntegrationResponse<LlmIntegrationType> => IntegrationAttributes[i.integrationType].category === "ai") || [];
  }

  public get enabled(): boolean {
    return this._enabled;
  }

  public get loading(): boolean {
    return this._loading;
  }

  public getSolution(fingerprint: string): AiSolutionResponse | null {
    return this.solutions.get(fingerprint) || null;
  }

  public async generateSolution(finding: UICodeRiskFinding, integration?: GetIntegrationResponse<LlmIntegrationType>): Promise<AiSolutionResponse | null> {
    const attributes = (integration && IntegrationAttributes[integration.integrationType]) || null;
    if (attributes && attributes.category !== "ai") {
      throw new Error("Integration is not an AI integration");
    }

    this._loading = true;
    try {
      this.solutions.set(finding.id, null);
      const solution = await Llm.generateSolution(finding.dataType, finding.scanner, finding.id, integration?.integrationOrgIdAndType);
      if (!solution) {
        throw new Error(`No solution returned from ${attributes?.displayName || "AI"}`);
      }
      this.solutions.set(finding.id, solution);
      return solution;
    } finally {
      this._loading = false;
    }
  }

  public async updateModel(item: GetIntegrationResponse<LlmIntegrationType>) {
    const integration = this.integrations?.find((i) => i.integrationOrgIdAndType === item.integrationOrgIdAndType);
    const metadata = integration?.metadata;
    if (!integration || !LlmIntegrationMetadata.is(metadata)) {
      throw new Error("Invalid AI integration");
    }
    const chosenModel = await Dialog.select(
      metadata.ai.modelsInSelection.map((m) => ({ title: m, value: m })),
      "Model Selection",
      metadata.ai.chosenModel
    );
    if (!chosenModel) {
      return;
    }
    metadata.ai.chosenModel = chosenModel;
    await Llm.updateModel(integration.integrationType, integration.integrationOrgId, metadata);
  }

  private async init() {
    this._enabled = (await Flags.tryGet("integrations"))?.ai?.front ?? false;
    this.init = () => Promise.resolved;
  }
}

export const Ai = new AiState();
