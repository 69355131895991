import { ApiBase } from "@/api/api-base";
import { LabelResponse } from "$/interfaces/ui-api/response/label-response";

class LabelsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "labels", name: "LabelsApi" });
  }

  public async list(): Promise<string[]> {
    const res = await this.client.get<LabelResponse[]>("");
    const labels = new Set(res.data.map((l) => l.name)).toArray().sort();
    return labels;
  }
}

export const Labels = new LabelsApi();
