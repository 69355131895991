
<div class="component action-dialog">
  <v-dialog
    v-model="model"
    @click:outside="closeDialog"
    class="component action-dialog action-dialog--container"
    :content-class="`component action-dialog action-dialog--teleport ${fullscreen ? 'action-dialog--fullscreen' : ''}`"
    scrollable
    :persistent="dialog?.persistent ?? false">
    <template v-if="dialog">
      <v-form @submit.prevent="onSubmit" v-model="valid">
        <v-card>
          <v-card-title>{{ dialog.title }}</v-card-title>
          <v-divider></v-divider>
          <component v-if="component" :is="component" v-bind="dialog.bindData" :resolve="resolve" :reject="reject" :valid="valid"> </component>
          <v-card-text v-else-if="html" v-html-sanitize="html.html"> </v-card-text>
          <v-card-text class="content-row-container" v-else>
            <span v-for="(row, idx) of rows" :key="idx">{{ row }}</span>
          </v-card-text>
          <v-card-actions v-if="dialog.actions" ref="actions" class="justify-end">
            <v-btn
              v-for="action of dialog.actions"
              dense
              text
              @click="onAction(action)"
              :color="action.color"
              :key="action.name"
              :default="!!action.default"
              :type="!!action.submit ? 'submit' : null"
              :disabled="!!action.submit && !valid">
              {{ action.name }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </template>
  </v-dialog>
</div>
