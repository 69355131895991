import safe from "safe-regex";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface RegExpConstructor {
    /**
     *
     * @param pattern The regular expression pattern to compile.
     * @param flags Optional. A string that contains the flags to add.
     * Alternatively, if a RegExp object is supplied for the pattern, the flags string will replace any of that object's flags (and lastIndex will be reset to 0).
     * May contain any combination of the following characters:
     * - [`d` (indices)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/hasIndices): Generate indices for substring matches.
     * - [`g` (global)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/global): Find all matches rather than stopping after the first match.
     * - [`i` (ignore case)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/ignoreCase): When matching, casing differences are ignored.
     * - [`m` (multiline)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/multiline): Treat beginning and end assertions (`^` and `$`) as working over multiple lines. In other words, match the beginning or end of each line (delimited by `\n` or `\r`), not only the very beginning or end of the whole input string.
     * - [`s` (dotAll)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/dotAll): Allows `.` to match newlines.
     * - [`u` (unicode)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/unicode): Treat pattern as a sequence of Unicode code points.
     * - [`v` (unicodeSets)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/unicodeSets): An upgrade to the `u` flag that enables set notation in character classes as well as properties of strings.
     * - [`y` (sticky)](https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/sticky): Matches only from the index indicated by the `lastIndex` property of this regular expression in the target string. Does not attempt to match from any later indexes.
     */
    tryParse(pattern: string, flags?: string): RegExp | null;

    /**
     * Making sure the regex isn't vulnerable to catastrophic exponential-time regular expressions.
     * @param pattern The regular expression pattern to validate.
     * @returns `true` if the regex is valid, `false` otherwise
     */
    checkForExponentialTime(pattern: string | RegExp): boolean;
  }
}

RegExp.tryParse ||= function tryParse(pattern: string, flags?: string): RegExp | null {
  try {
    const patternFlags = /^(\(\?(?<flags>[dgimsuvy]+)\))?(?<pattern>.*)/.exec(pattern);
    const { pattern: matchedPattern, flags: matchedFlags } = patternFlags?.groups || {};
    if (matchedPattern) {
      pattern = matchedPattern;
    }
    flags ||= matchedFlags;
    if (flags) {
      flags = Array.from(new Set(flags.split("").sort())).join("");
    }
    return new RegExp(pattern, flags);
  } catch {
    return null;
  }
};

RegExp.checkForExponentialTime ||= function checkForExponentialTime(pattern: string | RegExp): boolean {
  try {
    if (typeof pattern === "string") {
      const regex = RegExp.tryParse(pattern);
      if (!regex) {
        return false;
      }
      pattern = regex;
    }
    return safe(pattern);
  } catch {
    return false;
  }
};
