import Vue from "vue";
import { CreateADOMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/create-ado-mitigation-request";
import { CreateADOStaleRepoMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/create-ado-stale-repo-mitigation-request";
import { CreateADOStaleUserMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/create-ado-stale-user-mitigation-request";
import { CreateADOUnenforceablePolicyMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/create-ado-unenforceable-policy-mitigation-request";
import { ADOReportItemResource, GenerateADOMitigationRequest } from "$/interfaces/ui-api/request/mitigations/ado/generate-ado-mitigation-request";
import { CreateGithubMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-github-mitigation-request";
import { CreateGithubStaleRepoMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-github-stale-repo-mitigation-request";
import { CreateGithubStaleUserMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-github-stale-user-mitigation-request";
import { CreateMisconfiguredCodeownersMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-misconfigured-codeowners-mitigation-request";
import { CreateOrgOwnerMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-org-owner-mitigation-request";
import { CreateGithubUnenforceablePolicyMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/create-unenforceable-policy-mitigation-request";
import { DeleteMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/delete-mitigation-request";
import { GenerateGithubMitigationRequest, GithubReportItemResource } from "$/interfaces/ui-api/request/mitigations/github/generate-github-mitigation-request";
import { GenerateOrgOwnerMitigationRequest } from "$/interfaces/ui-api/request/mitigations/github/generate-org-owner-mitigation-request";
import { RevertMitigationRequest } from "$/interfaces/ui-api/request/revert-mitigation-request";
import { CreateADOMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/create-ado-mitigation-response";
import { CreateADOStaleRepoMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/create-ado-stale-repo-mitigation-response";
import { CreateADOStaleUserMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/create-ado-stale-user-mitigation-response";
import { CreateADOUnenforceablePolicyMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/create-ado-unenforceable-policy-response";
import { GetADOMitigationResponse } from "$/interfaces/ui-api/response/mitigations/ado/get-ado-mitigation-response";
import { GetADOMitigationToApply } from "$/interfaces/ui-api/response/mitigations/ado/get-ado-mitigation-to-apply";
import { CreateGithubMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-github-mitigation-response";
import { CreateGithubStaleRepoMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-github-stale-repo-mitigation-response";
import { CreateGithubStaleUserMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-github-stale-user-mitigation-response";
import { CreateMisconfiguredCodeownersMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-misconfigured-codeowners-mitigation-response";
import { CreateOrgOwnerMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-org-owner-mitigation-response";
import { CreateGithubUnenforceablePolicyMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/create-unenforceable-policy-response";
import { GetGithubMitigationResponse } from "$/interfaces/ui-api/response/mitigations/github/get-github-mitigation-response";
import { GetGithubMitigationToApply, GetOrgOwnerExcessiveMitigation } from "$/interfaces/ui-api/response/mitigations/github/get-github-mitigation-to-apply";
import { ADORiskReportQueryType, GitHubRiskReportQueryType, RiskReportType } from "$/report/risk-report/risk-report";
import { Mitigations as Api } from "@/api";

export const Mitigations = new (class MitigationState {
  public githubMitigations: GetGithubMitigationResponse[] | null = null;
  public adoMitigations: GetADOMitigationResponse[] | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public async getGithubMitigationsFromRiskTypeAndResources(
    riskReportType: GitHubRiskReportQueryType,
    resources: GithubReportItemResource[]
  ): Promise<GetGithubMitigationToApply[] | null> {
    const chunkedResources = resources.chunk(10);
    const results = await Promise.all(
      chunkedResources.map(async (chunk) => {
        const generateMitigationRequest: GenerateGithubMitigationRequest = {
          resources: chunk,
          queryType: riskReportType
        };
        return await Api.generate("github", generateMitigationRequest);
      })
    );
    return results.flat();
  }

  public async getOrgOwnerMitigationsFromRiskTypeAndResources(
    riskReportType: RiskReportType,
    resources: GenerateOrgOwnerMitigationRequest[]
  ): Promise<GetOrgOwnerExcessiveMitigation[] | null> {
    if (!(riskReportType in GitHubRiskReportQueryType)) {
      return null;
    }
    const chunkedResources = resources.chunk(10);
    const results = await Promise.all(
      chunkedResources.map(async (chunk) => {
        return await Api.generateOrgOwners("github", chunk);
      })
    );
    return results.flat();
  }

  public async getADOMitigationsFromRiskTypeAndResources(riskReportType: ADORiskReportQueryType, resources: ADOReportItemResource[]): Promise<GetADOMitigationToApply[] | null> {
    const chunkedResources = resources.chunk(10);
    const results = await Promise.all(
      chunkedResources.map(async (chunk) => {
        const generateMitigationRequest: GenerateADOMitigationRequest = {
          resources: chunk,
          queryType: riskReportType
        };
        return await Api.generate("ado", generateMitigationRequest);
      })
    );
    return results.flat();
  }

  public async createGitHubMitigation(mitigationToApply: CreateGithubMitigationRequest[]): Promise<CreateGithubMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.create("github", chunk);
      })
    );
    return results.flat();
  }

  public async createOrgOwnerMitigation(mitigationToApply: CreateOrgOwnerMitigationRequest[]): Promise<CreateOrgOwnerMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.createOrgOwner("github", chunk);
      })
    );
    return results.flat();
  }

  public async createGithubStaleUserMitigation(mitigationToApply: CreateGithubStaleUserMitigationRequest[]): Promise<CreateGithubStaleUserMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.createStaleUser("github", chunk);
      })
    );
    return results.flat();
  }

  public async createGithubStaleRepoMitigation(mitigationToApply: CreateGithubStaleRepoMitigationRequest[]): Promise<CreateGithubStaleRepoMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.createStaleRepo("github", chunk);
      })
    );
    return results.flat();
  }

  public async createGithubUnenforceablePolicyMitigation(
    mitigationToApply: CreateGithubUnenforceablePolicyMitigationRequest[]
  ): Promise<CreateGithubUnenforceablePolicyMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.createUnenforceablePolicy("github", chunk);
      })
    );
    return results.flat();
  }

  public async createADOMitigation(mitigationToApply: CreateADOMitigationRequest[]): Promise<CreateADOMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.create("ado", chunk);
      })
    );
    return results.flat();
  }

  public async createADOStaleUserMitigation(mitigationToApply: CreateADOStaleUserMitigationRequest[]): Promise<CreateADOStaleUserMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.createStaleUser("ado", chunk);
      })
    );
    return results.flat();
  }

  public async createADOStaleRepoMitigation(mitigationToApply: CreateADOStaleRepoMitigationRequest[]): Promise<CreateADOStaleRepoMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.createStaleRepo("ado", chunk);
      })
    );
    return results.flat();
  }

  public async createADOUnenforceablePolicyMitigation(
    mitigationToApply: CreateADOUnenforceablePolicyMitigationRequest[]
  ): Promise<CreateADOUnenforceablePolicyMitigationResponse[]> {
    return await Api.createUnenforceablePolicy("ado", mitigationToApply);
  }

  public async createMisconfiguredCodeownersMitigation(
    mitigationToApply: CreateMisconfiguredCodeownersMitigationRequest[]
  ): Promise<CreateMisconfiguredCodeownersMitigationResponse[]> {
    const chunkedMitigations = mitigationToApply.chunk(10);
    const results = await Promise.all(
      chunkedMitigations.map(async (chunk) => {
        return await Api.createMisconfiguredCodeowners("github", chunk);
      })
    );
    return results.flat();
  }

  public async deleteMitigation(assetIdSCMTypeAndResourceTypeLastIngestionTimestamp: string): Promise<void> {
    const deleteMitigationRequest: DeleteMitigationRequest = {
      assetIdSCMTypeAndResourceTypeLastIngestionTimestamp: assetIdSCMTypeAndResourceTypeLastIngestionTimestamp
    };
    await Api.delete(deleteMitigationRequest);
  }

  public async revertMitigation(assetIdSCMTypeAndResourceType: string, assetIdSCMTypeAndResourceTypeLastIngestionTimestamp: string): Promise<void> {
    const revertMitigationRequest: RevertMitigationRequest = {
      assetIdSCMTypeAndResourceType: assetIdSCMTypeAndResourceType,
      assetIdSCMTypeAndResourceTypeLastIngestionTimestamp: assetIdSCMTypeAndResourceTypeLastIngestionTimestamp
    };
    await Api.revert(revertMitigationRequest);
  }

  public async initGithubMitigations() {
    this.githubMitigations = await Api.get("github");
  }

  public async initADOMitigations() {
    this.adoMitigations = await Api.get("ado");
  }
})();
