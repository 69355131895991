import { AdoCallbackHandler } from "./ado-callback-handler";
import { AgentCallbackHandler } from "./agent-callback-handler";
import { Auth0CallbackHandler } from "./auth0-callback-handler";
import { CallbackHandlerBase } from "./callback-handler-base";
import { GitHubCallbackHandler } from "./github-callback-handler";
import { JiraCallbackHandler } from "./jira-callback-handler";
import { MsTeamsCallbackHandler } from "./msteams-callback-handler";
import { PaymentCallbackHandler } from "./payment-callback-handler";
import { SlackCallbackHandler } from "@/services/auth/callback-handlers/slack-callback-handler";

export const CallbackHandlers = new (class CallbackHandlerRepository {
  public resolve(path: string): CallbackHandlerBase | null {
    const handler = [
      GitHubCallbackHandler,
      AdoCallbackHandler,
      SlackCallbackHandler,
      PaymentCallbackHandler,
      Auth0CallbackHandler,
      MsTeamsCallbackHandler,
      AgentCallbackHandler,
      JiraCallbackHandler
    ].find((h) => h.path === path);
    return handler ? new handler() : null;
  }
})();
