<template>
  <div class="component premium-feature">
    <slot v-if="$tier.current < minTier" name="upgrade">
      <v-btn class="black--text" v-bind="$attrs" color="upgrade" :to="{ name: 'upgrade', params: { highlight: minTier } }"> {{ text }} </v-btn>
    </slot>
    <slot v-else></slot>
  </div>
</template>
<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import { Tier } from "$/interfaces/ui-api/billing-tiering/tier";

@Component({})
export default class PremiumFeature extends Vue {
  @Prop({ type: Number, default: false })
  protected minTier!: Tier;

  @Prop({ type: String, default: "Upgrade" })
  protected text!: string;
}
</script>

<style scoped lang="scss"></style>
