<template>
  <v-container class="component page home-page">
    <v-row v-if="$state.Layout.onboarding.isDoneOnboarding">
      <v-col>
        <v-card>
          <v-card-title>Congratulations!</v-card-title>
          <v-card-subtitle>You have completed the onboarding process</v-card-subtitle>
          <v-card-text>
            What next?
            <v-list>
              <v-list-item v-if="rootJobs && rootJobs.length === 0">
                <v-list-item-icon>
                  <v-icon>mdi-factory</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <router-link :to="{ name: 'jobs' }"><a>Create a crawling job</a></router-link>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else-if="rootJobs && rootJobs.filter((j) => j.status === 'complete').length > 0">
                <v-list-item-icon>
                  <v-icon>mdi-server-security</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <router-link :to="{ path: '/risks' }"><a>View Risks</a></router-link>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-else>
                <v-list-item-icon>
                  <v-icon>mdi-factory</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <router-link :to="{ name: 'jobs' }"><a>Monitor crawling job status</a></router-link>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-view-grid-plus</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <router-link :to="{ name: 'admin-integrations' }"><a>Add another integration</a></router-link>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { JobWatcher, Layout, UIGetJobsResponse } from "@/state";

const DEFAULT_PAGE = { name: "risks" };
@Component({
  beforeRouteEnter(to, from, next) {
    return next(Layout.onboarding.isDoneOnboarding ? DEFAULT_PAGE : undefined);
  }
})
export class HomePage extends Vue implements JobWatcher {
  protected redirecting = false;
  public rootJobs: UIGetJobsResponse[] | null = null;

  public beforeMount(): void {
    this.$state.Jobs.watch(this);
  }

  public beforeDestroy(): void {
    this.$state.Jobs.unwatch(this);
  }

  public onRootJobsChanged(jobs: UIGetJobsResponse[] | null) {
    this.rootJobs = jobs;
  }

  @Watch("$state.Layout.onboarding.isDoneOnboarding")
  protected onDoneOnboardingChange(isDoneOnboarding: boolean): void {
    if (!isDoneOnboarding && this.redirecting) {
      return;
    }
    // prevent multi-redirects
    this.redirecting = true;
    void this.$router.push(DEFAULT_PAGE);
  }
}

export default HomePage;
</script>
