import Vue from "vue";
import { SCMType } from "$/scm-type";
import { router, vuetify } from "@/plugins";
import { Auth, Integrations, Preferences } from "@/state";
import { LocalStorageItem } from "../utils/local-storage-item";

const APP_NAME = "[arnica]"; // TODO: extract to config

class Drawer {
  private _show = true;
  public anchored = true;
  public mini = false;

  public get active(): boolean {
    return Auth.activated;
  }

  public get show(): boolean {
    return this._show && router.currentRoute.meta?.navDrawer !== false && !Auth.user?.public;
  }

  public set show(value: boolean) {
    this._show = value;
  }

  public toggleShow(): void {
    this._show = !this._show;
  }

  public async togglePinned() {
    this.pinned = !this.anchored;
    await Preferences.save({ pinned: this.pinned });
  }

  public get pinned(): boolean {
    return this.anchored;
  }

  public set pinned(pinned: boolean) {
    this.anchored = pinned;
    this.mini = !pinned;
  }
}

class UserMenu {
  private _show = true;
  public editProfile = false;
  public preferences = false;

  public get show(): boolean {
    return this._show && !!Auth.user;
  }

  public set show(value: boolean) {
    this._show = value;
  }
}

class Onboarding {
  public get dismissed(): boolean {
    //temporarily disable demo data
    return true; //(Preferences.user ?? Preferences.defaults).onboardingDismissed;
  }

  public get onboardingStep(): number {
    if (!this.hasOrganization) {
      return 1;
    }
    if (!this.hasAtLeastOneIntegration) {
      return 2;
    }
    // if (!this.hasAtLeastOneInvitationSentOrSkipped) {
    //   return 3;
    // }
    return -1;
  }

  public get isDoneOnboarding() {
    return this.onboardingStep === -1;
  }

  //TODO: move to an onboarding state class
  public get orgId() {
    return Auth.user?.userInfo?.orgId ?? null;
  }

  public get hasAtLeastOneIntegration() {
    return Integrations.hasAtLeastOneSourceControlIntegration;
  }

  /*public get hasAtLeastOneInvitationSentOrSkipped() {
    return Members.members.length > 1 || Members.isSkipped;
  }*/

  public get hasOrganization() {
    return !!this.orgId;
  }
}

class ExpandedItemDetails {
  private readonly _orientation = new LocalStorageItem<"bottom" | "right">("layout.expandedItemDetails.orientation", "bottom");
  public get orientation(): "bottom" | "right" {
    return this._orientation.item || "bottom";
  }
  public get direction(): "vertical" | "horizontal" {
    return this._orientation.item === "bottom" ? "vertical" : "horizontal";
  }
  public toggle() {
    this._orientation.item = this._orientation.item === "bottom" ? "right" : "bottom";
  }
}

/**
 * Built-in tracker symbol for backward compatibility.
 * @deprecated Use `track()` and `untrack()` instead.
 */
const MANUAL_TRACKER = Symbol("MANUAL_TRACKER");

export const Layout = new (class LayoutState {
  readonly #tracker = new Set<symbol>();
  private _loading = false;
  private _title = "";
  public readonly drawer = new Drawer();
  public readonly userMenu = new UserMenu();
  public readonly onboarding = new Onboarding();
  public readonly expandedItemDetails = new ExpandedItemDetails();
  public scmType: SCMType | null = null;
  public preferencesLoaded = false;
  public appLoaded = false;
  public footer = true;
  public help = false;
  public isFullScreen = false;
  public constructor() {
    Vue.observable(this);
  }

  /**
   * Tracks a symbol to determine if the loading state should be set to true.
   * @param tracker a symbol to track the loading state for
   */
  public track(tracker?: symbol): void {
    if (!tracker) {
      return;
    }
    this.#tracker.add(tracker);
    this._loading = this.#tracker.size > 0;
  }

  /**
   * Untracks a symbol to determine if the loading state should be set to false.
   * @param tracker a symbol to track the loading state for
   * @returns
   */
  public untrack(tracker?: symbol): void {
    if (!tracker) {
      return;
    }
    this.#tracker.delete(tracker);
    this._loading = this.#tracker.size > 0;
  }

  /**
   * Resets tracking for all tracked symbols.
   */
  public resetTracking(): void {
    console.debug("resetTracking");
    this.#tracker.clear();
    this._loading = false;
  }

  /**
   * Gets the current loading state.
   */
  public get loading(): boolean {
    return this._loading;
  }

  /**
   * Sets the current loading state.
   * @note uses a built-in tracker symbol
   * @deprecated use `track()` and `untrack()` instead
   */
  public set loading(value: boolean) {
    if (value) {
      this.track(MANUAL_TRACKER);
    } else {
      this.untrack(MANUAL_TRACKER);
    }
  }

  public get title(): string {
    return this._title;
  }

  public set title(pageName: string | null) {
    this._title = pageName ?? "";
    document.title = LayoutState.buildTitle(pageName);
  }

  /**
   * The CSS class for the current theme.
   */
  public get themeClass(): `theme--${"dark" | "light"}` {
    return vuetify.framework.theme.dark ? "theme--dark" : "theme--light";
  }

  public get dark(): boolean {
    return vuetify.framework.theme.dark;
  }

  public reloadHome() {
    const url = new URL(window.location.href);
    url.hash = url.hash.replace(/\/error(\/.*)?/, "");
    window.location.href = url.toString();
    window.location.reload();
  }

  private static buildTitle(pageName?: string | null) {
    return pageName ? `${APP_NAME} - ${pageName}` : APP_NAME;
  }

  public toggleFullScreen() {
    if (this.isFullScreen) {
      void document.exitFullscreen();
      this.isFullScreen = false;
    } else {
      void document.documentElement.requestFullscreen();
      this.isFullScreen = true;
    }
  }
})();
