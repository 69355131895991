
<v-container fluid class="component checkbox-dialog-content">
  <span class="subtitle">{{ subTitle }}</span>
  <v-text-field v-if="longList" clearable dense v-model.trim="search" label="Search" autofocus></v-text-field>
  <v-list dense ref="list">
    <v-list-item-group v-model="selectedItem">
      <v-data-table
        dense
        :headers="headers"
        :items="filteredOptions"
        :items-per-page="10"
        item-key="value"
        :footer-props="{
          'items-per-page-options': [10],
          'disable-items-per-page': true
        }">
        <template v-slot:header.value="">
          <v-checkbox dense v-model="allSelected" class="select-all-button" :indeterminate="getIndeterminate"></v-checkbox>
        </template>
        <template v-slot:item.value="{ item }">
          <v-checkbox class="checkbox-in-table" dense hide-details v-model="selected" :value="item"></v-checkbox>
        </template>

        <template v-for="(header, index) in headers" v-slot:[getSlotName(header.value)]="{ item }">
          <v-checkbox v-if="header.isCheckbox" :key="item + index" class="checkbox-in-table" dense hide-details v-model="selected" :value="item"></v-checkbox>
          <span v-else :key="item + index">{{ cellText(item, header) }} </span>
        </template>
        <template #footer.prepend>
          <v-spacer />
        </template>
      </v-data-table>
    </v-list-item-group>
  </v-list>
</v-container>
