import type { LlmIntegrationType } from "../integration";
import type { IntegrationAttributes } from "./integration-attributes";

interface LlmIntegrationAttributes extends IntegrationAttributes {
  type: LlmIntegrationType;
}

export const LlmIntegrationAttributes = {
  openai: {
    type: "openai",
    category: "ai",
    displayName: "OpenAI",
    hasJobs: false,
    includeMetadata: true,
    ui: {
      icon: "mdi-openai",
      reactIcon: "simple-icons:openai",
      editable: true,
      removable: true,
      editTooltip: "Click here to select the OpenAI model you want to use"
    }
  },
  "azure-openai": {
    type: "azure-openai",
    category: "ai",
    displayName: "Azure OpenAI",
    hasJobs: false,
    includeMetadata: true,
    ui: {
      icon: "mdi-azure",
      reactIcon: "mdi:microsoft-azure",
      editable: false,
      removable: true
    }
  }
} as const satisfies Record<LlmIntegrationType, LlmIntegrationAttributes>;
