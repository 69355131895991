import { ADOAsset, GHAsset } from "../../report/risk-report/risk-report";
import { SCMType } from "../../scm-type";

export enum RiskReportItemType {
  IDENTITY = "IDENTITY",
  GROUP = "GROUP",
  ORG = "ORG",
  PROJECT = "PROJECT",
  REPO = "REPO",
  BRANCH = "BRANCH",
  APP_PERMISSION = "APP_PERMISSION",
  APP_FULL_NAME = "APP_FULL_NAME",
  SECRET_FINDING = "SECRET_FINDING",
  CODE_ANALYSIS = "CODE_ANALYSIS",
  SERVICE_ACCOUNT = "SERVICE_ACCOUNT"
}

export interface DismissedRiskReportItem {
  arnicaOrgId: string;
  riskReportItemSCMTypeAndItemTypeAndId: string;
  riskReportItemId: string;
  riskReportItemType: RiskReportItemType;
  scmType: SCMType;
  userWhoDismissed: string;
  dateDismissed: string;
  comment: string;
  orgNames: string[];
}

export const generateRiskItemAssetId = (riskReportItemAsset: ADOAsset | GHAsset, scmType: SCMType, riskReportType: RiskReportItemType): string | null => {
  if (scmType === SCMType.AZURE_DEVOPS) {
    const asset = riskReportItemAsset as ADOAsset;
    if (riskReportType === RiskReportItemType.ORG) {
      return generateOrgRiskItemId(asset.org);
    } else if (riskReportType === RiskReportItemType.PROJECT) {
      if (!asset.project) {
        return null;
      }
      return generateADOProjectRiskItemId(asset.org, asset.project);
    } else if (riskReportType === RiskReportItemType.REPO) {
      if (!asset.project || !asset.repo) {
        return null;
      }
      return generateADORepoRiskItemId(asset.org, asset.project, asset.repo);
    } else if (riskReportType === RiskReportItemType.BRANCH) {
      if (!asset.project || !asset.repo || !asset.branch) {
        return null;
      }
      return generateADOBranchRiskItemId(asset.org, asset.project, asset.repo, asset.branch);
    } else {
      throw new Error("Unknown ADO Asset Risk Item Type");
    }
  } else if (scmType === SCMType.GITHUB) {
    const asset = riskReportItemAsset as GHAsset;
    if (riskReportType === RiskReportItemType.ORG) {
      return generateOrgRiskItemId(asset.org);
    }
    if (riskReportType === RiskReportItemType.REPO) {
      if (!asset.repo) {
        return null;
      }
      return generateGithubRepoRiskItemId(asset.org, asset.repo);
    }
    if (riskReportType === RiskReportItemType.BRANCH) {
      if (!asset.repo || !asset.branch) {
        return null;
      }
      return generateGithubBranchRiskItemId(asset.org, asset.repo, asset.branch);
    } else {
      throw new Error("Unknown GitHub Asset Risk Item Type");
    }
  } else {
    throw new Error("Invalid SCM Type");
  }
};

export const generateOrgRiskItemId = (orgName: string): string => {
  return orgName;
};

export const generateADOProjectRiskItemId = (orgName: string, project: string): string => {
  return `${orgName}/${project}`;
};

export const generateADORepoRiskItemId = (orgName: string, project: string, repo: string): string => {
  return `${orgName}/${project}/${repo}`;
};

export const generateADOBranchRiskItemId = (orgName: string, project: string, repo: string, branch: string): string => {
  return `${orgName}/${project}/${repo}/${branch}`;
};

export const generateGithubRepoRiskItemId = (orgName: string, repo: string): string => {
  return `${orgName}/${repo}`;
};

export const generateGithubBranchRiskItemId = (orgName: string, repo: string, branch: string): string => {
  return `${orgName}/${repo}/${branch}`;
};
