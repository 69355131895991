import { BusinessImportanceLabel } from "$/business-importance";
import { getColorBySeverity } from "@/pages/inventory/details/sbom/components/sbom-component-common";

const IMPORTANCE_ICONS: Record<BusinessImportanceLabel, string> = {
  // high: "mdi-podium-gold",
  // medium: "mdi-podium-silver",
  // low: "mdi-podium-bronze"
  // high: "mdi-star",
  // medium: "mdi-star-half-full",
  // low: "mdi-star-outline"
  high: "mdi-chevron-double-up",
  medium: "mdi-chevron-up",
  low: "mdi-chevron-down"
};

export function businessImportanceColor(label?: BusinessImportanceLabel | null): string {
  if (!label) {
    return getColorBySeverity("unknown");
  }
  return getColorBySeverity(label);
}

export function businessImportanceIcon(label?: BusinessImportanceLabel | null): string {
  return (label && IMPORTANCE_ICONS[label]) ?? IMPORTANCE_ICONS["low"];
}
