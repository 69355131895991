import { PolicyItemType } from "../../policy-item-base";
import { PolicyConditionSubType } from "../policy-condition";
import { PolicyConditionAsset } from "./policy-condition-asset";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    target_branch = "target_branch"
  }
}

(PolicyConditionSubType as { target_branch: "target_branch" }).target_branch = "target_branch";

export interface PolicyConditionAssetTargetBranch extends PolicyConditionAsset {
  sub: PolicyConditionSubType.target_branch;
}

export const PolicyConditionAssetTargetBranch = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.target_branch
  } as const
} as const;
