import Vue from "vue";


abstract class StorageItemBase<T = unknown> {
  //ensure Vue detects it
  private _item: T | null = null;

  public constructor(private readonly key: string, defaultValue: T | null = null) {
    Vue.observable(this);
    //watch for changes in _item
    const rawItem = this.getStore().getItem(this.key);
    this._item = rawItem ? (JSON.parse(rawItem) as T) : defaultValue;
  }

  protected abstract getStore(): Storage;

  public get item(): T | null {
    return this._item;
  }

  public set item(value: T | null) {
    //null and undefined are treated as a null value
    // 0, "", false are valid values (and can safely be JSON stringified)
    if ((value ?? null) !== null) {
      this._item = value;
      try {
        this.getStore().setItem(this.key, JSON.stringify(value));
      } catch (e) {
        console.error(`Error parsing value for key ${this.key}`, e);
      }
    } else {
      this._item = null;
      this.getStore().removeItem(this.key);
    }
  }
}

export class LocalStorageItem<T = unknown> extends StorageItemBase<T> {
  protected override getStore(): Storage {
    return window.localStorage;
  }
}

export class SessionStorageItem<T = unknown> extends StorageItemBase<T> {
  protected override getStore(): Storage {
    return window.sessionStorage;
  }
}

export interface LocalStorageItemConstructor<T = unknown> {
  new (key: string, defaultValue: T): LocalStorageItem<T>;

  get item(): T;

  set item(value: T);
}
