// @spellchecker: ignore notaloeveradev notaloevera
//TODO: should come from policy
import { PathToCodeOwners } from "../github/interfaces/code-owners-info";
import moment from "moment";

export const AUDIT_HISTORY = moment.duration(90, "days");
export const COMMITS_HISTORY = moment.duration(90, "days");
export const PULL_REQUEST_HISTORY = moment.duration(90, "days");

//TODO: what is the implication of this for old mitigation
export const DEFAULT_PATH_TO_CODEOWNERS: PathToCodeOwners = ".github";
export const DEFAULT_CODEOWNERS_CREATED_MESSAGE = "Created by arnica";
export const DEFAULT_CODEOWNERS_DELETED_MESSAGE = "Deleted by arnica";
export const DEFAULT_ARNICA_MITIGATION_MESSAGE = "[secret masked by Arnica]";
export const DEFAULT_ARNICA_CODEOWNERS_FILE_COMMENT = "#This Code Owners is automatically maintained by arnica.io. Please maintain this file through Arnica.";
export const DEFAULT_REQUIRED_PR_REVIEWERS = 1;

export const GITHUB_ONLINE_BASE_URL = "https://github.com";

export const TENANT_PREFIX = "tenant";

export const GITHUB = "github";

export const SYSTEM_USER = "SYSTEM";

// should come from env var
export const ARNICA_ADMINS_GROUP_NAME = "arnica-admins";

export const RUN_LOCALLY = "RUN_LOCALLY";
export const USE_REMOTE = "USE_REMOTE";
export const USE_REMOTE_DB_ONLY = "USE_REMOTE_DB_ONLY";
export const IS_DEV = "IS_DEV";
export const IS_K8S = "IS_K8S";
export const IS_DOCKER = "IS_DOCKER";
export const AWS_LAMBDA_FUNCTION_NAME = "AWS_LAMBDA_FUNCTION_NAME";

export const ARNICA_SUPPORT_EMAIL = "support@arnica.io";

export const ARNICA_BOT_NAMES = ["notaloeveradev[bot]", "notaloevera[bot]", "arnica-github-connector[bot]", "notaloeverastaging[bot]"];

export const UNKNOWN_ACTOR_ID = "{unknown}";
