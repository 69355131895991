<template>
  <v-snackbar app top class="component layout-component snackbar" v-model="snackbar" :timeout="timeout" :color="$state.Popup.message.color">
    <div v-if="$state.Popup.message.html" class="html-container" v-html-sanitize="$state.Popup.message.text" :key="id"></div>
    <div v-else class="line-container">
      <span class="text-content" v-for="(line, idx) of lines" :key="idx">{{ line }}</span>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn class="reset-color" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      <v-btn class="reset-color" text v-if="$state.Popup.message.route" @click="goToRoute()">View</v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { PopupOptions } from "@/state";

@Component
export class SnackBar extends Vue {
  protected id = "";
  private active = false;

  protected get snackbar(): boolean {
    return this.active;
  }

  protected set snackbar(value: boolean) {
    this.active = value;
    if (!value) {
      this.$state.Popup.message.onClose?.();
    }
  }

  protected get lines(): string[] {
    return this.$state.Popup.message.text.split("\n");
  }

  protected get timeout(): number {
    const timeout = this.$state.Popup.message.timeout;
    return timeout < 0 || timeout === Infinity ? -1 : timeout;
  }

  @Watch("$state.Popup.message", { immediate: true })
  protected onMessage(msg: PopupOptions): void {
    this.id = window.crypto.randomUUID(); // forces recreation of the wrapper div
    this.snackbar = !!msg.text;
  }

  private goToRoute() {
    if (!this.$state.Popup.message.route) {
      throw new Error("Unexpected undefined route");
    }
    this.$router.push(this.$state.Popup.message.route);
    this.snackbar = false;
  }
}

export default SnackBar;
</script>

<style lang="scss">
.component.layout-component.snackbar {
  .line-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .v-snack__wrapper {
    div.html-container {
      a {
        color: unset;
      }
    }
  }

  .text-content {
    word-break: break-word;
  }
}
</style>
