import Axios from "axios";

declare global {
  interface ErrorConstructor {
    /**
     * Tries to resolve the message from an error object (including an Axios/NestJS error object)
     * @param error Possible error object
     */
    tryResolveMessage(error?: unknown): string | null;
  }
}

Error.tryResolveMessage = function tryResolveMessage(error: unknown): string | null {
  try {
    if (!(error instanceof Error)) {
      return null;
    }

    if (!Axios.isAxiosError(error)) {
      return error.message || null;
    }

    const data = error.response?.data;
    const msgData = typeof data === "object" && data && "message" in data ? (data as { message: string }) : null;
    return msgData?.message || error.message || null;
  } catch {
    return null;
  }
};
