
<v-footer app v-if="$state.Layout.footer" class="component app-footer" :inset="$state.Layout.drawer.show" :height="$state.Layout.footer ? '35px' : 0">
  <v-layout justify-center>
    <small>
      &copy; {{ currentYear }}, Arnica Inc. All Rights Reserved.
      <!-- <v-chip color="primary" label x-small>Beta</v-chip> -->
      <template v-if="$state.Auth.authenticatedAndEmailVerified">
        <a href="javascript:" id="feedback">Feedback</a>
      </template>
    </small>
  </v-layout>
</v-footer>
