// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface StringConstructor {
  /**
   * Pluralizes a word based on the count of items.
   * @param items items to count for pluralization
   * @param singular the singular form of the word
   * @param plural the plural form of the word
   */
  pluralize<T>(items: T[] | undefined | null, singular: string, plural: string): string;

  /**
   * Pluralizes a word based on the count.
   * @param count count for pluralization
   * @param singular the singular form of the word
   * @param plural the plural form of the word
   */
  pluralize(count: number, singular: string, plural: string): string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface String {
  escapeHtml(): string;

  /**
   * Uses encodeURIComponent
   */
  encodeURI(): string;

  /**
   * Uses encodeURIComponent on each path segment
   */
  encodePathURI(): string;

  /**
   * Uses decodeURIComponent
   */
  decodeURI(): string;

  toNumber(): number;

  toBoolean(): boolean;


  /**
   * Converts a string to a base64 string.
   * @param urlSafe Optional. If true, replaces `+` with `-` and `/` with `_`. Default: false.
   */
  toBase64(urlSafe?: boolean): string;

  toDate(): Date;

  toISOString(): string;

  byteLength(): number;

  entropy(): number;

  lines(): string[];

  /**
   * Replaces multiple spaces with an underscore and lowercases the string.
   */
  toSnakeCase(): string;

  /**
   * Replaces multiple spaces with an underscore and lowercases the string.
   */
  toKebabCase(): string;

  /**
   * Removes all non-alphanumeric and underscore characters.
   */
  toAlphaNumeric(): string;

  /**
   * Get selected lines from a string. (inclusive)
   * @param start
   * @param end
   */
  lineRange(start: number, end: number): string;

  capitalize(): string;

  /**
   * Title case in Chicago Manual of Style (CMOS) style.
   */
  titleCase(): string;

  isNumber(): boolean;

  escapeForRegEx(): string;

  removeLeadingSlash(): string;

  /**
   * Truncates a string to a max length.
   * @param maxLength Max length of the string
   * @param suffix Optional. Default: `…` (&hellip;)
   */
  truncate(maxLength: number, suffix?: string): string;

  /**
   * Ensures the URL is HTTPS.
   * e.g.
   * - http://example.com -> https://example.com
   * - https://example.com -> https://example.com
   * - example.com -> https://example.com
   */
  ensureHttps(): string;

  /**
   * Converts a string to a URL.
   * @throws URIError if the string is not a valid URI
   */
  toURL(): URL;

  /**
   * Removes a string from the beginning of the string.
   * @param remove The string to remove
   */
  removePrefix(remove: string): string;

  /**
   * Normalizes all newlines (`\r\n` and `\n`) to just `\n`.
   */
  normalizeNewlines(): string;
}

String.pluralize ||= function pluralize<T>(count: number | T[] | undefined | null, singular: string, plural: string): string {
  count = !count ? 0 : typeof count !== "number" ? count.length : count;
  return count === 1 ? singular : plural;
};

String.prototype.escapeHtml ||= function escapeHtml(this: string) {
  return this.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
};

String.prototype.encodeURI ||= function encodeURI(this: string) {
  return encodeURIComponent(this).replace(/'/g, "%27");
};

String.prototype.encodePathURI ||= function encodePathURI(this: string) {
  return this.split("/")
    .map((s) => s.encodeURI())
    .join("/");
};

String.prototype.decodeURI ||= function decodeURI(this: string) {
  return decodeURIComponent(this);
};

String.prototype.toNumber ||= function toNumber(this: string) {
  return Number(this);
};

String.prototype.toBoolean ||= function toBoolean(this: string) {
  return this === "true";
};

String.prototype.toBase64 ||= function toBase64(this: string, urlSafe = false) {
  return Buffer.from(this).toString(urlSafe ? "base64url" : "base64");
};

String.prototype.toDate ||= function toDate(this: string) {
  return new Date(this);
};

String.prototype.toISOString ??= function toISOString(this: string) {
  return new Date(this).toISOString();
};

String.prototype.byteLength ||= function byteLength(this: string) {
  return Buffer.byteLength(this);
};

String.prototype.entropy ||= function entropy(this: string) {
  const chars = new Set(this);
  const charCount = chars.size;
  const length = this.length;
  return Math.log2(charCount ** length);
};

String.prototype.lines ||= function lines(this: string) {
  return this.split("\n");
};

String.prototype.toSnakeCase ||= function toSnakeCase(this: string) {
  return this.toLowerCase().replaceAll(/\s+/g, "_");
};

String.prototype.toKebabCase ||= function toKebabCase(this: string) {
  return this.toLowerCase().replaceAll(/[\s_]+/g, "-");
};

String.prototype.toAlphaNumeric ||= function toAlphaNumeric(this: string) {
  return this.replaceAll(/[^A-Za-z0-9_]+/g, "");
};

String.prototype.lineRange ||= function getLines(this: string, start: number, end: number) {
  return this.lines()
    .slice(start, end + 1)
    .join("\n");
};

String.prototype.capitalize ||= function capitalize(this: string) {
  return this.split(" ")
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(" ");
};

String.prototype.isNumber ||= function isNumber(this: string): boolean {
  const str = this.trim();
  const num = Number(str);
  return !!str && !Number.isNaN(num);
};

String.prototype.escapeForRegEx ||= function escapeForRegEx(this: string) {
  return this.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const minorWords = new Set(["a", "an", "the", "and", "as", "but", "for", "if", "nor", "or", "so", "yet", "at", "by", "in", "of", "off", "on", "per", "to", "up", "via"]);

String.prototype.titleCase ||= function toTitleCase(this: string): string {
  const words = this.trim().split(/\s+/);
  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (!word) {
      continue;
    }
    const isUpperCase = word === word.toUpperCase();
    if (isUpperCase) {
      continue;
    }
    if (word.includes("-")) {
      words[i] = word
        .split("-")
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
        .join("-");
      continue;
    }
    const prevWord = words[i - 1];

    if (i === 0 || word.includes("-") || (prevWord && /:\s*/.test(prevWord)) || !minorWords.has(word)) {
      words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  }
  return words.join(" ");
};

String.prototype.removeLeadingSlash ||= function removeLeadingSlash(this: string) {
  return this.replace(/^\/+/, "");
};

String.prototype.truncate ||= function truncate(this: string, maxLength: number, suffix = "…") {
  return this.length <= maxLength ? this : `${this.slice(0, maxLength - suffix.length)}${suffix}`;
};

String.prototype.ensureHttps ||= function ensureHttps(this: string) {
  //return this.replace(/^https?:\/\//, "https://");
  return this.replace(/^(?:https?:\/\/)?/, "https://");
};

String.prototype.toURL ||= function toURL(this: string) {
  return new URL(this);
};

String.prototype.removePrefix ||= function removePrefix(this: string, remove: string) {
  if (this.startsWith(remove)) {
    return this.slice(remove.length);
  }
  return this;
};

String.prototype.normalizeNewlines ||= function normalizeNewlines(this: string) {
  return this.replaceAll("\r\n", "\n");
};
