// declare module "url" {
//   // eslint-disable-next-line @typescript-eslint/no-namespace
//   namespace URL {
//     function canParse(url: string, base?: string): boolean;
//   }
// }

(URL as unknown as { canParse(url: string, base?: string): boolean }).canParse ??= function canParse(url: string, base?: string): boolean {
  try {
    new URL(url, base);
    return true;
  } catch {
    return false;
  }
};
