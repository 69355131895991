import { CallbackHandlerBase } from "./callback-handler-base";
import { Integrations as Api } from "@/api";
import { router } from "@/plugins";
import { Integrations, Popup } from "@/state";

export class JiraCallbackHandler extends CallbackHandlerBase {
  public static get path(): string {
    return "/callback/jira";
  }

  protected provideUserFeedback(): void {
    void Popup.info("Jira integration(s) added successfully");
  }

  public async handle(query: URLSearchParams): Promise<void> {
    try {
      const error = query.get("error");
      if (error) {
        const error_description = query.get("error_description");
        throw new Error(error_description || "Oops, something went wrong. Please try again.");
      }

      const code = query.get("code");
      if (!code) {
        throw new Error("Invalid callback");
      }

      await Api.create({ type: "jira", code });

      await Integrations.init();

      this.provideUserFeedback();

      this.sendToAnalytics("jira");

      this.cleanupUrl();
    } catch (e) {
      this.cleanupUrl();
      await this.handleIntegrationError(e);
    } finally {
      await router.push({ name: "admin-integrations" });
    }
  }
}
