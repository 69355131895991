import DOMPurify from "dompurify";
import Vue, { DirectiveOptions } from "vue";
import { DirectiveBinding } from "vue/types/options";

// Add a hook to make all links with "target" be set to "_blank"
DOMPurify.addHook("afterSanitizeAttributes", function (node) {
  // set all elements owning target to target=_blank
  if ("target" in node) {
    node.setAttribute("target", "_blank");
    node.setAttribute("rel", "noopener noreferrer");
    // add an "external link" icon to indicate that it opens in a new tab
    const icon = document.createElement("i");
    icon.classList.add("mdi", "mdi-open-in-new", "ml-1", "link-on-hover");
    node.appendChild(icon);
  }
});
export default class HtmlSanitizeDirective implements DirectiveOptions {
  public inserted(el: HTMLElement, binding: DirectiveBinding) {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  }

  public update(el: HTMLElement, binding: DirectiveBinding) {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  }
}

Vue.directive("html-sanitize", new HtmlSanitizeDirective());
