import Vue from "vue";
import { PolicyItem, PolicyItemType, PolicySubType } from "$/interfaces/policies";
import { ObservableMap } from "@/utility-types/observable-map";
import { PolicyRuleWrapper } from "./policy-rule-wrapper";

export class PolicyItemWrapper<C = undefined> implements PolicyItem<C> {
  readonly #original: string;
  public readonly validationErrors;
  public arnicaOrgId!: string;
  public sub!: PolicySubType;
  public config!: C;
  public rules!: PolicyRuleWrapper[];
  public id!: string;
  public type!: PolicyItemType.policy;

  public constructor(policy: PolicyItem) {
    Object.assign(this, policy);
    this.rules = PolicyRuleWrapper.fromPolicy(policy);
    this.#original = JSON.stringify(this);
    this.validationErrors = new ObservableMap<Vue, string>();
  }

  public toJSON(): Omit<this, "validationErrors" | "toJSON" | "isDirty" | "reset"> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { validationErrors, ...rest } = this;
    return rest;
  }

  public isDirty(): boolean {
    return this.#original !== JSON.stringify(this);
  }

  public reset(): void {
    const original = JSON.parse(this.#original);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { validationErrors, ...rest } = original;
    Object.assign(this, rest);
    this.rules = PolicyRuleWrapper.fromPolicy(this);
  }
}
