import { RouteConfig } from "vue-router";
import { Scope } from "@/interfaces/scope";
import { FlagCheck } from "@/plugins/route-guards/sidebar-routes";
import { InventoryV2IsEnabledAndCompletedCheck } from "@/pages/inventory-v2/inventory-v2-is-enabled-and-completed-check";
import * as State from "@/state";

export const arnicaAdminRoutes: RouteConfig[] = [
  {
    path: "/arnica-admin",
    component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/admin-page.vue"),
    meta: {
      scopes: [Scope.arnica_admin],
      title: "Arnica Admin",
      icon: "mdi-stethoscope",
      sidebar: true
    },
    children: [
      {
        path: "summary",
        component: () => import(/* webpackChunkName: "inventory-summary" */ "@/pages/inventory/inventory-summary-page.vue"),
        meta: {
          scopes: [Scope.arnica_admin, Scope.inventory_ro],
          title: "Summary",
          sidebar: InventoryV2IsEnabledAndCompletedCheck.isTrue,
          icon: "mdi-sitemap",
          scmDropDown: true
        }
      },
      {
        path: "repos-v2",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory-v2/repos-table/repos-table.vue"),
        meta: {
          scopes: [Scope.arnica_admin, Scope.inventory_ro],
          title: "Repositories",
          sidebar: InventoryV2IsEnabledAndCompletedCheck.isFalse,
          icon: "mdi-source-repository",
          scmDropDown: false
        }
      },
      {
        path: "users-v2",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory-v2/users-table/users-table.vue"),
        meta: {
          scopes: [Scope.arnica_admin, Scope.inventory_ro],
          title: "Identities",
          sidebar: InventoryV2IsEnabledAndCompletedCheck.isFalse,
          icon: "mdi-account",
          scmDropDown: false
        }
      },
      {
        path: "owners",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory/details/groups/pages/groups-page.vue"),
        meta: {
          scopes: [Scope.arnica_admin, Scope.inventory_ro],
          title: "Product Owners",
          sidebar: FlagCheck.productsV2.front.isTrue,
          icon: "mdi-badge-account-outline",
          scmDropDown: true
        }
      },
      {
        path: "importance",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/inventory/details/business-importance/temp-business-importance-page.vue"),
        meta: {
          scopes: [Scope.arnica_admin, Scope.inventory_ro],
          title: "Business Importance",
          sidebar: FlagCheck.businessImportance.front.isFalse,
          icon: "mdi-priority-high",
          scmDropDown: false
        }
      },
      {
        path: "products",
        component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/products-v2/products-table.vue"),
        meta: {
          scopes: [Scope.arnica_admin, Scope.inventory_ro],
          sidebar: FlagCheck.productsV2.front.isFalse,
          title: "Products v2",
          frontIcon: {
            icon: "mdi-beta",
            tooltip: "Products v2"
          },
          icon: "mdi-package",
          scmDropDown: false
        }
      },
      {
        path: "maintenance",
        name: "maintenance",
        component: () => import("@/pages/maintenance/maintenance.vue"),
        beforeEnter(to, _from, next) {
          if (!State.Auth.isArnicaAdmin) {
            return next({ name: "home" });
          }
          return next();
        },
        meta: {
          title: "Maintenance",
          icon: "mdi-wrench",
          scopes: [Scope.arnica_admin]
        }
      },
    ]
  }
];
