import { Popup } from "./popup-state";
import Vue from "vue";
import { PolicyItem, PolicySubType } from "$/interfaces/policies";
import { PolicyV2 as Api } from "@/api";
import { PolicyItemWrapper } from "@/interfaces/policy-item-wrapper";
import { ObservableMap } from "@/utility-types/observable-map";

export const PolicyV2 = new (class PolicyV2State {
  private readonly _policies = new ObservableMap<PolicySubType, PolicyItemWrapper | null>();
  private _loading = false;

  public constructor() {
    Vue.observable(this);
  }

  /**
   * Indicates the policies are being loaded
   */
  public get loading(): boolean {
    return this._loading;
  }

  /**
   * Gets a list of supported policies for this tenant
   * If the value of the policy is `null`, it means it has not been loaded yet
   */
  public get policies(): [PolicySubType, PolicyItemWrapper | null][] {
    return this.getEntries();
  }

  /**
   * Gets a specific policy for this tenant
   * @param subType The policy sub type to get
   * @returns A policy item or `null` if the policy is not supported/loaded
   */
  public get(subType: PolicySubType): PolicyItem | null {
    return this._policies.get(subType) || null;
  }

  /**
   * Saves a policy
   * @param policy The policy to save
   * @returns The saved policy
   */
  public async save(policy: PolicyItem): Promise<PolicyItem> {
    const saved = await Api.save(policy);
    this._policies.set(policy.sub, new PolicyItemWrapper(saved));
    return saved;
  }

  /**
   * Resets a policy to its default state
   * @param policy The policy to reset
   * @returns The reset policy
   */
  public async reset(policy: PolicyItem): Promise<PolicyItem> {
    const resetPolicy = await Api.reset(policy);
    this._policies.set(policy.sub, new PolicyItemWrapper(resetPolicy));
    return resetPolicy;
  }

  /**
   * Imports a policy YAML
   * @param policyYaml The policy YAML to import
   * @returns The imported policy
   */
  public async import(policyYaml: string, sub: PolicySubType, id: string): Promise<PolicyItem> {
    const saved = await Api.import(policyYaml, sub, id);
    this._policies.set(saved.sub, new PolicyItemWrapper(saved));
    return saved;
  }

  private getEntries(): [PolicySubType, PolicyItemWrapper | null][] {
    void this.initPolicies();
    this.getEntries = () => [...this._policies.entries()];
    return this.getEntries();
  }

  private async initPolicies(): Promise<void> {
    try {
      this._loading = true;
      const list = await Api.get();
      for (const sub of list) {
        this._policies.set(sub, null);
      }
      await Promise.all(list.map((subType) => this.loadPolicy(subType)));
    } catch (e) {
      console.error(e);
    } finally {
      this._loading = false;
    }
  }

  private async loadPolicy(subType: PolicySubType): Promise<void> {
    try {
      const policy = await Api.get(subType);
      this._policies.set(policy.sub, new PolicyItemWrapper(policy));
    } catch (e) {
      this._policies.delete(subType);
      void Popup.autoHandleError(e);
    }
  }
})();
