import { PolicyItemType } from "../policy-item-base";
import { PolicyAction, PolicyActionSubType } from "./policy-action";

declare module "./policy-action" {
  enum PolicyActionSubType {
    reset_branch = "reset_branch"
  }
}

(PolicyActionSubType as { reset_branch: "reset_branch" }).reset_branch = "reset_branch";

export interface PolicyActionResetBranch extends PolicyAction {
  sub: PolicyActionSubType;
  notify: boolean;
}

export const PolicyActionResetBranch = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.reset_branch
  },
  generateDefault(): PolicyActionResetBranch {
    return {
      id: `${PolicyItemType.condition}-${Math.floor(Date.now() * Math.random())}`,
      name: "",
      notify: true,
      sub: PolicyActionSubType.reset_branch,
      type: PolicyItemType.action,
      children: []
    }
  }
} as const;
