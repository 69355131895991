<template>
  <a :class="`${color}--text`" v-if="!confirm" @click.stop="confirm = true">
    <slot name="default"></slot>
  </a>
  <strong v-else>
    <a :class="`${confirmColor}--text`" @click.stop="$emit('click')" @mouseleave="confirm = false">
      <slot name="confirmation">Yes I'm sure</slot>
    </a>
  </strong>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class ConfirmLink extends Vue {
  protected confirm = false;

  @Prop({ type: String, default: "blue" })
  protected color!: string;

  @Prop({ type: String, default: "red" })
  protected confirmColor!: string;
}

export default ConfirmLink;
</script>
