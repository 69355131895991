// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Number {
  /**
   * Returns the number if it is not NaN, otherwise returns the default value.
   * @param defaultValue [optional] The default value to return if the number is NaN. Default: 0.
   */
  numberOrDefault(defaultValue?: number): number;

  /**
   * Returns the number as a string with grouped digits.
   * @param separator [optional] The separator to use. Default: ",".
   */
  groupDigits(separator?: string): string;

  /**
   * Converts the number to a human readable string.
   * @param {number} [precision=2] The number of decimal places to include.
   * @default precision 2
   * @param {string[]} [labels=["", "K", "M", "G", "T"]]  The labels to use for each order of magnitude.
   * @default labels ["", "K", "M", "G", "T"]
   * @returns The humanized string.
   */
  humanize(precision?: number, labels?: string[]): string;
}

Number.prototype.numberOrDefault ||= function defaultOnNaN(this: number, defaultValue = 0): number {
  return Number.isNaN(this) ? defaultValue : this;
};

Number.prototype.groupDigits ||= function groupDigits(this: number, separator = ","): string {
  const [integer = "0", decimal] = this.toString().split(".");
  const grouped = integer.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  return decimal ? `${grouped}.${decimal}` : grouped;
};

(() => {
  // executed in closure to avoid adding the default labels to the global scope
  const DEFAULT_HUMANIZE_LABELS = ["", "K", "M", "G", "T"];
  Number.prototype.humanize ||= function humanize(this: number, precision = 2, labels: string[] = DEFAULT_HUMANIZE_LABELS): string {
    labels = Array.from(labels);
    let current = this;
    const sign = current < 0 ? "-" : "";
    current = Math.abs(current);
    let suffix = labels.shift();
    if (current === Infinity || Number.isNaN(current)) {
      return `${sign}${current}${suffix}`;
    }
    while (current >= 1000 && labels.length > 0) {
      current = current / 1000;
      suffix = labels.shift();
    }
    precision = Math.pow(10, precision);
    const fixed = Math.round(current * precision) / precision;
    const grouped = fixed.groupDigits();
    const result = `${sign}${grouped}${suffix}`;
    return result;
  };
})();
