import { AccessLevel } from "../ado/interfaces/user";
import { CodeOwnersFile } from "../github/interfaces/code-owners-info";
import { PostPRActions } from "../github/interfaces/post-pr-actions";
import { GitHubPermission } from "../github/types";
import { MisconfiguredCodeownersError } from "../report/insight-report/misconfigured-codeowners-report-item";
import { ADOInventoryReportQueryType, GitHubInventoryReportQueryType, InventoryReportQueryType } from "../report/inventory-report/inventory-report";
import { ADORiskReportQueryType, GitHubRiskReportQueryType, RiskReportType } from "../report/risk-report/risk-report";
import { SCMType } from "../scm-type";
import { IntegrationType, ScmIntegrationType } from "./integration/integration";
import { IntegrationAttributes } from "./integration/integration-attributes";
import { JobType } from "./job";
import { JobStatus } from "./job-status";
import { GroupResource } from "./group";

/**
 * @obsolete use `generateResourceId` instead
 */
export function generateGithubResourceId(orgName: string, repoName?: string, branchName?: string): string {
  return generateResourceId(SCMType.GITHUB, orgName, undefined, repoName, branchName);
}

/**
 * @obsolete use `generateResourceId` instead
 */
export function generateADOResourceId(orgName: string, projectName?: string, repoName?: string, branchName?: string): string {
  return generateResourceId(SCMType.AZURE_DEVOPS, orgName, projectName, repoName, branchName);
}

function generateResourceIdWithoutProject(orgName: string, repoName?: string, branchName?: string): string {
  if (!repoName) {
    return `${orgName}`;
  }
  if (!branchName) {
    return `${orgName}/${repoName}`;
  }
  return `${orgName}/${repoName}/${branchName}`;
}

function generateResourceIdWithProject(orgName: string, projectName?: string, repoName?: string, branchName?: string): string {
  if (!projectName) {
    return `${orgName}`;
  }
  if (!repoName) {
    return `${orgName}/${projectName}`;
  }
  if (!branchName) {
    return `${orgName}/${projectName}/${repoName}`;
  }
  return `${orgName}/${projectName}/${repoName}/${branchName}`;
}

export function generateResourceId(scmType: SCMType, orgName: string, projectName?: string, repoName?: string, branchName?: string): string {
  const attributes = IntegrationAttributes.get(scmType);
  if (!attributes) {
    throw new Error(`Unsupported SCM type: ${scmType}`);
  }
  return attributes.project.isSupported
    ? generateResourceIdWithProject(orgName, projectName, repoName, branchName)
    : generateResourceIdWithoutProject(orgName, repoName, branchName);
}

export const generateResourceIdFor = {
  organization(orgName: string): string {
    const components = [orgName];
    return components.map((c) => (c || "").encodeURI()).join("/");
  },
  project(orgName: string, projectName: string | undefined): string {
    const components = [orgName, projectName];
    return components.map((c) => (c || "").encodeURI()).join("/");
  },
  repository(orgName: string, projectName: string | undefined, repoName: string): string {
    const components = [orgName, projectName, repoName];
    return components.map((c) => (c || "").encodeURI()).join("/");
  },
  branch(orgName: string, projectName: string | undefined, repoName: string, branch: string): string {
    const components = [orgName, projectName, repoName, branch];
    return components.map((c) => (c || "").encodeURI()).join("/");
  },
  groupResource({ org, project, repo }: GroupResource): string {
    if (repo) {
      return generateResourceIdFor.repository(org, project, repo || "");
    }
    if (project) {
      return generateResourceIdFor.project(org, project);
    }
    return generateResourceIdFor.organization(org);
  }
} as const;

export interface MitigationResource {
  org: string;
  repo?: string;
  branch?: string;
}

export function generateMitigationAssetIdIntegrationTypeAndResourceType(integrationType: IntegrationType, resourceType: ResourceType, resourceId: string): string {
  return `${integrationType}-${resourceType}-${resourceId}`;
}

export function generateMitigationKey(
  assetIdSCMTypeAndResourceType: string,
  lastIngestionTimestamp: string,
  queryType: RiskReportType | InventoryReportQueryType,
  action: GithubMitigationAction | ADOMitigationAction,
  user?: string
): string {
  return `${assetIdSCMTypeAndResourceType}-${lastIngestionTimestamp}-${queryType}-${action}${user ? `-${user}` : ""}`;
}

export function getMitigationHistorySortKey(mitigation: Pick<Mitigation, "assetIdSCMTypeAndResourceTypeLastIngestionTimestamp">) {
  const { assetIdSCMTypeAndResourceTypeLastIngestionTimestamp } = mitigation;
  return `${assetIdSCMTypeAndResourceTypeLastIngestionTimestamp}_${new Date().toISOString()}`;
}

export function getJobSortKeyFromMitigation(mitigationStatus: MitigationStatus, assetIdSCMTypeAndResourceTypeLastIngestionTimestamp: string) {
  const jobType: JobType = mitigationStatusToJobType[mitigationStatus];
  return `${jobType}-${assetIdSCMTypeAndResourceTypeLastIngestionTimestamp}`;
}

export function getRevertJobSortKeyFromMitigationKey(mitigationKey: string) {
  return `revert-${mitigationKey}`;
}

export function getMitigateJobSortKeyFromMitigationKey(mitigationKey: string) {
  return `mitigate-${mitigationKey}`;
}

export type MitigationStatus =
  | "pending-mitigation"
  | "pending-revert"
  | "mitigating"
  | "reverting"
  | "mitigated"
  | "reverted"
  | "error-mitigating"
  | "error-reverting"
  | "mitigate-pr-closed"
  | "revert-pr-closed"
  | "awaiting-mitigation-pull-request-approval"
  | "awaiting-revert-pull-request-approval";

export const mitigationStatusToJobStatus: Record<MitigationStatus, JobStatus> = {
  "pending-mitigation": "pending",
  mitigating: "running",
  mitigated: "complete",
  "error-mitigating": "error",
  "pending-revert": "pending",
  reverting: "running",
  reverted: "complete",
  "mitigate-pr-closed": "error",
  "revert-pr-closed": "error",
  "error-reverting": "error",
  "awaiting-mitigation-pull-request-approval": "complete",
  "awaiting-revert-pull-request-approval": "complete"
};

export const mitigationStatusToJobType: Record<MitigationStatus, JobType> = {
  "pending-mitigation": "mitigate",
  mitigating: "mitigate",
  mitigated: "mitigate",
  "error-mitigating": "mitigate",
  "pending-revert": "revert-mitigation",
  reverting: "revert-mitigation",
  reverted: "revert-mitigation",
  "error-reverting": "revert-mitigation",
  "mitigate-pr-closed": "mitigate",
  "revert-pr-closed": "revert-mitigation",
  "awaiting-mitigation-pull-request-approval": "mitigate",
  "awaiting-revert-pull-request-approval": "revert-mitigation"
};

export const GithubMitigationAction = {
  RESTRICT_PUSH: "RESTRICT_PUSH",
  MODIFY_RESTRICT_PUSH: "MODIFY_RESTRICT_PUSH",
  MODIFY_CODEOWNERS: "MODIFY_CODEOWNERS",
  CREATE_CODEOWNERS: "CREATE_CODEOWNERS",
  ENABLE_AND_MODIFY_CODEOWNERS: "ENABLE_AND_MODIFY_CODEOWNERS",
  REDUCE_MAINTAIN_TO_WRITE: "REDUCE_MAINTAIN_TO_WRITE",
  REDUCE_ADMIN_TO_MAINTAIN: "REDUCE_ADMIN_TO_MAINTAIN",
  REDUCE_ADMIN_TO_WRITE: "REDUCE_ADMIN_TO_WRITE",
  REDUCE_ORG_OWNER_TO_MEMBER: "REDUCE_ORG_OWNER_TO_MEMBER",
  ARCHIVE: "ARCHIVE",
  REMOVE_USER: "REMOVE_USER",
  CONFIGURE_CODEOWNERS: "CONFIGURE_CODEOWNERS",
  ENFORCE_ARNICA_STATUS_CHECK: "ENFORCE_ARNICA_STATUS_CHECK"
} as const;

export type GithubMitigationAction = (typeof GithubMitigationAction)[keyof typeof GithubMitigationAction];

export enum ADOMitigationAction {
  REMOVE_USER = "REMOVE_USER",
  DISABLE_REPOSITORY = "DISABLE_REPOSITORY",
  REMOVE_EXCESSIVE_REVIEWERS = "REMOVE_EXCESSIVE_REVIEWERS",
  REMOVE_EXCESSIVE_CONTRIBUTORS = "REMOVE_EXCESSIVE_CONTRIBUTORS",
  ENABLE_PR_REVIEWS = "ENABLE_PR_REVIEWS",
  REMOVE_EXCESSIVE_ADMINS = "REMOVE_EXCESSIVE_ADMINS",
  ENFORCE_ARNICA_STATUS_CHECK = "ENFORCE_ARNICA_STATUS_CHECK"
}

export type MitigationAction = GithubMitigationAction | ADOMitigationAction;

export const ResourceType = {
  ORG: "ORG",
  PROJECT: "PROJECT",
  REPO: "REPO",
  BRANCH: "BRANCH"
} as const;

export type ResourceType = (typeof ResourceType)[keyof typeof ResourceType];

export function getGithubResourceType(repoName?: string, branchName?: string): ResourceType {
  if (branchName) {
    return ResourceType.BRANCH;
  }
  if (repoName) {
    return ResourceType.REPO;
  }
  return ResourceType.ORG;
}

export function getADOResourceType(projectName?: string, repoName?: string, branchName?: string): ResourceType {
  if (branchName) {
    return ResourceType.BRANCH;
  }
  if (repoName) {
    return ResourceType.REPO;
  }
  if (projectName) {
    return ResourceType.PROJECT;
  }
  return ResourceType.ORG;
}

export interface Mitigation {
  /** Pretend it's called sort key **/
  assetIdSCMTypeAndResourceTypeLastIngestionTimestamp: string;
  assetIdSCMTypeAndResourceType: string;
  lastIngestionTimestamp: string;
  arnicaOrgId: string;
  integrationOrgId: string;
  integrationType: ScmIntegrationType;
  resourceId: string;
  resourceType: ResourceType;
  status: MitigationStatus;
  action: MitigationAction;
  /**
   * Some mitigation jobs such as mitigations done via pull requests are done in multiple phases.
   * For a pull request mitigation, the first phase would be to create a mitigation, and the second would be to
   * update the branch protection setting after the pull request is merged in. All new mitigation will start with this
   * equal to 1.
   */
  phase: number;
  typeOfPermissionFixed: RiskReportType | InventoryReportQueryType;
  usersKeepingLosingPermissions: UsersKeepingLosingPermissions;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  isSelfHosted: boolean;
  error?: string;
}

export interface GithubMitigation extends Mitigation {
  repoName?: string;
  branchName?: string;
  baseUrl: string;
  typeOfPermissionFixed: GitHubRiskReportQueryType | GitHubInventoryReportQueryType;
  action: GithubMitigationAction;
  integrationType: "github";
  adminUsersToMaintain?: {
    usersDirectlyLosingPermissions: string[];
    usersInTeamLosingPermissions: UsersInTeam[];
  };
  /**
   * This is a list of parent teams which had to be changed due to it having too high of a permission to allow mitigation
   * to merely occur on the child team. These teams are monitored for when a new user is added to them, to give them the
   * original permission the team had
   */
  parentTeamsWithPermissionChanged?: OriginalTeamPermission[];
  /**
   * This is a list of teams removed from a codeowners file. This is to know where to add new users added to these teams
   * to the codeowners file
   */
  teamFormerPermissions?: IdentityCODEOWNERSPermissions[];
  /**
   * This is a list of users removed from a codeowners file. This is so that if a user asks for permission back, we can
   * add them back to the paths they should be in
   */
  //TODO: when we have an easy way to rename columns - rename to userFormerCodeownersPermissions
  userFormerPermissions?: IdentityCODEOWNERSPermissions[];

  /**
   * This is the resulting CODEOWNERS file (logical representation of the file) if the mitigation would be applied, including any metadata on auto generated teams
   */
  codeOwnersFile?: CodeOwnersFile;

  orgOwnerMitigationRepoPermissions?: OrgOwnerMitigationRepoPermissions /** */;
  codeownersErrors?: MisconfiguredCodeownersError[];
  identitiesFormerRepoPermission?: IdentitiesFormerRepoPermission[];
  /**
   * This will only be set if the mitigation method is via pull request
   */
  pullRequestNumber?: number;
  pullRequestCloseStatus?: "merged" | "closed";
  postPRActions?: PostPRActions;
  isRemoveRestrictPushBranchProtectionRequired?: boolean;
}

export interface ADOMitigation extends Mitigation {
  projectName?: string;
  repoName?: string;
  branchName?: string;
  typeOfPermissionFixed: ADORiskReportQueryType | ADOInventoryReportQueryType;
  action: ADOMitigationAction;
  integrationType: "azure-devops";
  usersKeepingLosingPermissions: ADOUsersKeepingLosingPermissions;
  userEntitlementInfo?: UserEntitlementInfo;
  createdPoliciesIds?: number[];
  previousPermissionsToResource?: PreviousPermissionsToResource[];
  usersAddedToGroups?: UsersInTeam[];
  createdTeams?: CreatedTeam[];
  usersRemovedFromTeams?: UsersInTeam[];
}

export type MitigationType = GithubMitigation | ADOMitigation;

export interface OrgOwnerMitigationRepoPermissions {
  grantMaintainRepo: string[];
  grantWriteRepo: string[];
}

export interface IdentityCODEOWNERSPermissions {
  identity: string;
  codeOwnerPaths?: string[];
}

export interface IdentitiesFormerRepoPermission {
  identity: string;
  identityType: "user" | "team";
  repoPermission: RepoPermission;
}

export interface UserEntitlementInfo {
  accessLevel?: AccessLevel;
  groupDescriptors: string[];
}

export interface CreatedTeam {
  id: string;
  descriptor: string;
}

export type RepoPermission = "none" | "read" | "triage";

export interface UsersKeepingLosingPermissions {
  usersDirectlyLosingPermissions: string[];
  usersDirectlyKeepingPermissions: string[];
  usersInTeamKeepingPermissions: UsersInTeam[];
  usersInTeamLosingPermissions: UsersInTeam[];
}

export interface ADOUsersKeepingLosingPermissions extends UsersKeepingLosingPermissions {
  usersInTeamKeepingPermissions: ADOUsersInTeam[];
  usersInTeamLosingPermissions: ADOUsersInTeam[];
}

export function isCompletedMitigation(mitigation: Mitigation): boolean {
  return mitigation.status === "mitigated";
}

export function isRevertedMitigation(mitigation: Mitigation): boolean {
  return mitigation.status === "reverted";
}

export function isRevertingMitigationStatus(mitigationStatus: MitigationStatus): boolean {
  return mitigationStatus === "reverting" || mitigationStatus === "pending-revert" || mitigationStatus === "awaiting-revert-pull-request-approval";
}

export function isErrorMitigationStatus(status: MitigationStatus) {
  return status === "error-mitigating" || status === "error-reverting" || status === "mitigate-pr-closed" || status === "revert-pr-closed";
}

export function isPendingOrRunningMitigationStatus(status: MitigationStatus) {
  return status === "pending-revert" || status === "pending-mitigation" || status === "mitigating" || status === "reverting";
}

export function getUsersLosingPermissions(usersDirectlyLosingPermissions: string[], usersInTeamLosingPermissions: UsersInTeam[]): string[] {
  const usersLosingPermissions = new Set<string>(usersDirectlyLosingPermissions);
  for (const userInTeam of usersInTeamLosingPermissions) {
    userInTeam.users.forEach((user) => usersLosingPermissions.add(user));
  }
  return Array.from(usersLosingPermissions);
}

export function getUsersKeepingPermissions(usersKeepingLosingPermissions: UsersKeepingLosingPermissions): string[] {
  const usersKeepingPermissions = new Set<string>(usersKeepingLosingPermissions.usersDirectlyKeepingPermissions);
  for (const userInTeam of usersKeepingLosingPermissions.usersInTeamKeepingPermissions) {
    userInTeam.users.forEach((user) => usersKeepingPermissions.add(user));
  }
  return Array.from(usersKeepingPermissions);
}

export function getTeamsFromUsersKeepingLosingPermissions(usersInTeamKeepingPermissions: UsersInTeam[], usersInTeamLosingPermissions: UsersInTeam[]): string[] {
  const teams = new Set<string>();
  for (const userInTeam of usersInTeamKeepingPermissions) {
    teams.add(userInTeam.team);
  }
  for (const userInTeam of usersInTeamLosingPermissions) {
    teams.add(userInTeam.team);
  }
  return Array.from(teams);
}

export function sumExcessivePermissionsInMitigation(usersKeepingLosingPermissions: UsersKeepingLosingPermissions): number {
  let sum = 0;
  sum += usersKeepingLosingPermissions.usersDirectlyLosingPermissions.length;
  usersKeepingLosingPermissions.usersInTeamLosingPermissions.forEach((usersInTeam) => {
    sum += usersInTeam.users.length;
  });
  return sum;
}

export function sumValidPermissionsInMitigation(usersKeepingLosingPermissions: UsersKeepingLosingPermissions): number {
  let sum = 0;
  sum += usersKeepingLosingPermissions.usersDirectlyKeepingPermissions.length;
  usersKeepingLosingPermissions.usersInTeamKeepingPermissions.forEach((usersInTeam) => {
    sum += usersInTeam.users.length;
  });
  return sum;
}

export function sumTotalPermissionsInMitigation(usersKeepingLosingPermissions: UsersKeepingLosingPermissions): number {
  return sumValidPermissionsInMitigation(usersKeepingLosingPermissions) + sumExcessivePermissionsInMitigation(usersKeepingLosingPermissions);
}

export interface MitigationHistory extends Mitigation {
  sortKey: string;
}

export interface UsersInTeam {
  team: string;
  users: string[];
}

export interface ADOUsersInTeam extends UsersInTeam {
  teamDisplayName: string;
}

export interface OriginalTeamPermission {
  teamSlug: string;
  teamName: string;
  permission: GitHubPermission;
  usersGivenDirectPermission: string[];
}

export interface PreviousPermissionsToResource {
  aceDescriptor: string;
  allow: number;
  deny: number;
}
