import { IntegrationOrgIdAndType } from "$/dynamo";
import type { UICodeRiskFinding } from "@/pages/risks/code/ui-code-risk-item";
import { getResourceSCMUrl } from "@/utils";
import { Integrations } from "@/state";
import type { SCMLinkIdentityType } from "$/utils/links";

export function findingLinkFilter(
  item: Pick<UICodeRiskFinding, "org" | "integrationType" | "baseUrl" | "context" | "lineNumber"> & Partial<Pick<UICodeRiskFinding, "integrationOrgId" | "path" | "metadata">>,
  identity: Exclude<SCMLinkIdentityType, "user"> = "commit"
): string | null {
  try {
    const { org, integrationOrgId, integrationType, baseUrl, context, path, metadata, lineNumber } = item;
    if (!path || metadata?.commitHidden) {
      return null;
    }
    const { hash, lineRange } = context?.commitDetails || {};
    const integrationOrgIdAndType = IntegrationOrgIdAndType.from(integrationOrgId || org, integrationType);
    const integration = Integrations.getIntegrationsByIntegrationOrgIdAndType(integrationOrgIdAndType);
    return hash
      ? getResourceSCMUrl(
          {
            ...item,
            baseUrl: integration?.baseUrl || baseUrl,
            commitHash: hash,
            commitLineNumber: lineRange?.start ?? lineNumber
          },
          identity
        )
      : null;
  } catch (e) {
    console.warn(`findingLinkFilter`, e);
    return null;
  }
}
