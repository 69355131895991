
<v-menu class="component layout-component app-menu" bottom min-width="200px" rounded offset-y v-if="$state.Layout.userMenu.show">
  <template v-slot:activator="{ on }">
    <v-btn icon x-large v-on="on">
      <v-avatar color="gray" size="32">
        <v-img v-if="$state.Auth.user && $state.Auth.user.picture" :src="$state.Auth.user.picture" @error="onImgError">
          <template v-slot:placeholder>
            <v-progress-circular indeterminate color="success" size="30"></v-progress-circular>
          </template>
        </v-img>
        <v-icon dark v-else>mdi-account-circle</v-icon>
      </v-avatar>
    </v-btn>
  </template>

  <user-menu></user-menu>
</v-menu>
