<template>
  <v-card class="component layout-component user-menu">
    <template v-if="isUserMenuVisible">
      <v-list dense nav>
        <v-list-item link disabled>
          <v-list-item-content>
            <v-list-item-title>
              <v-chip v-if="role" x-small disabled label class="float-right user-chip" :class="showBadges ? 'badges' : ''">
                <user-role :user="user" :managedBySso="showBadges"></user-role>
              </v-chip>
              <v-icon small>mdi-email</v-icon>
              {{ email }}
              <v-icon small color="green" v-if="emailVerified">mdi-check-bold</v-icon>
            </v-list-item-title>
            <v-list-item-subtitle v-if="showOrgDetails">
              <v-chip x-small outlined label class="float-right re-enable user-chip" :to="{ name: 'upgrade' }">{{ $tier.title }}</v-chip>
              <v-icon small>mdi-office-building</v-icon>
              <template v-if="orgName">
                {{ orgName }}
                <small>[{{ orgId }}]</small>
              </template>
              <template v-else>
                {{ orgId }}
              </template>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <template v-if="emailVerified && !public">
          <v-list-item link @click.stop="$state.Layout.userMenu.editProfile = true">
            <v-list-item-icon>
              <v-icon>mdi-account-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Account</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click.stop="$state.Layout.userMenu.preferences = true">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Preferences</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>

        <v-list-item link @click.stop="$state.Auth.signOut()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sign-Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

    <template v-if="!public">
      <edit-profile-dialog></edit-profile-dialog>
      <settings-dialog></settings-dialog>
    </template>
  </v-card>
</template>

<script lang="ts">
import UserRole from "../../admin/user-role.vue";
import EditProfileDialog from "./edit-profile-dialog.vue";
import { RoleInfo } from "./role-converter";
import SettingsDialog from "./settings-dialog.vue";
import { Component, Vue } from "vue-property-decorator";
import { Role, UserManagementConfig } from "$/dynamo";

@Component({
  components: {
    EditProfileDialog,
    SettingsDialog,
    UserRole
  }
})
export default class UserMenu extends Vue {
  protected userManagementConfig: UserManagementConfig = { managedBySso: false };
  protected async beforeMount() {
    this.userManagementConfig = (await this.$api.Orgs.getManagementConfig()) || { managedBySso: false };
  }
  protected get user() {
    return this.$state.Auth.user?.userInfo ?? {};
  }
  protected get managedBySso() {
    return this.userManagementConfig.managedBySso;
  }
  protected get email() {
    return this.$state.Auth.user?.email;
  }
  protected get emailVerified() {
    return this.$state.Auth.isEmailVerified;
  }
  protected get role() {
    const { role } = this.$state.Auth.user?.userInfo || {};
    return role ? RoleInfo.from(role) : null;
  }
  protected get showBadges() {
    return !!(this.managedBySso && this.role && this.role.role !== Role.owner);
  }
  protected get orgId() {
    return this.$state.Auth.user?.userInfo?.orgId;
  }
  protected get orgName() {
    return this.$state.Auth.user?.userInfo?.orgName;
  }
  protected get public() {
    return this.$state.Auth.user?.public;
  }
  protected get isUserMenuVisible() {
    return this.$state.Layout.userMenu.show;
  }
  protected get showOrgDetails() {
    return !this.public && this.orgId;
  }
}
</script>

<style lang="scss">
.component.layout-component.user-menu {
  .re-enable {
    pointer-events: auto;
  }
  .user-chip {
    min-width: 75px;
    display: flex;
    justify-content: center;
  }
  .badges {
    .v-chip__content {
      margin-left: -0.5em;
    }
    .badges-list {
      .v-icon {
        margin: 0.1em !important;
      }
      justify-content: center !important;
    }
  }
}
</style>
