import Router, { Route } from "vue-router";
import * as State from "@/state";

declare module "vue-router/types/router" {
  export interface RouteMeta {
    /**
     * Indicates if the route should not init state.
     * Default is `false` (state is initialized).
     */
    skipInit?: boolean;
  }
}

async function initStateRoute(to: Route): Promise<void> {
  if (
    to.meta?.skipInit === true ||
    !State.Auth.hasOrgId // includes check for authenticated
  ) {
    return;
  }
  await Promise.allSettled([State.Integrations.init(false, true), State.Members.init()]);
}

export function install(router: Router): Router {
  router.afterEach(initStateRoute);
  return router;
}
