import { ADOHardeningReportQueryType, ADORiskReportQueryType, GitHubHardeningReportQueryType, GitHubRiskReportQueryType } from "../../../report/risk-report/risk-report";
import { SCMType } from "../../../scm-type";

//TODO: add date generate
export type GetInsightsReportResponse = {
  insights: GetInsightResponseItem[];
  dateGenerated: string;
};

export interface GetInsightResponseItem {
  title: string;
  shortSummary: string;
  type: InsightType;
  scmType: SCMType | null;
  page: InsightPageName;
  countItem: number;
  countTotal: number;
  longSummary?: string;
  percentage?: number;
  mitigationSummary?: string;
  severity: number;
}

export type InsightType =
  | ADORiskReportQueryType
  | ADOHardeningReportQueryType
  | GitHubRiskReportQueryType
  | GitHubHardeningReportQueryType
  | typeof SECRETS_PAGE_NAME
  | typeof ANOMALIES_PAGE_NAME;
export const PERMISSIONS_PAGE_NAME = "permissions";
export const HARDENING_PAGE_NAME = "hardening";
export const SECRETS_PAGE_NAME = "secrets";
export const ANOMALIES_PAGE_NAME = "anomalies";
export const CODE_PAGE_NAME = "code";
export const ALL_FINDINGS_PAGE_NAME = "all-findings";
export const TRENDS_PAGE_NAME = "trends";

export type InsightPageName = typeof PERMISSIONS_PAGE_NAME | typeof HARDENING_PAGE_NAME | typeof SECRETS_PAGE_NAME | typeof ANOMALIES_PAGE_NAME;
