import { LineRange, ScmUrlGenerator } from "../../data-access/src/scm-url-generator";

export class GitHubUrlGenerator implements ScmUrlGenerator {
  public getOrganizationUrl(organizationId: string): string {
    return `https://github.com/${organizationId.encodeURI()}`;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getProjectUrl(organizationId: string, _projectId: string): string {
    return `https://github.com/${organizationId.encodeURI()}`;
  }
  public getRepositoryUrl(organizationId: string, repositoryId: string): string {
    return `https://github.com/${organizationId.encodeURI()}/${repositoryId.encodeURI()}`;
  }
  public getRepositoryBranchUrl(organizationId: string, repositoryId: string, branch: string): string {
    return `https://github.com/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/tree/${branch.encodeURI()}`;
  }
  public getRepositoryCommitUrl(organizationId: string, repositoryId: string, commitSha: string): string {
    return `https://github.com/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/commit/${commitSha}`;
  }

  public getRepositoryBranchFileUrl(organizationId: string, repositoryId: string, branch: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, branch, path, lines);
  }
  public getRepositoryCommitFileUrl(organizationId: string, repositoryId: string, commitSha: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, commitSha, path, lines);
  }
  private getRepositoryFileUrl(organizationId: string, repositoryId: string, commitReference: string, path: string, lines?: LineRange): string {
    const lineEnd = lines?.end ? `-${lines.end}` : "";
    const lineInfo = lines?.start ? `#L${lines?.start}${lineEnd}` : "";
    return `https://github.com/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/blob/${commitReference}/${path.encodePathURI()}${lineInfo}`;
  }

  public getRepositoryPullRequestUrl(organizationId: string, repositoryId: string, pullRequestId: number): string {
    return `https://github.com/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/pull/${pullRequestId}`;
  }
  public getRepositoryPullRequestCommentUrl(organizationId: string, repositoryId: string, pullRequestId: number, commentId: string): string {
    return `https://github.com/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/pull/${pullRequestId}#issuecomment-${commentId.encodeURI()}`;
  }
  public getUserUrl(_organizationId: string, userId: string): string {
    return `https://github.com/${userId.encodeURI()}`;
  }
  public getRepositoryId(_projectId: string | undefined, repoId: string): string {
    return repoId;
  }
}
