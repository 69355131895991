import { CodeRiskDismissOptionsTypes, SecretDismissOptionsType } from "../../../finding-types";
import type { CodeRiskFinding } from "../../../sast/code-risk-finding";
import type { PolicyEventConditionContext, PolicyEventContext } from "../policy-event-context";
import { PolicyItemType } from "../policy-item-base";
import { PolicyTrigger, PolicyTriggerSubType } from "./policy-trigger";

declare module "./policy-trigger" {
  enum PolicyTriggerSubType {
    user_dismissed_finding = "user_dismissed_finding"
  }
}

(PolicyTriggerSubType as { user_dismissed_finding: "user_dismissed_finding" }).user_dismissed_finding = "user_dismissed_finding";

export type ChatOpsUser = {
  id: string | null;
  name: string;
  kind?: "user" | "channel" | "team" | "comment";
};

export interface DismissalData {
  chatOpsRequester: ChatOpsUser;
  chatOpsReviewer?: ChatOpsUser;
  dismissalApproved?: boolean;
  rejectionDetails?: string;
  dismissalReason?: CodeRiskDismissOptionsTypes | SecretDismissOptionsType;
  dismissalDetails?: string;
  requiresReview?: boolean;
}

export type PolicyUserDismissedFindingEventContext = PolicyEventConditionContext<CodeRiskFinding> & {
  dismissalData: DismissalData;
  finding: CodeRiskFinding;
};

export const PolicyUserDismissedFindingEventContext = {
  is(obj: PolicyEventContext): obj is PolicyUserDismissedFindingEventContext {
    return "dismissalData" in obj;
  }
} as const;

export interface PolicyTriggerUserDismissedFinding extends PolicyTrigger {
  sub: PolicyTriggerSubType.user_dismissed_finding;
}

export const PolicyTriggerUserDismissedFinding = {
  component: {
    type: PolicyItemType.trigger,
    sub: PolicyTriggerSubType.user_dismissed_finding
  }
} as const;
