import { UniversalScmIntegrationType } from "../../../../shared/src/dynamo";
import { BitbucketCloudUrlGenerator } from "../../bitbucket-cloud/src/bitbucket-cloud-url-generator";
import { BitbucketServerUrlGenerator } from "../../bitbucket-server/src/bitbucket-server-url-generator";
import { ScmUrlGenerator } from "../../data-access/src/scm-url-generator";
import { GitLabUrlGenerator } from "../../gitlab/src/gitlab-url-generator";
import { GitHubUrlGenerator } from "../../github/src/github-url-generator";
import { AdoUrlGenerator } from "../../ado/src/ado-url-generator";

export class ScmUrlGeneratorFactory {
  private readonly urlGenerators: Record<UniversalScmIntegrationType, (baseUrl: string | undefined) => ScmUrlGenerator> = {
    "bitbucket-server": () => new BitbucketServerUrlGenerator(),
    "bitbucket-cloud": () => new BitbucketCloudUrlGenerator(),
    gitlab: (baseUrl: string | undefined) => new GitLabUrlGenerator(baseUrl),
    github: ()  => new GitHubUrlGenerator(),
    "azure-devops": () => new AdoUrlGenerator()
  };

  public build(integrationType: UniversalScmIntegrationType, baseUrl: string | undefined): ScmUrlGenerator {
    const generator = this.urlGenerators[integrationType];
    return generator(baseUrl);
  }
}
