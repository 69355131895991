export enum CredentialsType {
  access_token = "access_token",
  login = "login"
}

interface AccessTokenCredentialsData {
  type: CredentialsType.access_token;
  pat: string;
}

interface UsernamePasswordCredentialsData {
  type: CredentialsType.login;
  username: string;
  password: string;
}

export type CredentialsData = AccessTokenCredentialsData | UsernamePasswordCredentialsData;

export interface Credentials {
  getAuth(): string;

  getCloneToken(): string;

  data: CredentialsData;
}

export class PATCredentials implements Credentials {
  private readonly pat: string;

  public constructor(public readonly data: CredentialsData) {
    if (data.type !== CredentialsType.access_token) {
      throw new Error("PATCredentials must be created with CredentialsType.access_token");
    }

    this.pat = data.pat;
  }

  public getAuth(): string {
    return `Bearer ${this.pat}`;
  }

  public getCloneToken(): string {
    return this.pat;
  }
}

export class LoginCredentials implements Credentials {
  private readonly username: string;
  private readonly password: string;

  public constructor(public readonly data: CredentialsData) {
    if (data.type !== CredentialsType.login) {
      throw new Error("LoginCredentials must be created with CredentialsType.login");
    }

    this.username = data.username;
    this.password = data.password;
  }

  public getAuth(): string {
    return `Basic ${Buffer.from(`${this.username}:${this.password}`).toString("base64")}`;
  }

  public getCloneToken(): string {
    return encodeURIComponent(this.password);
  }
}
