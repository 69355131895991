import { ApiBase } from "./api-base";
import { Callbacks } from "./callbacks-api";
import { IntegrationType } from "$/dynamo";
import {
  GitHubIntegrationCallbackRequestResponse,
  IntegrationInitiationRequest,
  UpdateUniversalScmIntegrationInitiationRequest,
  ValidCredentialsResult
} from "$/interfaces/ui-api/request/integrations/integration-initiation-request";
import { GetTeamsResponse } from "$/interfaces/ui-api/response/get-github-teams";
import { GetIntegrationResponse } from "$/interfaces/ui-api/response/get-integration-response";
import { AxiosUserException } from "@/errors/axios-user-exception";
import { Auth, Popup } from "@/state";

class AdoIntegrationsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "integrations/azure-devops", name: "AdoIntegrationsApi" });
  }

  public async getIntegrationUrl(): Promise<string> {
    const res = await this.client.get<string>("url");
    return res.data;
  }
}

class IntegrationsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "integrations", name: "IntegrationsApi" });
  }
  public readonly "azure-devops" = new AdoIntegrationsApi();

  public async create(params: IntegrationInitiationRequest): Promise<void> {
    // TODO: we are passing as queryStringParameters due to slack's overly opinionated callbackHandler
    // we should extract the body of that method and implement it without tightly coupling it to req/res and assuming everything is a query param
    try {
      await this.client.post<void>(params.type, params, { params: { ...params } });
    } catch (e) {
      const userException = AxiosUserException.tryCreate(e);
      if (userException) {
        void Popup.error(userException.message);
        throw userException;
      }
      throw e;
    }
  }

  public async registerCallback(params: IntegrationInitiationRequest): Promise<GitHubIntegrationCallbackRequestResponse> {
    if (Auth.authenticated) {
      const res = await this.client.post<GitHubIntegrationCallbackRequestResponse | void>(params.type, params);
      return res.data ?? { created: false };
    } else {
      return await Callbacks.register(params);
    }
  }

  public async getAll(healthCheck = false, silent = false): Promise<GetIntegrationResponse[]> {
    const res = await this.client.get<GetIntegrationResponse[]>("", {
      params: { healthCheck: false }, // disabled for performance
      silent
    });
    return res.data;
  }

  public async delete(integrationType: IntegrationType, integrationOrgId: string): Promise<void> {
    await this.client.delete<void>(`/${integrationType.encodeURI()}/${integrationOrgId.encodeURI()}`);
  }

  public async update(integrationType: IntegrationType, integrationOrgId: string, params: UpdateUniversalScmIntegrationInitiationRequest): Promise<void> {
    await this.client.patch<void>(`/${integrationType.encodeURI()}/${integrationOrgId.encodeURI()}`, params);
  }

  public async validateCredentials(params: IntegrationInitiationRequest): Promise<ValidCredentialsResult> {
    try {
      const res = await this.client.post<ValidCredentialsResult>(`/${params.type.encodeURI()}/validate`, params);
      return res.data;
    } catch (e) {
      const userException = AxiosUserException.tryCreate(e);
      if (userException) {
        void Popup.error(userException.message);
        throw userException;
      }
      throw e;
    }
  }

  public async getGitHubTeams(org: string): Promise<GetTeamsResponse[]> {
    const res = await this.client.get<GetTeamsResponse[]>(`/github/orgs/${org}/teams`);
    return res.data;
  }
}
export const Integrations = new IntegrationsApi();
