import { escapeRegExp } from "lodash";
import { RiskSeverity } from "../risk-severity";
import { TrivyJSON } from "../sca/interfaces/trivy-scan-results";
import { removeTrailingSlash, stringifyError } from "../utils";
import {
  ApplicationTreeNode,
  InfrastructureFileTreeNode,
  InfrastructureTypeTreeNode,
  InfrastructureVulnerabilityTreeNode,
  LibraryTreeNode,
  SBOMNode,
  VulnerabilityTreeNode
} from "./interfaces/sbom-nodes";
import { CheckovSBOM, RawSBOMReports, SBOMReportItemResponse, SumVulnerabilities, TrivyCycloneDx } from "./interfaces/sbom-report-item";
import { ScoreCardResults, ShortScoreCardResults } from "./interfaces/scorecard";
import { CheckovVulnerability, TrivyVulnerability } from "./interfaces/vulnerability";
import { CheckovCheckDetails, CheckovDetailsByCheckId } from "./sbom-constants";
import { ConsoleLogger } from "../logging";

/**
 * The name of the property in the library component that holds the package type
 */
export const TRIVY_PACKAGE_TYPE_PROPERTY_NAME_IN_LIB_COMPONENT = "aquasecurity:trivy:PkgType";

/**
 * The name of the property in the application component that holds the package type
 */
export const TRIVY_PACKAGE_TYPE_PROPERTY_NAME_IN_APP_COMPONENT = "aquasecurity:trivy:Type";

/**
 * Custom property name in order to keep track of package types which we get findings for but aren't supported by trivy
 */
export const ORIGINAL_COMPONENT_PACKAGE_TYPE_PROPERTY = "original-component-package-type";

export const RISK_CONVERTER: Readonly<Readonly<Record<RiskSeverity, number>> & Readonly<Record<number, RiskSeverity>>> = {
  critical: 1,
  high: 2,
  medium: 3,
  low: 4,
  info: 5,
  unknown: 6,
  1: "critical",
  2: "high",
  3: "medium",
  4: "low",
  5: "info",
  6: "unknown"
} as const;

interface VulnerabilitySeveritySortArgs {
  sumVulnerabilities: SumVulnerabilities;
  numApplications?: number;
  numPackages?: number;
  reputation?: number;
  stars?: number;
}

export const TRIVY_PACKAGE_TYPE_TO_SYSTEM_MAP: Record<TrivyCycloneDx.PackageType, TrivyCycloneDx.RefSystem> = {
  npm: "npm",
  pom: "maven",
  pip: "pypi",
  pipenv: "pypi",
  poetry: "pypi",
  gomod: "golang",
  yarn: "npm",
  cargo: "cargo",
  gradle: "maven",
  nuget: "nuget",
  gemspec: "gem",
  bundler: "gem",
  composer: "composer",
  conan: "conan",
  cocoapods: "cocoapods",
  pub: "pub",
  sbt: "maven"
}

export class SBOMUtils {
  private constructor() {
    throw new Error("Should not be instantiated");
  }

  public static getRefForDependency(name: string, system: TrivyCycloneDx.RefSystem, version: string | undefined): string {
    const formattedName = name.replaceAll(":", "/");
    const purl = `pkg:${system}/${formattedName}`;
    if (version) {
      return `${purl}@${version}`;
    }
    return purl;
  }

  public static getVulnerabilityRating(v: TrivyCycloneDx.Vulnerability): TrivyCycloneDx.Rating {
    const cvssv31Ratings = v.ratings?.filter((r) => r.method === "CVSSv31") ?? [];
    const cvssv30Ratings = v.ratings?.filter((r) => r.method === "CVSSv3") ?? [];
    const cvssv2Ratings = v.ratings?.filter((r) => r.method === "CVSSv2") ?? [];
    const otherRatings = v.ratings?.filter((r) => r.severity) ?? [];

    return (
      cvssv31Ratings.find((r) => r.source?.name === "nvd") ??
      cvssv31Ratings[0] ??
      cvssv30Ratings.find((r) => r.source?.name === "nvd") ??
      cvssv30Ratings[0] ??
      cvssv2Ratings.find((r) => r.source?.name === "nvd") ??
      cvssv2Ratings[0] ??
      (otherRatings[0] as TrivyCycloneDx.Rating) ??
      ({ ...(v.ratings?.[0] ?? {}), ...{ severity: "unknown" } } as TrivyCycloneDx.Rating)
    );
  }

  /**
   * Summarizes a list of dictionaries with counts into a single dictionary
   * @param listOfDicts
   */
  public static reduceListOfDictCounts(listOfDicts: Record<string, number>[]) {
    return listOfDicts.reduce(
      (prev, cur) => {
        for (const key in cur) {
          prev[key] = (prev[key] ?? 0) + (cur[key] ?? 0);
        }
        return prev;
      },
      {} /* initial */
    );
  }

  /**
   * Sorts by vulnerability severity, count, reputation, etc.
   * @param a
   * @param b
   */
  public static sortByVulnerabilitySeverity<T extends VulnerabilitySeveritySortArgs>(a: T, b: T) {
    return (
      b.sumVulnerabilities.critical - a.sumVulnerabilities.critical ||
      b.sumVulnerabilities.high - a.sumVulnerabilities.high ||
      b.sumVulnerabilities.medium - a.sumVulnerabilities.medium ||
      b.sumVulnerabilities.low - a.sumVulnerabilities.low ||
      b.sumVulnerabilities.info - a.sumVulnerabilities.info ||
      b.sumVulnerabilities.unknown - a.sumVulnerabilities.unknown ||
      (a.reputation ?? 0) - (b.reputation ?? 0) ||
      (a.stars ?? 0) - (b.stars ?? 0) ||
      (b.numApplications ?? 0) - (a.numApplications ?? 0) ||
      (b.numPackages ?? 0) - (a.numPackages ?? 0)
    );
  }
  /**
   * Sorts by vulnerability severity, count, reputation, etc.
   * @param a
   * @param b
   */
  public static sortBySumVulnerabilities(a: Partial<SumVulnerabilities>, b: Partial<SumVulnerabilities>) {
    return (
        (b.critical ?? 0) - (a.critical ?? 0) ||
        (b.high ?? 0) - (a.high ?? 0) ||
        (b.medium ?? 0) - (a.medium ?? 0) ||
        (b.low ?? 0) - (a.low ?? 0) ||
        (b.info ?? 0) - (a.info ?? 0) ||
        (b.unknown ?? 0) - (a.unknown ?? 0)
    );
  }

  /**
   * Sort all table results, including ones without vulnerability summary
   * @param a
   * @param b
   */
  public static additionalInfoSort(a?: SBOMReportItemResponse["additionalInfo"], b?: SBOMReportItemResponse["additionalInfo"]): number {
    const sortResult = 0;
    if (!a && !b) {
      return sortResult;
    }
    if (!a) {
      return 1;
    }
    if (!b) {
      return -1;
    }
    return SBOMUtils.sortByVulnerabilitySeverity(a, b);
  }

  public static getSBOMNodes(item: SBOMReportItemResponse, rawSBOMReports: RawSBOMReports): (ApplicationTreeNode | InfrastructureTypeTreeNode)[] {
    const ret = SBOMUtils.getTrivyNodes(rawSBOMReports.trivyCycloneDxSBOM, item);
    ret.sort(SBOMUtils.sortByVulnerabilitySeverity);
    return ret;
  }

  public static readonly defaultVulnerabilitySeverity: SumVulnerabilities = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    info: 0,
    unknown: 0
  } as const;

  public static hasVulnerabilities(v: SumVulnerabilities, minSeverity: RiskSeverity = "unknown"): boolean {
    return Object.entries(v)
      .filter(([severity, count]) => RISK_CONVERTER[severity as RiskSeverity] <= RISK_CONVERTER[minSeverity])
      .some(([severity, count]) => count > 0);
  }
  public static getLibraryTypesAndPathsByBomRef(sbom: TrivyCycloneDx.SBOM | null): Map<string, { path: string; type: TrivyCycloneDx.PackageType }[]> {
    if (!sbom) {
      return new Map();
    }
    const { applications, librariesByBomRef, dependenciesByBomRef } = this.getAppsLibsAndDeps(sbom);

    const appTreeViewItems = applications.flatMap((a) => {
      const bomRef = a["bom-ref"];
      return (
        dependenciesByBomRef
          .get(bomRef)
          ?.dependsOnFlat?.map((d) => {
            const lib: TrivyCycloneDx.Library | undefined = librariesByBomRef.get(d);
            if (!lib) {
              return null;
            }
            const path = a.name;
            const libKey = lib["bom-ref"];
            const type = SBOMUtils.getPackageTypeFromComponent(a);
            if (!type) {
              //TODO: log
              return null;
            }
            return { libKey, path, type };
          })
          .nonNullable() || []
      );
    });
    return appTreeViewItems.groupBy((k) => k.libKey).mapValues((v) => v.map((k) => ({ path: k.path, type: k.type })));
  }

  public static getTrivyNodes(sbom: TrivyCycloneDx.SBOM | null, item: SBOMReportItemResponse): ApplicationTreeNode[] {
    return this.getTrivyNodesInternal(sbom, item, false);
  }

  public static getDirectTrivyNodes(sbom: TrivyCycloneDx.SBOM | null, item: SBOMReportItemResponse): ApplicationTreeNode[] {
    return this.getTrivyNodesInternal(sbom, item, true);
  }

  private static getTrivyNodesInternal(sbom: TrivyCycloneDx.SBOM | null, item: SBOMReportItemResponse, directOnly: boolean): ApplicationTreeNode[] {
    if (!sbom) {
      return [];
    }
    const { applications, librariesByBomRef, dependenciesByBomRef } = this.getAppsLibsAndDeps(sbom);

    return applications.map((app) => {
      const appBomRef = app["bom-ref"];
      const appNodeId = `${item.integrationOrgId}/${item.project ?? ""}/${item.repo}/${appBomRef}`;
      const appTrivyDependency = dependenciesByBomRef.get(appBomRef);
      const appDependsOn = directOnly ? appTrivyDependency?.dependsOn : appTrivyDependency?.dependsOnFlat;
      const libraries = appDependsOn?.map((dependsOnRef) =>
          this.getLibraryTreeNode({
            librariesByBomRef,
            dependsOnRef,
            appNodeId,
            app
          })
        ).nonNullable() || [];

      libraries.sort(SBOMUtils.sortByVulnerabilitySeverity);
      return this.getApplicationTreeNode(appNodeId, app, libraries);
    });
  }

  private static getApplicationTreeNode(appNodeId: string, application: TrivyCycloneDx.LibraryOrApplication, libraries: LibraryTreeNode[]): ApplicationTreeNode {
    return {
      id: appNodeId,
      name: application.name,
      nodeType: "application",
      packageType: this.getPackageTypeFromComponent(application),
      children: libraries,
      sumVulnerabilities: SBOMUtils.reduceListOfDictCounts(libraries.map((l) => l.sumVulnerabilities ?? {})) as SumVulnerabilities,
      // make sure numPackages isn't affected by filtering
      numPackages: libraries.length,
      search: `${application.name} ${libraries.map((l) => l.search).join(" ")}`
    };
  }

  public static getPackageTypeFromComponent(application: TrivyCycloneDx.LibraryOrApplication) {
    return application.properties?.find((x) => x.name === TRIVY_PACKAGE_TYPE_PROPERTY_NAME_IN_APP_COMPONENT || x.name === TRIVY_PACKAGE_TYPE_PROPERTY_NAME_IN_LIB_COMPONENT)
      ?.value as TrivyCycloneDx.PackageType | undefined;
  }

  private static getLibraryTreeNode({
    librariesByBomRef,
    dependsOnRef,
    appNodeId,
    vulnerabilitiesByBomRef,
    app
  }: {
    librariesByBomRef: Map<string, TrivyCycloneDx.Library>;
    dependsOnRef: string;
    appNodeId: string;
    vulnerabilitiesByBomRef?: Map<string, NonEmptyArray<TrivyCycloneDx.Vulnerability>>;
    app: TrivyCycloneDx.LibraryOrApplication;
  }) {
    const lib: TrivyCycloneDx.Library | undefined = librariesByBomRef.get(dependsOnRef);
    if (!lib) {
      return null;
    }
    const libNodeId = `${appNodeId}/${dependsOnRef}`;
    const vulnerabilities: VulnerabilityTreeNode[] =
      vulnerabilitiesByBomRef?.get(dependsOnRef)?.map((v: TrivyVulnerability) => {
        return this.getVulnerabilityTreeNode(v, libNodeId);
      }) ?? [];

    vulnerabilities.sort(SBOMUtils.sortByVulnerabilitySeverity);

    // if a library appears in more than one package file type (Pipfile.lock, requirements.txt, poetry.lock, etc.)
    // then the SBOM lib will have an array of packageTypes (pipenv, pip, poetry, respectively)
    // since all logic is the same for pipenv, pip, poetry, etc. we just take the first one.
    //TODO: we can add another abstraction, e.g. packageRepositoryType and packgeFileType, e.g. npm is the package repository, yarn and npm are the package file types, (same with maven and gradle etc.)
    const sbomPackageType = lib.packageType ?? lib.packageTypes?.[0];
    const scorecard = this.getScorecard(lib, sbomPackageType);

    const path = app.name;
    const gitBlameDetails = lib.gitBlameDetailsByPath?.[path];
    const isDev = lib.isDev;
    const lineRange = lib.lineRangesByPath?.[path];
    const libNode: LibraryTreeNode = {
      id: libNodeId,
      name: `${lib.name}@${lib.version}`,
      nodeType: "library",
      children: vulnerabilities,
      packageType: sbomPackageType,
      scorecard,
      statistics: lib.statistics,
      licenses: lib.descriptionAndLicense?.licenses,
      reputationRisk: lib.reputationRisk,
      description: lib.descriptionAndLicense?.descriptionContentType === "text/plain" ? lib.descriptionAndLicense?.description : undefined,
      sumVulnerabilities: SBOMUtils.defaultVulnerabilitySeverity,
      gitBlameDetails,
      lineRange,
      updatedAt: lib.updatedAt,
      isDev,
      releaseDate: lib.releaseDate,
      latestVersion: lib.latestVersion,
      latestVersionReleaseDate: lib.latestVersionReleaseDate
    };
    libNode.search = `${lib.name}@${lib.version} ${vulnerabilities.map((v) => Object.values(v).join(" ")).join(" ")} ${lib.descriptionAndLicense?.licenses
      ?.map((l) => l.name)
      .distinct()
      ?.join(" ")} `.toLowerCase();
    libNode.sumVulnerabilities = {
      ...libNode.sumVulnerabilities,
      ...libNode.children
        .groupBy((k) => k.vulnerability?.rating?.severity)
        .mapValues((x) => x.length)
        .toRecord()
    };
    return libNode;
  }

  private static getScorecard(lib: TrivyCycloneDx.Library, sbomPackageType?: TrivyCycloneDx.PackageType) {
    const scorecard =
      this.shortenScorecard(lib.scorecard) ??
      (sbomPackageType
        ? {
            links: {
              origins: [],
              repo: lib.descriptionAndLicense?.repoURL ?? ""
            },
            system: sbomPackageType
          }
        : undefined);

    if (scorecard?.links && !scorecard.links.repo) {
      scorecard.links.repo = lib.descriptionAndLicense?.repoURL ?? "";
    }
    return scorecard;
  }

  private static getVulnerabilityTreeNode(v: TrivyVulnerability, libNodeId: string): VulnerabilityTreeNode {
    v.rating = SBOMUtils.getVulnerabilityRating(v);

    return {
      id: `${libNodeId}/${v.id}`,
      name: v.id,
      nodeType: "vulnerability",
      vulnerability: v,
      children: [],
      search: `${Object.values(v)}}`,
      sumVulnerabilities: {
        ...SBOMUtils.defaultVulnerabilitySeverity,
        [v.rating.severity]: 1
      }
    };
  }

  public static getAppsLibsAndDeps(sbom: TrivyCycloneDx.SBOM) {
    const components = sbom.components ?? [];
    const applications = components.filter((c) => c.type === "application") ?? [];
    const librariesByBomRef = components.filter((c): c is TrivyCycloneDx.Library => c.type === "library").mapBy((k) => k["bom-ref"]);
    const dependenciesByBomRef = (sbom.dependencies ?? []).mapBy((k) => k["ref"]);
    return { applications, librariesByBomRef, dependenciesByBomRef };
  }

  public static getVulnerabilitiesByBomRef(vulnerabilities: TrivyCycloneDx.Vulnerability[]) {
    return vulnerabilities
      .flatMap((v) => v.affects.distinct((a) => a.ref).map((a) => ({ key: a.ref, vulnerability: v })))
      .groupBy((k) => k.key)
      .mapValues((x) => x.map((y) => y.vulnerability) as NonEmptyArray<TrivyCycloneDx.Vulnerability>);
  }

  public static getCheckovNodes(checkov: CheckovSBOM.BOM | null, item: SBOMReportItemResponse): InfrastructureTypeTreeNode[] {
    if (!checkov) {
      return [];
    }

    try {
      const allVulnerabilities = Array.guarantee(checkov.vulnerabilities?.vulnerability);

      const vulnerabilitiesByRef = allVulnerabilities.groupBy((v) => v.affects?.target?.ref);
      const allComponents = Array.guarantee(checkov.components?.component);

      const applications =
        allComponents
          .map(SBOMUtils.mapCheckovComponents)
          .nonNullable()
          .groupBy((x) => x.packageType) ?? new Map();

      const nodes: InfrastructureTypeTreeNode[] = [];
      applications.forEach((packageInfraItems, packageType) => {
        if (packageType === "secrets") {
          return;
        }
        const infraTypeNodeId = `${item.integrationOrgId}/${item.project ?? ""}/${item.repo}/${packageType}`;

        const infraItemsByFile = packageInfraItems.groupBy((x) => x.file);
        const infrastructureFiles = infraItemsByFile.map((filepath, fileInfraItems) => {
          const fileVulnerabilities = fileInfraItems.flatMap((x) => vulnerabilitiesByRef?.get(x.bomRef) ?? []).nonNullable();
          const infraFileNodeId = `${infraTypeNodeId}/${filepath}`;
          const vulnerabilities: InfrastructureVulnerabilityTreeNode[] = fileVulnerabilities
            .map((v: CheckovVulnerability) => {
              const rating = v.ratings.rating;
              const checkovDetails = this.getCheckovDetails(v.id);
              const checkovSeverity = checkovDetails?.severity ?? rating.severity ?? null;
              if (!checkovSeverity || checkovSeverity === "unknown") {
                return null;
              }
              rating.severity = checkovSeverity;
              v.rating = rating;

              const node: InfrastructureVulnerabilityTreeNode = {
                id: `${infraFileNodeId}/${v["@bom-ref"]}`,
                name: v.id,
                nodeType: "infrastructure-vulnerability",
                packageType,
                children: [],
                vulnerability: v,
                sumVulnerabilities: {
                  ...SBOMUtils.defaultVulnerabilitySeverity,
                  [rating.severity]: 1
                }
              };
              return node;
            })
            .nonNullable();
          vulnerabilities.sort(SBOMUtils.sortByVulnerabilitySeverity);
          const fileTreeViewItem: InfrastructureFileTreeNode = {
            id: infraFileNodeId,
            name: filepath,
            nodeType: "infrastructure-file",
            packageType,
            sumVulnerabilities: SBOMUtils.reduceListOfDictCounts(vulnerabilities.map((x) => x.sumVulnerabilities)) as SumVulnerabilities,
            children: vulnerabilities
          };
          return fileTreeViewItem;
        });
        infrastructureFiles.sort(SBOMUtils.sortByVulnerabilitySeverity);
        const appTreeViewItem: InfrastructureTypeTreeNode = {
          id: infraTypeNodeId,

          //TODO: move to UI
          name: packageType,
          nodeType: "infrastructure-type",
          packageType,
          sumVulnerabilities: SBOMUtils.reduceListOfDictCounts(infrastructureFiles.map((x) => x.sumVulnerabilities)) as SumVulnerabilities,
          search: packageType,
          children: infrastructureFiles
        };
        nodes.push(appTreeViewItem);
      });
      return nodes;
    } catch (e) {
      //TODO: pass a logger or error callback to this function
      console.warn(`getCheckovNodes: error parsing checkov SBOM: ${stringifyError(e)}`);
      return [];
    }
  }

  private static mapCheckovComponents(component: CheckovSBOM.Component): { packageType: string; file: string; bomRef: string } | null {
    const bomRef = component["@bom-ref"];
    const suffixString = component.name;
    const regexExpression = `pkg:(.+)/cli_repo/([^/]+)/(.+)(${escapeRegExp(suffixString)}.*)`;
    const regex = new RegExp(regexExpression);
    const sbomDecoded = decodeURIComponent(bomRef);
    const regExpExecArray = regex.exec(sbomDecoded);
    const [, packageType, , file] = regExpExecArray ?? [];
    if (!file || !packageType) {
      return null;
    }

    return {
      packageType,
      file: removeTrailingSlash(file),
      bomRef
    };
  }

  public static isApplicationNode(node: SBOMNode): node is ApplicationTreeNode {
    return node.nodeType === "application";
  }

  public static isLibraryNode(node: SBOMNode): node is LibraryTreeNode {
    return node.nodeType === "library";
  }

  public static isVulnerabilityNode(node: SBOMNode): node is VulnerabilityTreeNode {
    return node.nodeType === "vulnerability";
  }

  public static isInfrastructureTypeNode(node: SBOMNode): node is InfrastructureTypeTreeNode {
    return node.nodeType === "infrastructure-type";
  }

  public static isInfrastructureFileNode(node: SBOMNode): node is InfrastructureFileTreeNode {
    return node.nodeType === "infrastructure-file";
  }

  public static isInfrastructureVulnerabilityNode(node: SBOMNode): node is InfrastructureVulnerabilityTreeNode {
    return node.nodeType === "infrastructure-vulnerability";
  }

  public static shortenScorecard(scorecard?: ScoreCardResults): ShortScoreCardResults | undefined {
    if (!scorecard) {
      return;
    }
    const project = scorecard.version?.projects.find((p) => p.type === "GITHUB");
    const stars = project?.stars;
    const reputation = project?.scorecardV2?.score;
    const system = scorecard.package?.system;
    const links = scorecard.version?.links || { origins: [] };
    return {
      reputation,
      stars,
      system,
      links
    };
  }

  public static getCheckovDetails(checkID: keyof typeof CheckovDetailsByCheckId): CheckovCheckDetails | null {
    return CheckovDetailsByCheckId[checkID] ?? null;
  }

  public static normalizePackageNameAndVersion(
    component: { name: string; version: string },
    sbomPackageType: TrivyCycloneDx.PackageType
  ): { packageName: string; packageVersion: string };
  public static normalizePackageNameAndVersion(
    component?: { name?: string; version?: string },
    sbomPackageType?: TrivyCycloneDx.PackageType
  ): { packageName?: string; packageVersion?: string };
  public static normalizePackageNameAndVersion(
    component?: { name?: string; version?: string },
    sbomPackageType?: TrivyCycloneDx.PackageType
  ): { packageName?: string; packageVersion?: string } {
    try {
      let packageName = component?.name;
      let packageVersion = component?.version;
      if (!packageName || !packageVersion) {
        // using console warn as the UI is using this class, and our shared logger is dependent on `process`
        // until we have a shim for it for the UI we can't use it
        console.warn(`normalizePackageNameAndVersion: missing package name or version: ${packageName} ${packageVersion}`);
        return { packageName, packageVersion };
      }
      switch (sbomPackageType) {
        case "pom":
        case "gradle":
          packageName = packageName.replace("/", ":");
          break;
        case "nuget":
          packageName = packageName.toLowerCase();
          break;
        case "pip":
        case "pipenv":
        case "poetry":
          packageName = SBOMUtils.normalizePypiPackageName(packageName); // packageName.toLowerCase().replace(/[_.]/g, "-");
          if (packageVersion.split(".").length === 2) {
            packageVersion += ".0";
          }
          break;
        case "gomod":
          packageName = packageName.toLowerCase();
          if (!packageVersion.startsWith("v")) {
            packageVersion = `v${packageVersion}`;
          }
          break;
      }
      return { packageName, packageVersion };
    } catch (e) {
      // using console warn as the UI is using this class, and our shared logger is dependent on `process`
      // until we have a shim for it for the UI we can't use it
      console.warn(`normalizePackageNameAndVersion: error normalizing package name and version: ${Error.stringify(e)}`);
      return { packageName: component?.name, packageVersion: component?.version };
    }
  }

  /**
   * Returns a normalized package key as used in deps.dev
   * @param component
   * @param sbomPackageType
   */
  public static getNormalizedPackageKey(component: { name: string; version: string } | TrivyJSON.Vulnerability, sbomPackageType: TrivyCycloneDx.PackageType): string;
  public static getNormalizedPackageKey(component: { name: string; version: string } | TrivyJSON.Vulnerability, sbomPackageType?: TrivyCycloneDx.PackageType): string | null;
  public static getNormalizedPackageKey(component: { name: string; version: string } | TrivyJSON.Vulnerability, sbomPackageType?: TrivyCycloneDx.PackageType): string | null {
    if ("PkgName" in component && "InstalledVersion" in component) {
      return SBOMUtils.getNormalizedPackageKey({ name: component.PkgName, version: component.InstalledVersion }, sbomPackageType);
    }
    const { packageName, packageVersion } = SBOMUtils.normalizePackageNameAndVersion(component, sbomPackageType);
    if (!packageName || !packageVersion) {
      return null;
    }
    return SBOMUtils.getPackageKey(packageName, packageVersion);
  }

  public static getPackageKey(packageName: string, packageVersion: string): string {
    return `${packageName}@${packageVersion}`;
  }

  public static normalizePypiPackageName(packageName: string): string {
    //https://packaging.python.org/en/latest/guides/distributing-packages-using-setuptools/#name
    /*
     * Comparison of project names is case insensitive and treats arbitrarily-long runs of underscores, hyphens, and/or periods as equal. For example, if you register a project named cool-stuff, users will be able to download it or declare a dependency on it using any of the following spellings:
     *
     * Cool-Stuff
     * cool.stuff
     * COOL_STUFF
     * CoOl__-.-__sTuFF
     */
    //TODO: unit test all above cases
    //return packageName.replaceAll(/[_.-]+/g, "-").toLowerCase();
    return packageName.toLowerCase().replace(/[_.]/g, "-");
  }

  public static getSBOMPackageTypesForLibrary(c: TrivyCycloneDx.Library): TrivyCycloneDx.PackageType[] | undefined {
    const packageTypeProperties = c.properties?.filter((x) => x.name === TRIVY_PACKAGE_TYPE_PROPERTY_NAME_IN_LIB_COMPONENT);
    return packageTypeProperties?.map((p) => p.value as TrivyCycloneDx.PackageType);
  }

  public static getNameAndVersion(fullPackageNameAndVersion: string): { name: string; version: string } {
    const lastAt = fullPackageNameAndVersion.lastIndexOf("@");
    const name = fullPackageNameAndVersion.substring(0, lastAt);
    const version = fullPackageNameAndVersion.substring(lastAt + 1);
    return { name, version };
  }

  /**
   * When the same library (name+version) is in different files, trivy only shows it once in the SBOM, for example:
   *
   * ```
   * path1/requirements.txt
   *
   * path2/Pipfile.lock
   *
   * path3/poetry.lock
   * ```
   *
   * All with `acme@1.2.3`
   *
   * Trivy will show one library component with 1 property of name `aquasecurity:trivy:PkgType` and value `pip` (corresponding to the first file)
   *
   * This code will add the missing package type properties (`pipenv` and `poetry`) to the library component properties.
   *
   * See the unit test for better understanding of the input and output.
   *
   * @param input
   */
  public static enrichPackageTypes(input: TrivyCycloneDx.SBOM): void {
    const libraries = this.getLibraries(input);
    const apps = input.components?.filter((c) => c.type === "application") ?? [];
    const libsByLibRef = libraries.mapBy((k) => k["bom-ref"]);
    const appsByAppRef = apps.mapBy((k) => k["bom-ref"]);

    //map between key the library bom-ref to the set of app bom-refs that depends on it
    //the apps also have the packageType, (under "aquasecurity:trivy:Type" and not under "aquasecurity:trivy:PkgType", thanks aqua...)
    //so the plan is to get the packageTypes from the apps and add them to the library properties...
    const appsForLibs = new Map<string, Set<string>>();

    for (const dep of input.dependencies ?? []) {
      const libraryRefsDependsOn = dep.dependsOnFlat?.filter((d) => libsByLibRef?.has(d)) ?? [];

      for (const libraryRef of libraryRefsDependsOn) {
        appsForLibs.getOrSet(libraryRef, () => new Set<string>()).add(dep.ref);
      }
    }
    // this is going to only contain new library components
    for (const [libraryBomRef, appBomRefsForLib] of appsForLibs.entries()) {
      // get the index to delete

      const library = libsByLibRef?.get(libraryBomRef);
      for (const appBomRef of appBomRefsForLib) {
        const app = appsByAppRef?.get(appBomRef);

        if (!app || !library) {
          //TODO: log, although this should not happen
          continue;
        }
        const packageType = this.getPackageTypeFromComponent(app);
        if (!packageType) {
          //TODO: log, although this should not happen
          continue;
        }
        // yes we may have duplicates, but we will remove them later
        library.properties.push({
          name: TRIVY_PACKAGE_TYPE_PROPERTY_NAME_IN_LIB_COMPONENT,
          value: packageType
        });
      }
    }

    for (const library of libraries) {
      library.properties = library.properties.distinct((p) => JSON.stringify(p));
    }
  }

  public static getLibraries(input: TrivyCycloneDx.SBOM) {
    const libraries = input.components?.filter(SBOMUtils.isLibrary) ?? [];
    return libraries;
  }

  public static getApplications(input: TrivyCycloneDx.SBOM) {
    const applications = input.components?.filter(SBOMUtils.isApplication) ?? [];
    return applications;
  }

  public static isLibrary(component: TrivyCycloneDx.LibraryOrApplication): component is TrivyCycloneDx.Library {
    return component.type === "library";
  }

  public static isApplication(component: TrivyCycloneDx.LibraryOrApplication): component is TrivyCycloneDx.Application {
    return component.type === "application";
  }

  //TODO: getDirectLibraries

  /**
   * Trivy version 0.42.1 no longer shows all package dependencies in a flat way in the `dependsOn` property.
   * This method adds `dependsOnFlat` to all application nodes in the `dependencies` list that includes the flat list of their dependencies
   * (All references in our code to `dependsOn` were switched to `dependsOnFlat` to keep backward compatibility)
   *
   * @param input
   */
  public static enrichFlattenedDependencies(input: TrivyCycloneDx.SBOM) {
    const { applications, dependenciesByBomRef, librariesByBomRef } = SBOMUtils.getAppsLibsAndDeps(input);
    for (const application of applications) {
      const appDirectDependencies = dependenciesByBomRef.get(application["bom-ref"]);
      if (!appDirectDependencies) {
        continue;
      }
      const flattened = new Set<string>();
      this.traverseDependencyTree(appDirectDependencies.ref, flattened, dependenciesByBomRef);
      flattened.delete(appDirectDependencies.ref);
      appDirectDependencies.dependsOnFlat = Array.from(flattened).sort();
    }
  }

  /**
   * Helper method for addFlattenedDependencies, do not use directly.
   */
  private static traverseDependencyTree(ref: string, visited: Set<string>, dependenciesByBomRef: Map<string, TrivyCycloneDx.TrivyDependency>) {
    if (visited.has(ref)) {
      return;
    }

    visited.add(ref);

    for (const directDependencyRef of dependenciesByBomRef.get(ref)?.dependsOn ?? []) {
      this.traverseDependencyTree(directDependencyRef, visited, dependenciesByBomRef);
    }
  }
}
