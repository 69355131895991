import { CodeRiskFindingType } from "../../../../finding-types";
import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    type = "type"
  }
}

(PolicyConditionSubType as { type: "type" }).type = "type";

export interface PolicyConditionFindingType extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.type;
  name: CodeRiskFindingType;
}

export const PolicyConditionFindingType = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.type
  } as const
} as const;
