interface Date {
  /**
   * Converts a date to a string that can be used as part of a file name.
   */
  toFileISOString(): string;
}

Date.prototype.toFileISOString = function toFileISOString(this: Date): string {
  return this.toISOString().replaceAll(":", "");
};
