import scssVariables from "@/variables.module.scss";
import { RiskSeverity } from "$/risk-severity";
import { Vulnerability } from "$/sbom/interfaces/vulnerability";
import { vuetify } from "@/plugins";

function isVulnerability(item: any): item is Vulnerability {
  return !!item?.rating?.severity;
}

const SEVERITY_ICON_MAP: Record<RiskSeverity, string> = {
  // critical: "mdi-alpha-c-circle",
  // high: "mdi-alpha-h-circle",
  // medium: "mdi-alpha-m-circle",
  // low: "mdi-alpha-l-circle",
  // info: "mdi-alpha-i-circle",
  // unknown: "mdi-alpha-u-circle"

  critical: "mdi-chevron-triple-up",
  high: "mdi-chevron-double-up",
  medium: "mdi-chevron-up",
  low: "mdi-chevron-down",
  info: "mdi-chevron-double-down",
  unknown: "mdi-help-circle-outline"
};

/**
 * @see {@link https://v2.vuetifyjs.com/en/styles/colors/#material-colors}
 */

// export const BASE_SEVERITY_COLOR_MAP: Record<RiskSeverity, string> = {
//   critical: scssVariables.darkCritical,
//   high: "orange",
//   medium: "yellow",
//   low: "light-blue",
//   //low: "lime",
//   info: "teal",
//   unknown: "grey"
// };

const SEVERITY_ORDER_MAP: Record<RiskSeverity, number> = {
  critical: 5,
  high: 4,
  medium: 3,
  low: 2,
  info: 1,
  unknown: 0
};

export function getIconBySeverity(vulnerabilitySeverity: RiskSeverity): string {
  return SEVERITY_ICON_MAP[vulnerabilitySeverity || "unknown"];
}

//TODO: this should be a css class!
export function getColorBySeverity(vulnerabilityOrSeverity?: Vulnerability | RiskSeverity | null): string {
  const vulnerabilitySeverity = isVulnerability(vulnerabilityOrSeverity) ? vulnerabilityOrSeverity?.rating?.severity : vulnerabilityOrSeverity;
  while(!vuetify.framework.theme) {
    console.log("waiting for vuetify to load");
  }
  const dark = vuetify.framework.theme.dark;
  const theme = dark ? "dark" : "light";
  const sevCap = vulnerabilitySeverity?.capitalize();
  const color = scssVariables[`${theme}${sevCap}`];
  return color ?? scssVariables[`${theme}Unknown`] as string;
}

export function getColorByScore(scorecardScore: number) {
  if (scorecardScore >= 7) {
    return "success";
  } else if (scorecardScore >= 5) {
    return "info";
  } else if (scorecardScore >= 3) {
    return "warning";
  } else {
    return "error";
  }
}

export function getOrderBySeverity(severity: RiskSeverity): number {
  return SEVERITY_ORDER_MAP[severity || "unknown"];
}
