import { RouteConfig } from "vue-router";
import { PolicyRuleTypes } from "$/dynamo";
import { Scope } from "@/interfaces/scope";
import { Tier } from "@/state";
import { Demo } from "@/state/demo-state";
import { jobsV2Routes } from "./jobs-v2/jobs-v2-routes";

export const adminPageRoutes: RouteConfig[] = [
  {
    path: "/admin",
    component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/admin-page.vue"),
    meta: {
      scopes: [Scope.registered],
      sidebar: !Demo.enabled,
      title: "Admin",
      icon: "mdi-account-cog"
    },
    children: [
      {
        path: "/",
        name: "dashboard",
        redirect: { name: "admin-policy-v2" },
        meta: {
          scopes: [Scope.registered],
          title: "Dashboard",
          sidebar: false,
          icon: "mdi-account-cog"
        }
      },
      {
        path: "policy-v2",
        name: "admin-policy-v2",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/policy-v2/policy-page.vue"),
        meta: {
          scopes: [Scope.policies_rw],
          sidebar: true,
          title: "Policies",
          icon: "mdi-arrow-decision"
        }
      },
      ...jobsV2Routes,
      {
        path: "policy/legacy/secrets",
        name: "admin-policy-legacy-secrets",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/policy/policy-page.vue"),
        props: {
          policyType: PolicyRuleTypes.SECRET_DETECTION
        },
        meta: {
          scopes: [Scope.registered],
          sidebar: false,
          title: "Secrets Policy (legacy)",
          icon: "mdi-arrow-decision"
        }
      },
      {
        path: "integrations",
        name: "admin-integrations",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/integrations-page.vue"),
        meta: {
          scopes(scopes) {
            return !!(scopes[Scope.integrations_ro] || scopes[Scope.integrations_rw]);
          },
          sidebar: true,
          title: "Integrations",
          icon: "mdi-view-grid-plus"
        }
      },
      {
        path: "jobs",
        name: "jobs",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/jobs-page.vue"),
        meta: {
          scopes: [Scope.jobs_ro],
          sidebar: false,
          title: "Jobs",
          icon: "mdi-factory"
        }
      },
      {
        path: "users-and-roles",
        name: "admin-users-and-roles",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/admin-users-and-roles-page.vue"),
        meta: {
          scopes: [Scope.user_rw],
          sidebar: true,
          title: "Users and Roles",
          icon: "mdi-account-group"
        }
      },
      {
        path: "plan",
        name: "plan",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/admin-plan-page.vue"),
        meta: {
          scopes: [Scope.billing_rw],
          title: "Manage Plan",
          icon: "mdi-briefcase-account-outline",
          sidebar: true
        }
      },
      {
        path: "upgrade",
        name: "upgrade",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/upgrade-page.vue"),
        meta: {
          scopes: [Scope.billing_rw],
          title: "Upgrade",
          icon: "mdi-upload-lock-outline",
          get sidebar(): boolean {
            return Tier.under.enterprise;
          }
        }
      },
      {
        path: "api",
        name: "api",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/api-page.vue"),
        meta: {
          scopes: [Scope.api_keys_rw],
          title: "API",
          icon: "mdi-api",
          get sidebar(): boolean {
            return Tier.is.enterprise;
          }
        }
      }
    ]
  }
];
