import { Auth } from "./auth-state";
import Vue from "vue";
import { GetUserInfoResponse } from "$/interfaces/ui-api/response/get-user-info-response";
import { Users } from "@/api";

export const Members = new (class MembersState {
  //public isSkipped = true;
  public members: GetUserInfoResponse[] = [];

  public constructor() {
    Vue.observable(this);
  }

  public async init() {
    this.members = !Auth.user?.registered ? [] : await Users.all();
  }
})();
