import { ApiBase } from "./api-base";
import { AxiosRequestConfig } from "axios";
import { ProductFilter, ProductFilterField, ProductSort, UIProduct, UIProductDTO } from "$/ui-models/products-v2/ui-product";
import { uuid } from "$/ui-models/uuid";
import { CreateOwnersParameters } from "$/ui-models/products-v2/ui-product-owner";

const ENABLE_SEARCH_VERB = !!new URLSearchParams(window.location.search).get("searchVerb");

interface GetProductOptions {
  page: number;
  pageSize: number;
  filter?: ProductFilter;
  sort?: ProductSort;
  search?: string;
  silent?: boolean;
}

class ProductsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "products", name: "ProductsApi" });
  }

  public async getAll({ page, pageSize, filter, sort, search, silent = false }: GetProductOptions): Promise<UIProduct[]> {
    const config: AxiosRequestConfig = {
      url: "/list",
      method: ENABLE_SEARCH_VERB ? "SEARCH" : "POST",
      silent,
      params: {
        page,
        pageSize
      },
      data: { filter, sort, search }
    };

    const res = await this.client.request<UIProductDTO[]>(config);
    return res.data?.map((d) => new UIProduct(d));
  }

  public async getCounts(filter?: ProductFilter, search?: string, silent = false): Promise<number> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<number>("/counts", { filter, search }, config);
    return res.data ?? null;
  }

  public async getFilterCounts(
    filter?: ProductFilter,
    search?: string,
    column?: ProductFilterField,
    silent = false
  ): Promise<PartialRecord<keyof ProductFilter, Record<string, number>> | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<PartialRecord<keyof ProductFilter, Record<string, number>> | null>("/filter-counts", { filter, search, column }, config);
    return res.data ?? null;
  }

  public async create(productName: string): Promise<UIProduct> {
    const res = await this.client.post<UIProductDTO>("/", { name: productName });
    return new UIProduct(res.data);
  }

  public async delete(productId: uuid): Promise<void> {
    await this.client.delete(productId.encodeURI());
  }

  public async addAssets(productId: uuid, assets: { locationIds?: uuid[]; repositoryIds?: uuid[] }): Promise<void> {
    await this.client.post(`/${productId.encodeURI()}/assets`, assets);
  }

  public async deleteRepositoryAsset(productId: uuid, repositoryId: uuid): Promise<void> {
    await this.client.delete(`/${productId.encodeURI()}/assets/repositories/${repositoryId.encodeURI()}`);
  }

  public async deleteLocationAsset(productId: uuid, locationId: uuid): Promise<void> {
    await this.client.delete(`/${productId.encodeURI()}/assets/locations/${locationId.encodeURI()}`);
  }

  public async rename(productId: uuid, newName: string): Promise<void> {
    await this.client.patch(productId.encodeURI(), { name: newName });
  }

  public async exists(name: string, productIdExceptions?: uuid[]): Promise<boolean> {
    const url = await this.getUrl(`/`);
    url.searchParams.set("name", name);
    if (productIdExceptions?.length) {
      const exceptions = productIdExceptions.join(",");
      url.searchParams.set("exceptions", exceptions);
    }
    const res = await this.client.head(url.toString(), { validateStatus: (status) => status in [200, 404] });
    return res.status === 200;
  }

  public async setRepositoryBranches(productId: uuid, repositoryId: uuid, branches: string[]): Promise<void> {
    await this.client.patch(`${productId.encodeURI()}/assets/repositories/${repositoryId.encodeURI()}`, { branches });
  }

  public async setRepositoryPaths(productId: uuid, repositoryId: uuid, paths: string[]): Promise<void> {
    await this.client.patch(`${productId.encodeURI()}/assets/repositories/${repositoryId.encodeURI()}`, { paths });
  }

  public async addOwners(productId: uuid, owners: CreateOwnersParameters): Promise<void> {
    await this.client.post(`${productId.encodeURI()}/owners`, owners);
  }

  public async addSecurityChampion(productId: uuid, identityId: uuid): Promise<void> {
    const data: CreateOwnersParameters = { securityChampions: [{ identityId }] };
    await this.client.post(`${productId.encodeURI()}/owners`, data);
  }

  public async addProductOwner(productId: uuid, identityId: uuid, comment?: string): Promise<void> {
    const data: CreateOwnersParameters = { productOwners: [{ identityId, comment }] };
    await this.client.post(`${productId.encodeURI()}/owners`, data);
  }

  public async deleteSecurityChampion(productId: uuid, identityId: uuid): Promise<void> {
    await this.client.delete(`${productId.encodeURI()}/owners/securityChampions/${identityId.encodeURI()}`);
  }

  public async deleteProductOwner(productId: uuid, identityId: uuid): Promise<void> {
    await this.client.delete(`${productId.encodeURI()}/owners/productOwners/${identityId.encodeURI()}`);
  }

  public async updateProductOwnerComment(productId: uuid, identityId: uuid, comment: string): Promise<void> {
    await this.client.patch(`${productId.encodeURI()}/owners/productOwners/${identityId.encodeURI()}`, { comment });
  }
}

export const Products = new ProductsApi();
