import { Flags } from "./flags-state";
import { Integrations } from "./integrations-state";
import { Popup } from "./popup-state";
import Vue from "vue";
import { IssuesIntegrationType } from "$/dynamo";
import { IntegrationAttributes } from "$/dynamo/integration/integration-attributes";
import { GetIntegrationResponse } from "$/interfaces/ui-api/response/get-integration-response";
import { FieldDescriptor, IssueType, Project, ProjectUser, Status } from "$/issues";
import { Cache } from "$/utility-types/cache";
import { Issues as IssuesApi } from "@/api/issues-api";

const CACHE_TTL_SECONDS = 60;

class IssuesState {
  private _enabled = false;
  private readonly projectsCache = new Cache<`project_${string}_${IssuesIntegrationType}`, Project[]>({ ttlSeconds: CACHE_TTL_SECONDS });
  private readonly issueTypesCache = new Cache<`issueType_${string}_${IssuesIntegrationType}_${string}`, IssueType[]>({ ttlSeconds: CACHE_TTL_SECONDS });
  private readonly statusCache = new Cache<`status_${string}_${IssuesIntegrationType}_${string}_${string}`, Status[]>({ ttlSeconds: 60 });
  private readonly projectUsersCache = new Cache<`user_${string}_${IssuesIntegrationType}_${string}_${string}`, ProjectUser[]>({ ttlSeconds: CACHE_TTL_SECONDS });
  private readonly fieldsCache = new Cache<`field_${string}_${IssuesIntegrationType}_${string}_${string}`, FieldDescriptor[]>({ ttlSeconds: CACHE_TTL_SECONDS });

  public constructor() {
    Vue.observable(this);
  }

  public get enabled() {
    return this._enabled;
  }

  public get integrations(): GetIntegrationResponse<IssuesIntegrationType>[] {
    void this.init();
    return Integrations.integrations?.filter((i): i is GetIntegrationResponse<IssuesIntegrationType> => IntegrationAttributes[i.integrationType].category === "issues") || [];
  }

  public async listProjects(integrationType: IssuesIntegrationType, orgId: string): Promise<Project[]> {
    const key = `project_${orgId}_${integrationType}` as const;
    const items = await this.projectsCache.getOrResolve(key, undefined, async () => {
      try {
        return await IssuesApi[integrationType].listProjects(orgId);
      } catch (e) {
        void Popup.autoHandleError(e);
        return [];
      }
    });
    return items;
  }

  public async listIssueTypes(integrationType: IssuesIntegrationType, orgId: string, projectId: string): Promise<IssueType[]> {
    const key = `issueType_${orgId}_${integrationType}_${projectId}` as const;
    const items = await this.issueTypesCache.getOrResolve(key, undefined, async () => {
      try {
        return await IssuesApi[integrationType].listIssueTypes(orgId, projectId);
      } catch (e) {
        console.warn(e);
        // void Popup.autoHandleError(e);
        return [];
      }
    });
    return items;
  }

  public async listStatuses(integrationType: IssuesIntegrationType, orgId: string, projectId: string, issueTypeId: string): Promise<Status[]> {
    const key = `status_${orgId}_${integrationType}_${projectId}_${issueTypeId}` as const;
    const items = await this.statusCache.getOrResolve(key, undefined, async () => {
      try {
        return await IssuesApi[integrationType].listStatuses(orgId, projectId, issueTypeId);
      } catch (e) {
        console.warn(e);
        // void Popup.autoHandleError(e);
        return [];
      }
    });
    return items;
  }

  public async listProjectAssignees(integrationType: IssuesIntegrationType, orgId: string, projectId: string, search?: string): Promise<ProjectUser[]> {
    const key = `user_${orgId}_${integrationType}_${projectId}_${search || ""}` as const;
    const items = await this.projectUsersCache.getOrResolve(key, undefined, async () => {
      try {
        return await IssuesApi[integrationType].listProjectAssignees(orgId, projectId, search);
      } catch (e) {
        void Popup.autoHandleError(e);
        return [];
      }
    });
    return items;
  }

  public async listFieldDescriptors(integrationType: IssuesIntegrationType, orgId: string, projectId: string, issueType: string): Promise<FieldDescriptor[]> {
    const key = `field_${orgId}_${integrationType}_${projectId}_${issueType}` as const;
    const items = await this.fieldsCache.getOrResolve(key, undefined, async () => {
      try {
        return await IssuesApi[integrationType].listFieldDescriptors(orgId, projectId, issueType);
      } catch (e) {
        console.warn(e);
        // void Popup.autoHandleError(e);
        return [];
      }
    });
    return items;
  }

  public async tryGetUser(integrationType: IssuesIntegrationType, orgId: string, userId: string): Promise<ProjectUser | null> {
    const key = `user_${orgId}_${integrationType}_[USER]_${userId}` as const;
    const items = await this.projectUsersCache.getOrResolve(key, undefined, async () => {
      try {
        return [await IssuesApi[integrationType].getUser(orgId, userId)].nonNullable();
      } catch (e) {
        console.warn(e);
        return [];
      }
    });
    return items.at(0) || null;
  }

  private async init(): Promise<void> {
    this._enabled = (await Flags.tryGet("integrations"))?.issues?.front ?? false;
    this.init = () => Promise.resolved;
  }
}

export const Issues = new IssuesState();
