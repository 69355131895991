import { PolicyActionInstantMessage, PolicyActionResetBranch } from "../policy-actions";
import { PolicyConditionBoolean, PolicyConditionFindingSecretValidation, PolicyConditionFindingSeverity } from "../policy-conditions";
import { PolicyItemType } from "../policy-item-base";
import { PolicyTriggerDetectedOnPush, PolicyTriggerDetectedOnScan } from "../policy-triggers";
import { PolicyItem, PolicySubType } from "./policy-item";
import { PolicyRule } from "../policy-rule";

declare module "./policy-item" {
  enum PolicySubType {
    secrets = "secrets"
  }

  interface PolicySubTypeMapper {
    [PolicySubType.secrets]: PolicyItemSecrets;
  }
}

export interface CustomSecretsDetection {
  enabled: boolean;
  severity: PolicyConditionFindingSeverity["name"];
  name: string;
  regex: string;
}

export interface CustomSecretsIgnorePaths {
  enabled: boolean;
  name: string;
  regex: string;
}

interface PolicyItemSecretsConfig {
  /**
   * Custom secrets configuration
   */
  customSecrets: {
    /**
     * List with custom secrets regex detection
     * @note If empty, custom secrets will not be detected
     */
    detection: CustomSecretsDetection[];

    validation?: {
      /**
       * Url for sending custom secrets for validation
       * @note If empty, custom secrets will not be sent for validation
       */
      url: string;
      /**
       * If true, raw file with secret will be send to validationUrl
       */
      raw: boolean;
      /**
       * If true, regex that matched will be sent
       */
      match: boolean;
      /**
       * If true, commit will be sent
       */
      commit: boolean;
    };

    /**
     * List of paths to ignore
     * @note Paths are relative to the repository root
     */
    ignoredPaths: CustomSecretsIgnorePaths[];
  };
}

(PolicySubType as { secrets: "secrets" }).secrets = "secrets";

export interface PolicyItemSecrets extends PolicyItem<PolicyItemSecretsConfig> {
  sub: PolicySubType.secrets;
  config: PolicyItemSecretsConfig;
}

export const PolicyItemSecrets = {
  is(item: PolicyItem): item is PolicyItemSecrets {
    return item.sub === PolicySubType.secrets;
  },
  generateDefaultConfig(existing?: RecursivePartial<PolicyItemSecretsConfig>): PolicyItemSecretsConfig {
    const existingCustomSecrets = existing?.customSecrets;
    const existingDetection = existingCustomSecrets?.detection
      ?.filter((r): r is CustomSecretsDetection => !!(r.name && r.regex && r.severity))
      ?.map(({ enabled = false, name, regex, severity }) => ({ enabled, name, regex, severity }));

    const existingIgnoredPaths = existingCustomSecrets?.ignoredPaths
      ?.filter((p): p is CustomSecretsIgnorePaths => !!(p.name && p.regex))
      ?.map(({ enabled = false, name, regex }) => ({ enabled, name, regex }));

    const existingValidation = existingCustomSecrets?.validation;

    return {
      customSecrets: {
        detection: existingDetection ?? [],
        validation:
          existingValidation && existingValidation.url
            ? {
                url: existingValidation.url,
                raw: existingValidation.raw ?? false,
                match: existingValidation.match ?? false,
                commit: existingValidation.commit ?? false
              }
            : undefined,
        ignoredPaths: existingIgnoredPaths ?? [
          {
            enabled: true,
            name: "Default: ignored files",
            regex: "[.*\\/]?(cacert\\.pem|cacerts\\.pem|development\\.conf|ca-bundle\\.crt)$"
          },
          {
            enabled: true,
            name: "Default: ignored directories",
            regex: "[.*\\/]?(examples|testdata|h2demo|test|example|site-packages)\\/.*?"
          }
        ]
      }
    };
  },
  generateDefault(arnicaOrgId: string): PolicyItemSecrets {
    return {
      arnicaOrgId,
      id: PolicySubType.secrets,
      sub: PolicySubType.secrets,
      type: PolicyItemType.policy,
      config: PolicyItemSecrets.generateDefaultConfig(),
      rules: [
        {
          ...PolicyRule.generateDefault(),
          name: "Notify code pusher on validated secrets - requires ChatOps integration (good practice 🥇)",
          trigger: PolicyTriggerDetectedOnPush.generateDefault(),
          condition: PolicyConditionFindingSecretValidation.generateDefault(),
          actions: [PolicyActionInstantMessage.generateDefaultPusherOnly()],
          enabled: false
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Pipelineless zero new validated secrets - requires ChatOps integration (best practice 🏆)",
          trigger: PolicyTriggerDetectedOnPush.generateDefault(),
          condition: PolicyConditionFindingSecretValidation.generateDefault(),
          actions: [PolicyActionInstantMessage.generateDefaultPusherOnly(), PolicyActionResetBranch.generateDefault()],
          enabled: false
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Silent pipelineless scan on push (default 💯)",
          trigger: PolicyTriggerDetectedOnPush.generateDefault(),
          condition: PolicyConditionBoolean.generateDefault(),
          enabled: true
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Silent routine scan (default 💯)",
          trigger: PolicyTriggerDetectedOnScan.generateDefault(),
          condition: PolicyConditionBoolean.generateDefault(),
          enabled: true
        },
      ]
    } satisfies PolicyItemSecrets;
  }
};
