import { ChartType } from "chart.js";
import ChartJS from "chart.js/auto";

interface HighlightPluginOptions {
  enabled: boolean;
  index: number;
  borderColor?: string;
  borderWidth?: number;
  fillColor?: string;
}

declare module "chart.js" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PluginOptionsByType<TType extends ChartType> {
    highlight?: HighlightPluginOptions;
  }
}

function drawBox(chart: ChartJS, { borderColor, borderWidth, fillColor, index }: HighlightPluginOptions) {
  const idx = typeof index === "number" ? index : -1;
  const { x, y } = chart.scales;
  if (!x || !y || idx === -1) {
    return;
  }
  const width = x.width / (x.max + 1);
  const left = width * index + x.left;
  const { height, top } = y;
  const tick = x.getPixelForTick(idx);
  const ctx = chart.ctx;
  ctx.beginPath();
  if (borderColor) {
    ctx.strokeStyle = borderColor;
    ctx.lineWidth = borderWidth ?? 2;
    ctx.rect(left, top, width, height);
    ctx.stroke();
  }
  if (fillColor) {
    ctx.fillStyle = fillColor;
    ctx.fillRect(left, top, width, height);
  }
}

function drawLine(chart: ChartJS, { borderColor, borderWidth, fillColor, index }: HighlightPluginOptions) {
  const idx = typeof index === "number" ? index : -1;
  const color = borderColor ?? fillColor;
  const { x, y } = chart.scales;
  if (!x || !y || !color || idx === -1) {
    return;
  }

  const left = x.getPixelForValue(idx, idx);
  const ctx = chart.ctx;
  ctx.beginPath();
  ctx.lineWidth = borderWidth ?? 2;
  ctx.strokeStyle = color;
  ctx.moveTo(left, y.bottom);
  ctx.lineTo(left, y.top);
  ctx.stroke();
}

ChartJS.register({
  id: "highlight",
  beforeDraw(chart, _args, options: HighlightPluginOptions) {
    if (!options?.enabled) {
      return;
    }
    const { borderColor, fillColor } = options;
    if (!borderColor && !fillColor) {
      return;
    }

    if (chart.getSortedVisibleDatasetMetas().some((meta) => meta.type === "bar")) {
      drawBox(chart, options);
    } else {
      drawLine(chart, options);
    }
  }
});
