import { ApiBase } from "./api-base";
import Axios, { AxiosError } from "axios";
import { IdentityOverrideItemResponseRequest } from "$/interfaces/identity-override-item-response-request";

class AdminApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "", name: "AdminApi" });
  }

  protected override onResponseError(error: unknown): Promise<unknown> {
    if ((error as AxiosError)?.config?.url === "/admin/tenantOverride") {
      throw error;
    }
    return super.onResponseError(error);
  }

  public async ping() {
    const res = await this.client.get<{ status: string; version: string }>("/");
    return res.data;
  }

  public async getTenantOverride(): Promise<string | null> {
    try {
      const res = await this.client.get<string>(`/admin/tenantOverride`, { silent: true });
      return res.data || null;
    } catch (e) {
      if (Axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async setTenantOverride(arnicaOrgId: string | null): Promise<string | null> {
    try {
      const res = await this.client.post<string | null>(`/admin/tenantOverride`, { arnicaOrgId });
      return res.data || null;
    } catch (e) {
      if (Axios.isAxiosError(e) && e.response?.status === 404) {
        return null;
      }
      throw e;
    }
  }

  public async getIdentityOverrides(): Promise<IdentityOverrideItemResponseRequest[]> {
    const res = await this.client.get<IdentityOverrideItemResponseRequest[]>("/identities/override");
    return res.data;
  }

  public async setIdentityOverrideItem(params: IdentityOverrideItemResponseRequest): Promise<void> {
    await this.client.put<void>("/identities/override", params);
  }
}

export const Admin = new AdminApi();
