import { ApiBase } from "@/api/api-base";
import { ScmIntegrationType } from "$/dynamo";
import { OrganizationRepoData } from "$/interfaces/assets/organization-repo-data";
import { OrgUserIdentity } from "$/interfaces/assets/org-user-identity";
import { BillableUsers } from "$/interfaces/assets/billable-users";

class AssetsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "assets", name: "AssetsApi" });
  }

  public async getBillableUsersCount(): Promise<BillableUsers> {
    const response = await this.client.get(`users-billable`);
    return response.data;
  }

  public async getUsers(integrationType: ScmIntegrationType): Promise<OrgUserIdentity[]> {
    const response = await this.client.get(`users/${integrationType}`);
    return response.data;
  }

  public async getResources(integrationType: ScmIntegrationType): Promise<OrganizationRepoData[]> {
    const response = await this.client.get(`resources/${integrationType}`);
    return response.data;
  }
}
export const Assets = new AssetsApi();
