import { stringifyError } from "../utils";

declare global {
  interface ErrorConstructor {
    /**
     * Stringifies an error
     * @param error A possible error
     */
    stringify(error?: unknown): string;
    message(error?: unknown): string | undefined;

    /**
     * Creates a new error with the given error as the cause
     * @param cause
     */
    withCause(cause: unknown): Error;

    /**
     * Tries to get the error ID if exists
     * @param error A possible error
     * @returns The error ID if or `undefined` if not found
     */
    tryGetId(error: unknown): string | undefined;

    /**
     * Tries to get the error code if exists
     * @param error A possible error
     * @returns The error code if or `undefined` if not found
     */
    tryGetCode(error: unknown): string | undefined;
  }
}

function errorObjBuilder(error?: Error | { message?: string; stack?: string; name?: string }): { message?: string; stack?: string; name?: string } {
  if (!error) {
    return {};
  }
  const obj: { message?: string; stack?: string; name?: string } = {};
  "message" in error && (obj.message = error.message);
  "stack" in error && (obj.stack = error.stack);
  "name" in error && (obj.name = error.name);
  return obj;
}

Error.stringify ||= function stringifyError(e?: unknown) {
  try {
    if (!e) {
      return "undefined error";
    }

    const current = new Error();
    const initiator = errorObjBuilder(e) as ReturnType<typeof errorObjBuilder> & { cause?: ReturnType<typeof errorObjBuilder> };
    const cause = errorObjBuilder((e instanceof Error && e.cause) || undefined);

    initiator.stack = `Current: ${current.stack ?? "[N/A]"}\nInitiator: ${initiator.stack ?? "[N/A]"}\nCause: ${cause.stack ?? "[N/A]"}`;

    Object.assign(initiator, e);
    initiator.cause = cause;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { stack, ...other } = initiator;
    return `[EXCEPTION][${initiator.message}]${initiator.stack}\n${JSON.stringify(other, null, 2)}`;
  } catch {
    return String(e);
  }
};

Error.message ||= function (error?: unknown): string | undefined {
  return ((error as any)?.message as string) ?? undefined;
};

Error.withCause ||= function (cause: unknown): Error {
  return new Error(Error.message(cause), { cause });
};

Error.tryGetId ||= function (error: unknown): string | undefined {
  return error && typeof error === "object" && "id" in error && typeof error.id === "string" ? error.id : undefined;
};

Error.tryGetCode ||= function (error: unknown): string | undefined {
  return error && typeof error === "object" && "code" in error && typeof error.code === "string" ? error.code : undefined;
};
