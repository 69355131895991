<template>
  <component :is="component" to="/" :href="href">
    <template v-if="background === 'dark'">
      <img v-show="mini" src="/img/ArnicaA-DarkMode-NoBG.png" alt="Arnica logo" style="width: 40px" />
      <img v-show="!mini" class="logo" src="/img/ArnicaFull-DarkMode-NoBG.png" alt="Arnica Logo" />
    </template>
    <template v-else>
      <img v-show="mini" src="/img/ArnicaA-OnWhite-NoBG.png" alt="Arnica logo" style="width: 40px" />
      <img v-show="!mini" class="logo" src="/img/ArnicaFull-OnWhite-NoBG.png" alt="Arnica Logo" />
    </template>
  </component>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export class ArnicaLogo extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  protected mini!: boolean;

  @Prop({
    type: String,
    required: true
  })
  protected background!: "light" | "dark";

  private get registered(): boolean {
    return !!this.$state.Auth.scopes["registered"];
  }

  protected get component(): string {
    return this.registered ? "router-link" : "a";
  }

  protected get href(): string {
    return this.registered ? "#/" : "https://www.arnica.io";
  }
}

export default ArnicaLogo;
</script>

<style lang="scss">
img.logo {
  height: 40px;
}
</style>
