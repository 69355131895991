
<div class="component tooltip" :style="`top: ${top}px; left: ${left}px;`">
  <v-scale-transition>
    <div v-show="!!message" class="tooltip-content">
      <template v-if="message">
        <div v-if="html" v-html-sanitize="message"></div>
        <span v-else v-for="(line, idx) of message" :key="idx" :style="`text-align: ${align}`">{{ line }}</span>
      </template>
    </div>
  </v-scale-transition>
</div>
