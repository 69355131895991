export enum JobStatus {
  /**
   * The job has not been enqueued yet.
   */
  pending = "pending",

  /**
   * The job has been enqueued for execution.
   */
  enqueued = "enqueued",

  /**
   * The job is currently in progress.
   */
  in_progress = "in_progress",

  /**
   * The job has completed successfully.
   */
  completed = "completed",

  /**
   * The job has settled with error(s).
   */
  failed = "failed",

  /**
   * The job has been cancelled.
   */
  cancelled = "cancelled"
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace JobStatus {
  export const ERRORED: Set<JobStatus> = new Set([JobStatus.failed, JobStatus.cancelled]);
  export const SETTLED: Set<JobStatus> = new Set([JobStatus.completed, ...ERRORED]);
  export const UNSETTLED: Set<JobStatus> = new Set([JobStatus.pending, JobStatus.enqueued, JobStatus.in_progress]);
  export const SUCCESSFUL: Set<JobStatus> = new Set([JobStatus.completed]);
  export const STARTABLE: Set<JobStatus> = new Set([...SETTLED, JobStatus.pending]);

  export const ui: Readonly<Record<JobStatus, { displayName: string; icon: string; color: string }>> = {
    [JobStatus.pending]: { displayName: "Pending", icon: "mdi-progress-clock", color: "orange" },
    [JobStatus.enqueued]: { displayName: "Enqueued", icon: "mdi-progress-download", color: "orange" },
    [JobStatus.in_progress]: { displayName: "In Progress", icon: "mdi-run", color: "blue" },
    [JobStatus.completed]: { displayName: "Completed", icon: "mdi-check-circle-outline", color: "green" },
    [JobStatus.failed]: { displayName: "Failed", icon: "mdi-close-circle-outline", color: "red" },
    [JobStatus.cancelled]: { displayName: "Cancelled", icon: "mdi-minus-circle-outline", color: "grey" }
  };

  export function isSettled(state: JobStatus): boolean {
    return SETTLED.has(state);
  }

  export function isStartable(state: JobStatus): boolean {
    return STARTABLE.has(state);
  }

  export function isErrored(state: JobStatus): boolean {
    return ERRORED.has(state);
  }

  export function is(value: unknown): value is JobStatus {
    return typeof value === "string" && JobStatus[value as keyof typeof JobStatus] === value;
  }

  Object.hideProps(JobStatus, (k, v) => typeof v !== "string");
}
