declare global {
  interface String {
    /**
     * Extracts the content of an HTML string
     */
    extractHtmlContent(): string;
  }
}

/**
 * Extracts the content of an HTML string
 */
export function extractHtmlContent(html: string) {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
}

String.prototype.extractHtmlContent ??= function (this: string) {
  return extractHtmlContent(this);
};
