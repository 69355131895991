import Vue from "vue";
import type Router from "vue-router";
import { Layout } from "@/state";

export function install(router: Router): Router {
  const off = router.afterEach(async () => {
    Layout.appLoaded = true;
    await Vue.nextTick(); // off mutates the hook list, so we need to wait for the next tick
    off();
  });
  return router;
}
