import { Deferred } from "$/utility-types";
import { ConfigFlags } from "@arnica-internal/configs";
import _ from "lodash";
import Vue from "vue";
import "$/utility-types/keyed-object";

const DEBOUNCE_TIME = 300;

class FlagsState {
  private readonly cache = new Map<keyof ConfigFlags, Deferred<ConfigFlags[keyof ConfigFlags]>>();

  public constructor() {
    this.resolveFlags = _.debounce(this.resolveFlags.bind(this), DEBOUNCE_TIME, { maxWait: 1000 }) as FlagsState["resolveFlags"];
    Vue.observable(this);
  }

  private async resolveFlags(): Promise<void> {
    const keys = Array.from(this.cache)
      .filter(([, deferred]) => !deferred.settled)
      .map(([key]) => key);

    const flags = await Vue.api.Flags.getFlags(...keys);
    for (const [key, value] of Object.entries(flags)) {
      this.cache.get(key as keyof ConfigFlags)!.resolve(value);
    }
  }

  public async tryGet<T extends keyof ConfigFlags>(flag: T): Promise<ConfigFlags[T] | null> {
    const value = await this.get(flag);
    return value[flag];
  }

  public async get<T extends readonly (keyof ConfigFlags)[]>(...flags: T): Promise<NullableKeyedObject<T, ConfigFlags>> {
    const misses = flags.filter((f) => !this.cache.has(f));
    if (misses.length) {
      for (const miss of misses) {
        this.cache.set(miss, new Deferred());
      }
      void this.resolveFlags();
    }

    const results = await flags.mapAsync(async (f) => [f, await this.cache.get(f)!.promise] as const);
    return Object.fromEntries(results) as NullableKeyedObject<T, ConfigFlags>;
  }
}

export const Flags = new FlagsState();
