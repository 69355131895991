import { Tier } from "../state";
import { GetUserInfoResponse } from "$/interfaces/ui-api/response/get-user-info-response";
import { User } from "@/models/user";

interface AnalyticsUserInfo extends Record<string, string | boolean | null> {
  email: string;
  "Tenant ID": string | null;
  "Tenant Name": string | null;
  isArnicaOrgOwner: boolean;
  //tier: string | null,
}

export interface AnalyticsLogEventOptions {
  heap: boolean;
  fs: boolean;
}

class AnalyticsServiceClass {
  public logEvent(event: string, data?: Record<string, unknown>, group?: Record<string, unknown>, options?: AnalyticsLogEventOptions) {
    options ??= {
      heap: true,
      fs: true
    };

    const { heap, fs } = options;

    const properties = { ...data, ...group };

    if (heap) {
      window.heap.track(event, properties);
    }

    if (fs) {
      window.FS.event(event, properties);
    }
  }

  // public updateUserProperties(updatedProps: Record<string, string | boolean | number | null | undefined>) {
  public updateUserProperties(info: GetUserInfoResponse) {
    const email = info.email.toLocaleLowerCase();
    const updatedProps: AnalyticsUserInfo = {
      email,
      "Tenant ID": info.orgId,
      "Tenant Name": info.orgName,
      isArnicaOrgOwner: info.isOrgOwner ?? false,
      "Tenant Tier": Tier.name
    };

    window.heap.addUserProperties(updatedProps);
    window.FS.setUserVars(updatedProps);
  }

  public init(user: User) {
    const email = user.email?.toLocaleLowerCase();

    if (email) {
      window.FS.identify(email, {
        displayName: email,
        email
      });

      window.heap.identify(email);
    }
  }
}

export const AnalyticsService = new AnalyticsServiceClass();
