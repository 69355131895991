import Vue from "vue";
import { SCMType } from "$/scm-type";
import { IdentityOverride } from "@/state/identity-override-state";
import { Assets } from "@/state/assets-state";

export const ProfileNames = new (class ProfileNamesState {
  public isInitializedAtLeastOnce = false;
  private githubIdentityToNameMap: Map<string, string> | null = null;

  public constructor() {
    Vue.observable(this);
  }

  /**
   * Populate the Overrides Map with persisted data.
   */
  public async init() {
    if (!IdentityOverride.isInitializedAtLeastOnce) {
      await IdentityOverride.init();
    }
    this.githubIdentityToNameMap = await this.getIdentityToNameMap(SCMType.GITHUB);
    this.isInitializedAtLeastOnce = true;
  }

  public getGithubIdentityDisplayName(githubLogin: string): string {
    if (!this.githubIdentityToNameMap) {
      return githubLogin;
    }
    const displayName = this.githubIdentityToNameMap.get(githubLogin);
    return displayName ?? githubLogin;
  }

  //TODO: move this to a Sate object / service

  public async getIdentityToNameMap(scmType: SCMType): Promise<Map<string, string>> {
    if (scmType === SCMType.AZURE_DEVOPS) {
      throw new Error("not implemented");
    } else if (scmType === SCMType.GITHUB) {
      //only loads if not loaded yet
      if (!Assets.users.github) {
        await Assets.loadUsers("github");
      }
      const users = Assets.users.github;

      //TODO: ensure we don't call it in places where it's already called...
      if (!IdentityOverride.isInitializedAtLeastOnce) {
        await IdentityOverride.init();
      }
      const result = new Map<string, string>();

      for (const identityItem of users ?? []) {
        const override = IdentityOverride.get(scmType, identityItem.username);
        if (override && (override.name || override.namesToRemove.length)) {
          if (override.name) {
            result.set(identityItem.username, override.name);
          } else {
            const name = identityItem.names?.find((n) => !override.namesToRemove.includes(n));
            result.set(identityItem.username, name ?? identityItem.username);
          }
        } else if (identityItem.names && identityItem.names.length > 0) {
          result.set(identityItem.username, identityItem.names[0]!);
        } else {
          result.set(identityItem.username, identityItem.username);
        }
      }
      return result;
    }
    throw new Error(`Invalid SCM Type: ${scmType}`);
  }
})();
