
<v-container fluid class="component select-dialog-content">
  <v-text-field v-if="longList" clearable dense v-model.trim="search" label="Search" autofocus></v-text-field>

  <v-list dense :style="`min-height: ${minHeight}; width: ${initialWidth}`" ref="list">
    <v-list-item-group v-model="selectedItem" color="success">
      <template v-if="options.length">
        <v-list-item dense v-for="(option, idx) of pagedOptions" :key="idx" @click.stop="onClick(option)">
          <v-list-item-icon v-if="option.icon" class="side-icon">
            <v-icon>{{ option.icon }}</v-icon>
          </v-list-item-icon>
          <v-avatar size="24" v-if="option.avatar" class="avatar">
            <img :src="option.avatar" alt="" />
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ option.title }}</v-list-item-title>
            <v-list-item-subtitle v-if="option.description">{{ option.description }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-icon v-if="option.iconRight" class="right-icon">
            <v-icon>{{ option.iconRight }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item dense disabled>
          <v-list-item-content>
            <v-list-item-title>No options available</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list-item-group>
  </v-list>

  <div v-if="longList" class="float-end">
    <small> {{ start + 1 }}-{{ end }} of {{ total }} </small>
    <v-btn icon @click.stop.prevent="prevPage" title="Previous" aria-label="Previous page" :disabled="page <= 0">
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>
    <v-btn icon @click.stop.prevent="nextPage" title="Next" aria-label="Next page" :disabled="page >= pages - 1">
      <v-icon>mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</v-container>
