import { ApiBase } from "./api-base";
import { Role } from "$/dynamo";
import { GetUserInfoResponse } from "$/interfaces/ui-api/response/get-user-info-response";
import { UIPreferences } from "$/interfaces/ui-api/response/ui-preferences";
import { RoleInfo } from "@/components/layout/user-menu/role-converter";
import { Scope } from "@/interfaces/scope";
import { AnalyticsService } from "@/services/analytics-service";
import { Auth, Popup } from "@/state";

class UsersApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "users", name: "UsersApi" });
  }

  //TODO: better naming, in the backend it's called User, here it's called UserInfo, and we have another wrapper object called User, it's confusing
  public async me(addOrg = false, autoGenerateOrg = false, silent = false): Promise<GetUserInfoResponse<Scope>> {
    const res = await this.client.get<GetUserInfoResponse<Scope>>("/me", { params: { addOrg, autoGenerateOrg }, silent });
    const info = res.data;
    // make it easier for Vue to observe
    info.orgId ??= null;
    info.requiresActivation ??= false;
    AnalyticsService.updateUserProperties(info);
    return info;
  }

  public async all(): Promise<GetUserInfoResponse[]> {
    try {
      const res = await this.client.get<GetUserInfoResponse[]>("");
      return res.data;
    } catch (e) {
      debugger;
      void Popup.error(String(e));
      throw e;
    }
  }

  public async invite(emails: string[]): Promise<void> {
    await this.client.put<void>("", emails);
  }

  public async remove(email: string): Promise<void> {
    try {
      if (!Auth.scopes.registered) {
        return;
      }
      await this.client.delete<void>(`/${encodeURIComponent(email)}`);
    } catch (e) {
      void Popup.error(String(e));
      throw e;
    }
  }

  public async updatePreferences(preferences: UIPreferences, silent = false): Promise<void> {
    try {
      if (!Auth.scopes.registered) {
        return;
      }
      await this.client.put<void>("/me/preferences", preferences, { silent });
    } catch (e) {
      void Popup.error(String(e));
      throw e;
    }
  }

  public async updateRole(email: string, role: Role, silent = false): Promise<void> {
    try {
      await this.client.put<void>(`/role`, { email, role }, { silent });
      void Popup.info(` ${email} is now ${RoleInfo.from(role).label}`);
    } catch (e) {
      void Popup.autoHandleError(e);
      throw e;
    }
  }
}

export const Users = new UsersApi();
