import Vue from "vue";

interface IDep {
  notify(options: { type: string; target: unknown; key: string }): void;
}

interface IObserver {
  // eslint-disable-next-line @typescript-eslint/no-misused-new
  new (value: unknown, shallow?: boolean, mock?: boolean): IObserver;
  observeArray(value: unknown): void;
  dep: IDep;
}

export const Observer = Object.getPrototypeOf((Vue.observable({}) as { __ob__: unknown })["__ob__"]).constructor as IObserver;
