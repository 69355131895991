<template>
  <div class="component inline-checkbox">
    <input v-bind="$attrs" type="checkbox" :class="classes" v-bind:checked="value" v-on:change="$emit('change', $event.target.checked)" />
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export class InlineCheckbox extends Vue {
  @Model("change")
  protected value!: boolean;

  @Prop({ type: Boolean, default: false })
  protected outlined!: boolean;

  protected get classes(): string {
    return Object.entries({
      outlined: this.outlined
    })
      .filter(([, v]) => v)
      .map(([k]) => k)
      .join(" ");
  }
}

export default InlineCheckbox;
</script>

<style lang="scss">
.component.inline-checkbox {
  display: inline-block;

  input {
    &[disabled] {
      background: whitesmoke;
    }

    &.outlined {
      border: 1px solid lightgray;
      border-radius: 4px;
      margin-left: 0.3em;
      margin-right: 0.3em;
    }
  }
}
</style>
