import { TrivyCycloneDx } from "../../../../../sbom/interfaces/sbom-report-item";
import { PolicyItemType } from "../../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_manager = "package_manager"
  }
}

(PolicyConditionSubType as { package_manager: "package_manager" }).package_manager = "package_manager";

export interface PolicyConditionFindingPackageManager extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_manager;
  /**
   * One or more package managers
   */
  managers: TrivyCycloneDx.PackageType[];
}

export const PolicyConditionFindingPackageManager = {
  TYPES: TrivyCycloneDx.PackageType,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_manager
  }
} as const;
