import { GitHubUserPermission } from "./github-user-permission";

export const GITHUB_PERMISSION_RANK: Record<GitHubUserPermission, number> = {
  pull: 1,
  triage: 2,
  push: 3,
  maintain: 4,
  admin: 5,
  orgOwner: 6
};
