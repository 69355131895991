import { GithubMitigationAction, MitigationStatus } from "../../dynamo";
import { PathToCodeOwners } from "../../github/interfaces/code-owners-info";
import { IdentityAndDisplayName } from "../../interfaces/ui-api/identity-and-display-name";
import { SCMType } from "../../scm-type";

export interface RiskReportIdentityItem {
  identities: string[];
  group: string | null;
  asset: Asset;
  profileNames?: IdentityAndDisplayName[];
  mitigationStatus?: MitigationStatus;
}

export interface ADORiskReportIdentityItem extends RiskReportIdentityItem {
  groupDescriptor: string | null;
  asset: ADOAsset;
  repositoryID?: string;
  mitigateAsEnableReviewers?: boolean;
  isCrossRepoPolicy?: boolean;
  reverted?: boolean;
}

export interface GitHubExcessivePermissions extends RiskReportIdentityItem {
  baseURL: string;
  teamSlug: string | null;
  teamGithubId: string | null;
  asset: GHAsset;
  pathToCodeOwners?: PathToCodeOwners;
  lineNumber?: number;
  mitigationAction?: GithubMitigationAction;
  isAdmin?: boolean;
}

export interface GitHubRiskReportIdentityItem extends RiskReportIdentityItem {
  baseUrl: string;
  asset: GHAsset;
  teamSlug: string | null;
  pathToCodeOwners?: PathToCodeOwners;
  lineNumber?: number;
  profileNames?: IdentityAndDisplayName[];
  reverted?: boolean;
  isAdmin?: boolean;
  prNumber?: number;
}

export interface GitHubRiskReportAppItem {
  appFullName: string;
  htmlUrl: string;
  excessivePermissionNames: string[];
  asset: Asset;
  baseUrl: string;
  //to solve a compile error, app items should not have a profile name
  profileNames?: never;
}

//TODO: rename to ADOPermissionReportQueryType
export const ADORiskReportQueryType = {
  ADOADMIN: "ADOADMIN",
  ADOCODECONTRIBUTORS: "ADOCODECONTRIBUTORS",
  ADOPRAPPROVERS: "ADOPRAPPROVERS"
} as const;

export type ADORiskReportQueryType = (typeof ADORiskReportQueryType)[keyof typeof ADORiskReportQueryType];

export namespace ADORiskReportQueryType {
  export type ADOADMIN = typeof ADORiskReportQueryType.ADOADMIN;
  export type ADOCODECONTRIBUTORS = typeof ADORiskReportQueryType.ADOCODECONTRIBUTORS;
  export type ADOPRAPPROVERS = typeof ADORiskReportQueryType.ADOPRAPPROVERS;
}

//TODO: rename to GitHubPermissionReportQueryType
export const GitHubRiskReportQueryType = {
  GHWRITE: "GHWRITE",
  GHMAINTAIN: "GHMAINTAIN",
  GHREPOADMIN: "GHREPOADMIN",
  GHORGOWNER: "GHORGOWNER",
  GHCODEOWNERS: "GHCODEOWNERS",
  GHAPP: "GHAPP"
} as const;

export type GitHubRiskReportQueryType = (typeof GitHubRiskReportQueryType)[keyof typeof GitHubRiskReportQueryType];

export namespace GitHubRiskReportQueryType {
  export type GHWRITE = typeof GitHubRiskReportQueryType.GHWRITE;
  export type GHMAINTAIN = typeof GitHubRiskReportQueryType.GHMAINTAIN;
  export type GHREPOADMIN = typeof GitHubRiskReportQueryType.GHREPOADMIN;
  export type GHORGOWNER = typeof GitHubRiskReportQueryType.GHORGOWNER;
  export type GHCODEOWNERS = typeof GitHubRiskReportQueryType.GHCODEOWNERS;
  export type GHAPP = typeof GitHubRiskReportQueryType.GHAPP;
}

export const GitHubRiskReportQueryTypeValidator = {
  transform(value: unknown): GitHubRiskReportQueryType {
    if (typeof value === "string" && value in GitHubRiskReportQueryType) {
      return value as GitHubRiskReportQueryType;
    }
    throw new Error(`Invalid GitHubRiskReportQueryType: ${value}`);
  }
};

//TODO: reorganize this / rename, it's messy
export enum ADOHardeningReportQueryType {
  ADOInactiveUsers = "ADOInactiveUsers",
  ADOStaleRepositories = "ADOStaleRepositories",
  ADOUnenforceablePolicy = "ADOUnenforceablePolicy",
  ADOAccessibleWebhooks = "ADOAccessibleWebhooks"
}

export const GitHubHardeningReportQueryType = {
  // Hardening
  GHInactiveUsers: "GHInactiveUsers",
  GHStaleRepositories: "GHStaleRepositories",
  GHMisconfiguredCodeowners: "GHMisconfiguredCodeowners",
  GHUnenforceablePolicy: "GHUnenforcedArnicaStatusCheck",
  GHAccessibleWebhooks: "GHAccessibleWebhooks"
};

export type GitHubHardeningReportQueryType = (typeof GitHubHardeningReportQueryType)[keyof typeof GitHubHardeningReportQueryType];

export type RiskReportType = keyof typeof ADORiskReportQueryType | keyof typeof GitHubRiskReportQueryType;

//TODO: fix this interface-mania :)
export interface RiskReport<T extends RiskReportType, U = RiskReportIdentityItem[] | GitHubRiskReportAppItem[]> {
  reportItems: U;
  summary: RiskReportSummary<T>;
}

export interface RiskReportSummary<T extends RiskReportType> {
  arnicaOrgId: string;
  integrationOrgIdAndType: string;
  projectName?: string;
  queryType: T;
  scmType: SCMType;
  dateGenerated: string;
  totalNumberOfPermissions: number;
  excessiveNumberOfPermissions: number;
}
export interface ADORiskReport<T extends Iterable<ADORiskReportIdentityItem> = ADORiskReportIdentityItem[]> extends RiskReport<ADORiskReportQueryType, T> {
  reportItems: T;
  summary: RiskReportSummary<ADORiskReportQueryType>;
}

export type GitHubRiskReport = RiskReport<GitHubRiskReportQueryType, GitHubRiskReportIdentityItem[] | GitHubRiskReportAppItem[]>;

export type GitHubPermissionRiskReport = RiskReport<GitHubRiskReportQueryType, GitHubRiskReportIdentityItem[]>;

export type GitHubAppRiskReport = RiskReport<GitHubRiskReportQueryType, GitHubRiskReportAppItem[]>;

export interface Asset {
  org: string;
  repo?: string;
  branch?: string;
}

export interface ADOAsset extends Asset {
  project?: string;
}

export type GHAsset = Asset;
