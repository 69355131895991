import { Auth } from "./auth-state";
import { Preferences } from "./preferences-state";
import Vue from "vue";
import { IntegrationOrgIdAndType, IntegrationSortKeyFields, IntegrationType, ScmIntegrationType, integrationSortKey } from "$/dynamo";
import { IntegrationAttributes } from "$/dynamo/integration/integration-attributes";
import { GetIntegrationResponse, getIntegrationIcon, getIntegrationText } from "$/interfaces/ui-api/response/get-integration-response";
import { SCMType } from "$/scm-type";
import { Integrations as Api } from "@/api";
import { SCMTypeWithMetadata, SCM_TYPES_WITH_METADATA } from "@/models/scm-type-with-metadata";
import { Layout } from "@/state/layout-state";

export const Integrations = new (class IntegrationsState {
  //TODO: time based caching
  public integrations: GetIntegrationResponse[] | null = null;
  public integrationsByOrgIdAndType: Map<string, GetIntegrationResponse> | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public async init(healthCheck = false, silent = false): Promise<void> {
    this.integrations = await Api.getAll(healthCheck, silent);
    this.integrationsByOrgIdAndType = (this.integrations ?? []).mapBy((i) => i.integrationOrgIdAndType);
    //this doesn't trigger an HTTP call, just loads it from the user state if not loaded already.

    if (!Auth.user?.registered) {
      return;
    }

    if (this.integrations && Layout.scmType) {
      const hasIntegrationMatchingLastSCMType = this.integrations.find((i) => IntegrationType.toSCMType(i.integrationType) === Layout.scmType);
      if (!hasIntegrationMatchingLastSCMType) {
        Layout.scmType = null;
      }
    }
    if (!Layout.scmType) {
      const [defaultSCMType] = this.scmTypes;
      if (defaultSCMType) {
        Layout.scmType = defaultSCMType.value;
      }
      await Preferences.save({ scmType: Layout.scmType });
    }
  }

  public get hasAtLeastOneSourceControlIntegration() {
    return !!this.integrations?.some((i) => i.category === "source-control");
  }

  public get scmTypes(): SCMTypeWithMetadata[] {
    const integrations = this.integrations;
    if (!integrations) {
      return [];
    }
    return SCM_TYPES_WITH_METADATA.filter((st) => integrations.some((i) => IntegrationType.toSCMType(i.integrationType) === st.value));
  }

  public getSCMTypeIcon(scmType: SCMType): string {
    return getIntegrationIcon(IntegrationAttributes.get(scmType).type);
  }

  public getSCMTypeText(scmType: SCMType) {
    return getIntegrationText(IntegrationAttributes.get(scmType).type);
  }

  public getIntegrationTypeIcon(integrationType: ScmIntegrationType) {
    return getIntegrationIcon(integrationType);
  }

  public getIntegrationTypeText(integrationType: ScmIntegrationType) {
    return getIntegrationText(integrationType);
  }

  public getOrganizationName(integration: IntegrationSortKeyFields): string;
  public getOrganizationName(integrationOrgId: string, integrationType: IntegrationType): string;
  public getOrganizationName(integrationFieldsOrOrgId: IntegrationSortKeyFields | string, integrationType?: IntegrationType): string {
    const integrationOrgId = typeof integrationFieldsOrOrgId === "string" ? integrationFieldsOrOrgId : integrationFieldsOrOrgId.integrationOrgId;
    if (typeof integrationFieldsOrOrgId !== "string") {
      integrationType = integrationFieldsOrOrgId.integrationType;
    }
    if (!integrationType) {
      throw new Error("integrationType is required");
    }
    const integrationOrgIdAndType = integrationSortKey(integrationOrgId, integrationType);
    const integration = this.integrationsByOrgIdAndType?.get(integrationOrgIdAndType);
    return integration?.displayName || integrationOrgId;
  }

  public get hasSlack(): boolean {
    return !!this.integrations?.some((i) => i.integrationType === "slack");
  }

  public get hasGitHub(): boolean {
    return !!this.integrations?.some((i) => i.integrationType === "github");
  }

  public getIntegrationsByOrganizationId(integrationOrgId: string): GetIntegrationResponse[] | undefined {
    return this.integrations?.filter((i) => i.integrationOrgId === integrationOrgId);
  }

  public getIntegrationsByIntegrationOrgIdAndType(integrationOrgIdAndType: IntegrationOrgIdAndType): GetIntegrationResponse | undefined {
    return this.integrationsByOrgIdAndType?.get(integrationOrgIdAndType);
  }
})();
