import { CallbackHandlerBase } from "./callback-handler-base";
import * as Api from "@/api";
import { router } from "@/plugins";

interface Args {
  type: string;
  action: "success" | "cancel";
  state: string | null;
}

const CALLBACK_PATH = "/callback/payment";
const TYPE_PARAM = "type";
const ACTION_PARAM = "action";
const STATE_PARAM = "state";

export class PaymentCallbackHandler extends CallbackHandlerBase {
  public static get path(): string {
    return CALLBACK_PATH;
  }

  public async handle(query: URLSearchParams): Promise<void> {
    try {
      const action = query.get(ACTION_PARAM) as Args["action"] | null;
      const actionHandler = action && this[`on_${action}`];
      if (!actionHandler) {
        throw new Error("Invalid callback action");
      }

      const type = query.get(TYPE_PARAM);
      if (!type) {
        throw new Error("Invalid callback type");
      }
      const state = query.get(STATE_PARAM);

      window.history.replaceState(null, "", window.location.origin);
      await actionHandler.call(this, { type, action, state });
    } catch (e) {
      await this.handleIntegrationError(e);
    }
  }

  protected provideUserFeedback(): void {
    // throw new Error("Method not implemented.");
  }

  private async redirect(state: string | null): Promise<void> {
    await router.push({ name: "loading" });
    if (!state) {
      window.location.reload();
      return;
    }
    try {
      const redirect = window.sessionStorage.getItem(state);
      if (!redirect) return;
      window.sessionStorage.removeItem(state);
      window.location.href = redirect;
      window.location.reload();
    } catch (error) {
      console.warn("Failed to redirect", error);
    }
  }

  private async on_success({ type, action, state }: Args): Promise<void> {
    await Api.Payment.registerCallback(type, action);
    await this.redirect(state);
  }

  private async on_cancel({ state }: Args): Promise<void> {
    await this.redirect(state);
  }
}
