import { SCMType, UNIVERSAL_SCM_TYPE } from "../../scm-type";
import { ADOExcessiveLicensesInventoryItem } from "./ado/ado-excessive-licenses-inventory-item";
import { ADOTotalIdentitiesInventoryItem } from "./ado/ado-total-identities-inventory-item";
import { ADOTotalRepositoriesInventoryItem } from "./ado/ado-total-repositories-inventory-item";
import { UniversalScmRepositoriesInventoryItem } from "./universal-scm/universal-scm-repositories-inventory-item";
import { UniversalScmUsersInventoryItem } from "./universal-scm/universal-scm-users-inventory-item";
import { GithubAggregatedRepositoriesInventoryItem } from "./github/github-aggregated-repositories-inventory-item";
import { GithubInactiveAppsInventoryItem } from "./github/github-apps-inventory-item";
import { GithubCodeownerErrorsItem } from "./github/github-codeowner-errors-item";
import { GithubInactiveIdentitiesInventoryItem } from "./github/github-inactive-identities-inventory-item";
import { GithubRepositoriesBranchProtectionStatusItem } from "./github/github-repositories-branch-protection-status-item";
import { GithubRepositoriesInventoryItem } from "./github/github-repositories-inventory-item";
import { GithubTotalIdentitiesInventoryItem } from "./github/github-total-identities-inventory-item";
import { GithubTotalOrgsInventoryItem } from "./github/github-total-orgs-inventory-item";
import { GitHubUserRepoPermissionInventoryItem } from "./github/github-user-repo-permission-inventory-item";
import { GithubWebhooksInventoryItem } from "./github/github-webhooks-inventory-item";
import { GitHubTotalWorkflowsInventoryItem } from "./github/github-total-workflows-inventory-item";
import { ADOWebhooksInventoryItem } from "./ado/ado-webhooks-inventory-item";

export interface InventoryReportItem {}

export interface InventoryReport<T extends InventoryReportItem> {
  reportItems: T[];
  summary: InventoryReportSummary<InventoryReportQueryType>;
}

export interface InventoryReportSummary<T extends InventoryReportQueryType> {
  arnicaOrgId: string;
  /**
   *  null means this is an aggregated report over all the integrations
   */
  integrationOrgIdAndType: string | null;
  queryType: T;
  dateGenerated: string;
  value: number;
}

export const GitHubInventoryReportQueryType = {
  GH_TOTAL_IDENTITIES: "GH_TOTAL_IDENTITIES",
  GH_AGGREGATED_REPOSITORIES: "GH_AGGREGATED_REPOSITORIES",
  GH_TOTAL_REPOSITORIES: "GH_TOTAL_REPOSITORIES",
  GH_TOTAL_ORGS: "GH_TOTAL_ORGS",
  GH_INACTIVE_IDENTITIES: "GH_INACTIVE_IDENTITIES",
  GH_REPOSITORIES_NO_BRANCH_PROTECTION: "GH_REPOSITORIES_NO_BRANCH_PROTECTION",
  GH_APP: "GH_APP",
  GH_CODEOWNER_ERRORS: "GH_CODEOWNER_ERRORS",
  GH_USER_REPO_PERMISSION: "GH_USER_REPO_PERMISSION",
  GH_WEBHOOKS: "GH_WEBHOOKS",
  GH_TOTAL_WORKFLOWS: "GH_TOTAL_WORKFLOWS"
} as const;

export type GitHubInventoryReportQueryType = (typeof GitHubInventoryReportQueryType)[keyof typeof GitHubInventoryReportQueryType];

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GitHubInventoryReportQueryType {
  export type GH_TOTAL_IDENTITIES = typeof GitHubInventoryReportQueryType.GH_TOTAL_IDENTITIES;
  export type GH_AGGREGATED_REPOSITORIES = typeof GitHubInventoryReportQueryType.GH_AGGREGATED_REPOSITORIES;
  export type GH_TOTAL_REPOSITORIES = typeof GitHubInventoryReportQueryType.GH_TOTAL_REPOSITORIES;
  export type GH_TOTAL_ORGS = typeof GitHubInventoryReportQueryType.GH_TOTAL_ORGS;
  export type GH_INACTIVE_IDENTITIES = typeof GitHubInventoryReportQueryType.GH_INACTIVE_IDENTITIES;
  export type GH_REPOSITORIES_NO_BRANCH_PROTECTION = typeof GitHubInventoryReportQueryType.GH_REPOSITORIES_NO_BRANCH_PROTECTION;
  export type GH_APP = typeof GitHubInventoryReportQueryType.GH_APP;
  export type GH_CODEOWNER_ERRORS = typeof GitHubInventoryReportQueryType.GH_CODEOWNER_ERRORS;
  export type GH_USER_REPO_PERMISSION = typeof GitHubInventoryReportQueryType.GH_USER_REPO_PERMISSION;
  export type GH_WEBHOOKS = typeof GitHubInventoryReportQueryType.GH_WEBHOOKS;
  export type GH_TOTAL_WORKFLOWS = typeof GitHubInventoryReportQueryType.GH_TOTAL_WORKFLOWS;
}

export interface GitHubTotalIdentitiesInventoryReport extends InventoryReport<GithubTotalIdentitiesInventoryItem> {
  reportItems: GithubTotalIdentitiesInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_TOTAL_IDENTITIES>;
}

/**
 * This report combines information from the GitHubTotalRepositoriesInventoryReport and the GitHubRepositoriesBranchProtectionInventoryReport
 * to provide a single report which contains details about the repositories and the branch protection policies present on the branches in the
 * repository
 */
export interface GitHubAggregatedRepositoriesInventoryReport extends InventoryReport<GithubAggregatedRepositoriesInventoryItem> {
  reportItems: GithubAggregatedRepositoriesInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_AGGREGATED_REPOSITORIES>;
}

export interface GitHubTotalRepositoriesInventoryReport extends InventoryReport<GithubRepositoriesInventoryItem> {
  reportItems: GithubRepositoriesInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_TOTAL_REPOSITORIES>;
}

export interface GitHubTotalOrgsInventoryReport extends InventoryReport<GithubTotalOrgsInventoryItem> {
  reportItems: GithubTotalOrgsInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_TOTAL_ORGS>;
}

export interface GitHubInactiveIdentitiesInventoryReport extends InventoryReport<GithubInactiveIdentitiesInventoryItem> {
  reportItems: GithubInactiveIdentitiesInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_INACTIVE_IDENTITIES>;
}

export interface GitHubRepositoriesBranchProtectionInventoryReport extends InventoryReport<GithubRepositoriesBranchProtectionStatusItem> {
  reportItems: GithubRepositoriesBranchProtectionStatusItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_REPOSITORIES_NO_BRANCH_PROTECTION>;
}

export interface GitHubAppInventoryReport extends InventoryReport<GithubInactiveAppsInventoryItem> {
  reportItems: GithubInactiveAppsInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_APP>;
}

export interface GitHubCodeownerErrorsInventoryReport extends InventoryReport<GithubCodeownerErrorsItem> {
  reportItems: GithubCodeownerErrorsItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_CODEOWNER_ERRORS>;
}

export interface GitHubUserRepoPermissionInventoryReport extends InventoryReport<GitHubUserRepoPermissionInventoryItem> {
  reportItems: GitHubUserRepoPermissionInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_USER_REPO_PERMISSION>;
}

export interface GitHubTotalWorkflowsInventoryReport extends InventoryReport<GitHubTotalWorkflowsInventoryItem> {
  reportItems: GitHubTotalWorkflowsInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_TOTAL_WORKFLOWS>;
}

export interface GitHubWebhooksInventoryReport extends InventoryReport<GithubWebhooksInventoryItem> {
  reportItems: GithubWebhooksInventoryItem[];
  summary: InventoryReportSummary<GitHubInventoryReportQueryType.GH_WEBHOOKS>;
}

export interface ADOTotalIdentitiesInventoryReport extends InventoryReport<ADOTotalIdentitiesInventoryItem> {
  reportItems: ADOTotalIdentitiesInventoryItem[];
  summary: InventoryReportSummary<ADOInventoryReportQueryType.ADO_TOTAL_IDENTITIES>;
}

export interface ADOTotalRepositoriesInventoryReport extends InventoryReport<ADOTotalRepositoriesInventoryItem> {
  reportItems: ADOTotalRepositoriesInventoryItem[];
  summary: InventoryReportSummary<ADOInventoryReportQueryType.ADO_TOTAL_REPOSITORIES>;
}

export interface ADOExcessiveLicensesInventoryReport extends InventoryReport<ADOExcessiveLicensesInventoryItem> {
  reportItems: ADOExcessiveLicensesInventoryItem[];
  summary: InventoryReportSummary<ADOInventoryReportQueryType.ADO_EXCESSIVE_LICENSES>;
}

export interface ADOWebhooksInventoryReport extends InventoryReport<ADOWebhooksInventoryItem> {
  reportItems: ADOWebhooksInventoryItem[];
  summary: InventoryReportSummary<ADOInventoryReportQueryType.ADO_WEBHOOKS>;
}

export const ADOInventoryReportQueryType = {
  ADO_TOTAL_IDENTITIES: "ADO_TOTAL_IDENTITIES",
  ADO_TOTAL_REPOSITORIES: "ADO_TOTAL_REPOSITORIES",
  ADO_EXCESSIVE_LICENSES: "ADO_EXCESSIVE_LICENSES",
  ADO_WEBHOOKS: "ADO_WEBHOOKS"
} as const;

export type ADOInventoryReportQueryType = (typeof ADOInventoryReportQueryType)[keyof typeof ADOInventoryReportQueryType];

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ADOInventoryReportQueryType {
  export type ADO_TOTAL_IDENTITIES = typeof ADOInventoryReportQueryType.ADO_TOTAL_IDENTITIES;
  export type ADO_TOTAL_REPOSITORIES = typeof ADOInventoryReportQueryType.ADO_TOTAL_REPOSITORIES;
  export type ADO_EXCESSIVE_LICENSES = typeof ADOInventoryReportQueryType.ADO_EXCESSIVE_LICENSES;
  export type ADO_WEBHOOKS = typeof ADOInventoryReportQueryType.ADO_WEBHOOKS;
}

export const UniversalScmInventoryReportQueryType = {
  UNIVERSAL_SCM_USERS: "UNIVERSAL_SCM_USERS",
  UNIVERSAL_SCM_REPOSITORIES: "UNIVERSAL_SCM_REPOSITORIES"
} as const;

export type UniversalScmInventoryReportQueryType = (typeof UniversalScmInventoryReportQueryType)[keyof typeof UniversalScmInventoryReportQueryType];

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UniversalScmInventoryReportQueryType {
  export type UNIVERSAL_SCM_USERS = typeof UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_USERS;
  export type UNIVERSAL_SCM_REPOSITORIES = typeof UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_REPOSITORIES;
}

export interface UniversalScmUsersInventoryReport extends InventoryReport<UniversalScmUsersInventoryItem> {
  reportItems: UniversalScmUsersInventoryItem[];
  summary: InventoryReportSummary<UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_USERS>;
}

export interface UniversalScmRepositoriesInventoryReport extends InventoryReport<UniversalScmRepositoriesInventoryItem> {
  reportItems: UniversalScmRepositoriesInventoryItem[];
  summary: InventoryReportSummary<UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_REPOSITORIES>;
}

export const InventoryReportQueryType = {
  ...GitHubInventoryReportQueryType,
  ...ADOInventoryReportQueryType,
  ...UniversalScmInventoryReportQueryType
} as const;
export type InventoryReportQueryType = keyof typeof InventoryReportQueryType;

export const SCM_TYPE_FROM_INVENTORY_QUERY_TYPE: Record<InventoryReportQueryType, SCMType[]> = {
  [ADOInventoryReportQueryType.ADO_TOTAL_IDENTITIES]: [SCMType.AZURE_DEVOPS],
  [ADOInventoryReportQueryType.ADO_TOTAL_REPOSITORIES]: [SCMType.AZURE_DEVOPS],
  [ADOInventoryReportQueryType.ADO_EXCESSIVE_LICENSES]: [SCMType.AZURE_DEVOPS],
  [ADOInventoryReportQueryType.ADO_WEBHOOKS]: [SCMType.AZURE_DEVOPS],
  [GitHubInventoryReportQueryType.GH_TOTAL_IDENTITIES]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_TOTAL_REPOSITORIES]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_AGGREGATED_REPOSITORIES]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_INACTIVE_IDENTITIES]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_REPOSITORIES_NO_BRANCH_PROTECTION]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_APP]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_CODEOWNER_ERRORS]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_USER_REPO_PERMISSION]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_TOTAL_ORGS]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_WEBHOOKS]: [SCMType.GITHUB],
  [GitHubInventoryReportQueryType.GH_TOTAL_WORKFLOWS]: [SCMType.GITHUB],
  [UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_USERS]: [...UNIVERSAL_SCM_TYPE.values],
  [UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_REPOSITORIES]: [...UNIVERSAL_SCM_TYPE.values]
};
