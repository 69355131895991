import { ConfigFlags } from "@arnica-internal/configs";
import Vue from "vue";
import { DirectiveBinding } from "vue/types/options";
import { Flags, Auth } from "@/state";

type KeyOfFlags = keyof ConfigFlags;

class FlagDirective {
  public static async inserted(el: HTMLElement, binding: DirectiveBinding): Promise<void> {
    // based on https://stackoverflow.com/a/43543814/2355482
    const flagName = Object.keys(binding.modifiers).at(0) as KeyOfFlags | undefined;
    const comment = document.createComment(`${el.tagName} ${flagName}`);
    el.parentNode?.replaceChild(comment, el);
    if (!flagName) {
      console.warn("FlagDirective: no flag name");
      return;
    }
    const flag = await Flags.tryGet(flagName);
    const show = await FlagDirective.checkFlag(binding, flag);

    show && comment.parentNode?.replaceChild(el, comment);
  }

  private static async checkFlag<F extends KeyOfFlags>(binding: DirectiveBinding, value: ConfigFlags[F]): Promise<boolean> {
    switch (typeof binding.value) {
      case "undefined": {
        return !!value;
      }
      case "function": {
        try {
          const result = await binding.value(value);
          return !!result;
        } catch (e) {
          console.warn("FlagDirective: error evaluating flag value", e);
          return false;
        }
      }
      default: {
        return binding.value === value;
      }
    }
  }
}

class FlagOrAdminDirective extends FlagDirective {
  public static override async inserted(el: HTMLElement, binding: DirectiveBinding): Promise<void> {
    if (Auth.isArnicaAdmin) {
      return;
    }
    await super.inserted(el, binding);
  }
}

Vue.directive("flag", {
  inserted: FlagDirective.inserted
});

Vue.directive("flag-or-admin", {
  inserted: FlagOrAdminDirective.inserted
});
