/**
 * Augment the typings of Vue.js
 */
import Vue from "vue";
import * as Utils from "@/utils";

declare module "vue/types/vue" {
  interface Vue {
    $utils: typeof Utils;
  }

  interface VueConstructor {
    utils: typeof Utils;
  }
}

Vue.use({
  install() {
    Vue.prototype.$utils = Utils;
    Vue.utils = Utils;
  }
});
