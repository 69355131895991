import { SCMType, UNIVERSAL_SCM_TYPE } from "../../../scm-type";
import { IntegrationCategory, IntegrationType, ScmIntegrationType, UniversalScmIntegrationType } from "../integration";
import { AgentIntegrationAttributes } from "./agent-integration-attributes";
import { CommunicationIntegrationAttributes } from "./communication-integration-attributes";
import { IssuesIntegrationAttributes } from "./issues-integration-attributes";
import { LlmIntegrationAttributes } from "./llm-integration-attributes";
import { ScmIntegrationAttributes, ScmToIntegrationType, UniversalScmIntegrationAttributes } from "./scm-integration-attributes";

export interface IntegrationAttributes {
  type: IntegrationType;
  category: IntegrationCategory;
  displayName: string;
  hasJobs: boolean;
  /**
   * Includes metadata in integration response.
   */
  includeMetadata: boolean;
  ui: {
    editable: boolean;
    removable: boolean;
    editTooltip?: string;
    icon: `mdi-${string}`;
    reactIcon: `mdi:${string}` | `simple-icons:${string}`;
  };
}

function getAttributes(type: UniversalScmIntegrationType | UNIVERSAL_SCM_TYPE): UniversalScmIntegrationAttributes;
function getAttributes(type: ScmIntegrationType | SCMType): ScmIntegrationAttributes;
function getAttributes(type: Exclude<IntegrationType, ScmIntegrationType>): IntegrationAttributes;
function getAttributes<T extends IntegrationType | SCMType>(type: T): IntegrationAttributes;
function getAttributes(type: IntegrationType | SCMType): IntegrationAttributes {
  type = (ScmToIntegrationType[type as SCMType] || type) as IntegrationType;
  return IntegrationAttributes[type];
}

interface IntegrationAttributesUtils {
  get: typeof getAttributes;
}

export const IntegrationAttributes = {
  get: getAttributes, // defined outside the object to allow overloading
  ...ScmIntegrationAttributes,
  ...CommunicationIntegrationAttributes,
  ...IssuesIntegrationAttributes,
  ...LlmIntegrationAttributes,
  ...AgentIntegrationAttributes
} as const satisfies Readonly<Record<IntegrationType, IntegrationAttributes>> & IntegrationAttributesUtils;
