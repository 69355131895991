import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    severity = "severity"
  }
}

(PolicyConditionSubType as { severity: "severity" }).severity = "severity";

export interface PolicyConditionFindingSeverity extends PolicyCondition {
  sub: PolicyConditionSubType.severity;
  name: "critical" | "high" | "medium" | "low";
}

export const PolicyConditionFindingSeverity = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.severity
  } as const,
  generateDefault(severity: PolicyConditionFindingSeverity["name"]): PolicyConditionFindingSeverity {
    return {
      id: `${PolicyItemType.condition}-${Math.floor(Date.now() * Math.random())}`,
      name: severity,
      sub: PolicyConditionSubType.severity,
      type: PolicyItemType.condition
    };
  }
} as const;
