import { TrivyCycloneDx } from "../../../../../sbom/interfaces/sbom-report-item";
import { PolicyItemType } from "../../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_openssf_scorecard = "package_openssf_scorecard"
  }
}

(PolicyConditionSubType as { package_openssf_scorecard: "package_openssf_scorecard" }).package_openssf_scorecard = "package_openssf_scorecard";

export interface PolicyConditionFindingPackageOpenSsfScorecard extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_openssf_scorecard;
  /** OpenSSF scorecard (0.0-10.0) minimum (inclusive) */
  min: number;
  /** OpenSSF scorecard (0.0-10.0) maximum (inclusive) */
  max: number;
  /** Matches if there is no scorecard */
  includeUnscored: boolean;
}

export const PolicyConditionFindingPackageOpenSsfScorecard = {
  TYPES: TrivyCycloneDx.PackageType,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_openssf_scorecard
  }
} as const;
