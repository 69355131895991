import type { ScmIntegrationType } from "../../../dynamo";
import type { ScmIntegrationAttributes } from "../../../dynamo/integration/integration-attributes";
import { LicenseClassification, LicenseName } from "../../../licenses";
import { RiskSeverity } from "../../../risk-severity";
import { PolicyActionBlockPR, PolicyActionInstantMessage, PolicyActionPRComment, PolicyActionPRCommentRecognition } from "../policy-actions";
import { PolicyConditionBoolean, PolicyConditionFindingScaHasFix, PolicyConditionFindingSeverity, PolicyConditionSubType } from "../policy-conditions";
import { PolicyItemType } from "../policy-item-base";
import { PolicyRule } from "../policy-rule";
import { PolicyTriggerCodeRiskFixed, PolicyTriggerDetectedOnPR, PolicyTriggerDetectedOnPush, PolicyTriggerDetectedOnScan } from "../policy-triggers";
import { PolicyItem, PolicySubType } from "./policy-item";

declare module "./policy-item" {
  enum PolicySubType {
    code_risk = "code_risk"
  }

  interface PolicySubTypeMapper {
    [PolicySubType.code_risk]: PolicyItemCodeRisk;
  }
}

(PolicySubType as { code_risk: "code_risk" }).code_risk = "code_risk";

/**
 * Custom Semgrep rule configuration
 */
export interface CustomSemgrepRule {
  /**
   * Denotes whether the rule is enabled
   */
  enabled: boolean;

  /**
   * Rule severity
   * @note should be parsed from the rule content itself
   */
  severity: RiskSeverity;

  /**
   * Rule name
   * @note should be parsed from the rule content itself
   */
  name: string;

  /**
   * Rule content (semgrep YAML)
   * @note not saved into DDB due to size limitations, the UI should have the content.
   * @see `PolicyAdaptorCodeRisk` for more details on how this is handled
   */
  content?: string;
}

interface PolicyItemCodeRiskConfig {
  /**
   * Open-source license configuration
   */
  licenses: {
    /**
     * Defines license classification severity
     */
    classification: Record<LicenseClassification, RiskSeverity | null>;

    /**
     * A list of forbidden licenses (will override classification to "critical")
     * @note If a license is both forbidden and approved, it will be treated as forbidden
     */
    forbidden: LicenseName[];

    /**
     * A list of approved licenses (findings will be ignored)
     * @note If a license is both forbidden and approved, it will be treated as forbidden
     */
    approved: LicenseName[];
  };

  customText: {
    /**
     * Custom message to append to the footer of a message
     */
    messages: string;

    /**
     * Custom message to append to a PR comment
     */
    prComment: string;
  };

  /**
   * Semgrep configuration
   */
  semgrep: {
    /**
     * Custom semgrep rules
     * @note
     * - `key`: Rule ID
     * - `value`: Rule configuration
     */
    customRules: Record<string, CustomSemgrepRule>;
    /**
     * The default and arnica rule ids disabled by the user
     */
    disabledRules: string[];
  };

  /**
   * Status checks configuration
   */
  statusChecks: {
    /**
     * List of SCMs for which status checks should only be recorded and not reported
     */
    recordOnlyScms: Record<HasProperty<(typeof ScmIntegrationAttributes)[ScmIntegrationType], "supportsRecordingStatusChecks", true>["type"], boolean>;
  };
}

export interface PolicyItemCodeRisk extends PolicyItem {
  sub: PolicySubType.code_risk;
  config: PolicyItemCodeRiskConfig;
  /**
   * Version of the policy
   * @note
   * - `undefined` - initial
   * - `2` - migrated triggers
   * - `3` - migrated `customText`
   * - `3.1` - added `LicenseClassification.approved`
   * - `3.2` - migrate `PolicyConditionMatcherFindingSastProperty`
   */
  v?: 2 | 3 | 3.1 | 3.2;
}

export type ClassificationSource = "allow-list" | "deny-list" | "default";

export const PolicyItemCodeRisk = {
  is: (item?: PolicyItem | null): item is PolicyItemCodeRisk => item?.sub === PolicySubType.code_risk,
  generateDefault(arnicaOrgId: string): PolicyItemCodeRisk {
    return {
      arnicaOrgId,
      id: PolicySubType.code_risk,
      sub: PolicySubType.code_risk,
      type: PolicyItemType.policy,
      rules: [
        {
          ...PolicyRule.generateDefault(),
          name: "Notify code pusher on high+ severity risks - requires ChatOps integration (good practice 🥇)",
          trigger: PolicyTriggerDetectedOnPush.generateDefault(),
          condition: PolicyConditionFindingSeverity.generateDefault("high"),
          actions: [PolicyActionInstantMessage.generateDefaultPusherOnly()],
          enabled: false
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Notify code pusher on medium+ severity risks - requires ChatOps integration (best practice 🏆)",
          trigger: PolicyTriggerDetectedOnPush.generateDefault(),
          condition: PolicyConditionFindingSeverity.generateDefault("medium"),
          actions: [PolicyActionInstantMessage.generateDefaultPusherOnly()],
          enabled: false
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Comment on pull/merge request on high+ severity risks (good practice 🥇)",
          trigger: PolicyTriggerDetectedOnPR.generateDefault(),
          condition: {
            ...PolicyConditionBoolean.generateDefault(),
            sub: PolicyConditionSubType.and,
            children: [PolicyConditionFindingSeverity.generateDefault("high"), PolicyConditionFindingScaHasFix.generateDefault()]
          },
          actions: [PolicyActionPRComment.generateDefault()],
          enabled: false
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Comment on pull/merge request and fail status check on high+ severity risks (best practice 🏆)",
          trigger: PolicyTriggerDetectedOnPR.generateDefault(),
          condition: {
            ...PolicyConditionBoolean.generateDefault(),
            sub: PolicyConditionSubType.and,
            children: [PolicyConditionFindingSeverity.generateDefault("high"), PolicyConditionFindingScaHasFix.generateDefault()]
          },
          actions: [PolicyActionPRComment.generateDefault(), PolicyActionBlockPR.generateDefault()],
          enabled: false
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Comment on pull/merge request on medium+ severity risks (best practice 🏆)",
          trigger: PolicyTriggerDetectedOnPR.generateDefault(),
          condition: {
            ...PolicyConditionBoolean.generateDefault(),
            sub: PolicyConditionSubType.and,
            children: [PolicyConditionFindingSeverity.generateDefault("medium"), PolicyConditionFindingScaHasFix.generateDefault()]
          },
          actions: [PolicyActionPRComment.generateDefault()],
          enabled: false
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Recognize developer when a medium+ risk is mitigated on pull/merge request (fun practice 🎉)",
          trigger: PolicyTriggerCodeRiskFixed.generateDefault(),
          condition: PolicyConditionFindingSeverity.generateDefault("medium"),
          actions: [PolicyActionPRCommentRecognition.generateDefault()],
          enabled: false
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Silent pipelineless scan on push (default 💯)",
          condition: PolicyConditionBoolean.generateDefault(),
          trigger: PolicyTriggerDetectedOnPush.generateDefault()
        },
        {
          ...PolicyRule.generateDefault(),
          name: "Silent routine scan (default 💯)",
          condition: PolicyConditionBoolean.generateDefault(),
          trigger: PolicyTriggerDetectedOnScan.generateDefault()
        }
      ],
      config: PolicyItemCodeRisk.generateDefaultConfig()
    };
  },
  customFooterMessageText(item?: PolicyItem | null): string {
    return (PolicyItemCodeRisk.is(item) && item.config.customText.messages) || "";
  },
  customPrCommentText(item?: PolicyItem | null): string {
    return (PolicyItemCodeRisk.is(item) && item.config.customText.prComment) || "";
  },
  generateDefaultConfig(existing?: RecursivePartial<PolicyItemCodeRiskConfig>): PolicyItemCodeRiskConfig {
    existing ||= {};
    const existingClassification = existing.licenses?.classification as Record<LicenseClassification, RiskSeverity | null> | undefined;
    const existingSemgrepCustomRules = Object.fromEntries(Object.entries(existing.semgrep?.customRules || {}).filter((entry): entry is [string, CustomSemgrepRule] => !!entry[1]));
    const existingStatusChecksRecordOnlyScms = existing.statusChecks?.recordOnlyScms || {};
    return {
      customText: {
        messages: existing.customText?.messages || "",
        prComment: existing.customText?.prComment || ""
      },
      licenses: {
        classification: {
          [LicenseClassification.forbidden]: "high",
          [LicenseClassification.restricted]: "high",
          [LicenseClassification.reciprocal]: "medium",
          [LicenseClassification.exception]: "medium",
          [LicenseClassification.notice]: null,
          [LicenseClassification.permissive]: null,
          [LicenseClassification.unencumbered]: null,
          [LicenseClassification.unknown]: null,
          [LicenseClassification.approved]: null,
          ...existingClassification
        },
        forbidden: existing.licenses?.forbidden || [],
        approved: existing.licenses?.approved || []
      },
      semgrep: {
        customRules: existingSemgrepCustomRules,
        disabledRules: existing.semgrep?.disabledRules || []
      },
      statusChecks: {
        recordOnlyScms: {
          github: existingStatusChecksRecordOnlyScms.github ?? false,
          gitlab: existingStatusChecksRecordOnlyScms.gitlab ?? false,
          "bitbucket-cloud": existingStatusChecksRecordOnlyScms["bitbucket-cloud"] ?? false,
          "bitbucket-server": existingStatusChecksRecordOnlyScms["bitbucket-server"] ?? false
        }
      }
    };
  }
} as const;
