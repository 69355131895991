//do not remove, this is here to ensure all pages get any augmented methods, e.g. groupBy, mapBy, etc.
import "$/polyfills";
import "$/utils";
import App from "./App.vue";
import Vue from "vue";
import * as plugins from "@/plugins";

Vue.config.productionTip = false;

const root = new Vue({
  ...plugins,
  render: (h) => h(App)
}).$mount("#app");

if (!import.meta.env.PROD) {
  console.debug("root set");
  (window as unknown as { root: Vue }).root = root;
}
