import { Observer } from "./vue-helper";
import Vue from "vue";

class MapObserver extends Observer {
  public constructor(value: unknown, shallow?: boolean, mock?: boolean) {
    super(value, shallow, mock);
    if (!shallow && value instanceof Map) {
      this.observeArray(value);
    }
  }

  public override observeArray(value: unknown): void {
    // does not support nested Maps
    super.observeArray(value instanceof Map ? Array.from(value.values()) : value);
  }
}

export class ObservableMap<K, V> extends Map<K, V> {
  private _length = super.size;

  private _notify(method: string): void {
    this._length = super.size;
    this.__ob__?.dep?.notify({
      type: "map mutation",
      target: this,
      key: method
    });
  }

  private readonly __ob__: MapObserver;

  public constructor(entries?: Iterable<readonly [K, V]> | null) {
    super(entries);
    this.__ob__ ??= new MapObserver(this);
  }

  public get length(): number {
    return this._length;
  }

  public override get size(): number {
    return this._length;
  }

  public override clear(): void {
    super.clear();
    this._notify("clear");
  }

  public override delete(key: K): boolean {
    const deleted = super.delete(key);
    this._notify("delete");
    return deleted;
  }

  public override set(key: K, value: V): this {
    Vue.observable(value);
    super.set(key, value);
    Array.isArray(value) && this.__ob__?.observeArray(value);
    this._notify("set");
    return this;
  }
}
