import { PolicyItemType } from "../policy-item-base";
import { PolicyTrigger, PolicyTriggerSubType } from "./policy-trigger";

declare module "./policy-trigger" {
  enum PolicyTriggerSubType {
    detected_on_push = "detected_on_push"
  }
}

(PolicyTriggerSubType as { detected_on_push: "detected_on_push" }).detected_on_push = "detected_on_push";

export interface PolicyTriggerDetectedOnPush extends PolicyTrigger {
  sub: PolicyTriggerSubType.detected_on_push;
}

export const PolicyTriggerDetectedOnPush = {
  component: {
    type: PolicyItemType.trigger,
    sub: PolicyTriggerSubType.detected_on_push
  },
  generateDefault(): PolicyTriggerDetectedOnPush {
    return {
      type: PolicyItemType.trigger,
      sub: PolicyTriggerSubType.detected_on_push,
      id: `${PolicyItemType.trigger}-${Math.floor(Date.now() * Math.random())}`
    };
  }
} as const;
