import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * Apply in-flight GET request caching to the provided Axios instance.
 * (prevents multiple requests for the same resource from being made simultaneously)
 * @returns Axios instance with in-flight get request caching applied.
 */
export function applyInFlightGetRequestCaching(baseUrl: string | Promise<string>, axiosInstance: AxiosInstance): AxiosInstance {
  const inFlightRequestsMap = new Map<string, Promise<unknown>>();
  const originalGet = axiosInstance.get.bind(axiosInstance);

  axiosInstance.get = async <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: AxiosRequestConfig<D>): Promise<R> => {
    const queryString = new URLSearchParams(config?.params || {}).toString();
    const cacheKey = `GET ${await baseUrl}/${url}${queryString ? `?${queryString}` : ""}`;
    const inFlightRequest = inFlightRequestsMap.get(cacheKey);
    if (inFlightRequest) {
      return <Promise<R>>inFlightRequest;
    }

    const request = originalGet<T, R, D>(url, config);

    inFlightRequestsMap.set(cacheKey, request);

    try {
      const axiosResponse = await request;
      return axiosResponse;
    } finally {
      inFlightRequestsMap.delete(cacheKey);
    }
  };

  return axiosInstance;
}
