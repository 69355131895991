import { VuetifyThemeVariant } from "vuetify/types/services/theme";

import scssVariables from "@/variables.module.scss";

export const theme: { light?: Partial<VuetifyThemeVariant>; dark?: Partial<VuetifyThemeVariant> } = {
  light: {
    hover: "#ECEFF1",
    success: scssVariables["lightPrimary"],
    info: scssVariables["lightAlertInfo"],
    error: scssVariables["lightHigh"],
    warning: scssVariables["lightMedium"],
    secondary: scssVariables["lightUnknown"],
    primary: scssVariables["lightPrimary"]
  },
  dark: {
    hover: "#424242",
    success: scssVariables["darkPrimary"],
    info: scssVariables["darkAlertInfo"],
    error: scssVariables["darkHigh"],
    warning: scssVariables["darkMedium"],
    secondary: scssVariables["darkUnknown"],
    primary: scssVariables["darkPrimary"]
  }
};
