<template>
  <v-app>
    <overlay-simple-message
      @close="showOverlay.item = false"
      v-if="showOverlay.item && isMobile"
      button-text="Proceed Anyways"
      title="Mobile View Not Supported"
      text="This site is best experienced on a higher resolution device.">
    </overlay-simple-message>

    <template v-else>
      <loading-centered v-if="!$state.Layout.appLoaded"></loading-centered>
      <template v-else-if="showFullAppBar">
        <app-bar></app-bar>
        <navigation-drawer></navigation-drawer>

        <v-main :expand-left="!$state.Layout.drawer.show">
          <v-container fluid class="full-height app-container-padding">
            <v-slide-x-transition mode="out-in">
              <router-view />
            </v-slide-x-transition>
          </v-container>
        </v-main>

        <!-- <app-footer></app-footer> -->

        <snack-bar></snack-bar>
        <action-dialog></action-dialog>
        <tooltip></tooltip>
      </template>
      <template v-else>
        <app-bar></app-bar>
        <router-view />
      </template>
    </template>
  </v-app>
</template>

<script lang="ts">
import Tooltip from "./components/layout/tooltip.vue";
import OverlaySimpleMessage from "./components/overlay-simple-message.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { SCMType } from "$/scm-type";
import ActionDialog from "@/components/layout/action-dialog.vue";
import AppBar from "@/components/layout/app-bar.vue";
import AppFooter from "@/components/layout/app-footer.vue";
import LoadingCentered from "@/components/layout/loading-centered.vue";
import NavigationDrawer from "@/components/layout/navigation-drawer.vue";
import SnackBar from "@/components/layout/snack-bar.vue";
import { LocalStorageItem } from "@/utils/local-storage-item";

// List of events to listen to avoid auto-signOut
const EVENTS_TO_CHECK = ["click", "mousedown", "scroll", "keypress", "load"] as const;

const MINUTES_IDLE = 30;

const WAKE_UP_MESSAGE = "WAKE_UP_MESSAGE";

@Component({
  components: {
    AppBar,
    NavigationDrawer,
    AppFooter,
    SnackBar,
    LoadingCentered,
    ActionDialog,
    Tooltip,
    OverlaySimpleMessage
  }
})
export default class App extends Vue {
  protected showOverlay = new LocalStorageItem("showMobileOverlay", true);
  private lastActionTime = Date.now();

  //Create a broadcast channel to communicate with other instances of the app (on other tabs)
  private appChannel: BroadcastChannel | null = null;

  protected get isMobile(): boolean {
    return this.$vuetify.breakpoint.name === "xs";
  }

  protected get showFullAppBar(): boolean {
    return this.$route.meta?.appBar !== false;
  }

  @Watch("$state.Layout.scmType")
  protected async onSCMTypeChange(newValue: SCMType | null): Promise<void> {
    newValue && (await this.$state.Preferences.save({ scmType: newValue }));
  }

  public async beforeCreate(): Promise<void> {
    if (navigator.platform.toUpperCase().indexOf("MAC") === -1) {
      document.body.classList.add("non-mac-os");
    }
    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
      this.$vuetify.theme.dark = mediaQueryList.matches;

      mediaQueryList.addEventListener("change", async (e) => {
        this.$vuetify.theme.dark = e.matches;
        await this.$state.Preferences.save({ dark: e.matches });
      });
    }

    await this.$state.Notifications.init();
  }

  protected logOutTimer = 0;

  public created(): void {
    //Add an eventListener for every event in the array
    EVENTS_TO_CHECK.forEach((eventToCheck) => {
      window.addEventListener(eventToCheck, this.setLastActionTime.bind(this));
    }, false);
    if (location.hostname === "localhost") {
      return;
    }
    //initialize and listen to wake up messages from other tabs
    this.appChannel = new BroadcastChannel("arnica_app_channel");
    this.appChannel.onmessage = (e: MessageEvent) => {
      if (e.data === WAKE_UP_MESSAGE) {
        this.lastActionTime = Date.now();
      }
    };
    this.logOutTimer = window.setInterval(this.checkLogOut, 60 * 1000); //Every minute
  }

  private async checkLogOut(): Promise<void> {
    const timeSinceLastAction = Date.now() - this.lastActionTime;
    if (timeSinceLastAction > MINUTES_IDLE * 60 * 1000) {
      window.clearInterval(this.logOutTimer);
      await this.$state.Auth.signOut();
    }
  }

  private setLastActionTime(): void {
    this.lastActionTime = Date.now();
    if (this.appChannel) {
      this.appChannel.postMessage(WAKE_UP_MESSAGE);
    }
  }
}
</script>

<style lang="scss" scoped>
main.v-main[expand-left] {
  padding-left: 0 !important;
}

.app-container-padding {
  padding: 0 1rem;
}
</style>
<style lang="scss" src="./App.scss"></style>
