import { ApiBase } from "./api-base";
import { GetNotificationsResponse } from "$/interfaces/ui-api/response/get-notifications-response";

class NotificationsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "notifications", name: "NotificationsApi" });
  }

  protected override get logRequests(): boolean {
    return false;
  }

  protected override get logResponses(): boolean {
    return false;
  }

  public async get(silent = true): Promise<GetNotificationsResponse[]> {
    const res = await this.client.get<GetNotificationsResponse[]>("", { silent });
    const notifications = res.data;
    notifications.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));
    return notifications;
  }

  public async updateStatus(notification: GetNotificationsResponse, read: boolean) {
    await this.client.patch<void>(encodeURIComponent(notification.timestamp), { read });
  }

  public async markAllAsRead() {
    await this.client.post<void>(`read`);
  }

  public async deleteAll() {
    await this.client.delete<void>("");
  }

  public async delete(notification: GetNotificationsResponse) {
    await this.client.delete<void>(encodeURIComponent(notification.timestamp));
  }
}

export const Notifications = new NotificationsApi();
