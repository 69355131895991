import { TimeoutError } from "../errors";
import type moment from "moment";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface PromiseConstructor {
    /**
     * A static instance of a resolved Promise. Useful for when you need to return a Promise from a function but don't want to create a new one.
     */
    readonly resolved: Promise<void>;

    /**
     * Creates a Promise that is resolved with the value of the first resolved Promise that matches the predicate or rejected if any of the Promises are rejected.
     * @param promises An array of Promises.
     * @param [predicate] Optional. A function that is called with the result of each Promise. If the function returns true, the Promise is considered to be resolved. If no predicate is provided, the first truthy resolved Promise is returned.
     * @returns A new Promise which is resolved with the value of the first resolved Promise that matches the predicate.
     */
    raceTo<T extends readonly unknown[] | []>(promises: T, predicate?: (result: Awaited<T[number]>) => boolean): Promise<Awaited<T[number]> | undefined>;

    // adding deprecated message to allSettled
    /**
     * Creates a Promise that is resolved with an array of results when all
     * of the provided Promises resolve or reject.
     * @param values An array of Promises.
     * @returns A new Promise.
     * @deprecated Recommended to not use allSettled unless actively handling the results.
     */
    allSettled<T extends readonly unknown[] | []>(values: T): Promise<{ -readonly [P in keyof T]: PromiseSettledResult<Awaited<T[P]>> }>;

    /**
     * Creates a Promise that is resolved with an array of results when all
     * of the provided Promises resolve or reject.
     * @param values An array of Promises.
     * @returns A new Promise.
     * @deprecated Recommended to not use allSettled unless actively handling the results.
     */
    allSettled<T>(values: Iterable<T | PromiseLike<T>>): Promise<PromiseSettledResult<Awaited<T>>[]>;
  }

  interface Promise<T> {
    /**
     * Used to explicitly to note a promise does not need to be awaited.
     * @deprecated Use `void` statement instead.
     * @example
     * Instead of:
     * ```ts
     * async method() {
     *   ...
     *   executeInBackground().noAwait();
     *   ...
     * }
     * ```
     * use:
     * ```ts
     * async method() {
     *   ...
     *   void executeInBackground();
     *   ...
     * }
     * ```
     */
    noAwait(): void;

    /**
     * Ignores any errors that occur during the execution of the promise.
     */
    ignoreErrors(): Promise<T | undefined>;

    /**
     * Returns a new Promise that is resolved with the result of the original Promise, or rejected if the original Promise does not resolve within the specified timeout.
     * @returns A new Promise with the result of the original Promise.
     * @throws {TimeoutError} If the original Promise does not resolve within the specified timeout.
     */
    withTimeout(timeout: moment.Duration): Promise<T>;
  }
}

if (!Promise.resolved) {
  Object.defineProperty(Promise, "resolved", {
    value: Promise.resolve(),
    writable: false,
    enumerable: false
  });
}

Promise.raceTo ||= function raceTo<T extends readonly unknown[] | []>(
  promises: T,
  predicate: (result: Awaited<T[number]>) => boolean = (result) => !!result
): Promise<Awaited<T[number]> | undefined> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<Awaited<T[number]> | undefined>(async (resolve, reject) => {
    try {
      // the await is needed, in case none of the promises resolve the wrapping promise (i.e. none conform to the predicate)
      // in this case, the finally block will resolve the wrapping promise with undefined
      await Promise.allSettled(
        promises.map(async (promise: T[number]) => {
          try {
            const result = await promise;
            if (predicate(result)) {
              resolve(result);
            }
          } catch (error) {
            reject(error instanceof Error ? error : new AggregateError([error]));
          }
        })
      );
    } finally {
      // resolve the wrapping promise, if none of the promises resolved
      resolve(undefined);
    }
  });
};

Promise.prototype.noAwait ||= function noAwait(): void {
  // noop
};

Promise.prototype.ignoreErrors ||= async function ignoreErrors<T>(this: Promise<T>): Promise<T | undefined> {
  try {
    return await this;
  } catch {
    return undefined;
  }
};

Promise.prototype.withTimeout ||= async function withTimeout<T>(this: Promise<T>, timeout: moment.Duration): Promise<T> {
  const timeoutSymbol = Symbol("timeout");
  const timeoutPromise = setTimeout.async(timeout).then(() => timeoutSymbol);
  const result = await Promise.race([this, timeoutPromise]);
  if (result === timeoutSymbol) {
    throw new TimeoutError(`Promise did not resolve within ${timeout.toISOString()}`);
  }
  return result as T;
};
