import { ApiBase } from "./api-base";
import { CustomSemgrepRule } from "$/interfaces/policies/policy-items/policy-item-code-risk";
import { DefaultSemgrepRule } from "$/interfaces/ui-api/response/default-semgrep-rule";

class SemgrepApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "semgrep", name: "SemgrepApi" });
  }

  public async validateRule(rule: string): Promise<{ valid: true; rule: CustomSemgrepRule } | { valid: false; error: string }> {
    const res = await this.client.post<{ valid: true; rule: CustomSemgrepRule } | { valid: false; error: string }>("rules/validation", rule, {
      headers: { "Content-Type": "text/yaml" }
    });
    return res.data;
  }

  public async getDefaultRules(): Promise<DefaultSemgrepRule[]> {
    const res = await this.client.get<DefaultSemgrepRule[]>("rules/default");
    return res.data;
  }
}

export const Semgrep = new SemgrepApi();
