export const GitLeaksDescriptions = {
  "Adafruit API Key": "adafruit-api-key",
  "Adobe Client ID (OAuth Web)": "adobe-client-id",
  "Adobe Client Secret": "adobe-client-secret",
  "Age secret key": "age secret key",
  "Airtable API Key": "airtable-api-key",
  "Algolia API Key": "algolia-api-key",
  "Alibaba AccessKey ID": "alibaba-access-key-id",
  "Alibaba Secret Key": "alibaba-secret-key",
  "Asana Client ID": "asana-client-id",
  "Asana Client Secret": "asana-client-secret",
  "Atlassian API token": "atlassian-api-token",
  "Authress Service Client Access Key": "authress-service-client-access-key",
  AWS: "aws-access-token",
  "Beamer API token": "beamer-api-token",
  "Bitbucket Client ID": "bitbucket-client-id",
  "Bitbucket Client Secret": "bitbucket-client-secret",
  "Bittrex Access Key": "bittrex-access-key",
  "Bittrex Secret Key": "bittrex-secret-key",
  "Clojars API token": "clojars-api-token",
  "Codecov Access Token": "codecov-access-token",
  "Coinbase Access Token": "coinbase-access-token",
  "Confluent Access Token": "confluent-access-token",
  "Confluent Secret Key": "confluent-secret-key",
  "Contentful delivery API token": "contentful-delivery-api-token",
  "Databricks API token": "databricks-api-token",
  "Datadog Access Token": "datadog-access-token",
  "Defined Networking API token": "defined-networking-api-token",
  "DigitalOcean OAuth Access Token": "digitalocean-access-token",
  "DigitalOcean Personal Access Token": "digitalocean-pat",
  "DigitalOcean OAuth Refresh Token": "digitalocean-refresh-token",
  "Discord API key": "discord-api-token",
  "Discord client ID": "discord-client-id",
  "Discord client secret": "discord-client-secret",
  "Doppler API token": "doppler-api-token",
  "Droneci Access Token": "droneci-access-token",
  "Dropbox API secret": "dropbox-api-token",
  "Dropbox long lived API token": "dropbox-long-lived-api-token",
  "Dropbox short lived API token": "dropbox-short-lived-api-token",
  "Duffel API token": "duffel-api-token",
  "Dynatrace API token": "dynatrace-api-token",
  "EasyPost API token": "easypost-api-token",
  "EasyPost test API token": "easypost-test-api-token",
  "Etsy Access Token": "etsy-access-token",
  "Facebook Access Token": "facebook",
  "Fastly API key": "fastly-api-token",
  "Finicity API token": "finicity-api-token",
  "Finicity Client Secret": "finicity-client-secret",
  "Finnhub Access Token": "finnhub-access-token",
  "Flickr Access Token": "flickr-access-token",
  "Flutterwave Encryption Key": "flutterwave-encryption-key",
  "Finicity Public Key": "flutterwave-public-key",
  "Flutterwave Secret Key": "flutterwave-secret-key",
  "Frame.io API token": "frameio-api-token",
  "Freshbooks Access Token": "freshbooks-access-token",
  "GCP API key": "gcp-api-key",
  "Generic API Key": "generic-api-key",
  "GitHub App Token": "github-app-token",
  "GitHub Fine-Grained Personal Access Token": "github-fine-grained-pat",
  "GitHub OAuth Access Token": "github-oauth",
  "GitHub Personal Access Token": "github-pat",
  "GitHub Refresh Token": "github-refresh-token",
  "GitLab Personal Access Token": "gitlab-pat",
  "GitLab Pipeline Trigger Token": "gitlab-ptt",
  "GitLab Runner Registration Token": "gitlab-rrt",
  "Gitter Access Token": "gitter-access-token",
  "GoCardless API token": "gocardless-api-token",
  "Grafana api key (or Grafana cloud api key)": "grafana-api-key",
  "Grafana cloud api token": "grafana-cloud-api-token",
  "Grafana service account token": "grafana-service-account-token",
  "HashiCorp Terraform user/org API token": "hashicorp-tf-api-token",
  "Heroku API Key": "heroku-api-key",
  "HubSpot API Token": "hubspot-api-key",
  "Hugging Face Access token": "huggingface-access-token",
  "Hugging Face Organization API token": "huggingface-organization-api-token",
  "Intercom API Token": "intercom-api-key",
  "JFrog API Key": "jfrog-api-key",
  "JFrog Identity Token": "jfrog-identity-token",
  "JSON Web Token": "jwt",
  "Base64-encoded JSON Web Token": "jwt-base64",
  "Kraken Access Token": "kraken-access-token",
  "Kucoin Access Token": "kucoin-access-token",
  "Kucoin Secret Key": "kucoin-secret-key",
  "Launchdarkly Access Token": "launchdarkly-access-token",
  "Linear API Token": "linear-api-key",
  "Linear Client Secret": "linear-client-secret",
  "LinkedIn Client ID": "linkedin-client-id",
  "LinkedIn Client secret": "linkedin-client-secret",
  "Lob API Key": "lob-api-key",
  "Lob Publishable API Key": "lob-pub-api-key",
  "Mailchimp API key": "mailchimp-api-key",
  "Mailgun private API token": "mailgun-private-api-token",
  "Mailgun public validation key": "mailgun-pub-key",
  "Mailgun webhook signing key": "mailgun-signing-key",
  "MapBox API token": "mapbox-api-token",
  "Mattermost Access Token": "mattermost-access-token",
  "MessageBird API token": "messagebird-api-token",
  "MessageBird client ID": "messagebird-client-id",
  "Microsoft Teams Webhook": "microsoft-teams-webhook",
  "Netlify Access Token": "netlify-access-token",
  "New Relic ingest browser API token": "new-relic-browser-api-token",
  "New Relic user API ID": "new-relic-user-api-id",
  "New Relic user API Key": "new-relic-user-api-key",
  "npm access token": "npm-access-token",
  "NPM Access Token": "npm-access-token",
  "Nytimes Access Token": "nytimes-access-token",
  "Okta Access Token": "okta-access-token",
  "OpenAI API Key": "openai-api-key",
  "Plaid API Token": "plaid-api-token",
  "Plaid Client ID": "plaid-client-id",
  "Plaid Secret key": "plaid-secret-key",
  "PlanetScale API token": "planetscale-api-token",
  "PlanetScale OAuth token": "planetscale-oauth-token",
  "PlanetScale password": "planetscale-password",
  "Postman API token": "postman-api-token",
  "Prefect API token": "prefect-api-token",
  "Private Key": "private-key",
  "Pulumi API token": "pulumi-api-token",
  "PyPI upload token": "pypi-upload-token",
  "RapidAPI Access Token": "rapidapi-access-token",
  "Readme API token": "readme-api-token",
  "Rubygem API token": "rubygems-api-token",
  "Scalingo API token": "scalingo-api-token",
  "Sendbird Access ID": "sendbird-access-id",
  "Sendbird Access Token": "sendbird-access-token",
  "SendGrid API token": "sendgrid-api-token",
  "Sendinblue API token": "sendinblue-api-token",
  "Sentry Access Token": "sentry-access-token",
  "Shippo API token": "shippo-api-token",
  "Shopify access token": "shopify-access-token",
  "Shopify custom access token": "shopify-custom-access-token",
  "Shopify private app access token": "shopify-private-app-access-token",
  "Shopify shared secret": "shopify-shared-secret",
  "Sidekiq Secret": "sidekiq-secret",
  "Sidekiq Sensitive URL": "sidekiq-sensitive-url",
  "Slack App-level token": "slack-app-token",
  "Slack Bot token": "slack-bot-token",
  "Slack Configuration access token": "slack-config-access-token",
  "Slack Configuration refresh token": "slack-config-refresh-token",
  "Slack Legacy bot token": "slack-legacy-bot-token",
  "Slack Legacy token": "slack-legacy-token",
  "Slack Legacy Workspace token": "slack-legacy-workspace-token",
  "Slack User": "slack-user-token",
  "Slack Webhook": "slack-webhook-url",
  "Snyk API token": "snyk-api-token",
  "Square Access Token": "square-access-token",
  "Squarespace Access Token": "squarespace-access-token",
  "Stripe Access Token": "stripe-access-token",
  "SumoLogic Access ID": "sumologic-access-id",
  "SumoLogic Access Token": "sumologic-access-token",
  "Telegram Bot API Token": "telegram-bot-api-token",
  "Travis CI Access Token": "travisci-access-token",
  "Twilio API Key": "twilio-api-key",
  "Twitch API token": "twitch-api-token",
  "Twitter Access Secret": "twitter-access-secret",
  "Twitter Access Token": "twitter-access-token",
  "Twitter API Key": "twitter-api-key",
  "Twitter API Secret": "twitter-api-secret",
  "Twitter Bearer Token": "twitter-bearer-token",
  "Typeform API token": "typeform-api-token",
  "Vault Batch Token": "vault-batch-token",
  "Vault Service Token": "vault-service-token",
  "Yandex Access Token": "yandex-access-token",
  "Yandex API Key": "yandex-api-key",
  "Yandex AWS Access Token": "yandex-aws-access-token",
  "Zendesk Secret Key": "zendesk-secret-key"
} as const;

export const GitLeaksIcons: PartialRecord<keyof typeof GitLeaksDescriptions, string> = {
  "Adafruit API Key": "mdi-account-key",
  "Adobe Client ID (OAuth Web)": "mdi-adobe",
  "Adobe Client Secret": "mdi-adobe",
  // "Age secret key": "mdi-age",
  // "Airtable API Key": "mdi-airtable",
  // "Algolia API Key": "mdi-algolia",
  // "Alibaba AccessKey ID": "mdi-alibaba",
  // "Alibaba Secret Key": "mdi-alibaba",
  // "Asana Client ID": "mdi-asana",
  // "Asana Client Secret": "mdi-asana",
  "Atlassian API token": "mdi-atlassian",
  //"Authress Service Client Access Key": "mdi-authress",
  AWS: "mdi-aws",
  //"Beamer API token": "mdi-beamer",
  "Bitbucket Client ID": "mdi-bitbucket",
  "Bitbucket Client Secret": "mdi-bitbucket",
  // "Bittrex Access Key": "mdi-bittrex",
  // "Bittrex Secret Key": "mdi-bittrex",
  // "Clojars API token": "mdi-clojars",
  // "Codecov Access Token": "mdi-codecov",
  // "Coinbase Access Token": "mdi-coinbase",
  // "Confluent Access Token": "mdi-confluent",
  // "Confluent Secret Key": "mdi-confluent",
  // "Contentful delivery API token": "mdi-contentful",
  "Databricks API token": "mdi-databricks",
  "Datadog Access Token": "mdi-datadog",
  // "Defined Networking API token": "mdi-defined-networking",
  // "DigitalOcean OAuth Access Token": "mdi-digitalocean",
  // "DigitalOcean Personal Access Token": "mdi-digitalocean",
  // "DigitalOcean OAuth Refresh Token": "mdi-digitalocean",
  "Discord API key": "mdi-discord",
  "Discord client ID": "mdi-discord",
  "Discord client secret": "mdi-discord",
  //"Doppler API token": "mdi-doppler",
  //"Droneci Access Token": "mdi-drone",
  "Dropbox API secret": "mdi-dropbox",
  "Dropbox long lived API token": "mdi-dropbox",
  "Dropbox short lived API token": "mdi-dropbox",
  // "Duffel API token": "mdi-duffel",
  // "Dynatrace API token": "mdi-dynatrace",
  // "EasyPost API token": "mdi-easypost",
  // "EasyPost test API token": "mdi-easypost",
  "Etsy Access Token": "mdi-etsy",
  "Facebook Access Token": "mdi-facebook",
  // "Fastly API key": "mdi-fastly",
  // "Finicity API token": "mdi-finicity",
  // "Finicity Client Secret": "mdi-finicity",
  // "Finnhub Access Token": "mdi-finnhub",
  "Flickr Access Token": "mdi-flickr",
  // "Flutterwave Encryption Key": "mdi-flutterwave",
  // "Finicity Public Key": "mdi-flutterwave",
  // "Flutterwave Secret Key": "mdi-flutterwave",
  // "Frame.io API token": "mdi-frameio",
  //"Freshbooks Access Token": "mdi-freshbooks",
  "GCP API key": "mdi-google-cloud",
  //"Generic API Key": "mdi-generic",
  "GitHub App Token": "mdi-github",
  "GitHub Fine-Grained Personal Access Token": "mdi-github",
  "GitHub OAuth Access Token": "mdi-github",
  "GitHub Personal Access Token": "mdi-github",
  "GitHub Refresh Token": "mdi-github",
  "GitLab Personal Access Token": "mdi-gitlab",
  "GitLab Pipeline Trigger Token": "mdi-gitlab",
  "GitLab Runner Registration Token": "mdi-gitlab",
  // "Gitter Access Token": "mdi-gitter",
  // "GoCardless API token": "mdi-gocardless",
  // "Grafana api key (or Grafana cloud api key)": "mdi-grafana",
  // "Grafana cloud api token": "mdi-grafana",
  // "Grafana service account token": "mdi-grafana",
  "HashiCorp Terraform user/org API token": "mdi-terraform",
  //"Heroku API Key": "mdi-heroku",
  "HubSpot API Token": "mdi-hubspot",
  // "Hugging Face Access token": "mdi-huggingface",
  // "Hugging Face Organization API token": "mdi-huggingface",
  // "Intercom API Token": "mdi-intercom",
  "JFrog API Key": "mdi-jfrog",
  "JFrog Identity Token": "mdi-jfrog",
  "JSON Web Token": "mdi-jwt",
  "Base64-encoded JSON Web Token": "mdi-jwt",
  "Flutterwave Secret Key": "mdi-credit-card-outline",
  // "Kraken Access Token": "mdi-kraken",
  // "Kucoin Access Token": "mdi-kucoin",
  // "Kucoin Secret Key": "mdi-kucoin",
  // "Launchdarkly Access Token": "mdi-launchdarkly",
  // "Linear API Token": "mdi-linear",
  // "Linear Client Secret": "mdi-linear",
  "LinkedIn Client ID": "mdi-linkedin",
  "LinkedIn Client secret": "mdi-linkedin",
  // "Lob API Key": "mdi-lob",
  // "Lob Publishable API Key": "mdi-lob",
  "Mailchimp API key": "mdi-mailchimp",
  "Mailgun private API token": "mdi-mailgun",
  "Mailgun public validation key": "mdi-mailgun",
  "Mailgun webhook signing key": "mdi-mailgun",
  "MapBox API token": "mdi-mapbox",
  // "Mattermost Access Token": "mdi-mattermost",
  // "MessageBird API token": "mdi-messagebird",
  // "MessageBird client ID": "mdi-messagebird",
  "Microsoft Teams Webhook": "mdi-microsoft-teams",
  // "Netlify Access Token": "mdi-netlify",
  // "New Relic ingest browser API token": "mdi-newrelic",
  // "New Relic user API ID": "mdi-newrelic",
  // "New Relic user API Key": "mdi-newrelic",
  "npm access token": "mdi-npm",
  "NPM Access Token": "mdi-npm",
  // "Nytimes Access Token": "mdi-nytimes",
  // "Okta Access Token": "mdi-okta",
  // "OpenAI API Key": "mdi-openai",
  // "Plaid API Token": "mdi-plaid",
  // "Plaid Client ID": "mdi-plaid",
  // "Plaid Secret key": "mdi-plaid",
  // "PlanetScale API token": "mdi-planetscale",
  // "PlanetScale OAuth token": "mdi-planetscale",
  // "PlanetScale password": "mdi-planetscale",
  // "Postman API token": "mdi-postman",
  // "Prefect API token": "mdi-prefect",
  // "Private Key": "mdi-private-key",
  // "Pulumi API token": "mdi-pulumi",
  "PyPI upload token": "mdi-language-python",
  // "RapidAPI Access Token": "mdi-rapidapi",
  // "Readme API token": "mdi-readme",
  // "Rubygem API token": "mdi-rubygems",
  // "Scalingo API token": "mdi-scalingo",
  // "Sendbird Access ID": "mdi-sendbird",
  // "Sendbird Access Token": "mdi-sendbird",
  "SendGrid API token": "mdi-sendgrid",
  // "Sendinblue API token": "mdi-sendinblue",
  // "Sentry Access Token": "mdi-sentry",
  // "Shippo API token": "mdi-shippo",
  "Shopify access token": "mdi-shopify",
  "Shopify custom access token": "mdi-shopify",
  "Shopify private app access token": "mdi-shopify",
  "Shopify shared secret": "mdi-shopify",
  // "Sidekiq Secret": "mdi-sidekiq",
  // "Sidekiq Sensitive URL": "mdi-sidekiq",
  "Slack App-level token": "mdi-slack",
  "Slack Bot token": "mdi-slack",
  "Slack Configuration access token": "mdi-slack",
  "Slack Configuration refresh token": "mdi-slack",
  "Slack Legacy bot token": "mdi-slack",
  "Slack Legacy token": "mdi-slack",
  "Slack Legacy Workspace token": "mdi-slack",
  "Slack User": "mdi-slack",
  "Slack Webhook": "mdi-slack",
  //"Snyk API token": "mdi-snyk",
  "Square Access Token": "mdi-square-inc",
  //"Squarespace Access Token": "mdi-squarespace",
  "Stripe Access Token": "mdi-stripe",
  // "SumoLogic Access ID": "mdi-sumologic",
  // "SumoLogic Access Token": "mdi-sumologic",
  "Telegram Bot API Token": "mdi-telegram",
  "Travis CI Access Token": "mdi-travis-ci",
  "Twilio API Key": "mdi-twilio",
  "Twitch API token": "mdi-twitch",
  "Twitter Access Secret": "mdi-twitter",
  "Twitter Access Token": "mdi-twitter",
  "Twitter API Key": "mdi-twitter",
  "Twitter API Secret": "mdi-twitter",
  "Twitter Bearer Token": "mdi-twitter"
  // "Typeform API token": "mdi-typeform",
  // "Vault Batch Token": "mdi-vault",
  // "Vault Service Token": "mdi-vault",
  // "Yandex Access Token": "mdi-yandex",
  // "Yandex API Key": "mdi-yandex",
  // "Yandex AWS Access Token": "mdi-yandex",
  // "Zendesk Secret Key": "mdi-zendesk",
} as const;
