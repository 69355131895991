// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface Disposable {
  [Symbol.dispose](): void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface SymbolConstructor {
  /**
   * future proofing for the `using` keyword
   */
  readonly dispose: unique symbol;
}

(Symbol as { dispose: symbol }).dispose ??= Symbol("dispose");
