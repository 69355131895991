import { ADOUserPermission, ADO_PERMISSION_RANK } from "$/ado/ado-permission";
import { Group, GroupResource, generateResourceId, generateResourceIdFor } from "$/dynamo";
import { UserInfo } from "$/dynamo/findingBase";
import { SCMType } from "$/scm-type";
import { MenuItem } from "@/components/table-items/menu-item";
import { GroupsModelBase } from "@/pages/inventory/details/groups/model/groups-model-base";
import { OrgUserIdentity } from "$/interfaces/assets/org-user-identity";

export class ADOGroupsModel extends GroupsModelBase<typeof SCMType.AZURE_DEVOPS> {
  public readonly assets = new Map<string, GroupResource>();
  private readonly resources = new Map<string, Map<string, Map<string, GroupResource>>>();

  public get scmType(): typeof SCMType.AZURE_DEVOPS {
    return SCMType.AZURE_DEVOPS;
  }

  public ownerMenuItems(id: string): MenuItem[] {
    return [
      {
        display: `Filter by ${this.displayName(id)}`,
        icon: "mdi-filter",
        onClick: () => this.addFilter(id)
      }
    ];
  }

  public getUserAvatarUrl(id: string): string {
    return this.usersMap?.get(id)?.avatarUrl || "";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public getUserAccountUrl(_id: string): string {
    // ADO Users do not have a user page
    return "";
  }

  public resourceText(item: Group<SCMType>): string {
    const organizationsCount = item.resources.filter((item) => item.org && !item.project && !item.repo).length;
    const repositoriesCount = item.resources.filter((item) => item.repo).length;
    const projectsCount = item.resources.filter((item) => item.project && !item.repo).length;
    const resources: string[] = [];
    organizationsCount && resources.push(this.organizationsText(organizationsCount));
    projectsCount && resources.push(this.projectsText(projectsCount));
    repositoriesCount && resources.push(this.repositoriesText(repositoriesCount));
    return resources.join(" | ");
  }

  protected projectsText(projectsCount: number): string {
    return `${projectsCount} project${projectsCount === 1 ? "" : "s"}`;
  }

  public displayName(principalName: string): string {
    const user = this.usersMap?.get(principalName);
    return user?.names[0] ?? principalName;
  }

  public override getDescription(groupUserId: string): string {
    return groupUserId;
  }

  public displayPermission(permission: ADOUserPermission | null): string {
    switch (permission) {
      case "admin":
        return "Admin";
      case "contributor":
        return "Contributor";
      case "reviewer":
        return "Reviewer";
      case "read":
        return "Read";
      default:
        return "None";
    }
  }

  protected usersMap: Map<string, OrgUserIdentity> | null = null;

  /**
   * The outer map's key is the user id and the key is another map where the key is the resourceId
   * and the value is the permission level the user has on that repo.
   * @protected
   */
  protected adoUsersAssetPermissionMap: Map<string, Map<string, ADOUserPermission>> | null = null;

  public getAllGroupUserIds(): string[] {
    if (!this.usersMap) {
      return [];
    }
    return Array.from(this.usersMap?.values()).map((user) => user.login);
  }

  public userExists(userInfo: UserInfo): boolean {
    const key = userInfo.login || userInfo.email;
    if (!key) {
      return false;
    }

    const identity = this.usersMap?.has(key);
    return identity || false;
  }

  public getUserLatestActivityTime(owner: string): number | null {
    return this.usersMap?.get(owner)?.daysSinceLastAction ?? null;
  }

  public getUserHighestPermissionInGroup(owner: string, group: Group<SCMType>): ADOUserPermission | null {
    let highestPermission: ADOUserPermission | null = null;
    for (const asset of group.resources) {
      const userPermissionToAsset = this.getUserPermissionLevelToAsset(owner, asset.org, asset.project, asset.repo);
      if (!userPermissionToAsset) {
        continue;
      }
      if (!highestPermission || ADO_PERMISSION_RANK[userPermissionToAsset] > ADO_PERMISSION_RANK[highestPermission]) {
        highestPermission = userPermissionToAsset;
      }
    }
    return highestPermission;
  }

  private getUserPermissionLevelToAsset(owner: string, org: string, proj?: string, repo?: string): ADOUserPermission | null {
    const orgId = generateResourceId(this.scmType, org);
    let highestPermission: ADOUserPermission | null = this.adoUsersAssetPermissionMap?.get(owner)?.get(orgId) ?? null;
    if (proj) {
      const projId = generateResourceId(this.scmType, org, proj);
      const projectPermission = this.adoUsersAssetPermissionMap?.get(owner)?.get(projId) ?? null;
      if (!highestPermission || (projectPermission && ADO_PERMISSION_RANK[projectPermission] > ADO_PERMISSION_RANK[highestPermission])) {
        highestPermission = projectPermission;
      }
    }
    if (repo) {
      const repoId = generateResourceId(this.scmType, org, proj, repo);
      const repoPermission = this.adoUsersAssetPermissionMap?.get(owner)?.get(repoId) ?? null;
      if (!highestPermission || (repoPermission && ADO_PERMISSION_RANK[repoPermission] > ADO_PERMISSION_RANK[highestPermission])) {
        highestPermission = repoPermission;
      }
    }
    return highestPermission;
  }

  private generateUserTotalIdentityMap(): void {
    const userItems = this.state.Assets.users[this.integrationType];
    if (!userItems) {
      return;
    }
    this.usersMap = userItems.mapBy((item) => item.username);
  }

  public get loading(): boolean {
    return this.usersMap === null;
  }

  public generateAllAssetsMap(): void {
    const resources = this.resources;
    const resourceItems = this.state.Assets.resources[this.integrationType];
    if (!resourceItems) {
      return;
    }
    for (const org of resourceItems) {
      for (const repo of org.repos) {
        const resource = { org: org.integrationOrgId, project: repo.projectName, repo: repo.repoName };
        resources
          .getOrSet(resource.org, () => new Map())
          .getOrSet(resource.project, () => new Map())
          .set(resource.repo, resource);
        this.assets.set(generateResourceIdFor.organization(org.integrationOrgId), { org: org.integrationOrgId });
        this.assets.set(generateResourceIdFor.project(org.integrationOrgId, repo.projectName), {
          org: org.integrationOrgId,
          project: repo.projectName
        });
        this.assets.set(generateResourceIdFor.repository(org.integrationOrgId, repo.projectName, repo.repoName), {
          org: org.integrationOrgId,
          project: repo.projectName,
          repo: repo.repoName
        });
      }
    }
  }

  public override async init(initGroupsState = true): Promise<void> {
    await super.init(initGroupsState);
    try {
      await Promise.all([
        this.state.Assets.loadResources(this.integrationType),
        this.state.Assets.loadUsers(this.integrationType)
      ]);
      this.generateUserTotalIdentityMap();
      this.generateAllAssetsMap();
    } catch (e) {
      console.error(e);
    }
  }

  public getOrgs(): string[] {
    return Array.from(this.resources.keys());
  }

  public getProjects(groupResource: GroupResource): string[] {
    return (groupResource.org && Array.from(this.resources.get(groupResource.org)?.keys() || [])) || [];
  }

  public getRepos(groupResource: GroupResource): string[] {
    return (groupResource.org && groupResource.project && Array.from(this.resources.get(groupResource.org)?.get(groupResource.project)?.keys() || [])) || [];
  }
}
