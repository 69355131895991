import type { AgentIntegrationType } from "../integration";
import type { IntegrationAttributes } from "./integration-attributes";

interface AgentIntegrationAttributes extends IntegrationAttributes {
  type: AgentIntegrationType;
  hasJobs: false;
}

export const AgentIntegrationAttributes = {
  "scanner-agent": {
    type: "scanner-agent",
    category: "agent",
    displayName: "Scanner Agent",
    hasJobs: false,
    includeMetadata: false,
    ui: {
      icon: "mdi-account-search-outline",
      reactIcon: "mdi:account-search-outline",
      editable: false,
      removable: true
    }
  }
} as const satisfies Record<AgentIntegrationType, AgentIntegrationAttributes>;
