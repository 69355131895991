
  <v-dialog class="component layout-component settings-dialog" v-model="$state.Layout.userMenu.preferences" max-width="600px" transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-card-title>
        <span class="text-h5">Settings</span>
      </v-card-title>
      <v-card-text>
        <v-switch v-model="$vuetify.theme.dark">
          <template v-slot:label>
            <v-icon>mdi-theme-light-dark</v-icon>

            Theme: {{ $vuetify.theme.dark ? "Dark" : "Light" }}
          </template>
        </v-switch>

        <v-switch v-model="$state.Layout.drawer.pinned">
          <template v-slot:label>
            <v-icon>mdi-pin</v-icon>

            Menu: {{ $state.Layout.drawer.pinned ? "Pinned" : "Unpinned" }}
          </template>
        </v-switch>
        <!--				<v-switch v-model="$state.Members.isSkipped">
					<template v-slot:label>
						<v-icon>mdi-account-plus</v-icon>

						Skip invite users: {{ $state.Members.isSkipped ? "Skip" : "Keep" }}
					</template>
				</v-switch>-->
        <v-switch v-model="onboardingDismissed">
          <template v-slot:label>
            <v-icon>mdi-account-plus</v-icon>

            Skip product tour: {{ onboardingDismissed ? "Skip" : "Keep" }}
          </template>
        </v-switch>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
