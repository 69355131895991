import { ApiBase } from "./api-base";
import { BillingSummaryResponse } from "@/interfaces/api/response/billing-summary-response";
import { Popup } from "@/state";

class PaymentApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "payment", name: "PaymentApi" });
  }

  public async createCheckoutSession(plan: string, duration: string, identities: number): Promise<string> {
    try {
      const state = window.btoa(Date.now().toString());
      window.sessionStorage.setItem(state, window.location.href);

      const res = await this.client.post<string>(`/checkout/session/${plan}/${duration}/${identities}`, null, {
        params: { state }
      });
      return res.data;
    } catch (e) {
      void Popup.error(String(e));
      throw e;
    }
  }

  public async createBillingPortalSession(): Promise<string> {
    try {
      const res = await this.client.post<string>(`/portal/session`, null, {
        params: {
          returnUrl: window.location.href
        }
      });
      return res.data;
    } catch (e) {
      void Popup.error(String(e));
      throw e;
    }
  }

  public async registerCallback(type: string, action: string) {
    try {
      const res = await this.client.post<void>(`/callback/${type}/${action}`);
      return res.data;
    } catch (e) {
      void Popup.error(String(e));
      throw e;
    }
  }

  public async getBillingSummary(): Promise<BillingSummaryResponse> {
    const summary = await this.client.get<BillingSummaryResponse>("/billing/summary");
    return summary.data;
  }
}

export const Payment = new PaymentApi();
