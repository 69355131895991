import { ApiBase } from "./api-base";
import { GetJobsResponseDto } from "$/interfaces/ui-api/response/get-jobs-response";

class JobsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "jobs", name: "JobsApi" });
  }

  public async verifyIntegrity(): Promise<void> {
    await this.client.post<void>("verify-integrity");
  }

  public async create(): Promise<void> {
    await this.client.post<void>("");
  }

  public async rerun(jobId: string): Promise<void> {
    await this.client.put<void>(jobId.encodeURI());
  }

  public async delete(jobId: string): Promise<void> {
    await this.client.delete<void>(jobId.encodeURI());
  }

  public async get(silent = false): Promise<GetJobsResponseDto[]> {
    const res = await this.client.get<GetJobsResponseDto[]>("", { silent });
    return res.data;
  }
}

export const Jobs = new JobsApi();
