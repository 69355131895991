import { ALL_SCM_TYPES, SCMType } from "$/scm-type";
import { IntegrationType, ScmIntegrationType } from "$/dynamo";

export type SCMTypeWithMetadata = {
  value: SCMType;
  text: string;
  icon: string;
};

export const SCM_TYPES_WITH_METADATA = ALL_SCM_TYPES.map(scmType => {
  return {
    value: scmType,
    text: IntegrationType.getDisplayName(ScmIntegrationType.fromSCMType(scmType)),
    icon: IntegrationType.getIcon(ScmIntegrationType.fromSCMType(scmType))
  };
});