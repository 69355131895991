<template>
  <v-container fluid class="component checkbox-dialog-content">
    <span class="subtitle">{{ subTitle }}</span>
    <v-text-field v-if="longList" clearable dense v-model.trim="search" label="Search" autofocus></v-text-field>
    <v-list dense ref="list">
      <v-list-item-group v-model="selectedItem">
        <v-data-table
          dense
          :headers="headers"
          :items="filteredOptions"
          :items-per-page="10"
          item-key="value"
          :footer-props="{
            'items-per-page-options': [10],
            'disable-items-per-page': true
          }">
          <template v-slot:header.value="">
            <v-checkbox dense v-model="allSelected" class="select-all-button" :indeterminate="getIndeterminate"></v-checkbox>
          </template>
          <template v-slot:item.value="{ item }">
            <v-checkbox class="checkbox-in-table" dense hide-details v-model="selected" :value="item"></v-checkbox>
          </template>

          <template v-for="(header, index) in headers" v-slot:[getSlotName(header.value)]="{ item }">
            <v-checkbox v-if="header.isCheckbox" :key="item + index" class="checkbox-in-table" dense hide-details v-model="selected" :value="item"></v-checkbox>
            <span v-else :key="item + index">{{ cellText(item, header) }} </span>
          </template>
          <template #footer.prepend>
            <v-spacer />
          </template>
        </v-data-table>
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import type { DataTableHeader } from "vuetify";

export interface SelectDialogArgs {
  value: unknown | null;
}

@Component
export default class CheckboxDialogContent extends Vue {
  protected page = 0;
  protected search: string | null = null;
  protected selected: unknown[] = [];
  protected allSelected = false;

  protected cellText(item: any, header: { value: keyof typeof item }) {
    return item[header.value];
  }
  protected getSlotName(slotName: string) {
    return `item.${slotName}`;
  }

  @Prop({
    type: Object,
    required: true
  })
  protected readonly args!: SelectDialogArgs;

  @Prop({
    type: Array,
    required: true
  })
  protected readonly options!: unknown[];

  @Prop({
    type: Function,
    required: true
  })
  protected readonly resolve!: (option: SelectDialogArgs) => void;

  @Prop({
    type: Array,
    required: true
  })
  protected readonly headers!: DataTableHeader[];

  @Prop({
    type: String,
    required: true
  })
  protected readonly subTitle!: string;

  @Watch("allSelected")
  protected onSelectedChange(): void {
    if (this.allSelected) {
      this.selected = this.filteredOptions;
    } else {
      this.selected = this.selected.filter((s) => !this.filteredOptions.some((o) => o === s));
    }
  }

  @Watch("selected")
  protected onSelected(): void {
    if (this.selected.length === 0) {
      this.allSelected = false;
    }
    if (this.selected.length === this.filteredOptions.length) {
      this.allSelected = true;
    }
  }

  public created(): void {
    this.selected = this.args.value ? this.options.filter((o) => this.args.value.includes(o)) : [];
  }

  protected get getIndeterminate(): boolean {
    return this.selected.length > 0 && this.selected.length < this.filteredOptions.length;
  }

  protected get longList(): boolean {
    return this.options.length > 10;
  }

  protected get filteredOptions(): unknown[] {
    const search = (this.search || "").toLowerCase();

    return search
      ? this.options.filter((o) => this.headers.some((header) => (o[header.value].toLowerCase ? o[header.value].toLowerCase().includes(search) : false)))
      : this.options;
  }

  protected get selectedItem(): number {
    const value = this.args.value;
    return this.filteredOptions.findIndex((o) => o === value);
  }

  protected set selectedItem(value: number) {
    this.args.value = this.filteredOptions[value];
  }

  public async mounted(): Promise<void> {
    await this.$nextTick();
    const list = this.$refs["list"] as Vue;
    const el = list.$el;
    if (el) {
      this.focusSelected(el);
    }
  }

  private focusSelected(el: Element): void {
    const item = el.querySelector(".v-list-item.v-item--active.v-list-item--active") as HTMLElement;
    item?.focus();
  }

  @Watch("selected")
  protected onUpdate(): void {
    this.args.value = this.selected;
  }
}
</script>

<style lang="scss">
.component.checkbox-dialog-content {
  .subtitle {
    margin: 0 12px 12px 12px;
    display: block;
    max-width: 600px;
  }
  .select-all-button {
    margin: 0;
    & > div {
      height: 24px;
    }
  }
  .checkbox-in-table {
    margin-top: -3px;
  }
}
</style>
