import Vue from "vue";
import { RawLocation } from "vue-router";
import { AxiosUserException } from "@/errors/axios-user-exception";

export interface PopupOptions {
  text: string;
  /**
   * Time in milliseconds before message automatically closes.
   */
  timeout: number;
  color: string;
  /** Indicates the message should be treated as HTML. **NEVER USE FOR USER INPUT** */
  html: boolean;
  route?: RawLocation;
  onClose?: () => void;
}

type PartialPopupOptions = Partial<Omit<PopupOptions, "text">>;

const DEFAULTS: PopupOptions = {
  text: "",
  color: "primary",
  html: false,
  timeout: 4000
};

function onCloseFactory(...callbacks: (undefined | (() => void))[]) {
  return () => {
    for (const cb of callbacks) {
      cb?.();
    }
  };
}

class PopupState {
  private _message: PopupOptions = { ...DEFAULTS };

  public constructor() {
    Vue.observable(this);
  }

  public get INDEFINITELY() {
    return Infinity;
  }

  public get message() {
    return this._message;
  }

  public clear() {
    this._message = { ...DEFAULTS };
  }

  public pop(options: PopupOptions): Promise<void> {
    return new Promise((resolve) => {
      const onClose = onCloseFactory(resolve, options.onClose);
      this._message = { ...DEFAULTS, ...options, onClose };
    });
  }

  public async info(text: string, options?: PartialPopupOptions): Promise<void> {
    console.log(text);
    return await this.pop({
      ...DEFAULTS,
      ...options,
      text
    });
  }

  public async warn(text: string, options?: PartialPopupOptions): Promise<void> {
    console.warn(text);
    return await this.pop({
      ...DEFAULTS,
      color: "warning",
      ...options,
      text
    });
  }

  public async error(text: string, options?: PartialPopupOptions & { error?: unknown }): Promise<void> {
    const errorMessage = Error.tryResolveMessage(options?.error);
    if (errorMessage) {
      text += `: ${errorMessage}`;
    }

    console.error(text);
    return await this.pop({
      ...DEFAULTS,
      color: "error",
      ...options,
      text
    });
  }

  /**
   * Automatically handles errors by displaying them in a popup.
   * @deprecated Use `Promise.prototype.autoHandleError()` instead.
   * @example
   * // instead of:
   * try {
   *   return await somePromise;
   * } catch (e) {
   *   void Popup.autoHandleError(e);
   *   return;
   * }
   *
   * // use:
   * return await somePromise.autoHandleError();
   */
  public async autoHandleError(error: unknown): Promise<void> {
    const userException = AxiosUserException.tryCreate(error);
    if (!userException) {
      return this.error("Oops, something went wrong please try again later.");
    }
    const text = [...userException.message.split("\n").map((line) => `<span>${line}</span>`), userException.id ? `<sub>Error ID: ${userException.id}</sub>` : null]
      .nonNullable()
      .join("<br/>");
    console.error(userException.id, userException.statusCode, userException.error, userException.message, error);
    await this.pop({
      ...DEFAULTS,
      color: "error",
      html: true,
      text,
      timeout: this.INDEFINITELY
    });
  }
}

export const Popup = new PopupState();

declare global {
  interface Promise<T> {
    /**
     * Automatically handles errors by displaying them in a popup.
     * @note same as:
     * @example
     * try {
     *   return await somePromise;
     * } catch (e) {
     *   void Popup.autoHandleError(e);
     *   return defaultValue;
     * }
     */
    autoHandleError<D = void>(defaultValue?: D): Promise<T | D>;
  }
}

Promise.prototype.autoHandleError ||= async function autoHandleError<T, D = void>(this: Promise<T>, defaultValue?: D): Promise<T | D> {
  try {
    return await this;
  } catch (error) {
    void Popup.autoHandleError(error);
    return defaultValue as D;
  }
};
