import { PolicyItemType } from "../policy-item-base";
import { PolicyTrigger, PolicyTriggerSubType } from "./policy-trigger";

declare module "./policy-trigger" {
  enum PolicyTriggerSubType {
    detected_on_scan = "detected_on_scan"
  }
}

(PolicyTriggerSubType as { detected_on_scan: "detected_on_scan" }).detected_on_scan = "detected_on_scan";

export interface PolicyTriggerDetectedOnScan extends PolicyTrigger {
  sub: PolicyTriggerSubType.detected_on_scan;
}
export const PolicyTriggerDetectedOnScan = {
  component: {
    type: PolicyItemType.trigger,
    sub: PolicyTriggerSubType.detected_on_scan
  },
  generateDefault(): PolicyTriggerDetectedOnScan {
    return {
      type: PolicyItemType.trigger,
      sub: PolicyTriggerSubType.detected_on_scan,
      id: `${PolicyItemType.trigger}-${Math.floor(Date.now() * Math.random())}`
    };
  }
} as const;
