import { ApiBase } from "./api-base";
import { PolicyItem, PolicyItemOptions, PolicyRule, PolicyRuleItemKey, PolicySubType } from "$/interfaces/policies";

class PolicyV2Api extends ApiBase {
  public constructor() {
    super({ pathPrefix: "policies-v2", name: "PolicyV2Api" });
  }

  /**
   * Gets a list of supported policies for this tenant
   */
  public async get(): Promise<PolicySubType[]>;

  /**
   * Gets a specific policy for this tenant
   * @param subType The policy subtype to get
   */
  public async get(subType: PolicySubType): Promise<PolicyItem>;

  public async get(subType?: PolicySubType): Promise<PolicyItem | PolicySubType[]> {
    const res = await this.client.get<PolicyItem | PolicySubType[]>(subType ? subType : "");
    return res.data;
  }

  /**
   * Saves a policy
   * @param policy The policy to save
   * @returns The saved policy
   */
  public async save(policy: PolicyItem): Promise<PolicyItem> {
    const res = await this.client.put<PolicyItem>(`${policy.sub.encodeURI()}/${policy.id.encodeURI()}`, policy);
    return res.data;
  }

  /**
   * Resets a policy to its default state
   * @param policy The policy to reset
   * @returns The reset policy
   */
  public async reset(policy: PolicyItem): Promise<PolicyItem> {
    const res = await this.client.request<PolicyItem>({
      method: "DELETE",
      url: `${policy.sub.encodeURI()}/${policy.id.encodeURI()}`,
      data: policy
    });
    return res.data;
  }

  /**
   * Import a policy YAML
   * @param policy The policy YAML to import
   * @returns The imported policy
   */
  public async import(policyYaml: string, sub: PolicySubType, id: string): Promise<PolicyItem> {
    const res = await this.client.put<PolicyItem>(`import/${sub.encodeURI()}/${id.encodeURI()}`, policyYaml, { headers: { "Content-Type": "text/yaml" } });
    return res.data;
  }

  /**
   * Validates a policy
   * @param policy The policy to validate
   */
  public async validate(policy: PolicyItem): Promise<string | true> {
    const res = await this.client.post<string | true>(`validation/${policy.sub.encodeURI()}/${policy.id.encodeURI()}`, policy);
    return res.data;
  }

  /**
   * Get a list of supported options for a rule's components, for this tenant
   * @param policy The policy that contains the rule
   * @param rule The rule to get the options for
   * @param itemKey The component type to get options for
   * @param sub The option subtype
   * @returns A list of supported policy components
   */
  public async getTypeOptions<T extends PolicyRuleItemKey>(policy: PolicyItem, rule: PolicyRule, itemKey: T, sub?: string | null): Promise<PolicyItemOptions[T][]> {
    const res = await this.client.post<PolicyItemOptions[T][]>(`options/${policy.sub.encodeURI()}/rules/${rule.id.encodeURI()}/${itemKey.encodeURI()}`, policy, {
      params: { sub }
    });
    return res.data;
  }
}

export const PolicyV2 = new PolicyV2Api();
