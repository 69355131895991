import Vue from "vue";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});

export function currencyFilter(value: number): string {
  if (typeof value !== "number") {
    return value;
  } else if (Number.isNaN(value)) {
    return "-";
  }
  return formatter.format(value);
}

Vue.filter("currency", currencyFilter);
