import { ApiBase } from "./api-base";
import { ConfigFlags } from "@arnica-internal/configs";
import "$/utility-types/keyed-object";

class FlagsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "flags", name: "FlagsApi" });
  }

  /**
   * Gets a specific flag value for the current/general organization.
   * @param flag Flag name
   * @returns Value of the flag or `null` if the flag is not set
   */
  public async tryGetFlag<T extends keyof ConfigFlags>(flag: T): Promise<ConfigFlags[T] | null> {
    try {
      const res = await this.client.get<ConfigFlags[T] | null>(encodeURIComponent(flag));
      const data = res.data;
      return typeof data === "string" && data.length === 0 ? null : data;
    } catch (e) {
      return null;
    }
  }

  /**
   * Gets a list of flag values for the current/general organization.
   * @param flags Flags to resolve
   * @returns Object with flag names as keys and flag values as values (or `null` if the flag is not set)
   */

  public async getFlags<T extends readonly (keyof ConfigFlags)[]>(...flags: T): Promise<NullableKeyedObject<T, ConfigFlags>> {
    try {
      const res = await this.client.get<NullableKeyedObject<T, ConfigFlags>>("", {
        params: Object.fromEntries(flags.map((f) => [f, ""]))
      });
      return res.data;
    } catch (e) {
      return Object.fromEntries(flags.map((f) => [f, null])) as NullableKeyedObject<T, ConfigFlags>;
    }
  }
}

export const Flags = new FlagsApi();
