import { JobsV2 } from "@/state";
import { FlagCheck } from "@/plugins/route-guards/sidebar-routes";

export class InventoryV2IsEnabledAndCompletedCheck extends FlagCheck<"jobsV2"> {
  public static readonly isTrue = new InventoryV2IsEnabledAndCompletedCheck();
  public static readonly isFalse = new InventoryV2IsEnabledAndCompletedCheck({ invert: true });

  public constructor(
    private readonly options?: {
      /**
       * Denotes if the result be should be inverted.
       * @default false
       */
      invert?: boolean;
    }
  ) {
    super("jobsV2", (f) => !!f?.inventoryV2);
  }

  protected override async isEnabled(): Promise<boolean> {
    const [flagEnabled, jobComplete] = await Promise.all([super.isEnabled(), JobsV2.inventoryScan.isComplete()]);
    const enabled = flagEnabled && jobComplete;
    const invert = this.options?.invert || false;
    return invert ? !enabled : enabled;
  }
}
