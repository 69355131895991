import Vue from "vue";
import { GetPermissionGrantResponse } from "$/interfaces/ui-api/response/get-permission-grant-response";
import { Permissions } from "@/api";

export const PermissionGrants = new (class PermissionGrantsState {
  public permissionGrants: GetPermissionGrantResponse[] | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public async init() {
    this.permissionGrants = await Permissions.get();
  }
})();
