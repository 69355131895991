
<v-snackbar app top class="component layout-component snackbar" v-model="snackbar" :timeout="timeout" :color="$state.Popup.message.color">
  <div v-if="$state.Popup.message.html" class="html-container" v-html-sanitize="$state.Popup.message.text" :key="id"></div>
  <div v-else class="line-container">
    <span class="text-content" v-for="(line, idx) of lines" :key="idx">{{ line }}</span>
  </div>

  <template v-slot:action="{ attrs }">
    <v-btn class="reset-color" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
    <v-btn class="reset-color" text v-if="$state.Popup.message.route" @click="goToRoute()">View</v-btn>
  </template>
</v-snackbar>
