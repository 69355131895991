export enum TaskEnvironment {
  /**
   * No special requirements.
   */
  none = 0,

  /**
   * Task is long running.
   */
  longRunning = 1 << 0,

  // /**
  //  * Task requires large memory.
  //  */
  // largeMem = 1 << 1,
  //
  // /**
  //  * Task requires large storage.
  //  */
  // largeStorage = 1 << 2
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TaskEnvironment {
  const ALL_FLAGS = TaskEnvironment.longRunning;
  
  export function has(value: TaskEnvironment, flag: TaskEnvironment): boolean {
    return (value & flag) === flag;
  }

  export function toArray(value: TaskEnvironment): (keyof PickPropertiesImplementing<typeof TaskEnvironment, number>)[] {
    const flags = Object.values(TaskEnvironment)
      .filter((f): f is TaskEnvironment => typeof f === "number" && f !== TaskEnvironment.none && TaskEnvironment.has(value, f))
      .map((f) => TaskEnvironment[f]);
    return (flags.length ? flags : [TaskEnvironment[TaskEnvironment.none]]) as (keyof PickPropertiesImplementing<typeof TaskEnvironment, number>)[];
  }

  export function is(value: unknown): value is TaskEnvironment {
    return typeof value === "number" && (ALL_FLAGS & value) === value;
  }
  Object.hideFunctions(TaskEnvironment);
}
