import { FindingBase } from "../../../../dynamo/findingBase";
import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    blame_property = "blame_property"
  }
}

(PolicyConditionSubType as { blame_property: "blame_property" }).blame_property = "blame_property";

export interface PolicyConditionFindingBlameProperty extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.blame_property;
  /**
   * List of regular expressions to match against the finding's Git Blame properties.
   */
  matchers: string[];
  property: keyof typeof PolicyConditionFindingBlameProperty.PROPERTY_EXTRACTORS;
}

export const PolicyConditionFindingBlameProperty = {
  PROPERTY_EXTRACTORS: {
    // "Author's Login": (c) => c?.author?.login, // Not available in the context
    "Author's Email": (c) => c?.author?.email ?? undefined,
    "Author's Name": (c) => c?.author?.name ?? undefined,
    // "Committer's Login": (c) => c?.committer?.login, // Not available in the context
    "Committer's Email": (c) => c?.committer?.email ?? undefined,
    "Committer's Name": (c) => c?.committer?.name ?? undefined,
    "Pusher's User ID": (c) => c?.pusher?.login,
    "Pusher's Email": (c) => c?.pusher?.email ?? undefined,
    "Pusher's Name": (c) => c?.pusher?.name ?? undefined,
    "Commit Message": (c) => c?.commitDetails?.message ?? undefined
  } as const satisfies Record<string, (context: FindingBase["context"]) => string | undefined>,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.blame_property
  } as const
} as const;
