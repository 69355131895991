import { ApiBase } from "./api-base";
import { GetPermissionGrantResponse } from "$/interfaces/ui-api/response/get-permission-grant-response";

class PermissionsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "permission-grant", name: "PermissionsApi" });
  }

  public async get(): Promise<GetPermissionGrantResponse[]> {
    const res = await this.client.get<GetPermissionGrantResponse[]>("");
    return res.data;
  }
}

export const Permissions = new PermissionsApi();
