
<v-app>
  <overlay-simple-message
    @close="showOverlay.item = false"
    v-if="showOverlay.item && isMobile"
    button-text="Proceed Anyways"
    title="Mobile View Not Supported"
    text="This site is best experienced on a higher resolution device.">
  </overlay-simple-message>

  <template v-else>
    <loading-centered v-if="!$state.Layout.appLoaded"></loading-centered>
    <template v-else-if="showFullAppBar">
      <app-bar></app-bar>
      <navigation-drawer></navigation-drawer>

      <v-main :expand-left="!$state.Layout.drawer.show">
        <v-container fluid class="full-height app-container-padding">
          <v-slide-x-transition mode="out-in">
            <router-view />
          </v-slide-x-transition>
        </v-container>
      </v-main>

      <!-- <app-footer></app-footer> -->

      <snack-bar></snack-bar>
      <action-dialog></action-dialog>
      <tooltip></tooltip>
    </template>
    <template v-else>
      <app-bar></app-bar>
      <router-view />
    </template>
  </template>
</v-app>
