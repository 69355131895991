import axios from "axios";
import { BusinessImportanceReportItem } from "$/business-importance";
import { InferenceResponse } from "$/dynamo/inference";
import { ERROR_REPORT_NOT_READY } from "$/interfaces/ui-api/constants/error-codes";
import { ErrorResponse } from "$/interfaces/ui-api/response/error-response";
import { GetSecretsReportResponse } from "$/interfaces/ui-api/response/get-secrets-response";
import { DeoptimizedADORiskReport, OptimizedADORiskReport, deoptimizeADOReport, isOptimizedADOReport } from "$/report/ado/ado-report-utils";
import { GitResourceBase } from "$/report/interfaces/git-resource-base";
import {
  ADOExcessiveLicensesInventoryReport,
  ADOInventoryReportQueryType,
  ADOTotalIdentitiesInventoryReport,
  ADOTotalRepositoriesInventoryReport,
  GitHubAppInventoryReport,
  GitHubCodeownerErrorsInventoryReport,
  GitHubInactiveIdentitiesInventoryReport,
  GitHubInventoryReportQueryType,
  GitHubRepositoriesBranchProtectionInventoryReport,
  GitHubTotalIdentitiesInventoryReport,
  GitHubTotalRepositoriesInventoryReport,
  InventoryReport,
  InventoryReportItem,
  InventoryReportQueryType,
  UniversalScmInventoryReportQueryType,
  UniversalScmRepositoriesInventoryReport,
  UniversalScmUsersInventoryReport
} from "$/report/inventory-report/inventory-report";
import { ADORiskReport, ADORiskReportQueryType, GitHubRiskReportQueryType, RiskReport, RiskReportType } from "$/report/risk-report/risk-report";
import { SBOMNode } from "$/sbom/interfaces/sbom-nodes";
import { SBOMReportResponse } from "$/sbom/interfaces/sbom-report-item";
import { SCMType } from "$/scm-type";
import { ApiBase, BaseRequestParams } from "@/api/api-base";

type OpsMap = {
  secrets: GetSecretsReportResponse | null;
  inference: InferenceResponse[] | null;
  sbom: SBOMReportResponse | null;

  "business-importance": BusinessImportanceReportItem[] | null;
};

type ReportNames = keyof OpsMap;
type RiskReportResponse = RiskReport<RiskReportType> | null | ErrorResponse;

/**
 * These reports will not be loaded as they are never used
 */
export const EXCLUDED_INVENTORY_REPORTS = new Set<InventoryReportQueryType>([GitHubInventoryReportQueryType.GH_USER_REPO_PERMISSION]);

export class GetReportError extends Error {
  public constructor(msg: string, public errors: string[]) {
    super(msg);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, GetReportError.prototype);
  }
}

class ReportsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "report", name: "ReportsApi" });
  }

  public get GetReportError() {
    return GetReportError;
  }

  public async get<T extends ReportNames>(name: T, days?: number, silent = false): Promise<OpsMap[T]> {
    const res = await this.client.get<OpsMap[T]>(name, { params: { days }, silent });
    return res.data;
  }

  public async getSBOMReport(): Promise<SBOMReportResponse | null> {
    const response = await this.get("sbom");
    return response ?? null;
  }

  public async getBusinessImportanceReport(): Promise<BusinessImportanceReportItem[] | null> {
    const response = await this.get("business-importance");
    return response ?? null;
  }

  public async getSBOMReportDetails(reportItemKey: GitResourceBase, forDownload?: false | undefined, format?: "json" | "csv"): Promise<SBOMNode[] | null>;
  public async getSBOMReportDetails(reportItemKey: GitResourceBase, forDownload: true, format: "json" | "csv"): Promise<string | null>;
  public async getSBOMReportDetails(reportItemKey: GitResourceBase, forDownload = false, format: "json" | "csv" = "json"): Promise<SBOMNode[] | string | null> {
    try {
      const response = await this.client.get<SBOMNode[] | string | null>("sbom/details", {
        params: {
          ...reportItemKey,
          forDownload,
          format
        }
      });
      return response.data ?? null;
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        return [];
      }
      throw e;
    }
  }

  public async getAllRisks(params: BaseRequestParams = {}): Promise<RiskReport<RiskReportType>[]> {
    const promises: Promise<RiskReport<RiskReportType> | null | ErrorResponse>[] = [];
    const promiseDescriptions = [];
    for (const scmType in SCMType) {
      if (scmType === SCMType.GITHUB) {
        for (const queryType of Object.values(GitHubRiskReportQueryType)) {
          promises.push(this.getRisk(queryType, params));
          promiseDescriptions.push(`SCM Type: ${scmType}, Report Type: ${queryType}`);
        }
      } else if (scmType === SCMType.AZURE_DEVOPS) {
        for (const queryType of Object.values(ADORiskReportQueryType)) {
          promiseDescriptions.push(`SCM Type: ${scmType}, Report Type: ${queryType}`);
          promises.push(this.getRisk(queryType, params));
        }
      }
    }

    const promiseResults = await Promise.allSettled(promises);
    const ret: RiskReport<RiskReportType>[] = [];
    const errors: string[] = [];
    // using for instead of filter and map because of better type inference and no need for a type guard etc.
    for (let i = 0; i < promiseResults.length; i++) {
      const promiseResult = promiseResults[i];
      if (promiseResult?.status === "fulfilled") {
        const value = promiseResult.value;
        if (!value) {
          continue;
        }
        if ("error" in value) {
          errors.push(value.error);
          console.warn(value.error);
        } else {
          ret.push(value);
        }
      } else {
        const reason = promiseResult?.reason ?? "unknown";
        //TODO: retry logic etc... and correlate the failed reasons to the promise
        if (typeof reason === "object") {
          const errorBody = reason?.response?.data as ErrorResponse | undefined;
          const errorCode = errorBody?.code;
          const errorMessage = errorBody?.error;
          if (errorCode === ERROR_REPORT_NOT_READY) {
            //TODO: add context (e.g. which report this error is for)

            errors.push(`Query: [${promiseDescriptions[i]}] had an error: ${errorMessage}`);
          }
        } else {
          errors.push(reason);
          console.warn(reason);
        }
      }
    }
    if (errors.length > 0) {
      throw new GetReportError("Error getting some of the report queries", errors);
    }
    return ret;
  }

  //TODO: fix the mess with these interfaces, add type guards etc etc...
  public async getRisk(reportType: RiskReportType, params: BaseRequestParams = {}): Promise<RiskReportResponse> {
    try {
      const response = await this.client.get<RiskReportResponse>(`risk/${reportType}`, params);
      return response.data ?? null;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return {} as RiskReportResponse;
      }
      throw error;
    }
  }

  public async getRiskByProjectId(
    reportType: ADORiskReportQueryType,
    integrationOrgIdAndType: string,
    projectId: string,
    baseRequestParams: BaseRequestParams = {},
    optimize = true
  ): Promise<ADORiskReport | DeoptimizedADORiskReport | null> {
    try {
      //for testing purposes
      //const report = (await import("../assets/report-compressed.json")).default as unknown as OptimizedADORiskReport;

      const response = await this.client.get<ADORiskReport | OptimizedADORiskReport | null>(`risk/${reportType}/${integrationOrgIdAndType.encodeURI()}/${projectId.encodeURI()}`, {
        ...baseRequestParams,
        params: { optimize }
      });
      const report = response.data ?? null;

      if (isOptimizedADOReport(report)) {
        return deoptimizeADOReport(report);
      }
      //Layout.loading = false;
      return report;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return null;
      }
      throw error;
    }
  }

  public async getInventory(queryType: GitHubInventoryReportQueryType.GH_TOTAL_IDENTITIES, params: BaseRequestParams): Promise<GitHubTotalIdentitiesInventoryReport | null>;
  public async getInventory(queryType: GitHubInventoryReportQueryType.GH_TOTAL_REPOSITORIES, params: BaseRequestParams): Promise<GitHubTotalRepositoriesInventoryReport | null>;
  public async getInventory(queryType: GitHubInventoryReportQueryType.GH_INACTIVE_IDENTITIES, params: BaseRequestParams): Promise<GitHubInactiveIdentitiesInventoryReport | null>;
  public async getInventory(
    queryType: GitHubInventoryReportQueryType.GH_REPOSITORIES_NO_BRANCH_PROTECTION,
    params: BaseRequestParams
  ): Promise<GitHubRepositoriesBranchProtectionInventoryReport | null>;
  public async getInventory(queryType: GitHubInventoryReportQueryType.GH_APP, params: BaseRequestParams): Promise<GitHubAppInventoryReport | null>;
  public async getInventory(queryType: GitHubInventoryReportQueryType.GH_CODEOWNER_ERRORS, params: BaseRequestParams): Promise<GitHubCodeownerErrorsInventoryReport | null>;
  public async getInventory(queryType: ADOInventoryReportQueryType.ADO_TOTAL_IDENTITIES, params: BaseRequestParams): Promise<ADOTotalIdentitiesInventoryReport | null>;
  public async getInventory(queryType: ADOInventoryReportQueryType.ADO_TOTAL_REPOSITORIES, params: BaseRequestParams): Promise<ADOTotalRepositoriesInventoryReport | null>;
  public async getInventory(queryType: ADOInventoryReportQueryType.ADO_EXCESSIVE_LICENSES, params: BaseRequestParams): Promise<ADOExcessiveLicensesInventoryReport | null>;
  public async getInventory(
    queryType: UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_REPOSITORIES,
    params: BaseRequestParams
  ): Promise<UniversalScmRepositoriesInventoryReport | null>;
  public async getInventory(queryType: UniversalScmInventoryReportQueryType.UNIVERSAL_SCM_USERS, params: BaseRequestParams): Promise<UniversalScmUsersInventoryReport | null>;
  public async getInventory(queryType: InventoryReportQueryType, params: BaseRequestParams): Promise<InventoryReport<InventoryReportItem> | null>;
  public async getInventory(queryType: InventoryReportQueryType, params: BaseRequestParams = {}): Promise<InventoryReport<InventoryReportItem> | null> {
    if (EXCLUDED_INVENTORY_REPORTS.has(queryType)) {
      return null;
    }
    try {
      const response = await this.client.get<InventoryReport<InventoryReportItem>>(`inventory/${queryType}`, params);
      return response.data || null;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return {} as InventoryReport<InventoryReportItem>;
      }
      throw error;
    }
  }

  //TODO: Refactor with getRiskReports()
  public async getInventoryReports(params: BaseRequestParams = {}): Promise<InventoryReport<InventoryReportItem>[]> {
    const promises: Promise<InventoryReport<InventoryReportItem> | null | ErrorResponse>[] = [];
    const promiseDescriptions = [];

    for (const queryType in InventoryReportQueryType) {
      promises.push(this.getInventory(queryType as InventoryReportQueryType, params));
      promiseDescriptions.push(`Report Type: ${queryType}`);
    }

    //TODO: duplicate code from getRiskReports:

    const promiseResults = await Promise.allSettled(promises);
    const ret: InventoryReport<InventoryReportItem>[] = [];
    const errors: string[] = [];
    // using for instead of filter and map because of better type inference and no need for a type guard etc.
    for (let i = 0; i < promiseResults.length; i++) {
      const promiseResult = promiseResults[i];
      if (promiseResult?.status === "fulfilled") {
        const value = promiseResult.value;
        if (!value) {
          continue;
        }
        if ("error" in value) {
          errors.push(value.error);
          console.warn(value.error);
        } else {
          ret.push(value);
        }
      } else {
        const reason = promiseResult?.reason ?? "unknown";
        //TODO: retry logic etc... and correlate the failed reasons to the promise
        if (typeof reason === "object") {
          const errorBody = reason?.response?.data as ErrorResponse;
          const errorCode = errorBody?.code;
          const errorMessage = errorBody?.error;
          if (errorCode === ERROR_REPORT_NOT_READY) {
            //TODO: add context (e.g. which report this error is for)
            errors.push(`Query: [${promiseDescriptions[i]}] had an error: ${errorMessage}`);
          }
        } else {
          errors.push(reason);
          console.warn(reason);
        }
      }
    }
    if (errors.length > 0) {
      throw new GetReportError("Error getting some of the report queries", errors);
    }
    return ret;
  }
}

export const Reports = new ReportsApi();
