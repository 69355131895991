import { User as Auth0User } from "@auth0/auth0-spa-js";
// import { ARNICA_ADMINS_GROUP_NAME } from "$/constants";
import { GetUserInfoResponse } from "$/interfaces/ui-api/response/get-user-info-response";
import { Scope } from "@/interfaces/scope";

const GROUPS_CLAIM_NAME = "http://arnica.io/claims/groups";
const ROLES_CLAIM_NAME = "http://arnica.io/claims/roles";
export class User extends Auth0User {
  private _scopes: PartialRecord<Scope, true | undefined> = {};
  public readonly groups: string[];
  public readonly roles: string[];

  public constructor(auth0User: Auth0User, private _userInfo?: GetUserInfoResponse<Scope>) {
    // get user claims from the id token
    super();
    Object.assign(this, auth0User);
    this.groups = this[GROUPS_CLAIM_NAME] ?? [];
    this.roles = this[ROLES_CLAIM_NAME] ?? [];
    this.userInfo = _userInfo;
  }

  public get scopes(): PartialRecord<Scope, true | undefined> {
    return this._scopes;
  }

  public get userInfo(): GetUserInfoResponse<Scope> | undefined {
    return this._userInfo;
  }

  public set userInfo(userInfo: GetUserInfoResponse<Scope> | undefined) {
    this._userInfo = userInfo;
    this._scopes = Object.fromEntries((userInfo?.scopes || []).map((s) => [s, true])) || {};
  }

  public get isEmailVerified(): boolean {
    return !!(this.email && (this.email_verified || this.sub?.startsWith("samlp|")));
  }

  public get public(): boolean {
    return !!this.userInfo?.public;
  }

  public get registered(): boolean {
    return !!this.scopes.registered;
  }
}
