import { router } from "@/plugins";
import { Popup } from "@/state/popup-state";
import Vue from "vue";

const ErrorState = new (class ErrorState {
  private _message: string | null = null;
  private _details: string | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public get message() {
    return this._message;
  }

  public get details() {
    return this._details;
  }

  public async show(error: unknown, navigate = true) {
    console.warn(error);
    if (error instanceof Error) {
      this._message = error.message;
      this._details = error.stack ?? null;
    } else if (typeof error === "string") {
      this._message = error;
      this._details = null;
    } else if (error && typeof error === "object" && "name" in error && error.name) {
      if (error.name === "NetworkError") {
        await Popup.error("Network error");
        return;
      }
      this._message = String(error.name);
      this._details = null;
    } else {
      await this.clear();
    }
    if (navigate) {
      return await this.navigate("error");
    }
  }

  public async clear() {
    this._message = this._details = null;
    return await this.navigate("home");
  }

  private async navigate(page: string) {
    if (router.currentRoute.name === page) {
      return;
    }
    await router.push({ name: page });
  }
})();

export { ErrorState as Error };
