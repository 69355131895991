import Router, { Route, RouteRecord } from "vue-router";
import { Layout } from "@/state";

declare module "vue-router/types/router" {
  export interface RouteMeta {
    /**
     * Indicates the title of the route.
     */
    title?: string;

    /**
     * Indicates the icon of the route.
     */
    icon?: string;

    /**
     *  A forward icon
     */
    frontIcon?: { icon: string; tooltip?: string };
  }
}

function resolveRecordTitle(record: RouteRecord): string | undefined {
  return record.meta?.["title"];
}

function titledRoutes(to: Route): void {
  const title = to.matched.map(resolveRecordTitle).nonNullable().join(" │ ");
  Layout.title = title ?? null;
}

export function install(router: Router): Router {
  router.afterEach(titledRoutes);
  return router;
}
