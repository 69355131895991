
<v-overlay class="component overlay-simple-message text-center" :value="true">
  <v-container>
    <slot name="header"></slot>
    <slot>
      <v-row>
        <v-col>
          <h3 class="text-uppercase">{{ title }}</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>{{ text }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="primary" @click="close()"> {{ buttonText }}</v-btn>
        </v-col>
      </v-row>
    </slot>
    <slot name="footer"></slot>
  </v-container>
</v-overlay>
