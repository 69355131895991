import { ScmIntegrationCallbackHandlerBase } from "./scm-integration-callback-handler-base";
import { IntegrationType } from "$/dynamo";
import { Integrations } from "@/api";
import { router } from "../../../plugins";

const CALLBACK_PATH = "/callback/ado";
const CODE_PARAM = "code";
const STATE_PARAM = "state";
const ERROR_PARAM = "error";

const ERRORS: Record<string, string> = {
  access_denied: "Access denied to Azure DevOps, please try again."
} as const;

export class AdoCallbackHandler extends ScmIntegrationCallbackHandlerBase {
  public static get path(): string {
    return CALLBACK_PATH;
  }

  protected async createIntegration(query: URLSearchParams): Promise<IntegrationType> {
    try {
      const error = query.get(ERROR_PARAM);
      if (error) {
        throw new Error(ERRORS[error] ?? error);
      }

      const code = query.get(CODE_PARAM);
      const state = query.get(STATE_PARAM);
      if (!code || !state) {
        throw new Error("Invalid callback");
      }

    await Integrations.create({
      type: "azure-devops",
      code,
      state
    });

      return "azure-devops";
    } finally {
      window.history.replaceState(null, "", window.location.origin);
      await router.push({ name: "admin-integrations" });
    }
  }
}
