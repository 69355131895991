import { ApiBase } from "./api-base";

class StorageApi extends ApiBase {
  public constructor() {
    super({
      name: "Storage",
      pathPrefix: "storage"
    });
  }

  public async getSignedUrl(bucket: string, key: string): Promise<URL> {
    const res = await this.client.post<string>(`download/sign/${bucket}/${key}`);
    return new URL(res.data);
  }
}

export const Storage = new StorageApi();
