import { LineRange, ScmUrlGenerator } from "../../data-access/src/scm-url-generator";

export class BitbucketCloudUrlGenerator implements ScmUrlGenerator {
  public getOrganizationUrl(organizationId: string): string {
    return `https://bitbucket.org/${organizationId.encodeURI()}/workspace/overview/`;
  }

  public getProjectUrl(organizationId: string, projectId: string): string {
    return `https://bitbucket.org/${organizationId.encodeURI()}/workspace/projects/${projectId.encodeURI()}`;
  }

  public getRepositoryUrl(organizationId: string, repositoryId: string): string {
    return `https://bitbucket.org/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/`;
  }

  public getRepositoryBranchUrl(organizationId: string, repositoryId: string, branch: string): string {
    return `https://bitbucket.org/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/branch/${branch.encodeURI()}`;
  }

  public getRepositoryCommitUrl(organizationId: string, repositoryId: string, commitSha: string): string {
    return `https://bitbucket.org/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/commits/${commitSha.encodeURI()}`;
  }

  public getRepositoryBranchFileUrl(organizationId: string, repositoryId: string, branch: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, branch, path, lines);
  }
  public getRepositoryCommitFileUrl(organizationId: string, repositoryId: string, commitSha: string, path: string, lines?: LineRange): string {
    return this.getRepositoryFileUrl(organizationId, repositoryId, commitSha, path, lines);
  }
  public getRepositoryFileUrl(organizationId: string, repositoryId: string, commitReference: string, path: string, lines?: LineRange): string {
    const url = new URL(`https://bitbucket.org/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/src/${commitReference.encodeURI()}/${path.encodePathURI()}`); //#lines-9`;
    if (lines) {
      url.hash = `lines-${lines.start}`;
    }
    return url.toString();
  }

  public getRepositoryPullRequestUrl(organizationId: string, repositoryId: string, pullRequestId: number): string {
    return `https://bitbucket.org/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/pull-requests/${pullRequestId}`;
  }

  public getRepositoryPullRequestCommentUrl(organizationId: string, repositoryId: string, pullRequestId: number, commentId: string): string {
    return `https://bitbucket.org/${organizationId.encodeURI()}/${repositoryId.encodeURI()}/pull-requests/${pullRequestId}#comment-${commentId.encodeURI()}`;
  }

  public getUserUrl(_organizationId: string, userId: string): string {
    return `https://bitbucket.org/${userId.encodeURI()}/`;
  }

  public getRepositoryId(_projectId: string | undefined, repoId: string): string {
    return repoId;
  }
}
