import "../../../../shared/src/polyfills";
import { LegacyAdoApi } from "../../../../shared/src/ado/api/legacy-ado-api";
import { AdoUrlGenerator } from "./ado-url-generator";
import { AdoRepositoryIdentifier } from "./api/ado-repository-identifier";

const defaultBaseURL = "https://dev.azure.com";

// TODO merge with AdoUrlGenerator
/**
 * @deprecated use AdoUrlGenerator instead
 */
export class ADOUrls {
  private static readonly urlGenerator = new AdoUrlGenerator();

  /**
   * @deprecated use AdoUrlGenerator.getOrganizationUrl instead
   */
  public static organizationUrl(orgName: string, baseUrl = defaultBaseURL): string {
    return this.urlGenerator.getOrganizationUrl(orgName);
  }

  /**
   * @deprecated use AdoUrlGenerator.getProjectUrl instead
   */
  public static projectUrl(orgName: string, projectName: string, baseUrl = defaultBaseURL): string {
    return this.urlGenerator.getProjectUrl(orgName, projectName);
  }

  /**
   * @deprecated use AdoUrlGenerator.getRepositoryUrl instead
   */
  public static repositoryUrl(orgName: string, projectName: string, repoName: string, baseUrl = defaultBaseURL): string {
    const repositoryId = AdoRepositoryIdentifier.getId(projectName, repoName);
    return this.urlGenerator.getRepositoryUrl(orgName, repositoryId);
  }

  /**
   * @deprecated use AdoUrlGenerator.getRepositoryBranchUrl instead
   */
  public static branchUrl(orgName: string, projectName: string, repoName: string, branchName: string, baseUrl = defaultBaseURL): string {
    const repositoryId = AdoRepositoryIdentifier.getId(projectName, repoName);
    return this.urlGenerator.getRepositoryBranchUrl(orgName, repositoryId, branchName);
  }

  /**
   * @deprecated use AdoUrlGenerator.getRepositoryBranchFileUrl instead
   */
  public static fileUrl(orgName: string, projectName: string, repoName: string, branchName: string, path: string, baseUrl = defaultBaseURL): string {
    const repositoryId = AdoRepositoryIdentifier.getId(projectName, repoName);
    return this.urlGenerator.getRepositoryBranchFileUrl(orgName, repositoryId, branchName, path);
  }

  /**
   * @deprecated use AdoUrlGenerator.getRepositoryBranchFileUrl instead
   */
  public static lineUrl(orgName: string, projectName: string, repoName: string, branchName: string, path: string, lineNumber: number, baseUrl = defaultBaseURL): string {
    const repositoryId = AdoRepositoryIdentifier.getId(projectName, repoName);
    return this.urlGenerator.getRepositoryBranchFileUrl(orgName, repositoryId, branchName, path, {start: lineNumber});
  }

  /**
   * @deprecated use AdoUrlGenerator.getUserUrl instead
   */
  public static generateUserURL(orgName: string, baseUrl = defaultBaseURL) {
    return this.urlGenerator.getUserUrl(orgName, "");
  }

  public static generateGroupsURL(orgName: string, descriptor: string, baseUrl = defaultBaseURL) {
    return `${this.organizationUrl(orgName, baseUrl)}/_settings/groups?subjectDescriptor=${descriptor.encodeURI()}`;
  }

  public static generateRepoPermissionsURL(orgName: string, projectName: string, repoId: string, baseUrl = defaultBaseURL) {
    return `${this.projectUrl(orgName, projectName, baseUrl)}/_settings/repositories?repo=${repoId.encodeURI()}&_a=permissionsMid`;
  }

  public static generateBranchPermissionsURL(orgName: string, projectName: string, repoId: string, branchName: string, baseUrl = defaultBaseURL) {
    return `${this.projectUrl(orgName, projectName, baseUrl)}/_settings/repositories?repo=${repoId}&_a=permissionsMid&refs=refs/heads/${branchName.encodeURI()}`;
  }

  public static generateBranchPolicyURL(orgName: string, projectName: string, repoId: string, branchName: string, baseUrl = defaultBaseURL) {
    return `${this.projectUrl(orgName, projectName, baseUrl)}/_settings/repositories?_a=policiesMid&repo=${repoId.encodeURI()}&refs=refs/heads/${branchName.encodeURI()}`;
  }

  /**
   * instead of having a separate field for project, in any place it's called "projectAndRepo" use this function to set the value
   * @param project
   * @param repo
   */
  public static getProjectAndRepo(project: string | undefined, repo: string) {
    return project ? `${project}/${repo.encodeURI()}` : repo.encodeURI();
  }

  public static async getCloneURL(api: LegacyAdoApi, orgName: string, projectName: string, repoName: string): Promise<string> {
    const auth = await api.getFreshAuth();
    return `https://${auth.accessToken}@dev.azure.com/${orgName.encodeURI()}/${projectName.encodeURI()}/_git/${repoName.encodeURI()}`;
  }

  /**
   * @deprecated use AdoUrlGenerator.getRepositoryCommitUrl instead
   */
  public static commitUrl(orgName: string, projectName: string, repoName: string, commitId: string, baseUrl = defaultBaseURL): string {
    const repositoryId = AdoRepositoryIdentifier.getId(projectName, repoName);
    return this.urlGenerator.getRepositoryCommitUrl(orgName, repositoryId, commitId);
  }

  /**
   * @deprecated use AdoUrlGenerator.getRepositoryPullRequestUrl instead
   */
  public static pullRequestUrl(orgName: string, projectName: string, repoName: string, pullRequestId: number, baseUrl = defaultBaseURL): string {
    const repositoryId = AdoRepositoryIdentifier.getId(projectName, repoName);
    return this.urlGenerator.getRepositoryPullRequestUrl(orgName, repositoryId, pullRequestId);
  }

  /**
   * @deprecated use AdoUrlGenerator.getRepositoryPullRequestCommentUrl instead
   */
  public static pullRequestCommentUrl(orgName: string, projectName: string, repoName: string, pullRequestId: number, commentCreatedDate: Date, baseUrl = defaultBaseURL): string {
    const repositoryId = AdoRepositoryIdentifier.getId(projectName, repoName);
    const commentCreatedInMilli = commentCreatedDate.getTime();
    // commentId is the first 10 digits of the comment created date in milliseconds
    const commentId = commentCreatedInMilli.toString().substring(0, 10);
    return this.urlGenerator.getRepositoryPullRequestCommentUrl(orgName, repositoryId, pullRequestId, commentId);
  }

  public static serviceHooksUrl(orgName: string, projectName: string) {
    return `${this.projectUrl(orgName, projectName)}/_settings/serviceHooks`;
  }

  /**
   * @deprecated use AdoUrlGenerator.getRepositoryCommitFileUrl instead
   */
  public static commitLineUrl(orgName: string, projectName: string, repoName: string, commitHash: string, path: string, commitLineNumber: number, baseUrl = defaultBaseURL) {
    if (Number.isNaN(commitLineNumber)) {
      throw new Error(`commitLineNumber must be a number`);
    }
    if (!path.startsWith("/")) {
      path = `/${path}`;
    }
    const repositoryId = AdoRepositoryIdentifier.getId(projectName, repoName);
    return this.urlGenerator.getRepositoryCommitFileUrl(orgName, repositoryId, commitHash, path, {start: commitLineNumber});
  }
}
