import { getResourcePrefix } from "../../dashboard/dashboard-response";
import { ScmIntegrationType } from "../../dynamo";
import { IntegrationAttributes } from "../../dynamo/integration/integration-attributes";
import { ADOUrls } from "../../../../packages/scm/ado/src/ado-urls";

//TODO: find all interfaces that have these properties and move them to this common interface
// e.g. GitLinkInfo
// e.g. GitResourceMetadata
// e.g. CommonRepoInfo
export interface GitResourceBase {
  /**
   * TODO: refactor rename to org
   */
  integrationOrgId: string;
  integrationType: ScmIntegrationType;
  baseUrl?: string | undefined;
  repoId: string;
  repo: string;
  projectId?: string | undefined;
  project?: string | undefined;
  branch?: string | undefined;
  targetBranch?: string | undefined;
}

export const GitResourceBase = {
  /**
   * Convert a GitResourceBase to a human-readable string
   * @note for logs only
   * @param grb the Git Resource to convert to a human-readable string
   */
  stringify(grb: Pick<GitResourceBase, "integrationType" | "integrationOrgId" | "project" | "repo" | "branch">): string {
    return `${grb.integrationType}/${grb.integrationOrgId}/${ADOUrls.getProjectAndRepo(grb.project, grb.repo)}/${grb.branch ?? ""}`;
  },

  /**
   * Convert a GitResourceBase to a human-readable string
   * @param grb
   */
  getOrgProjectRepo(grb: Pick<GitResourceBase, "integrationOrgId" | "project" | "repo">): string {
    return [grb.integrationOrgId, grb.project, grb.repo]
      .map((r) => r?.encodeURI())
      .nonNullable()
      .join("/");
  },

  /**
   * Convert a GitResourceBase to a machine friendly unique string (e.g. for hashes etc) that is based on IDs not display names
   * @param grb
   */
  getOrgProjectIdRepoId(grb: Pick<GitResourceBase, "integrationOrgId" | "projectId" | "repoId">): string {
    return [grb.integrationOrgId, grb.projectId, grb.repoId]
      .map((r) => r?.encodeURI())
      .nonNullable()
      .join("/");
  },

  /**
   * TODO: support bitbucket
   * parse a string into a GitResourceBase
   */
  parse(resource: string): Omit<GitResourceBase, "baseUrl"> | undefined {
    const branchIndex = resource.lastIndexOf("/");
    const branch = resource.slice(branchIndex + 1) || undefined;
    const withoutBranch = resource.slice(0, branchIndex);

    const parts = withoutBranch.split("/");

    if (!Array.hasLengthAtLeast(parts, 3) || parts.length > 4) {
      return undefined;
    }

    const integrationType = parts[0] as ScmIntegrationType;
    const integrationOrgId = parts[1];
    let project: string | undefined;
    let repo: string;

    if (Array.hasLengthAtLeast(parts, 4)) {
      // Project and repo
      project = parts[2];
      repo = parts[3];
    } else {
      // No project, only repo
      repo = parts[2];
    }

    return {
      integrationType,
      integrationOrgId,
      project,
      //TODO: is that right?
      projectId: project,
      repo,
      //TODO: is that right?
      repoId: repo,
      branch
    };
  },
  /**
   * for sorting. Currently identical to stringify, but used for different purposes
   * @param grb
   * @returns
   */
  sortKey(grb: Pick<GitResourceBase, "integrationType" | "integrationOrgId" | "project" | "repo" | "branch">): string {
    return `${grb.integrationType}/${grb.integrationOrgId}/${ADOUrls.getProjectAndRepo(grb.project, grb.repo)}/${grb.branch ?? ""}`;
  },
  is(obj: unknown): obj is GitResourceBase {
    const obj1 = obj as GitResourceBase;
    return !!obj1 && typeof obj1 === "object" && !!obj1.integrationOrgId && !!obj1.integrationType && !!obj1.repo;
  },
  /**
   * a resource ID used as part of the DB migration to identify a resource.
   * One change is that the number of /'s is fixed, so a resource without a project will still have 4 /'s, e.g. github/org//repo
   * @param grb
   */
  toDBResourceId(grb: GitResourceBase): string {
    return getResourcePrefix({
      integrationType: grb.integrationType,
      orgId: grb.integrationOrgId,
      project: grb.project,
      repo: grb.repo
    });
  },
  /**
   * picks only the GitResource base fields from an interface that implements GitResourceBase
   * @param grb an object that implements GitResourceBase
   */
  pick(grb: GitResourceBase): GitResourceBase {
    return {
      integrationType: grb.integrationType,
      integrationOrgId: grb.integrationOrgId,
      project: grb.project,
      projectId: grb.projectId,
      repo: grb.repo,
      repoId: grb.repoId,
      branch: grb.branch,
      baseUrl: grb.baseUrl
    } as const satisfies GitResourceBase;
  },
  toDisplayString(grb: GitResourceBase): string {
    return `${IntegrationAttributes[grb.integrationType].displayName}: ${[GitResourceBase.getOrgProjectRepo(grb), grb.branch].nonNullable().join("#")}`;
  }
};

export const GitResourceBaseWithBranch = {
  is(obj: unknown): obj is GitResourceBaseWithBranch {
    return GitResourceBase.is(obj) && !!obj.branch;
  },
  pick(grb: GitResourceBaseWithBranch): GitResourceBaseWithBranch {
    return Object.pick(grb, "integrationType", "integrationOrgId", "project", "projectId", "repo", "repoId", "branch", "baseUrl");
  },
  toDisplayString(grb: GitResourceBase): string {
    return `${IntegrationAttributes[grb.integrationType].displayName}: ${GitResourceBase.getOrgProjectRepo(grb)}#${grb.branch}`;
  }
};

export const GitResourceBaseWithBranchAndDefaultBranch = {
  is(obj: unknown): obj is GitResourceBaseWithBranchAndDefaultBranch {
    return GitResourceBaseWithBranch.is(obj) && "defaultBranch" in obj;
  },
  pick(grb: GitResourceBaseWithBranchAndDefaultBranch): GitResourceBaseWithBranchAndDefaultBranch {
    return Object.pick(grb, "integrationType", "integrationOrgId", "project", "projectId", "repo", "repoId", "branch", "defaultBranch", "baseUrl");
  }
};

export const GitResourceBaseWithDefaultBranch = {
  is(obj: unknown): obj is GitResourceBaseWithDefaultBranch {
    return GitResourceBase.is(obj) && "defaultBranch" in obj;
  },
  pick(grb: GitResourceBaseWithDefaultBranch): GitResourceBaseWithDefaultBranch {
    return Object.pick(grb, "integrationType", "integrationOrgId", "project", "projectId", "repo", "repoId", "defaultBranch", "baseUrl");
  }
};

export type DefaultBranchInfo = { defaultBranch: string | undefined };
export type RequiredDefaultBranchInfo = { defaultBranch: string };

//TODO: make interface
//TODO: defaultBranch is optional but branch is required, the type names don't reflect this
export type GitResourceBaseWithDefaultBranch = GitResourceBase & DefaultBranchInfo;
export type GitResourceBaseWithRequiredDefaultBranch = GitResourceBase & RequiredDefaultBranchInfo;
export type GitResourceBaseWithBranch = GitResourceBase & RequiredPick<GitResourceBase, "branch">;
export type GitResourceBaseWithTargetBranch = GitResourceBase & RequiredPick<GitResourceBase, "branch" | "targetBranch">;
export type GitResourceBaseWithBranchAndDefaultBranch = GitResourceBaseWithBranch & DefaultBranchInfo;
export type RepoReportCommonInfoItem = GitResourceBaseWithDefaultBranch & { hasBranchProtection?: boolean; recentPRCount?: number; public?: boolean };
export type RepoReportCommonInfoItemWithBranch = GitResourceBaseWithBranchAndDefaultBranch & { hasBranchProtection?: boolean; recentPRCount?: number; public?: boolean };
