import { ApiBase } from "./api-base";
import { AxiosRequestConfig } from "axios";
import { RepoFilter, RepoFilterWithCounts, RepoInventoryItem, RepoInventoryItemDetails, RepoSort } from "$/inventory-v2/repo/repo";
import { UIRepoInventoryItem } from "@/pages/inventory-v2/repos-table/ui-repo-inventory-item";
import { UpdateRepo } from "$/inventory-v2/repo/update-repo";
import { BusinessImportanceLabel } from "$/business-importance";

const ENABLE_SEARCH_VERB = !!new URLSearchParams(window.location.search).get("searchVerb");

interface GetReposOptions {
  page: number;
  pageSize: number;
  filter?: RepoFilter;
  sort?: RepoSort;
  search?: string;
  silent?: boolean;
}

class ReposApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "inventory/repos", name: "ReposApi" });
  }

  public async getAll({ page = 1, pageSize = 20, filter, sort, search, silent = false }: GetReposOptions): Promise<RepoInventoryItem[]> {
    const config: AxiosRequestConfig = {
      method: ENABLE_SEARCH_VERB ? "SEARCH" : "POST",
      silent,
      params: {
        page,
        pageSize
      },
      data: { filter, sort, search }
    };

    const res = await this.client.request<RepoInventoryItem[]>(config);
    return res.data ?? null;
  }

  public async getCounts(filter?: RepoFilter, search?: string, silent = false): Promise<number> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<number>("/counts", { filter, search }, config);
    return res.data ?? null;
  }

  public async getFilterCounts(
    filter?: RepoFilter,
    search?: string,
    column?: RepoFilterWithCounts,
    silent = false
  ): Promise<PartialRecord<keyof RepoFilter, Record<string, number>> | null> {
    const config: AxiosRequestConfig = {
      silent
    };

    const res = await this.client.post<PartialRecord<keyof RepoFilter, Record<string, number>> | null>("/filter-counts", { filter, search, column }, config);
    return res.data ?? null;
  }

  /**
   * request for this endpoint
   *
   *   @RequiredScopes(Scope.report_ro)
   *   @RequiredScopes(Scope.inventory_ro)
   *   @Get("details")
   *   public async getDetails(
   *     @RequestOrg() org: Organization,
   *     @Query("integrationType") integrationType: string,
   *     @Query("orgId") orgId: string,
   *     @Query("projectId") projectId: string | undefined,
   *     @Query("repoId") repoId: string
   *   ): Promise<RepoInventoryItemDetails | null> {
   *     try {
   *       const details = await this.repos.getDetails(org.id, integrationType, orgId, repoId, projectId);
   *       return details;
   *     } catch (e) {
   *       this.logger.error(`getDetails: ${Error.stringify(e)}`);
   *       throw new InternalServerErrorException();
   *     }
   *   }
   *
   */

  public async getDetails(item: UIRepoInventoryItem): Promise<RepoInventoryItemDetails | null> {
    const config: AxiosRequestConfig = {
      silent: false
    };

    try {
      const res = await this.client.get<RepoInventoryItemDetails | null>(`/${item.uuid.encodeURI()}`, config);
      return res.data ?? null;
    } catch (e) {
      console.error(`getDetails: ${e}`);
      throw e;
    }
  }

  public async updateRepository(item: UIRepoInventoryItem, importance: BusinessImportanceLabel): Promise<RepoInventoryItemDetails | null> {
    const body: UpdateRepo = {
      businessImportance: importance
    };
    try {
      await this.client.patch<RepoInventoryItemDetails | null>(`/${item.uuid.encodeURI()}`, body);
    } catch (e) {
      console.error(`updateRepository: ${e}`);
      throw e;
    }
  }
}

export const Repos = new ReposApi();
