import { RouteConfig } from "vue-router";
import { Scope } from "@/interfaces/scope";
import { FlagCheck } from "@/plugins/route-guards/sidebar-routes";

export const productsPageRoutes: RouteConfig[] = [
  {
    name: "products",
    path: "products",
    component: () => import(/* webpackChunkName: "inventory-details" */ "@/pages/products-v2/products-table.vue"),
    meta: {
      scopes: [Scope.inventory_ro],
      sidebar: FlagCheck.productsV2.front.isTrue,
      title: "Products v2",
      frontIcon: {
        icon: "mdi-beta",
        tooltip: "Products v2"
      },
      icon: "mdi-book-multiple-outline",
      scmDropDown: false
    }
  }
];
