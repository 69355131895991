import { TrivyCycloneDx } from "../../../../../sbom/interfaces/sbom-report-item";
import { PolicyItemType } from "../../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../../policy-condition";

declare module "../../policy-condition" {
  enum PolicyConditionSubType {
    package_age = "package_age"
  }
}

(PolicyConditionSubType as { package_age: "package_age" }).package_age = "package_age";

export interface PolicyConditionFindingPackageAge extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.package_age;
  /** Package Age days minimum (inclusive) */
  min?: number;
  /** Package Age days maximum (inclusive) */
  max?: number;
}

export const PolicyConditionFindingPackageAge = {
  TYPES: TrivyCycloneDx.PackageType,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.package_age
  }
} as const;
