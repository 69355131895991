import Vue from "vue";
import { CreateAPIKeyRequest } from "$/interfaces/ui-api/request/create-api-key-request";
import { CreateAPIKeysResponse } from "$/interfaces/ui-api/response/create-api-key-response";
import { GetAPIKeysResponse } from "$/interfaces/ui-api/response/get-api-keys-response";
import { APIKeys as API } from "@/api";

export const APIKeys = new (class APIKeysState {
  public apiKeys: GetAPIKeysResponse[] | null = null;

  public constructor() {
    Vue.observable(this);
  }

  public async init() {
    this.apiKeys = await API.getAPIKeys();
  }

  public async createAPIKey(createAPIKeyRequest: CreateAPIKeyRequest): Promise<CreateAPIKeysResponse> {
    return await API.createAPIKey(createAPIKeyRequest);
  }
  public async deleteAPIKey(id: string) {
    return await API.delete(id);
  }
})();
