import { installGuards } from "./route-guards";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Router, { RawLocation, Route, RouteConfig } from "vue-router";
import { pageRoutes } from "@/pages/routes";

declare module "vue-router/types/router" {
  export interface Location {
    ignoreRedirectError?: boolean;
  }

  interface History {
    router: Router;
    base: string;
    current: Route;
    pending?: Route;
    cb: (r: Route) => void;
    ready: boolean;
    readyCbs: Function[];
    readyErrorCbs: Function[];
    errorCbs: Function[];
    listeners: Function[];
    cleanupListeners: Function;
  }

  export interface VueRouter {
    readonly history: History;
  }
}

async function ignoreRedirects(this: Router, navigation: Promise<Route>, location: RawLocation): Promise<Route> {
  try {
    const route = await navigation;
    return route;
  } catch (e) {
    if (Router.isNavigationFailure(e, Router.NavigationFailureType.redirected)) {
      return this.history.pending ?? this.currentRoute;
    }
    debugger;
    throw e;
  }
}

function withRedirectFactory<T extends "push" | "replace">(method: Router[T]): Router[T] {
  return function (this: Router, location: RawLocation, onComplete?: (error?: any) => void, onAbort?: (error?: any) => void) {
    const navigation = method.call(this, location, onComplete, onAbort) as Promise<Route> | void;
    if (typeof location === "string" || !location?.ignoreRedirectError || !navigation) {
      // ignores case where method was used with callbacks
      return navigation as Promise<Route>;
    }
    return ignoreRedirects.call(this, navigation, location);
  };
}

Router.prototype.push = withRedirectFactory(Router.prototype.push);
Router.prototype.replace = withRedirectFactory(Router.prototype.replace);

Vue.use(Router);

const routes: RouteConfig[] = [
  ...pageRoutes,
  {
    path: "*",
    name: "fallback",
    redirect: "/"
  }
];

export const router = new Router({
  mode: "hash",
  scrollBehavior(_to, _from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
  routes
});

Vue.observable(router);

// see: https://class-component.vuejs.org/guide/additional-hooks.html#additional-hooks
Component.registerHooks(["beforeRouteEnter", "beforeRouteLeave", "beforeRouteUpdate"]);

installGuards(router);

export default router;
