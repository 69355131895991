import { AssetOwnerData } from "../interfaces/asset-owner-data";
import {
  BASE_BUSINESS_IMPORTANCE_SCORE,
  BusinessImportanceLabel,
  BusinessImportanceLabelPriorities,
  BusinessImportanceParams,
  BusinessImportanceReportItem,
  InfrastructureFileInfoReport
} from "$/business-importance";
import { AutomaticRepoOwnersReason, Group, GroupResource, generateResourceIdFor, ScmIntegrationType } from "$/dynamo";
import { UserInfo } from "$/dynamo/findingBase";
import { IntegrationAttributes } from "$/dynamo/integration/integration-attributes";
import { ScmIntegrationAttributes } from "$/dynamo/integration/integration-attributes/scm-integration-attributes";
import { SCMType } from "$/scm-type";
import type { MenuItem } from "@/components/table-items/menu-item";
import { businessImportanceColor } from "@/filters/business-importance-filter";
import * as State from "@/state";
import { ObservableSet } from "@/utility-types/observable-set";

export const BUSINESS_IMPORTANCE_ICON_MAP = {
  2: "mdi-chevron-up-box",
  1: "mdi-equal-box",
  0: "mdi-chevron-down-box",
  "-1": "mdi-minus-box-outline"
} as const;

const REASON_USER_PRODUCT_OWNER = {
  ACTIVE_ADMINS: "actively administrating this repository",
  FREQUENT_PR_REVIEWERS: "frequently reviewing pull requests",
  FREQUENT_COMMITTERS: "frequently contributing code",
  MANUAL: "a manual assignment in the product owners page"
} as const;

export abstract class GroupsModelBase<T extends SCMType> {
  public businessImportanceResourceMap = new Map<string, BusinessImportanceReportItem>();
  protected readonly state: typeof State = State;

  public abstract get scmType(): T;

  public abstract assets: Map<string, GroupResource>;

  public abstract getAllGroupUserIds(): string[];

  /**
   * Returns all orgs
   */
  public abstract getOrgs(): string[];

  /**
   * Returns all the projects that are a part of the "org" in the resource
   * @param resource
   */
  public abstract getProjects(resource: GroupResource): string[];

  /**
   * Returns all the repos that are a part of the "org" and "project"(if applicable) in the resource
   * @param resource
   */
  public abstract getRepos(resource: GroupResource): string[];

  public assetOwnersMap: Map<string, AssetOwnerData> | null = null;

  public getImportanceIcon(importance: 2 | 1 | 0 | -1): string {
    return BUSINESS_IMPORTANCE_ICON_MAP[importance];
  }

  public get items(): Group<T>[] {
    return (
      this.state.Groups.get(this.scmType)
        ?.filter((g) => this.filterGroup(g))
        .map((g) => this.augmentImportance(g)) ?? []
    );
  }

  public filterGroup(group: Group<T>): boolean {
    if (this.userFilters.size === 0) {
      return true;
    }
    return group.owners.some((owner) => this.userFilters.has(owner.id));
  }

  public abstract get loading(): boolean;

  public abstract resourceText(item: Group<T>): string;

  public resourceId(item: GroupResource): string {
    return generateResourceIdFor.groupResource(item);
  }

  public abstract getUserLatestActivityTime(owner: string): number | null;

  public abstract displayName(groupUserId: string): string;

  public abstract getDescription(groupUserId: string): string;

  public abstract ownerMenuItems(id: string): MenuItem[];

  public abstract getUserAvatarUrl(id: string): string | null;

  public abstract getUserAccountUrl(id: string): string;

  public abstract userExists(userInfo: UserInfo): boolean;

  public userFilters: ObservableSet<string> = new ObservableSet();

  public get orgHeaderName(): string {
    return "Organization";
  }

  public get integrationType(): ScmIntegrationType {
    const integrationType = IntegrationAttributes.get(this.scmType).type;
    if (!integrationType) {
      throw new Error("Invalid integration type");
    }
    return integrationType;
  }

  public get projectsAreSupported(): boolean {
    return IntegrationAttributes[this.integrationType].project.isSupported;
  }

  public get projectIsRequired(): boolean {
    return IntegrationAttributes[this.integrationType].project.isRequired;
  }

  public get userFiltersList(): string[] {
    return [...this.userFilters];
  }

  public addFilter(user: string): void {
    this.userFilters.add(user);
  }

  public removeFilter(user: string): void {
    this.userFilters.delete(user);
  }

  public static businessImportanceSort(a: BusinessImportanceLabel, b: BusinessImportanceLabel): number {
    const aRank = BusinessImportanceLabelPriorities[a] || 0;
    const bRank = BusinessImportanceLabelPriorities[b] || 0;
    return aRank - bRank;
  }

  protected generateAssetOwnersMap(): Map<string, AssetOwnerData> {
    const currMap = new Map<string, AssetOwnerData>();
    //gets the highest priority for each org/repo
    for (const item of this.items) {
      const owners = item.owners;
      for (const resource of item.resources) {
        const currResKey = this.resourceId(resource);
        const currImportance = this.getResourceImportance(resource);
        if (currImportance) {
          const existingMapItem = currMap.get(currResKey)?.importance;
          if (!existingMapItem || BusinessImportanceLabelPriorities[currImportance] > BusinessImportanceLabelPriorities[existingMapItem]) {
            currMap.set(currResKey, { importance: currImportance, contributors: owners, assetName: item.name });
          }
        }
      }
    }
    return currMap;
  }

  public getResourceImportance(resource: GroupResource): BusinessImportanceLabel | null {
    const importance = resource.importance ?? this.businessImportanceResourceMap.get(this.resourceId(resource))?.businessImportance.label;
    if (resource.repo) {
      return importance ?? "low";
    }
    return importance ?? null;
  }

  public getResourceImportanceScore(resource: GroupResource): number {
    return this.businessImportanceResourceMap.get(this.resourceId(resource))?.businessImportance.score || BASE_BUSINESS_IMPORTANCE_SCORE;
  }

  public getResourceImportanceItem(resource: GroupResource): BusinessImportanceReportItem | null {
    return this.businessImportanceResourceMap.get(this.resourceId(resource)) || null;
  }

  public getResourceImportanceParams(resource: GroupResource): BusinessImportanceParams | null {
    return this.businessImportanceResourceMap.get(this.resourceId(resource))?.businessImportance.params || null;
  }

  public getResourceImportanceFiles(resource: GroupResource): InfrastructureFileInfoReport["itemsByTypeById"] | null {
    const reportItem = this.businessImportanceResourceMap.get(this.resourceId(resource))?.infrastructureFiles || null;
    if (!reportItem) {
      return null;
    }
    return reportItem.itemsByTypeById;
  }

  public getGroupImportanceParams(group: Group<T>): BusinessImportanceParams | null {
    const paramsArray: BusinessImportanceParams[] = group.resources.map((res) => this.getResourceImportanceParams(res)).nonNullable();
    return paramsArray.length > 0
      ? paramsArray.reduce((acc, curr) => ({
          iac: acc.iac || curr.iac,
          cicd: acc.cicd || curr.cicd,
          bp: acc.bp || curr.bp,
          prCount: acc.prCount + curr.prCount,
          contributorCount: acc.contributorCount + curr.contributorCount
        }))
      : null;
  }

  public getGroupImportanceFiles(group: Group<T>): InfrastructureFileInfoReport["itemsByTypeById"] | null {
    const reportItems: InfrastructureFileInfoReport["itemsByTypeById"][] = group.resources.map((res) => this.getResourceImportanceFiles(res)).nonNullable();
    const ret: InfrastructureFileInfoReport["itemsByTypeById"] = {} as InfrastructureFileInfoReport["itemsByTypeById"];
    for (const reportItem of reportItems) {
      for (const [type, items] of Object.entries(reportItem)) {
        const typedType = type as keyof InfrastructureFileInfoReport["itemsByTypeById"];
        ret[typedType] = ret[typedType] || {};
        for (const [id, count] of Object.entries(items)) {
          const typedId = id as keyof InfrastructureFileInfoReport["itemsByTypeById"][keyof InfrastructureFileInfoReport["itemsByTypeById"]];
          ret[typedType][typedId] = (ret[typedType][typedId] || 0) + count;
        }
      }
    }
    return ret;
  }

  public async createGroup(name: string): Promise<Group<T>> {
    const arnicaOrgId = this.state.Auth.user?.userInfo?.orgId;
    if (!arnicaOrgId) {
      throw new Error("Could not find arnica organization id");
    }
    const createdGroup = await this.state.Groups.create(this.scmType, name);
    this.augmentImportance(createdGroup);
    const groups = this.state.Groups.groupsByType?.get(this.scmType);
    if (groups) {
      groups.push(createdGroup);
      this.state.Groups.groupsByType?.set(this.scmType, groups);
    }
    return createdGroup;
  }

  public displayReasonUserAdded(reason: AutomaticRepoOwnersReason | null): string {
    switch (reason) {
      case "ACTIVE_ADMINS":
        return "Active Admin";
      case "FREQUENT_PR_REVIEWERS":
        return "PR Reviewer";
      case "FREQUENT_COMMITTERS":
        return "Contributor";
      default:
        return "Manual";
    }
  }

  public reasonUserProductOwner(user: string, reason: AutomaticRepoOwnersReason | "MANUAL"): string {
    return `${user} is classified as a product owner due to ${REASON_USER_PRODUCT_OWNER[reason]}`;
  }

  public displayGroupType(group: Group<T>): string {
    return group.type.capitalize();
  }

  public displayDaysSinceLastAction(days: number | null): number | "N/A" {
    if (days && (days > 90 || days < 0)) {
      days = null;
    }
    return days ?? "N/A";
  }

  protected organizationsText(organizationsCount: number): string {
    const orgLabel = IntegrationAttributes[this.integrationType].organizationLabel;
    return `${organizationsCount} ${orgLabel.toLowerCase()}${organizationsCount === 1 ? "" : "s"}`;
  }

  public lastActivity(ownerId: string): string {
    const activity = this.getUserLatestActivityTime(ownerId);
    const daysAgo = this.displayDaysSinceLastAction(activity);
    if (typeof daysAgo === "number") {
      return daysAgo === 0 ? "Today" : daysAgo === 1 ? "Yesterday" : `${daysAgo} days ago`;
    } else {
      return "N/A";
    }
  }

  protected repositoriesText(repositoriesCount: number): string {
    const repoText = repositoriesCount === 1 ? "repository" : "repositories";
    return `${repositoriesCount} ${repoText}`;
  }

  public getMaxGroupImportanceLevel(groups: Group<T>[]): BusinessImportanceLabel | null {
    const importance = groups
      .map((g) => this.getGroupImportanceLevel(g))
      .nonNullable()
      .maxBy((i) => BusinessImportanceLabelPriorities[i]);
    return importance ?? null;
  }

  public getMaxGroupImportance(groups: Group<T>[]): Group<T> | null {
    const group = groups.maxBy((g) => BusinessImportanceLabelPriorities[this.getGroupImportanceLevel(g) ?? "low"]);
    return group ?? null;
  }

  public getMaxGroupImportanceScore(groups: Group<T>[]): number | null {
    const score = groups
      .map((g) => this.getGroupImportanceScore(g))
      .nonNullable()
      .maxBy((s) => s);
    return score ?? null;
  }

  /**
   * Gets the highest importance value of all the assets
   * @param group
   * @protected
   */
  public getGroupImportanceLevel(group: Group<T>): BusinessImportanceLabel {
    const resourceImportance = group.resources.map((resource) => resource.importance ?? this.getResourceImportance(resource)).nonNullable();
    return resourceImportance.maxBy((importance) => BusinessImportanceLabelPriorities[importance]) ?? "low";
  }

  public getGroupImportanceScore(group: Group<T>): number {
    const resourceImportanceScore = group.resources.map((resource) => this.getResourceImportanceScore(resource)).nonNullable();
    return resourceImportanceScore.maxBy((score) => score) || BASE_BUSINESS_IMPORTANCE_SCORE;
  }

  /**
   * @deprecated
   * 1. this can be static
   * 2. better - this can be a function (to use as a filter)
   * 3. better - use class="text-capitalize"
   * @param importance
   */
  public getBusinessImportanceText(importance: BusinessImportanceLabel | null): string {
    return importance?.capitalize() || "Not set";
  }

  /**
   * @deprecated
   * 1. this can be static
   * 2. better - this can be a function (to use as a filter)
   * 3. better - there is already one (businessImportanceColor in business-importance-filter.ts)
   *
   * tl;dr use businessImportanceColor
   *
   * @param importance
   */
  public getBusinessImportanceColor(importance: BusinessImportanceLabel | null): string {
    return businessImportanceColor(importance);
  }

  public async updateGroupName(group: Group<T>, name: string): Promise<void> {
    group.name = name;
    const updated = await this.state.Groups.update(this.scmType, group);
    this.augmentImportance(updated);
  }

  public addOwner(group: Group<T>, ownerId: string): Promise<void> {
    if (group.owners.some((owner) => owner.id === ownerId)) {
      throw new Error("This user is already a part of this group");
    }
    group.owners.push({ id: ownerId });
    return Promise.resolved;
  }

  public removeOwner(group: Group<T>, ownerId: string): Promise<void> {
    const indexOfOwner = group.owners.findIndex((owner) => owner.id === ownerId);
    if (indexOfOwner === -1) {
      throw new Error(`Owner ${ownerId} could not be found in the group`);
    }
    group.owners.splice(indexOfOwner, 1);
    return Promise.resolved;
  }

  public async updateGroupOwners(group: Group<T>): Promise<void> {
    const updated = await this.state.Groups.update(this.scmType, group);
    this.augmentImportance(updated);
    await this.init();
  }

  public addAsset(group: Group<T>, asset: string): void {
    const groupResource = this.assets.get(asset);
    if (!groupResource) {
      throw new Error("Unknown asset attempting to be added");
    }
    const isExistingAsset = group.resources.find((existingAsset) => {
      return this.resourceId(existingAsset) === asset;
    });
    if (isExistingAsset) {
      throw new Error("The asset already exists on this group");
    }
    group.resources.push(groupResource);
  }

  public removeAsset(group: Group<T>, groupResource: GroupResource): Promise<void> {
    const indexOfAsset = group.resources.findIndex((asset) => this.resourceId(asset) === this.resourceId(groupResource));
    if (indexOfAsset === -1) {
      throw new Error(`Asset ${this.resourceId(groupResource)} could not be found in the group`);
    }
    group.resources.splice(indexOfAsset, 1);
    return Promise.resolved;
  }

  public async updateGroupAssets(group: Group<T>): Promise<Group<T>> {
    const result = await this.state.Groups.update(this.scmType, group);
    await this.init();
    return this.augmentImportance(result);
  }

  public async deleteGroup(group: Group<T>): Promise<void> {
    if (
      await this.state.Dialog.confirm(
        "Are you sure you want to remove the manual group?",
        "Arnica will create automatic group(s) and resume management of those groups should you remove this manual group."
      )
    ) {
      await this.state.Groups.delete(this.scmType, group);
    }
    await this.init();
  }

  /**
   *  Creates a map of resource => business importance report item
   * @private
   */
  private async generateBusinessImportanceResourceMap() {
    // if the report is missing, it's returning an empty string, this is a precaution to handle this edge case
    if (!this.state.InventoryReports.businessImportanceReport) {
      console.warn("Business importance report is not an array");
      return;
    }
    const report = await this.state.InventoryReports.businessImportanceReport;
    const businessReportItemsForSCM = report?.filter((item) => IntegrationAttributes[item.integrationType].scmType === this.scmType) ?? [];
    this.businessImportanceResourceMap = businessReportItemsForSCM.mapBy((item) => {
      const resource: GroupResource = {
        org: item.integrationOrgId,
        project: this.projectsAreSupported ? item.project : undefined,
        repo: item.repo
      };
      return this.resourceId(resource);
    });
  }

  protected get inventoryIncomplete(): boolean {
    return !this.state.Assets.hasInventory;
  }

  private augmentImportance(group: Group<T>): Group<T> {
    if (!("importance" in group)) {
      // helps DataTable with sorting of the importance column
      Object.defineProperty(group, "importance", {
        get: () => BusinessImportanceLabelPriorities[this.getGroupImportanceLevel(group) || "low"],
        enumerable: false
      });
    }
    return group;
  }

  /**
   *
   * @param initGroupsState if true, also initializes the groups state. This was added for the dashboard as it needs group models for both SCM types, hence we don't want to init the groups state twice
   */
  public async init(initGroupsState = true): Promise<void> {
    if (!this.state.Auth.user?.userInfo?.orgId) {
      void this.state.Popup.warn("Please create an organization first");
      return;
    }
    try {
      const promises = [];
      if (initGroupsState && !this.state.Groups.groupsByType?.get(this.scmType)) {
        promises.push(this.state.Groups.init());
      }
      await Promise.all(promises);
      this.assetOwnersMap = this.generateAssetOwnersMap();
      await this.generateBusinessImportanceResourceMap();
    } catch (e) {
      console.error(e);
    }
  }
}
