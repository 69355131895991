import { Popup } from "./popup-state";
import Axios from "axios";
import Vue from "vue";
import { CreateGroup, Group, ScmIntegrationType } from "$/dynamo";
import { GitResourceBase } from "$/report/interfaces/git-resource-base";
import { SCMType } from "$/scm-type";
import { Groups as Api } from "@/api";
import {
  customSortAssetImportance,
  customSortGroupImportance
} from "@/pages/inventory/details/groups/components/common/groups-common";
import { ADOGroupsModel } from "@/pages/inventory/details/groups/model/ado-groups-model";
import { GithubGroupsModel } from "@/pages/inventory/details/groups/model/github-groups-model";
import { GroupsModelBase } from "@/pages/inventory/details/groups/model/groups-model-base";
import { UniversalScmGroupsModel } from "@/pages/inventory/details/groups/model/universal-scm-groups-model";
import { InventoryReports } from "@/state/inventory-reports-state";
import { ObservableMap } from "@/utility-types/observable-map";

const GROUP_MODELS = {
  [SCMType.GITHUB]: new GithubGroupsModel(),
  [SCMType.AZURE_DEVOPS]: new ADOGroupsModel(),
  [SCMType.BITBUCKET_SERVER]: new UniversalScmGroupsModel(SCMType.BITBUCKET_SERVER),
  [SCMType.BITBUCKET_CLOUD]: new UniversalScmGroupsModel(SCMType.BITBUCKET_CLOUD),
  [SCMType.GITLAB]: new UniversalScmGroupsModel(SCMType.GITLAB)
} as const satisfies Record<SCMType, GroupsModelBase<keyof typeof SCMType> | null>;

export const Groups = new (class GroupsState {
  public readonly groupsModels = GROUP_MODELS as Record<SCMType, GroupsModelBase<SCMType> | null>;
  public groupsByType = new ObservableMap<SCMType, Group<SCMType>[]>();
  public groupsByResource = new ObservableMap<string, Group<SCMType>[]>();
  private readonly SUPPORTED_SCM_TYPES: SCMType[] = Object.keys(this.groupsModels) as SCMType[];

  public constructor() {
    Vue.observable(this);
  }

  private indexGroups<T extends SCMType>(scmType: T, groups: Group<T>[]): void {
    for (const group of groups) {
      for (const resource of group.resources) {
        const key = GitResourceBase.sortKey({
          integrationType: ScmIntegrationType.fromSCMType(scmType),
          integrationOrgId: resource.org || "",
          project: resource.project || "",
          repo: resource.repo || ""
        });
        this.groupsByResource.getOrSet(key, () => []).push(group);
      }
    }
  }

  //TODO: (question to Ivan) why not init all models by default?
  public async init(initAllModels = false) {
    await Promise.all(
      this.SUPPORTED_SCM_TYPES.map(async (scmType) => {
        const groups = await Api.get(scmType);
        this.groupsByType.set(scmType, groups);
        this.indexGroups(scmType, groups);
      })
    );
    if (initAllModels) {
      InventoryReports.refreshBusinessImportanceReport();
      await Object.values(this.groupsModels)
        .nonNullable()
        .mapAsync((i) => i.init(false));
    }
  }

  public get<T extends SCMType>(scmType: T): Group<T>[] | null {
    return (this.groupsByType.get(scmType) as Group<T>[]) ?? null;
  }

  public async create<T extends SCMType>(scmType: T, name: string): Promise<Group<T>> {
    const createGroup: CreateGroup<T> = {
      name,
      resources: [],
      owners: [],
      scm: scmType,
      type: "manual"
    };
    try {
      return await Api.create(createGroup);
    } catch (e) {
      if (Axios.isAxiosError(e) && e.response?.status === 409) {
        void Popup.error(`A group with the name '${name}' already exists.`);
      } else {
        void Popup.error(`An unknown error occurred. Please try again.`);
      }
      throw e;
    }
  }

  public async update<T extends SCMType>(scmType: T, group: Group<T>): Promise<Group<T>> {
    group.type = "manual";
    return await Api.update(scmType, group.sortKey, group);
  }

  public async delete<T extends SCMType>(scmType: T, group: Group<T>): Promise<void> {
    await Api.delete(scmType, group.sortKey);
  }

  public get getAll(): Group<SCMType>[] {
    const products = this.SUPPORTED_SCM_TYPES.flatMap((scmType) => {
      const groupModel = this.groupsModels[scmType];
      if (!groupModel) {
        return [];
      }
      const groups = this.groupsByType.get(scmType) ?? [];

      for (const group of groups) {
        Vue.set(group, "groupImportance", groupModel.getGroupImportanceLevel(group));
        //group.groupImportance = groupModel.getGroupImportanceLevel(group) ?? undefined;
        group.resources.sort((r1, r2) => {
          return customSortAssetImportance(r2, r1, groupModel);
        });
      }
      return groups;
    }).nonNullable();

    products.sort((a, b) => {
      const modelA = this.groupsModels[a.scm];
      const modelB = this.groupsModels[b.scm];
      if (!modelA || !modelB) {
        console.warn(`No groups model for ${a.scm} or ${b.scm}`);
        return 0;
      }
      return customSortGroupImportance(b, a, modelB, modelA);
    });
    return products;
  }
})();
