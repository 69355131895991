import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
// import "vuetify/dist/vuetify.min.css";
import * as directives from "vuetify/lib/directives";
import "@/assets/scss/theme-dark.scss";
import "@/assets/scss/theme-light.scss";
import { theme } from "@/configs";

declare module "vuetify" {
  export type SelectItem = ValueSelectItem | HeaderSelectItem | DividerSelectItem;
  /**
   * When using objects, will look for a `text`, `value` and `disabled` keys.
   * This can be changed using the `item-text`, `item-value` and `item-disabled` props.
   */
  interface ValueSelectItem {
    text: string | number | object;
    value: string | number | object;
    disabled?: boolean;
  }

  /**
   * Objects that have a `header` property are considered special cases and generate a list header; these items are not selectable.
   */
  interface HeaderSelectItem {
    header: string;
  }

  /**
   * Objects that have a `divider` property are considered special cases and generate a list divider; these items are not selectable.
   */
  interface DividerSelectItem {
    divider: boolean;
  }
}

Vue.use(Vuetify, {
  directives
});

export const vuetify = new Vuetify({
  rtl: false,
  theme: {
    dark: false,
    themes: {
      dark: theme.dark ?? {},
      light: theme.light ?? {}
    }
  }
});

export default vuetify;
