import { Cache } from "../../../shared/src/utility-types";
import { ApiBase } from "./api-base";
import moment from "moment";

class GitleaksApi extends ApiBase {
  private readonly validationCache = new Cache<string, { success: boolean; message?: string }>({ ttlSeconds: moment.duration(2, "minutes").asSeconds() });

  public constructor() {
    super({ pathPrefix: "gitleaks", name: "GitleaksApi" });
  }

  public async validateRegex(regex: string, language: string): Promise<{ success: boolean; message?: string }> {
    const result = await this.validationCache.getOrResolve(`${language}|${regex}`, undefined, async () => {
      try {
        const res = await this.client.post<{ success: boolean; message?: string }>(`validate`, { regex, language });
        return res.data;
      } catch (e) {
        console.error(e);
        return { success: false, message: Error.stringify(e) };
      }
    });
    return result;
  }
}

export const Gitleaks = new GitleaksApi();
