import { CreateGroup, Group, UpdateGroup } from "$/dynamo/group";
import { SCMType } from "$/scm-type";
import { ApiBase } from "@/api/api-base";

class GroupsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "groups", name: "GroupsApi" });
  }

  public async get<T extends SCMType>(scmType: T): Promise<Group<T>[]> {
    const res = await this.client.get<Group<T>[]>(scmType);
    return res.data;
  }

  public async create<T extends SCMType>(newGroup: CreateGroup<T>): Promise<Group<T>> {
    const res = await this.client.post<Group<T>>(newGroup.scm, newGroup);
    return res.data;
  }

  public async update<T extends SCMType>(scmType: T, groupId: string, updatedGroup: UpdateGroup<T>): Promise<Group<T>> {
    const res = await this.client.patch<Group<T>>(`${scmType}/${groupId.encodeURI()}`, updatedGroup);
    return res.data;
  }

  public async delete<T extends SCMType>(scmType: T, groupId: string): Promise<void> {
    await this.client.delete<void>(`${scmType}/${groupId.encodeURI()}`);
  }
}

export const Groups = new GroupsApi();
