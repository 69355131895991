import { GroupsModelBase } from "../../model/groups-model-base";
import { BASE_BUSINESS_IMPORTANCE_SCORE } from "$/business-importance";
import { Group, GroupOwner, GroupResource, ScmIntegrationType } from "$/dynamo";
import { SCMType } from "$/scm-type";
import { Integrations } from "@/state";

export interface GroupItem {
  product: string;
  org: string;
  project: string | undefined;
  repo: string | undefined;
  value: Group<SCMType>;
  type: "Manual" | "Automatic";
}

export const customSortOwners = (model: GroupsModelBase<SCMType>, items: GroupOwner[], index: string[], isDesc: boolean[]): GroupOwner[] => {
  let valueOfA: number | string | null;
  let valueOfB: number | string | null;
  items.sort((a, b) => {
    if (index[0] === "lastActivity") {
      valueOfA = model.getUserLatestActivityTime(a.id);
      valueOfB = model.getUserLatestActivityTime(b.id);
    } else if (index[0] === "reason") {
      valueOfA = a[index[0]] || null;
      valueOfB = b[index[0]] || null;
    } else {
      valueOfA = model.displayName(a.id).toLocaleLowerCase();
      valueOfB = model.displayName(b.id).toLocaleLowerCase();
    }

    if (valueOfA !== 0 && !valueOfA) {
      return !isDesc[0] ? 1 : -1;
    }
    if (valueOfB !== 0 && !valueOfB) {
      return !isDesc[0] ? -1 : 1;
    }
    return valueOfA < valueOfB ? (!isDesc[0] ? -1 : 1) : !isDesc[0] ? 1 : -1;
  });
  return items;
};

export function customSortAssetImportance(a: GroupResource, b: GroupResource, model: GroupsModelBase<SCMType>): 0 | 1 | -1 {
  const aImportance = model.getResourceImportance(a);
  const bImportance = model.getResourceImportance(b);
  const labelDiff = aImportance && bImportance && GroupsModelBase.businessImportanceSort(aImportance, bImportance);

  if (labelDiff) {
    return labelDiff > 0 ? 1 : -1;
  }

  if (a.importance || b.importance) {
    //so ones without importance(and org/project/repo) are moved to bottom
    return a.importance ? -1 : 1;
  }

  const aScore = model.getResourceImportanceScore(a);
  const bScore = model.getResourceImportanceScore(b);
  return aScore < bScore ? -1 : aScore > bScore ? 1 : 0;
}

export function customSortGroupImportance(a: Group<SCMType>, b: Group<SCMType>, modelA: GroupsModelBase<SCMType>, modelB?: GroupsModelBase<SCMType>): 0 | 1 | -1 {
  if (!modelB) {
    modelB = modelA;
  }
  const aLabel = modelA.getGroupImportanceLevel(a);
  const bLabel = modelB.getGroupImportanceLevel(b);
  const labelDiff = GroupsModelBase.businessImportanceSort(aLabel, bLabel);
  if (labelDiff) {
    return labelDiff > 0 ? 1 : -1;
  }

  const aManual = a.resources.some((r) => r.importance);
  const bManual = b.resources.some((r) => r.importance);
  if (aManual !== bManual) {
    return aManual ? 1 : -1;
  }

  const aScore = modelA.getGroupImportanceScore(a);
  const bScore = modelB.getGroupImportanceScore(b);
  if (aScore !== bScore) {
    return aScore > bScore ? 1 : -1;
  }

  const aFiles = modelA.getGroupImportanceFiles(a);
  const bFiles = modelB.getGroupImportanceFiles(b);
  const aIacFilesCount = Object.keys(aFiles?.iac ?? {}).length;
  const bIacFilesCount = Object.keys(bFiles?.iac ?? {}).length;
  if (aIacFilesCount !== bIacFilesCount) {
    return aIacFilesCount > bIacFilesCount ? 1 : -1;
  }

  const aGroupImportanceParams = modelA.getGroupImportanceParams(a);
  const bGroupImportanceParams = modelB.getGroupImportanceParams(b);

  const aHasBP = !!aGroupImportanceParams?.bp;
  const bHasBP = !!bGroupImportanceParams?.bp;
  if (aHasBP !== bHasBP) {
    return aHasBP ? 1 : -1;
  }
  const aPrCount = aGroupImportanceParams?.prCount ?? 0;
  const bPrCount = bGroupImportanceParams?.prCount ?? 0;
  if (aPrCount !== bPrCount) {
    return aPrCount > bPrCount ? 1 : -1;
  }
  const aCicdFiles = aFiles?.cicd;
  const bCicdFiles = bFiles?.cicd;
  const aCicdFilesCount = Object.keys(aCicdFiles ?? {}).length;
  const bCicdFilesCount = Object.keys(bCicdFiles ?? {}).length;
  if (aCicdFilesCount !== bCicdFilesCount) {
    return aCicdFilesCount > bCicdFilesCount ? 1 : -1;
  }
  return b.name.toLowerCase().localeCompare(a.name.toLowerCase()) as 0 | 1 | -1;
}

function compareBy<T>(a: T, b: T, key: keyof T): 0 | 1 | -1 {
  const aValue = a[key as keyof T] ?? null;
  const bValue = b[key as keyof T] ?? null;
  if (!aValue) {
    return -1;
  }
  if (!bValue) {
    return 1;
  }
  return aValue === bValue ? 0 : aValue > bValue ? -1 : 1;
}

function sortColumns<T>(items: T[], sortBy: (keyof T | "importance")[], isDesc: boolean[], importanceCompare: (a: T, b: T) => 0 | 1 | -1): T[] {
  for (let i = sortBy.length - 1; i >= 0; i--) {
    const key = sortBy[i];
    const desc = isDesc[i] ? -1 : 1;
    if (key === "importance") {
      items.sort((a, b) => importanceCompare(a, b) * desc);
    } else if (key) {
      items.sort((a, b) => compareBy(a, b, key) * desc);
    }
  }
  return items;
}

export function customSortAssets(model: GroupsModelBase<SCMType>, items: GroupResource[], sortBy: (keyof GroupResource)[], isDesc: boolean[]): GroupResource[] {
  return sortColumns(items, sortBy, isDesc, (a, b) => customSortAssetImportance(a, b, model));
}

export function customSortGroups(model: GroupsModelBase<SCMType>, items: Group<SCMType>[], sortBy: (keyof Group<SCMType> | "importance")[], isDesc: boolean[]): Group<SCMType>[] {
  return sortColumns(items, sortBy, isDesc, (a, b) => customSortGroupImportance(a, b, model));
}

export function groupResourceName(r?: GroupResource, group?: Group<SCMType>) {
  if (!r) {
    return "";
  }
  const org = group ? Integrations.getOrganizationName(r.org, ScmIntegrationType.fromSCMType(group.scm)) : r.org;
  return [org, r.project, r.repo].filter((r) => r?.length).join("/");
}
