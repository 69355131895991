import { ApiBase } from "./api-base";
import { CommunicationIntegrationType } from "$/dynamo";
import { BotChannel, BotUser } from "$/interfaces/bots";

class BotsApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "bots", name: "BotsApi" });
  }

  public async getChannels(bot: CommunicationIntegrationType, integrationOrgId?: string): Promise<BotChannel[]> {
    const res = await this.client.get<BotChannel[]>(`${bot}/channels`, {
      params: { integrationOrgId: integrationOrgId || "" }
    });
    return res.data;
  }

  public async getUsers(bot: CommunicationIntegrationType, integrationOrgId?: string): Promise<BotUser[]> {
    const res = await this.client.get<BotUser[]>(`${bot}/users`, {
      params: { integrationOrgId: integrationOrgId || "" }
    });
    return res.data;
  }
}

export const Bots = new BotsApi();
