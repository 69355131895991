import { PolicyItemBase, PolicyItemType } from "../policy-item-base";
import { PolicyRule } from "../policy-rule";

declare module "../policy-item-base" {
  interface PolicyComponents {
    [PolicyItemType.policy]: PolicyItem;
  }

  enum PolicyItemType {
    policy = "policy"
  }
}

(PolicyItemType as { policy: "policy" }).policy = "policy";

/**
 * A list of all policy sub-types.
 * @note This enum should be augmented in modules to add new types of policy components.
 */
export enum PolicySubType {}
(PolicySubType as unknown) = {
  /** es-build minification workaround */
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PolicySubTypeMapper {}

export interface PolicyItem<C = unknown> extends PolicyItemBase<PolicyItemType.policy> {
  arnicaOrgId: string;
  sub: PolicySubType;
  rules: PolicyRule[];
  config?: C;
  /**
   * Version of the policy
   * @note
   * - `undefined` - initial
   * - `2` - migrated triggers
   */
  v?: number;
  updatedAt?: string;
  updatedBy?: string;
}

export const PolicyItem = {
  sortProps<C = unknown>(policy: PolicyItem<C>): PolicyItem<C> {
    const { arnicaOrgId, id, rules, sub, type, config, updatedAt, updatedBy, v } = policy;
    return {
      arnicaOrgId,
      sub,
      type,
      id,
      config,
      rules: rules.map((r) => PolicyRule.sortProps(r)),
      updatedAt,
      updatedBy,
      v
    };
  }
};

export type PolicyItemCategory = "Policy";

export class DisabledPolicy implements PolicyItem {
  public readonly id: string = "NoFeatureFlag";
  public readonly rules: PolicyRule[] = [];
  public readonly type = PolicyItemType.policy;

  public constructor(public readonly arnicaOrgId: string, public readonly sub: PolicySubType) {}

  public static is(policy: PolicyItem): policy is DisabledPolicy {
    return policy instanceof DisabledPolicy;
  }
}
