import Router, { Route } from "vue-router";
import { AnalyticsService } from "@/services/analytics-service";

function onRouteChanged(newVal: Route): void {
  AnalyticsService.logEvent(
    `${newVal.fullPath} Page Visited`,
    {
      route: newVal.fullPath,
      routeFrom: newVal.redirectedFrom,
      time: new Date()
    },
    { group: "General" },
    // we do NOT send page visited events to heap and fullstory
    { heap: false, fs: false }
  );
}

export function install(router: Router): Router {
  router.afterEach(onRouteChanged);
  return router;
}
