
<div class="component user-role">
  <div v-if="managedBySso && ssoRoles.length">
    <v-row class="badges-list">
      <v-tooltip bottom v-for="role in ssoRoles" :key="role">
        <template v-slot:activator="{ attrs, on }">
          <v-icon v-on="on" v-bind="attrs" right x-small>{{ toRoleInfo(role).icon }}</v-icon>
        </template>
        <span>{{ toRoleInfo(role).label }}</span>
      </v-tooltip>
    </v-row>
  </div>
  <div v-else>
    <v-icon left x-small>{{ roleInfo.icon }}</v-icon>
    <span>
      {{ roleInfo.label }}
    </span>
  </div>
</div>
