<template>
  <div class="component inline-textbox">
    <v-text-field v-bind="$attrs" :type="type" :class="classes" v-bind:value="value" v-on:input="$emit('input', $event.target.value)" />
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-property-decorator";

@Component({
  inheritAttrs: false
})
export class InlineTextbox extends Vue {
  @Model("input")
  protected value!: string;

  @Prop({ type: String, default: "text" })
  protected type!: string;

  @Prop({ type: Boolean, default: false })
  protected outlined!: boolean;

  @Prop({ type: Boolean, default: true })
  protected centered!: boolean;

  protected get classes(): string {
    return Object.entries({
      "no-spin": this.type === "number",
      "text-align-center": this.centered,
      outlined: this.outlined
    })
      .filter(([, v]) => v)
      .map(([k]) => k)
      .join(" ");
  }
}

export default InlineTextbox;
</script>

<style lang="scss">
.component.inline-textbox {
  display: inline-block;
}
</style>
