
<!-- :key used to force a redraw, see https://stackoverflow.com/questions/63337982/toggle-v-navigation-drawers-expand-on-hover-not-resizing-content -->
<v-navigation-drawer
  class="component layout-component navigation-drawer"
  floating
  :app="$state.Layout.drawer.show"
  permanent
  width="200px"
  v-if="$state.Auth.authenticated"
  v-show="$state.Layout.drawer.show"
  :expand-on-hover="!$state.Layout.drawer.anchored"
  :key="$state.Layout.drawer.anchored"
  :mini-variant.sync="$state.Layout.drawer.mini">
  <div class="d-flex justify-space-around pt-1">
    <arnica-logo :background="$vuetify.theme.dark ? 'dark' : 'light'" :mini="$state.Layout.drawer.mini" class="d-flex" />
  </div>
  <div class="scoped-scroll">
    <v-spacer></v-spacer>

    <v-list dense nav>
      <template v-for="(item, i) in sideBarNavItems">
        <navigation-item :key="`nav-${i}`" :item="item" :root="true"></navigation-item>
        <v-divider :key="`div-${i}`" v-if="i < sideBarNavItems.length - 1"></v-divider>
      </template>
    </v-list>
  </div>
  <v-slide-y-reverse-transition>
    <v-footer fixed class="navbar-footer" v-show="!$state.Layout.drawer.mini">
      <v-btn x-small plain @click.stop="$state.Layout.drawer.togglePinned()">
        <template v-if="$state.Layout.drawer.anchored">
          <v-icon small>mdi-pin-off</v-icon>
          Unpin
        </template>
        <template v-else>
          <v-icon small>mdi-pin</v-icon>
          Pin
        </template>
      </v-btn>
    </v-footer>
  </v-slide-y-reverse-transition>
</v-navigation-drawer>
