import { ScmIntegrationType } from "../../../../dynamo";
import { PolicyItemType } from "../../policy-item-base";
import { PolicyAction, PolicyActionSubType } from "../policy-action";

declare module "../policy-action" {
  enum PolicyActionSubType {
    add_additional_reviewers = "add_additional_reviewers",
    add_additional_reviewers_lookup = "add_additional_reviewers_lookup",
    add_additional_reviewers_static = "add_additional_reviewers_static"
  }
}

(PolicyActionSubType as { add_additional_reviewers: "add_additional_reviewers" }).add_additional_reviewers = "add_additional_reviewers";
(PolicyActionSubType as { add_additional_reviewers_lookup: "add_additional_reviewers_lookup" }).add_additional_reviewers_lookup = "add_additional_reviewers_lookup";
(PolicyActionSubType as { add_additional_reviewers_static: "add_additional_reviewers_static" }).add_additional_reviewers_static = "add_additional_reviewers_static";
interface IntegrationSpecificReviewer {
  /**
   * The integration info if applicable
   * @note Do not specify to perform a full lookup
   */
  integration?: ScmIntegrationType;
}

interface IdentitySpecificReviewer {
  /**
   * Reviewer ID
   */
  id: string;

  /**
   * Reviewer name
   */
  name?: string;
}

interface LookupReviewer<T extends string> {
  lookup: T;
}

interface ReviewerBase<T extends string> {
  type: T;
}

interface GroupReviewer extends ReviewerBase<"group">, IdentitySpecificReviewer, IntegrationSpecificReviewer {}
interface UserReviewer extends ReviewerBase<"user">, IdentitySpecificReviewer, IntegrationSpecificReviewer {}
interface PusherReviewer extends ReviewerBase<"user">, LookupReviewer<"pusher"> {}
interface AuthorReviewer extends ReviewerBase<"user">, LookupReviewer<"author"> {}
interface CommitterReviewer extends ReviewerBase<"user">, LookupReviewer<"committer"> {}
interface ProductReviewer extends ReviewerBase<"user">, LookupReviewer<"product"> {}

export interface PolicyActionAddAdditionalReviewers extends PolicyAction {
  sub: PolicyActionSubType;
  children: (PolicyActionAddAdditionalReviewersLookup | PolicyActionAddAdditionalReviewersStatic)[];
}

export const PolicyActionAddAdditionalReviewers = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.add_additional_reviewers
  }
} as const;

export interface PolicyActionAddAdditionalReviewersLookup extends PolicyAction {
  sub: PolicyActionSubType.add_additional_reviewers_lookup;

  /**
   * The reviewer configuration
   */
  to: PusherReviewer | AuthorReviewer | CommitterReviewer | ProductReviewer | null;
}

export interface PolicyActionAddAdditionalReviewersStatic extends PolicyAction {
  sub: PolicyActionSubType.add_additional_reviewers_static;

  /**
   * The reviewer configuration
   */
  to: GroupReviewer | UserReviewer | null;
}

export type PolicyActionAddAdditionalReviewersType = PolicyActionAddAdditionalReviewersLookup | PolicyActionAddAdditionalReviewersStatic;
