import { SECRET_VALIDATION_RESULTS, SecretsPageTableItems } from "./secrets-constants";
import { VerificationResults } from "$/interfaces/ui-api/response/get-secrets-response";
import { BUSINESS_IMPORTANCE_ICON_MAP } from "@/pages/inventory/details/groups/model/groups-model-base";

export function getVerificationResultName(verificationResult: keyof typeof VerificationResults): string {
  return SECRET_VALIDATION_RESULTS[verificationResult ?? "UNVERIFIED"]?.title ?? "Unknown";
}
export function getVerificationResultIcon(verificationResult: keyof typeof VerificationResults): string {
  return SECRET_VALIDATION_RESULTS[verificationResult ?? "UNVERIFIED"]?.icon ?? "mdi-question";
}
export function getVerificationResultColor(verificationResult: keyof typeof VerificationResults): string {
  return SECRET_VALIDATION_RESULTS[verificationResult ?? "UNVERIFIED"]?.color ?? "mdi-question";
}

export function getImportanceIcon(item: SecretsPageTableItems): string {
  return item.importanceValue ? BUSINESS_IMPORTANCE_ICON_MAP[item.importanceValue] : "";
}
