import { SCMType } from "../scm-type";

export interface IdentityOverrideItemResponseRequest {
  identityId: string;
  name?: string | null;
  namesToRemove: string[];
  email?: string | null;
  scmType: SCMType;
}

export const IdentityOverrideItemResponseRequest = {
  //TODO: move this to the Dto, and move the Dto to shared
  sortKey(scmType: SCMType, identityId: string): string {
    return `${scmType}_totalIdentities_${identityId}`;
  },
  getSortKeyComponents(sortKey: string): { scmType?: SCMType; identityId?: string } {
    const [scmType, identityId] = sortKey.split("_totalIdentities_");
    return { scmType: scmType as SCMType | undefined, identityId };
  }
} as const;
