import { Semgrep } from "../../../../sast/interfaces/semgrep-results";
import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    sast_property = "sast_property"
  }
}

(PolicyConditionSubType as { sast_property: "sast_property" }).sast_property = "sast_property";

export interface PolicyConditionFindingSastProperty extends PolicyCondition {
  type: PolicyItemType.condition;
  sub: PolicyConditionSubType.sast_property;
  /**
   * List of regular expressions to match against the SAST finding's property.
   */
  matchers: string[];
  property: keyof typeof PolicyConditionFindingSastProperty.PROPERTY_EXTRACTORS;
}

/**
 * @deprecated for migration only
 */
interface PolicyConditionFindingSastRules {
  id: string;
  type: PolicyItemType.condition;
  sub: "sast_rules";
  name?: string;
  children?: PolicyCondition[];
  rules: string[];
}

function migrateCondition(condition: PolicyCondition): PolicyCondition {
  if (!condition) {
    return condition;
  }

  if (condition.sub === ("sast_rules" as PolicyConditionSubType)) {
    const { id, rules } = condition as unknown as PolicyConditionFindingSastRules;
    const sastPropCondition: PolicyConditionFindingSastProperty = {
      id,
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.sast_property,
      matchers: rules,
      property: "Rule ID"
    };
    return sastPropCondition;
  }

  if (condition.children?.length) {
    condition.children = condition.children.map((child) => migrateCondition(child));
  }
  return condition;
}

export const PolicyConditionFindingSastProperty = {
  PROPERTY_EXTRACTORS: {
    "Rule ID": (r) => r?.check_id,
    Fingerprint: (r) => r?.extra?.fingerprint,
    Code: (r) => r?.extra?.lines,
    Description: (r) => r?.extra?.message,
    Category: (r) => r?.extra?.metadata?.category,
    "Sub Category": (r) => r?.extra?.metadata?.subcategory?.toString(),
    Confidence: (r) => r?.extra?.metadata?.confidence,
    CWE: (r) => r?.extra?.metadata?.cwe?.toString(),
    OWASP: (r) => r?.extra?.metadata?.owasp?.toString(),
    References: (r) => r?.extra?.metadata?.references?.toString(),
    Source: (r) => r?.extra?.metadata?.source,
    Technology: (r) => r?.extra?.metadata?.technology?.toString(),
    Class: (r) => r?.extra?.metadata?.vulnerability_class?.toString(),
    Origin: (r) => r?.extra?.metadata?.["semgrep.dev"]?.rule?.origin,
    "Rule Version ID": (r) => r?.extra?.metadata?.["semgrep.dev"]?.rule?.version_id
  } as const satisfies Record<string, (result: Semgrep.Result | undefined) => string | undefined>,
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.sast_property
  } as const,
  migrate(policy: import("../../policy-items/policy-item-code-risk").PolicyItemCodeRisk): import("../../policy-items/policy-item-code-risk").PolicyItemCodeRisk {
    if ((policy.v ?? 2) >= 3.2) {
      return policy;
    }
    const rules = policy.rules?.filter((r) => r.condition) || [];
    for (const rule of rules) {
      rule.condition && (rule.condition = migrateCondition(rule.condition));
    }
    policy.v = 3.2;
    return policy;
  }
} as const;
