import Vue from "vue";
import { JobsV2 as JobsV2Api } from "@/api";
import { Flags } from "@/state/flags-state";

export class JobState {
  public constructor(private readonly jobName: string) {}

  public async isComplete(): Promise<boolean> {
    if (!(await this.jobsV2FlagIsEnabled())) {
      return false;
    }

    const completionDate = await JobsV2Api.getLastSettledDate(this.jobName);
    return completionDate !== null;
  }

  private async jobsV2FlagIsEnabled(): Promise<boolean> {
    const flags = await Flags.tryGet("jobsV2");
    return !!flags?.back;
  }
}

class JobsV2State {
  public readonly inventoryScan = new JobState("InventoryScanJob");
  public constructor() {
    Vue.observable(this);
  }
}

export const JobsV2 = new JobsV2State();
