import { ApiBase } from "./api-base";
import { Integrations } from "./integrations-api";
import { AgentIntegrationInitiationRequest } from "$/interfaces/ui-api/request/integrations/integration-initiation-request";

type KeyResponse = {
  apiKey: string;
  org: string;
  name: string;
};

export type AgentIntegrationDetails = Omit<AgentIntegrationInitiationRequest, "code" | "type">;

class AgentsAPI extends ApiBase {
  public constructor() {
    super({ pathPrefix: "agents", name: "AgentsAPI" });
  }

  /**
   * Creates a new Agent integration and returns the API key for the agent
   * @returns A promise that resolves to the API key for the agent
   */
  public async createIntegration(): Promise<string> {
    const code = window.crypto.randomUUID();
    await Integrations.create({
      type: "scanner-agent",
      displayName: "Arnica Scanner Agent",
      code,
    });

    const res = await this.client.get<KeyResponse | null>(`token/${code}`);
    if (!res.data) {
      throw new Error("Failed to get token");
    }
    return res.data.apiKey;
  }
}

export const Agents = new AgentsAPI();
