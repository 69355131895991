import { ApiBase } from "./api-base";
import { GitHubIntegrationCallbackRequestResponse, IntegrationInitiationRequest } from "$/interfaces/ui-api/request/integrations/integration-initiation-request";

class CallbacksApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "callbacks", name: "CallbacksApi" });
  }

  public async register(params: IntegrationInitiationRequest): Promise<GitHubIntegrationCallbackRequestResponse> {
    const res = await this.client.post<GitHubIntegrationCallbackRequestResponse>(params.type, params, { anonymous: true });
    return res.data;
  }
}

export const Callbacks = new CallbacksApi();
