import moment from "moment";
import Vue from "vue";
import { GetNotificationsResponse } from "$/interfaces/ui-api/response/get-notifications-response";
import { Notifications as Api } from "@/api";
import { ScopeDirective } from "@/directives/scope-directive";
import { Scope } from "@/interfaces/scope";
import { Auth } from "@/state/auth-state";
import { Popup } from "./popup-state";
import { TickerSignal } from "../../../shared/src/utility-types/signal";
import { DisposedError } from "../../../shared/src/errors";

const DELAY = moment.duration(1, "minute");

export const Notifications = new (class NotificationState {
  private readonly ticker = new TickerSignal(DELAY);
  private _notifications: GetNotificationsResponse[] = [];

  public constructor() {
    Vue.observable(this);
    void this.poll();
  }

  public get unreadCount(): number {
    return this._notifications.filter((n) => !n.read).length;
  }

  public async init(): Promise<void> {
    await this.reload();
  }

  public get notifications(): GetNotificationsResponse[] {
    return this._notifications;
  }

  public get count(): number {
    return this._notifications.length;
  }

  public setDelay(delay: moment.Duration): void {
    if (!delay.isValid()) {
      throw new Error("Invalid delay");
    }
    this.ticker.updateInterval(delay);
  }

  public resetDelay(): void {
    this.ticker.updateInterval(DELAY);
  }

  public clear(): void {
    this._notifications = [];
  }

  public async reload(): Promise<void> {
    if (!Auth.authenticatedAndEmailVerified || !Auth.user?.registered) {
      return;
    }

    this._notifications = await Api.get(false);
  }

  public async markAllAsRead(): Promise<void> {
    await Api.markAllAsRead();
    await this.reload();
  }

  public async deleteAll(): Promise<void> {
    await Api.deleteAll();
    await this.reload();
  }

  public async delete(notification: GetNotificationsResponse): Promise<void> {
    await Api.delete(notification);
    await this.reload();
  }

  private async poll(): Promise<void> {
    do {
      try {
        await this.ticker.waitOne();
        if (!Auth.authenticatedAndEmailVerified || !ScopeDirective.hasAll(Auth.scopes, [Scope.notifications_ro])) {
          continue;
        }

        const before = this._notifications;
        const after = (this._notifications = await Api.get());
        if (after.filter((n) => !n.read).length > before.filter((n) => !n.read).length) {
          void Popup.info("You have new unread notifications", {
            timeout: Infinity,
            route: "/notifications"
          });
        }
      } catch (error) {
        if (error instanceof DisposedError) {
          return;
        }
        console.error("Notifications.poll", error);
      }
    } while (!this.ticker.disposed);
  }
})();
