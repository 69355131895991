import { PolicyItemType } from "../../policy-item-base";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    secret_types = "secret_types"
  }
}

(PolicyConditionSubType as { secret_types: "secret_types" }).secret_types = "secret_types";

export interface PolicyConditionFindingSecretTypes extends PolicyCondition {
  sub: PolicyConditionSubType.secret_types;
  /** The secret's specific types */
  specificTypes: string[];
}

export const PolicyConditionFindingSecretTypes = {
  component: {
    type: PolicyItemType.condition,
    sub: PolicyConditionSubType.secret_types
  } as const
} as const;
